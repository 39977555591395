export const styles = {
  container: {
    mb: 1.5,
  },

  stack: {
    width: "100%",
  },

  divider: {
    mt: 0.5,
  },
};
