/* eslint-disable react/jsx-props-no-spreading */
import { Box, IconButton, Typography, Stack } from "@mui/material";
import { CloseOutlined, ArrowBack } from "@mui/icons-material";

import { styles } from "./styles";

export default function PanelHeader({ icon, title, close, isPrimaryLight, editRouteMode }: any) {
  return (
    <Box sx={isPrimaryLight ? styles.containerPrimaryLight : styles.containerPrimary}>
      <Stack alignItems="center" direction="row" spacing={1}>
        {editRouteMode ? (
          <IconButton aria-label="delete" color="inherit" size="small" onClick={close}>
            <ArrowBack />
          </IconButton>
        ) : (
          icon
        )}
        <Typography component="h3" variant="subtitle1">
          {title}
        </Typography>
      </Stack>
      <Stack>
        {!editRouteMode && (
          <IconButton aria-label="delete" color="inherit" size="small" onClick={close}>
            <CloseOutlined />
          </IconButton>
        )}
      </Stack>
    </Box>
  );
}
