import React from "react";

import AdvancedMarker from "../AdvancedMarker";
import { getNoaaHazardIcon } from "../Utils/NoaaHazardsIcons";

import HazardPopover from "@/components/MapPopovers/HazardPopover";
import { INOAAHazards } from "@/types/navaids";
import { useAppSelector } from "@/hooks/useRedux";

interface IPrivatePlaceMarker {
  hazard: INOAAHazards;
}

function NoaaHazardMarker({ hazard }: IPrivatePlaceMarker) {
  const { logged } = useAppSelector((state) => state.auth);

  const hazardIcon = getNoaaHazardIcon(hazard);

  if (!hazardIcon) return null;

  return (
    <AdvancedMarker
      key={hazard.id}
      icon={hazardIcon}
      position={{ lat: Number(hazard.lat), lng: Number(hazard.lng) }}
      selected={false}
      onClick={() => null}
    >
      {logged && <HazardPopover hazard={hazard} icon={hazardIcon} />}
    </AdvancedMarker>
  );
}

export default NoaaHazardMarker;
