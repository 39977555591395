import { Box, Grow } from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";

import { styles } from "./styles";

import { ITempPin } from "@/utils/poi";
import PanelHeader from "@/components/PanelHeader/PanelHeader";
import SearchCoordinatesExpanded from "@/components/Search/SearchCoordinatesExpanded";
import { setAllowCoordinates, setTempPinCopy, setTempPin } from "@/store/slices/reportpins";
import { useAppDispatch } from "@/hooks/useRedux";

interface IReportPinCoordinatesPanel {
  isOpen: boolean;
  coordinates?: string;
  tempPin: ITempPin;
  tempPinCopy: ITempPin;
  onClose: () => void;
}

function ReportPinCoordinatesPanel({
  isOpen,
  tempPin,
  tempPinCopy,
  coordinates,
  onClose,
}: IReportPinCoordinatesPanel) {
  const dispatch = useAppDispatch();

  const handleConfirmCoordinates = () => {
    if (tempPin?.location) {
      dispatch(setAllowCoordinates(false));
      dispatch(setTempPinCopy(undefined));
      dispatch(setTempPin({ ...tempPin, location: tempPinCopy?.location }));
    }
  };

  return (
    <Grow unmountOnExit in={isOpen} style={{ transformOrigin: "top center" }} timeout="auto">
      <Box sx={styles.container}>
        <PanelHeader
          isPrimaryLight
          close={onClose}
          icon={<MyLocationIcon sx={styles.headerIcon} />}
          title="Edit Coordinates"
        />
        <SearchCoordinatesExpanded
          coordinates={coordinates}
          onConfirmCoordinates={handleConfirmCoordinates}
        />
      </Box>
    </Grow>
  );
}

export default ReportPinCoordinatesPanel;
