import { Box } from "@mui/material";

import { styles } from "./styles";

import SearchResults from "@/components/Search/SearchResults";

interface IPoiListDrawer {
  categorySelected: boolean;
}
function PoiListDrawer({ categorySelected }: IPoiListDrawer) {
  return (
    <Box id="scrollableDiv" sx={styles(categorySelected).scrollContainer}>
      <SearchResults />
    </Box>
  );
}

export default PoiListDrawer;
