import { CardMedia, Button, Stack } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import Modal from "@mui/material/Modal";

import { styles } from "./styles";

interface IPhotoModal {
  open: boolean;
  url: string;
  onClose: () => void;
}

function PhotoModal({ open, url, onClose }: IPhotoModal) {
  return (
    <Modal
      aria-describedby="modal-modal-description"
      aria-labelledby="modal-modal-title"
      open={open}
      onClose={onClose}
    >
      <Stack alignItems="center" direction="row" justifyContent="center" sx={styles.modalContainer}>
        <Button
          aria-label="delete"
          className="closeButton"
          color="whiteBg"
          size="small"
          sx={styles.closeButton}
          variant="iconButtonContained"
          onClick={onClose}
        >
          <CloseOutlined sx={{ fontSize: 20 }} />
        </Button>

        <CardMedia
          alt="Voyage Image"
          component="img"
          image={`${url}` || "/img/placeholders/Image.png"}
          sx={styles.cardMedia}
        />
      </Stack>
    </Modal>
  );
}

export default PhotoModal;
