import { layoutStyles } from "@/themes/layoutStyles";

export const styles = {
  searchCategoriesOnMap: {
    position: "absolute",
    top: 0,
    left: 488,
    m: 2,
    ml: 1.5,
    zIndex: 7,
  },

  scrollContainer: {
    ...layoutStyles.scroll,

    overflowY: "auto",
    maxHeight: "calc(100vh - 235px)",
    height: "100%",
    "& .MuiAccordionSummary-root:first-of-type": {
      borderTop: "none",
    },
  },
};
