import axios from "./config";

export default {
  getVoyages: (params: any) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v3+json" },
      method: "get",
      params,
      url: "/voyages",
    }),
  getVoyageById: (id: number) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v2+json" },
      method: "get",
      url: `/voyages/${id}`,
    }),
  deleteVoyage: (voyageId: number) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v2+json" },
      method: "delete",
      url: `/voyages/${voyageId}`,
    }),
  addVoyageToFavorites: (voyageId: number) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v2+json" },
      method: "post",
      url: `/voyages/${voyageId}/add_favorite`,
    }),
  removeVoyageToFavorites: (voyageId: number) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v2+json" },
      method: "post",
      url: `/voyages/${voyageId}/remove_favorite`,
    }),
  downloadGpxFile: (id: number) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v3+json" },
      method: "get",
      url: `/voyages/${id}/export`,
    }),
  downloadGpxFileByEmail: (id: number) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v3+json" },
      method: "post",
      url: `/voyages/${id}/export_send`,
    }),
  getVoyagesReport: (params: any) =>
    axios.request({
      headers: {
        Accept: "application/vnd.argo.v4+json",
      },
      responseType: "arraybuffer",
      method: "get",
      url: `/reports/voyages`,
      params,
    }),
};
