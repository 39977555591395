import { createApi } from "@reduxjs/toolkit/query/react";

import { customBaseQuery } from "./customBaseQuery";

import { IDepthShading } from "@/types/map";

const API_NAME = "argoApi";

export const argoApi = createApi({
  reducerPath: API_NAME,
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getDepthShadings: builder.query({
      query: (params) => ({
        url: `api/v1/depth_shadings`,
        params,
      }),
      providesTags: ["DepthShadings"] as any,
    }),
    updateShading: builder.mutation({
      query: (payload) => ({
        url: `api/v1/depth_shadings/${payload.id}`,
        method: "put",
        body: {
          current: payload.current,
          visible: payload.visible,
          name: payload.name,
          depth_shader_items_attributes: payload.depth_shader_items,
        },
      }),
      onQueryStarted(
        { name, depth_shader_items, current, visible, id, user_id },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          argoApi.util.updateQueryData(
            "getDepthShadings",
            { user_id },
            (data) =>
              ({
                ...data,
                data: {
                  ...data.data,
                  items: data.data.items.map((item: IDepthShading) => {
                    if (current) {
                      if (item.id === id) {
                        return {
                          ...item,
                          name: name || item.name,
                          depth_shader_items: depth_shader_items || item.depth_shader_items,
                          current: current || item.current,
                          visible: visible === undefined ? item.visible : visible,
                        };
                      }

                      return { ...item, current: false };
                    }
                    if (item.id === id) {
                      return {
                        ...item,
                        name: name || item.name,
                        depth_shader_items: depth_shader_items || item.depth_shader_items,
                        current: current || item.current,
                        visible: visible === undefined ? item.visible : visible,
                      };
                    }

                    return { ...item };
                  }),
                },
              } as any),
          ),
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),
    addShading: builder.mutation({
      query: (payload) => ({
        url: `api/v1/depth_shadings`,
        method: "post",
        body: {
          name: payload.name,
          current: payload.current,
          depth_shader_items_attributes: payload.depth_shader_items_attributes,
        },
      }),
      invalidatesTags: (result, error) => (error ? [] : ["DepthShadings"]) as any,
    }),
    deleteShading: builder.mutation({
      query: (payload) => ({
        url: `api/v1/depth_shadings/${payload.id}`,
        method: "delete",
        body: { current: payload.current },
      }),
      onQueryStarted({ id, user_id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          argoApi.util.updateQueryData(
            "getDepthShadings",
            { user_id },
            (data) =>
              ({
                ...data,
                data: {
                  ...data.data,
                  items: data.data.items.filter((item: IDepthShading) => item.id !== id),
                },
              } as any),
          ),
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),
    getUserOptions: builder.query({
      query: () => ({
        url: `/api/v1/user_options`,
      }),
    }),
    updateGlobalOpacity: builder.mutation({
      query: (body) => ({
        url: `/api/v1/user_options`,
        method: "put",
        body,
      }),
      onQueryStarted({ shader_opacity, depth_shading_enabled }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          argoApi.util.updateQueryData("getUserOptions", undefined, (data) => {
            if (depth_shading_enabled === undefined) {
              return {
                ...data,
                data: {
                  ...data.data,
                  shader_opacity,
                },
              } as any;
            }

            return {
              ...data,
              data: {
                ...data.data,
                depth_shading_enabled,
              },
            } as any;
          }),
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),
    getPlaces: builder.query({
      query: (params) => ({
        url: `/places`,
        Accept: "application/vnd.argo.v2+json",
        params,
      }),
    }),
    getNavAids: builder.query({
      query: (params) => ({
        url: `/api/v1/navigational_aids`,
        params,
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.ne_latitude !== previousArg?.ne_latitude &&
        currentArg?.ne_longitude !== previousArg?.ne_longitude &&
        currentArg?.sw_latitude !== previousArg?.sw_latitude &&
        currentArg?.sw_longitude !== previousArg?.sw_longitude,
    }),
    getQuickPins: builder.query({
      query: (params) => ({
        url: `/api/v1/quick_pins`,
        params,
      }),
    }),
    getTideAndCurrents: builder.query({
      query: (params) => ({
        url: `/api/v1/tide_currents`,
        params,
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.ne_latitude !== previousArg?.ne_latitude &&
        currentArg?.ne_longitude !== previousArg?.ne_longitude &&
        currentArg?.sw_latitude !== previousArg?.sw_latitude &&
        currentArg?.sw_longitude !== previousArg?.sw_longitude,
    }),
    getPois: builder.query({
      query: (params) => ({
        url: `/api/v1/pois`,
        params,
      }),
    }),
    getOptimizedPois: builder.query({
      query: (params) => ({
        url: `/api/v1/optimized_lists/pois/upsert`,
        params,
        keepUnusedDataFor: 5,
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.ne_latitude !== previousArg?.ne_latitude &&
        currentArg?.ne_longitude !== previousArg?.ne_longitude &&
        currentArg?.sw_latitude !== previousArg?.sw_latitude &&
        currentArg?.sw_longitude !== previousArg?.sw_longitude,
    }),
    getOptimizedNavAids: builder.query({
      query: (params) => ({
        url: `/api/v1/optimized_lists/navigational_aids/upsert`,
        params,
        keepUnusedDataFor: 5,
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.ne_latitude !== previousArg?.ne_latitude &&
        currentArg?.ne_longitude !== previousArg?.ne_longitude &&
        currentArg?.sw_latitude !== previousArg?.sw_latitude &&
        currentArg?.sw_longitude !== previousArg?.sw_longitude,
    }),
  }),
});

export const {
  useGetDepthShadingsQuery,
  useUpdateShadingMutation,
  useDeleteShadingMutation,
  useAddShadingMutation,
  useGetUserOptionsQuery,
  useUpdateGlobalOpacityMutation,
  useGetPlacesQuery,
  useGetNavAidsQuery,
  useGetQuickPinsQuery,
  useGetTideAndCurrentsQuery,
  useGetPoisQuery,
  useGetOptimizedPoisQuery,
  useGetOptimizedNavAidsQuery,
} = argoApi;
