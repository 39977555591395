import {
  Button,
  DialogContent,
  DialogActions,
  Stack,
  TextField,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { useEffect } from "react";

import { styles } from "./styles";

import BaseModal from "@/components/Modals/BaseModal";
import useDisclosure from "@/hooks/useDisclosure";

interface ICoordinatesModal {
  invalidValue: string;
}

function CopyToClipboard(value: string) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value);
    onOpen();
    setTimeout(() => onClose(), 2000);
  };

  return (
    <Tooltip arrow open={isOpen} placement="top" title="Copied to clipboard">
      <IconButton size="small" onClick={() => handleCopy(value)}>
        <ContentCopy sx={{ fontSize: 18, color: "primary.main" }} />
      </IconButton>
    </Tooltip>
  );
}

function CoordinatesModal({ invalidValue }: ICoordinatesModal) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  useEffect(() => {
    const snackbar = document.getElementsByClassName("MuiSnackbar-root")[0] as HTMLElement;

    if (isOpen) {
      snackbar.style.display = "none";
    }
  }, [isOpen]);

  return (
    <>
      <Button color="error" size="small" sx={styles.modalButton} onClick={onOpen}>
        Learn More
      </Button>
      <BaseModal
        ariaDescribedby="Invalid Coordinate"
        ariaLabelledby="Invalid Coordinate"
        open={isOpen}
        size="xs"
        title="Invalid Coordinate"
        onClose={onClose}
      >
        <DialogContent sx={styles.dialogContent}>
          <Stack spacing={2}>
            <Stack>
              <Typography gutterBottom variant="body1">
                This is not an available format:
              </Typography>
              <TextField
                disabled
                fullWidth
                id="invalidValue"
                name="invalidValue"
                value={invalidValue}
              />
            </Stack>

            <Typography variant="body1">The following examples might be helpful:</Typography>
            <TextField
              fullWidth
              InputProps={{
                endAdornment: CopyToClipboard("39.259008, -76.452829"),
              }}
              id="dd"
              label="Decimal Degrees (DD)"
              name="dd"
              value="39.259008, -76.452829"
            />

            <TextField
              fullWidth
              InputProps={{
                endAdornment: CopyToClipboard("39°36.2846'N 76°7.4877'W"),
              }}
              id="ddm"
              label="Degrees and Decimal Minutes (DDM)"
              name="ddm"
              value="39°36.2846'N 76°7.4877'W"
            />

            <TextField
              fullWidth
              InputProps={{
                endAdornment: CopyToClipboard("39°36'17.07''N 76°7'29.26''W"),
              }}
              id="dms"
              label="Degrees, Minutes and Seconds (DMS)"
              name="dms"
              value="39°36'17.07''N 76°7'29.26''W"
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button type="button" variant="contained" onClick={onClose}>
            Dismiss
          </Button>
        </DialogActions>
      </BaseModal>
    </>
  );
}

export default CoordinatesModal;
