import { createSlice } from "@reduxjs/toolkit";

import { IFriends, IBoatersFilters } from "@/types/users";

export interface IBoatersState {
  boaterSelectedInfo: IFriends | null;
  boaterSelectedInfoDrawer: IFriends | null;
  filters: IBoatersFilters;
}

const initialState: IBoatersState = {
  boaterSelectedInfo: null,
  boaterSelectedInfoDrawer: null,
  filters: {
    friends: true,
    others: true,
    affiliationsId: [],
  },
};

export const boatersSlice = createSlice({
  name: "boaters",
  initialState,
  reducers: {
    setBoaterInfo: (state, action) => {
      state.boaterSelectedInfo = action.payload;
    },
    setBoaterInfoDrawer: (state, action) => {
      state.boaterSelectedInfoDrawer = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const { setBoaterInfo, setFilters, setBoaterInfoDrawer } = boatersSlice.actions;

export default boatersSlice.reducer;
