/* global GeolocationPosition */
import { List, Stack, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import SearchResultListItem from "../SearchResultListItem";
import SearchResultsSkeleton from "../SearchResultsSkeleton/SearchResultsSkeleton";

import { findPoi, getDistanceFromLocation } from "@/utils/poi";
import { IMapPois } from "@/types/pois";
import useSearch from "@/hooks/useSearch";
import { useAppSelector } from "@/hooks/useRedux";
import { getPosition } from "@/utils/maps";
import { Position } from "@/types/map";
import EmptyState from "@/components/EmptyState/EmptyState";

interface ISearchResults {
  setPoiToRoute?: (poi: IMapPois, inputType: number) => void;
  inputType?: number;
}
export default function SearchResults({ setPoiToRoute, inputType }: ISearchResults) {
  const { selectedPoi, poiList, loading, debouncedQuery, selectedCategories, page } =
    useAppSelector((state) => state.search);
  const { creatingRoute } = useAppSelector((state) => state.route);
  const { setPoi, getMorePois, hasMore } = useSearch();

  const handleSelectPoi = async (poi: IMapPois) => {
    if (creatingRoute) {
      if (setPoiToRoute && inputType) setPoiToRoute(poi, inputType);
    } else {
      setPoi(poi);
    }
  };

  const [position, setPosition] = useState<Position | null>(null);

  const showPosition = (pos: GeolocationPosition) => {
    setPosition({
      lat: pos.coords.latitude,
      lng: pos.coords.longitude,
    });
  };

  useEffect(() => {
    if (!position) {
      getPosition(showPosition);
    }
  }, [poiList]);

  if (loading) return <SearchResultsSkeleton />;
  if (poiList && poiList?.length <= 0)
    return (
      <EmptyState
        imageURL="img/placeholders/EmptyStateNoResults.svg"
        subtitle="We couldn't find any matching results. Please check your search criteria and try again."
        sx={{ height: "calc(100% - 125px)", px: 3 }}
        title="No Results Found"
      />
    );

  return (
    <List disablePadding>
      {poiList && (
        <InfiniteScroll
          dataLength={poiList?.length}
          hasMore={hasMore}
          loader={
            <Stack alignItems="center" direction="row" justifyContent="center" sx={{ p: 1 }}>
              <CircularProgress size={30} />
            </Stack>
          }
          next={() => getMorePois(debouncedQuery, selectedCategories, page + 1)}
          scrollableTarget={creatingRoute ? "scrollableDivRoute" : "scrollableDiv"}
        >
          {poiList.map((item: IMapPois, i: number) => (
            <SearchResultListItem
              key={`item-list-${item.id}`}
              action={() => handleSelectPoi({ ...item, numberInList: i + 1 })}
              distance={
                position
                  ? getDistanceFromLocation(position, {
                      lat: Number(item.lat),
                      lng: Number(item.lng),
                    })
                  : undefined
              }
              icon={findPoi(item.place_type || item.poi_type)}
              id={item.id}
              numberList={i}
              primaryLabel={item.name}
              secondaryLabel={item.address || `${item.lat}, ${item.lng}`}
              selected={selectedPoi?.id === item.id}
            />
          ))}
        </InfiniteScroll>
      )}
    </List>
  );
}
