import axios from "./config";

import { LoginEmail, Props, UserLoginGoogle, UserRegisterData } from "@/types/users";

export default {
  authenticate: (data: LoginEmail) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v3+json" },
      method: "post",
      url: "/auth/sign_in",
      data,
    }),
  register: (data: UserRegisterData) => axios.post("/auth", data),
  googleAuth(data: UserLoginGoogle) {
    return axios.request({
      headers: { Accept: "application/vnd.argo.v4+json" },
      method: "post",
      url: "/auth/google/sign_in",
      data,
    });
  },
  googleAuthUserInfo: (token: string) =>
    fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
      method: "get",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    }),
  appleAuth: (data: Props) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v4+json" },
      method: "post",
      url: "/auth/apple/sign_in",
      data,
    }),
  startRecoveryPassword: (data: Props) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v3+json" },
      method: "post",
      url: "/auth/password",
      data,
    }),
  resetPassword: (data: Props, headers?: Props) =>
    axios.request({
      headers: {
        Accept: "application/vnd.argo.v1+json",
        "access-token": headers?.token,
        client: headers?.clientId,
        uid: headers?.uid,
      },
      method: "put",
      url: "/auth/password",
      data,
    }),
  changePassword: (data: Props) =>
    axios.request({
      headers: {
        Accept: "application/vnd.argo.v1+json",
      },
      method: "put",
      url: "/api/v1/auth/password_change",
      data,
    }),
};
