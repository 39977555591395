export const styles = {
  dialogTitle: {
    pt: 2,
  },

  radioGroup: {
    border: "1px solid",
    borderColor: "divider",
    borderRadius: 1,
    "& .MuiListItem-root:last-of-type": {
      borderBottom: "none",
    },
  },
};
