import { Navigate } from "react-router-dom";
import React from "react";

import usePremiumUser from "@/hooks/usePremiumUser";
import {
  membership_name_yearly,
  membership_name_monthly,
  membership_name_lifetime,
} from "@/components/Premium/PremiumPaymentCard/PremiumPaymentCard";
import { useAppSelector } from "@/hooks/useRedux";

interface IPremiumRoute {
  children: React.ReactElement;
  reverse?: boolean;
}

function PremiumRoute({ children, reverse }: IPremiumRoute): React.ReactElement {
  const isPremium: boolean = usePremiumUser();
  const { userInformation } = useAppSelector((state) => state.user);

  const isMonthlyOrYearly =
    (userInformation?.latest_user_subscription?.membership_activated &&
      (userInformation?.latest_user_subscription?.membership_name === membership_name_yearly ||
        userInformation?.latest_user_subscription?.membership_name === membership_name_monthly)) ||
    userInformation?.latest_user_subscription?.membership_name === membership_name_lifetime ||
    !userInformation?.latest_user_subscription ||
    (userInformation?.latest_user_subscription &&
      !userInformation?.latest_user_subscription?.membership_activated);

  if (reverse) {
    return !isPremium || isMonthlyOrYearly ? children : <Navigate to="/" />;
  }

  return isPremium ? children : <Navigate to="/" />;
}

export default PremiumRoute;
