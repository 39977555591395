import { Fab, Box, ClickAwayListener, Tooltip } from "@mui/material";

import PinsModal from "../PinsModal";

import { styles } from "./styles";

import BaseIcon from "@/components/BaseIcon";
import { ReactComponent as PinsIcon } from "&/img/icons/PinsIcon.svg";
import useDisclosure from "@/hooks/useDisclosure";
import { useAppSelector, useAppDispatch } from "@/hooks/useRedux";
import { setPremiumSubscriptionModal } from "@/store/slices/user";

function PinsOptions() {
  const dispatch = useAppDispatch();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const { logged } = useAppSelector((state) => state.auth);

  const handleOpen = () => {
    if (logged) {
      onOpen();
    } else {
      dispatch(setPremiumSubscriptionModal(true));
    }
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box>
        <Tooltip arrow placement="left" title="Create a Pin">
          <Fab size="small" sx={styles.fabButton} onClick={handleOpen}>
            <BaseIcon icon={PinsIcon} />
          </Fab>
        </Tooltip>
        {isOpen && (
          <PinsModal isOpen={isOpen} pinsType="both" title="Create a Pin" onClose={onClose} />
        )}
      </Box>
    </ClickAwayListener>
  );
}

export default PinsOptions;
