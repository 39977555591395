import { TCoordinates } from "./SearchCoordinatesInput/SearchCoordinatesInput";

export const handleShowCoordinates = (
  coordinates: TCoordinates,
  latRegion: string,
  lngRegion: string,
) => {
  let showCoordinates = null;

  if (coordinates.activeTab === "DDM") {
    showCoordinates =
      coordinates.latDDM.degrees &&
      coordinates.latDDM.minutes &&
      coordinates.lngDDM.degrees &&
      coordinates.lngDDM.minutes
        ? `${coordinates.latDDM.degrees}°${coordinates.latDDM.minutes}'${latRegion} ${coordinates.lngDDM.degrees}°${coordinates.lngDDM.minutes}'${lngRegion}`
        : null;
  } else if (coordinates.activeTab === "DMS") {
    showCoordinates =
      coordinates.latDMS.degrees &&
      coordinates.latDMS.minutes &&
      coordinates.latDMS.seconds &&
      coordinates.lngDMS.degrees &&
      coordinates.lngDMS.minutes &&
      coordinates.lngDMS.seconds
        ? `${coordinates.latDMS.degrees}°${coordinates.latDMS.minutes}'${coordinates.latDMS.seconds}"${latRegion} ${coordinates.lngDMS.degrees}°${coordinates.lngDMS.minutes}'${coordinates.lngDMS.seconds}"${lngRegion}`
        : null;
  } else {
    showCoordinates =
      coordinates.latValue && coordinates.lngValue
        ? `${coordinates.latValue}, ${coordinates.lngValue}`
        : null;
  }

  return showCoordinates;
};
