import * as React from "react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  IconButton,
  Box,
} from "@mui/material";

import { styles } from "./styles";

import dayjs from "@/config/dayjs";

interface IReportPinCommentListItem {
  userFirstName: string;
  userLastName: string;
  userAvatar: string;
  comment: string;
  time: string;
}

export default function ReportPinCommentListItem({
  userFirstName,
  userLastName,
  userAvatar,
  time,
  comment,
}: IReportPinCommentListItem) {
  const previous: dayjs.Dayjs = dayjs(time);

  return (
    <Box component="li" sx={styles.li}>
      <ListItem component="div" sx={styles.listItem}>
        <ListItemAvatar>
          <IconButton sx={styles.iconButton}>
            <Avatar alt={`${userFirstName} ${userLastName}`} src={userAvatar} sx={styles.avatar} />
          </IconButton>
        </ListItemAvatar>
        <ListItemText
          primary={`${userFirstName} ${userLastName}`}
          primaryTypographyProps={{
            component: "h4",
            variant: "subtitle2",
          }}
          secondary={`${dayjs(previous).fromNow()}`}
          secondaryTypographyProps={{
            component: "p",
            variant: "caption",
          }}
          sx={{ ml: 1.5 }}
        />
      </ListItem>
      <Box pb={1} px={2}>
        <Typography variant="body2">{comment}</Typography>
      </Box>
    </Box>
  );
}
