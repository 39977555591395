import { ControlPosition, MapControl } from "@vis.gl/react-google-maps";

import MapOptions from "@/components/MapOptions";
import MapZoomControls from "@/components/MapZoomControls";
import OtherOptions from "@/components/OtherOptions";
import ContextMenuCard from "@/components/MapOptions/ContextMenuCard";

interface IMapControls {
  zoom: number;
  clogpage: boolean;
  zoomControl: (value: number) => void;
}

function MapControls({ zoom, clogpage, zoomControl }: IMapControls) {
  return (
    <>
      {!clogpage && (
        <>
          <MapControl position={ControlPosition.RIGHT_TOP}>
            <>
              <MapOptions />
              <OtherOptions />
            </>
          </MapControl>
          <MapControl position={ControlPosition.BOTTOM_CENTER}>
            <ContextMenuCard />
          </MapControl>
        </>
      )}
      <MapControl position={ControlPosition.RIGHT_BOTTOM}>
        <MapZoomControls zoom={zoom} zoomControl={zoomControl} />
      </MapControl>
    </>
  );
}

export default MapControls;
