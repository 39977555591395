import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useUnitSystem } from "@argonav/unit-system";
import { useMap } from "@vis.gl/react-google-maps";

import { useAppDispatch, useAppSelector } from "./useRedux";

import {
  addPoiList,
  addSelectedPoi,
  addDebouncedQuery,
  setLoading,
  addCategory,
  addSelectedPoiFromMap,
  addPage,
  setShowSearchArea,
} from "@/store/slices/search";
import { IMapPois } from "@/types/pois";
import ServicesMap from "@/services/map";
import ServicesPoi from "@/services/poi";
import ServicesUser from "@/services/user";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import {
  AMENITIES_KEY,
  PARTNERS_KEY,
  PLACES_KEY,
  TPOI_TYPE,
  isLatLng,
  isDMSFormat,
  isDDMFormat,
} from "@/utils/poi";
import { DEFAULT_ZOOM, getZoomForRoute, fitToRegion } from "@/utils/maps";
import { setTempPin, setTempPinCopy, setMyPinsDrawer } from "@/store/slices/reportpins";
import {
  degreesMinutesSecondsFormatRegex,
  degreesDecimalsMinutesFormatRegex,
} from "@/utils/globals";

const useSearch = () => {
  const dispatch = useAppDispatch();
  const map = useMap();
  const { debouncedQuery, poiList, selectedCategories, loading, showSearchArea } = useAppSelector(
    (state) => state.search,
  );
  const { tempPin, tempPinCopy, myPinsDrawer } = useAppSelector((state) => state.reportpins);
  const [query, setQuery] = useState<string>("");
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { convertToLatLngFromDMS } = useUnitSystem();

  const setCleanPoiCategory = () => {
    dispatch(addCategory([]));
  };

  const setCleanSelectedPoi = () => {
    setQuery("");
    dispatch(addSelectedPoi(null));
  };

  const setCleanPoiList = () => {
    dispatch(addPoiList(null));
    dispatch(addPage(1));
    setHasMore(true);
  };

  const searchPois = async (
    search: string,
    categories?: TPOI_TYPE[],
    page?: number,
    pageTotal = 20,
    loading = true,
  ) => {
    dispatch(setLoading(loading));
    try {
      let coordinates = null;
      let nameToShow = "";

      if (isLatLng(`${search}`)) {
        coordinates = search.split(",");

        nameToShow = `GPS Location ${dayjs(new Date()).format("YYYY-MM-DD hh:mm:ss A")}`;
      } else if (isDDMFormat(search)) {
        const matches = degreesDecimalsMinutesFormatRegex.exec(search);

        if (matches) {
          const getCoordinateValues = (startIndex: number) => {
            const degrees = parseInt(matches[startIndex], 10);
            const minutes = parseFloat(matches[startIndex + 1]);
            const hemisphere = matches[startIndex + 2];

            return { degrees, minutes, hemisphere };
          };

          const latValues = getCoordinateValues(1);
          const lngValues = getCoordinateValues(4);

          const latLng = convertToLatLngFromDMS(latValues, lngValues);

          coordinates = [
            `${latValues.hemisphere === "S" ? "-" : ""}${latLng.lat}`,
            `${lngValues.hemisphere === "W" ? "-" : ""}${latLng.lng}`,
          ];

          nameToShow = `GPS Location ${dayjs(new Date()).format("YYYY-MM-DD hh:mm:ss A")}`;
        }
      } else if (isDMSFormat(search)) {
        const matches = degreesMinutesSecondsFormatRegex.exec(search);

        if (matches) {
          const getCoordinateValues = (startIndex: number) => {
            const degrees = parseInt(matches[startIndex], 10);
            const minutes = parseInt(matches[startIndex + 1], 10);
            const seconds = parseFloat(matches[startIndex + 2]);
            const hemisphere = matches[startIndex + 3];

            return { degrees, minutes, seconds, hemisphere };
          };

          const latValues = getCoordinateValues(1);
          const lngValues = getCoordinateValues(5);

          const latLng = convertToLatLngFromDMS(latValues, lngValues);

          coordinates = [
            `${latValues.hemisphere === "S" ? "-" : ""}${latLng.lat}`,
            `${lngValues.hemisphere === "W" ? "-" : ""}${latLng.lng}`,
          ];

          nameToShow = `GPS Location ${dayjs(new Date()).format("YYYY-MM-DD hh:mm:ss A")}`;
        }
      }

      const center = map && map.getCenter();

      const dataRequest = {
        page_total: pageTotal,
        page,
        lat: coordinates ? coordinates[0] : center?.lat(),
        lng: coordinates ? coordinates[1] : center?.lng(),
        poi_type: categories
          ?.filter((i: TPOI_TYPE) => i.type === PLACES_KEY)
          .map((o) => o.value)
          .join(),
        amenities: categories
          ?.filter((i: TPOI_TYPE) => i.type === AMENITIES_KEY)
          .map((o) => o.value)
          .join(),
        snag_a_slip:
          categories?.some(
            (i: TPOI_TYPE) => i.type === PARTNERS_KEY && i.value === "Snag-A-Slip",
          ) || null,
        dockshare:
          categories?.some((i: TPOI_TYPE) => i.type === PARTNERS_KEY && i.value === "Dockshare") ||
          null,
        transient_available: categories?.some((i: TPOI_TYPE) => i.value === "Transient Slip")
          ? true
          : null,
        location: "anywhere",
        distance: 3000,
        keyword: coordinates ? "" : search,
      };

      const { status, data } = await ServicesMap.searchMapPois(dataRequest);

      if (status === 200) {
        dispatch(setLoading(false));
        if (coordinates)
          return [
            { name: nameToShow, lat: coordinates[0], lng: coordinates[1], poi_type: "Other" },
            ...data,
          ];

        return data;
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      dispatch(setLoading(false));
    }
  };

  const handleGetPoiInfo = async (item: IMapPois) => {
    try {
      const { status, data } = await ServicesPoi.getPoiByUid(item.uid);

      if (status === 200) {
        dispatch(addSelectedPoi(data.data));
        dispatch(addSelectedPoiFromMap(false));
        const { status: statusRaiting, data: dataRaiting } = await ServicesPoi.getPoiRatings(
          data?.data?.id,
        );

        if (statusRaiting === 200) {
          dispatch(addSelectedPoi({ ...data.data, all_reviews: dataRaiting }));
        }
      }
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
  };

  const setPoi = async (poi: IMapPois | null) => {
    if (!poi) {
      dispatch(addSelectedPoi(null));
    } else {
      handleGetPoiInfo(poi);
      dispatch(addSelectedPoiFromMap(true));
      if (map) {
        map.moveCamera({
          center: {
            lat: poi && parseFloat(String(poi.lat)),
            lng:
              poi && poiList ? parseFloat(String(poi.lng)) - 0.0045 : parseFloat(String(poi.lng)),
          },
          zoom: DEFAULT_ZOOM,
        });
      }

      await ServicesUser.setSearchHistory(poi.id);
    }
  };

  const getMorePois = async (value: string, category?: TPOI_TYPE[], page = 1) => {
    const results = await searchPois(value, category, page, 20, false);

    if (results) {
      if (results.length > 0) {
        dispatch(addPoiList([...(poiList || []), ...results]));
        dispatch(addPage(page));
      } else {
        setHasMore(false);
      }
    }
  };

  const getListOfPois = async (value: string, category?: TPOI_TYPE[], page = 1) => {
    const results = await searchPois(value, category, page, 20);

    if (results) {
      if (results.length > 0) {
        if (map) {
          const routeCenter = fitToRegion(results);

          const routeZoom = getZoomForRoute(results[0], results[results.length - 1]);

          map.moveCamera({
            center: {
              lat: routeCenter.latitude,
              lng: routeCenter.longitude,
            },
            zoom: routeZoom,
          });
        }
      }

      dispatch(addSelectedPoi(null));
      if (tempPin) dispatch(setTempPin(undefined));
      if (tempPinCopy) dispatch(setTempPinCopy(undefined));
      if (myPinsDrawer?.open) dispatch(setMyPinsDrawer({ open: false, type: "places" }));
      dispatch(addPoiList(results));
      if (showSearchArea) dispatch(setShowSearchArea(false));
    }
  };

  useEffect(() => {
    const timerId = setTimeout(() => dispatch(addDebouncedQuery(query)), 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [query]);

  useEffect(() => {
    const categoriesReady = selectedCategories.length > 0;
    const keywordReady = query !== "" && debouncedQuery !== "";

    const readyToSearch = categoriesReady || keywordReady;

    if (readyToSearch && !loading) {
      getListOfPois(debouncedQuery, selectedCategories);
    } else if (!keywordReady && !categoriesReady) {
      setCleanPoiList();
    }
  }, [debouncedQuery]);

  return {
    query,
    hasMore,
    setQuery,
    setCleanSelectedPoi,
    setCleanPoiCategory,
    setCleanPoiList,
    setPoi,
    getListOfPois,
    getMorePois,
  };
};

export default useSearch;
