import * as React from "react";
import { Box } from "@mui/material";

export default function ArgoDivider() {
  return (
    <Box
      className="argoDivider"
      sx={{ backgroundColor: "#E1F5FE", width: "100%", height: "8px" }}
    />
  );
}
