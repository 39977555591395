export const styles = {
  googleButton: {
    backgroundColor: "grey.100",
    "&:hover": {
      backgroundColor: "grey.50",
    },
  },

  facebookButton: {
    backgroundColor: "#3B5998",
    "&:hover": {
      backgroundColor: "#5574B5",
    },
  },

  appleButton: {
    backgroundColor: "common.black",
    "&:hover": {
      backgroundColor: "grey.800",
    },
  },
};
