/* global google */

import MapControls from "../MapControls";
import DeckGlOverlay from "../MapOverlays/DeckGlOverlay";
import Markers from "../Markers";

import { Position } from "@/types/map";
import { useAppSelector } from "@/hooks/useRedux";

interface IMapContentWrapper {
  clogpage: boolean;
  bounds: google.maps.LatLngBounds | undefined;
  location: Position | null;
  mapLayerConfig: any;
  zoomControl: (value: number) => void;
  onCleanListOfResults: () => void;
}

function MapContentWrapper({
  clogpage,
  bounds,
  location,
  mapLayerConfig,
  zoomControl,
  onCleanListOfResults,
}: IMapContentWrapper) {
  const { zoom } = useAppSelector((state) => state.mapParams);

  return (
    <>
      <MapControls clogpage={clogpage} zoom={zoom} zoomControl={zoomControl} />
      <DeckGlOverlay layers={mapLayerConfig.layers} />
      <Markers
        bounds={bounds}
        clogpage={clogpage}
        userPosition={location}
        zoom={zoom}
        onCleanPoiList={onCleanListOfResults}
      />
    </>
  );
}

export default MapContentWrapper;
