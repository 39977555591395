/* global google */
import NoaaHazardsList from "./NoaaHazardsList";

import { useGetNavAidsQuery } from "@/store/api/argoApi";
import { TRegionData } from "@/types/map";
import { getRegionFromBounds } from "@/utils/maps";
import { useAppSelector } from "@/hooks/useRedux";
import { useThrottle } from "@/hooks/useThrottle";

interface INoaaHazards {
  bounds: google.maps.LatLngBounds;
}

function NoaaHazards({ bounds }: INoaaHazards) {
  const region: TRegionData = getRegionFromBounds(bounds);
  const throttledRegion = useThrottle(region);
  const { poisConfigured } = useAppSelector((state) => state.map);

  const { data } = useGetNavAidsQuery(
    {
      sw_latitude: throttledRegion.southWestLat,
      sw_longitude: throttledRegion.southWestLng,
      ne_latitude: throttledRegion.northEastLat,
      ne_longitude: throttledRegion.northEastLng,
      kinds: "wreck,obstruction,rock",
      page_total: 100,
    },
    { skip: !throttledRegion.hasRegion },
  );

  if (!data?.data?.items) {
    return null;
  }

  return <NoaaHazardsList items={data.data.items} poisConfigured={poisConfigured} />;
}

export default NoaaHazards;
