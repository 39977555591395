export const styles = {
  fabButton: {
    boxShadow: 3,
    backgroundColor: "grey.600",
    color: "common.white",
    "& .MuiSvgIcon-root": {
      fontSize: 22,
    },
    "&:hover": {
      backgroundColor: "grey.700",
      color: "common.white",
    },
  },
};
