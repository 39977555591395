import { alpha } from "@mui/material/styles";

import { layoutStyles } from "@/themes/layoutStyles";
import defaultTheme from "@/themes/theme";

export const styles = {
  dialogActions: {
    px: 3,
    py: 2,
  },

  routePointsBox: {
    border: "1px solid",
    borderColor: "divider",
    borderRadius: 1,
    "& .routePointsItem": {
      borderBottom: "1px solid",
      borderColor: "divider",
      px: 2,
      py: 1.5,
    },
    "& .routePointsItem:last-of-type": {
      borderBottom: "none",
    },
  },

  formControlLabel: {
    mx: 0,
    width: "100%",
    border: "2px solid",
    borderColor: "divider",
    borderRadius: 1,
    px: 3,
    py: 1.5,
    transition: defaultTheme.transitions.create(["all", "transform"], {
      duration: defaultTheme.transitions.duration.standard,
    }),
    "&:hover": {
      borderColor: `${alpha(defaultTheme.palette.primary.main, 0.5)}`,
      backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.04)}`,
    },
  },

  formControlLabelSelected: {
    mx: 0,
    width: "100%",
    border: "2px solid",
    borderColor: `${alpha(defaultTheme.palette.primary.main, 0.5)}`,
    borderRadius: 1,
    px: 3,
    py: 1.5,
    transition: defaultTheme.transitions.create(["all", "transform"], {
      duration: defaultTheme.transitions.duration.standard,
    }),
    "&:hover": {
      backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.04)}`,
    },
  },

  radio: {
    ml: "-9px",
  },

  formControl: {
    width: "100%",
  },

  customShadings: {
    ...layoutStyles.scroll,
    width: "100%",
    mt: 1,
    pr: 2,
    overflowY: "auto",
    maxHeight: "400px",
  },

  depthIndicator: {
    width: "100%",
    "& .depthIndicatorItem": {
      width: "100%",
      flex: 1,
    },

    "& .depthIndicatorItem:first-of-type .depthIndicatorColor": {
      borderRadius: "12px 0px 0px 12px",
    },

    "& .depthIndicatorItem:last-of-type .depthIndicatorColor": {
      borderRadius: "0px 12px 12px 0px",
    },
  },
};
