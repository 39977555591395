import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";

import apiConfig from "@/config/api";

export const customBaseQuery: BaseQueryFn<string | FetchArgs, any, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: apiConfig.apiBaseURL,
    prepareHeaders: (headers) => {
      const storedAuth = localStorage.getItem("auth");

      if (storedAuth) {
        const auth = JSON.parse(storedAuth);

        headers.set("uid", auth.uid);
        headers.set("client", auth.client);
        headers.set("access-token", auth["access-token"]);
        // if (!headers.has("accept")) {
        //   headers.set("accept", apiConfig.defaultApiVersion);
        // }

        return headers;
      }
    },
  });

  const result = await baseQuery(args, api, extraOptions);
  // const newTokenInfo = {
  //   "access-token": result?.meta?.response?.headers?.get("access-token"),
  //   expiry: result?.meta?.response?.headers?.get("expiry"),
  // };

  // await api.dispatch(setNewTokenExpiry(newTokenInfo));

  return result;
};
