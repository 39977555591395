import { Stack, Typography } from "@mui/material";
import Radio, { RadioProps } from "@mui/material/Radio";
import {
  EditOutlined,
  DeleteOutlined,
  ContentCopy,
  VisibilityOffOutlined,
} from "@mui/icons-material";
import { useState } from "react";
import LockIcon from "@mui/icons-material/Lock";

import { styles } from "./styles";

import DepthIndicatorLayout from "@/components/DepthShading/DepthIndicatorLayout";
import { IDepthShading } from "@/types/map";
import QuickMenu from "@/components/QuickMenu";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { setDepthShadingToEdit } from "@/store/slices/depthshading";
import AlertDialog from "@/components/AlertDialog/AlertDialog";
import {
  useDeleteShadingMutation,
  useUpdateShadingMutation,
  useAddShadingMutation,
} from "@/store/api/argoApi";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import usePremiumUser from "@/hooks/usePremiumUser";
import { setPremiumSubscriptionModal } from "@/store/slices/user";

export default function DepthShadingRadio(
  props: RadioProps & {
    item: IDepthShading;
    depthShadingData: { data: { items: IDepthShading[] } };
  },
) {
  const dispatch = useAppDispatch();
  const {
    item,
    depthShadingData,
  }: { item: IDepthShading; depthShadingData: { data: { items: IDepthShading[] } } } = props;
  const [itemToDelete, setItemToDelete] = useState<IDepthShading | null>(null);
  const { user } = useAppSelector((state) => state.auth);
  const isPremium = usePremiumUser();
  const [deleteShading] = useDeleteShadingMutation();
  const [updateShading] = useUpdateShadingMutation();
  const [addShading] = useAddShadingMutation();

  const handleHidePresetShading = async (item: IDepthShading) => {
    updateShading({ id: item.id, visible: false, user_id: user?.id });
  };

  const handleDuplicatePresetShading = async (item: IDepthShading) => {
    if (!isPremium) {
      dispatch(setPremiumSubscriptionModal(true));

      return;
    }
    try {
      const newCustomShading = {
        name: `Custom Shading ${(depthShadingData?.data?.items?.length ?? 0) - 1}`,
        current: false,
        depth_shader_items_attributes: item.depth_shader_items.map((item) => ({
          color: item.color,
          distance: item.distance,
        })),
        user_id: user?.id,
      };

      await addShading(newCustomShading);
      dispatch(
        setSnackBarMsjSucceded({
          state: true,
          type: "success",
          msj: "Custom Depth Shading duplicated",
        }),
      );
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
  };

  const optionsMenuCustomShading = [
    {
      text: isPremium ? (
        "Edit"
      ) : (
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack sx={{ fontSize: 14 }}>{`Edit `}</Stack>
          <LockIcon sx={{ width: 14, height: 14, ml: 0.4 }} />
        </Stack>
      ),
      icon: <EditOutlined fontSize="small" />,
      action: () => {
        if (!isPremium) {
          dispatch(setPremiumSubscriptionModal(true));

          return;
        }
        dispatch(setDepthShadingToEdit(item));
      },
    },
    {
      text: isPremium ? (
        "Delete"
      ) : (
        <Stack alignItems="center" direction="row" justifyContent="center">
          <Stack sx={{ fontSize: 14 }}>{`Delete `}</Stack>
          <LockIcon sx={{ width: 14, height: 14, ml: 0.4 }} />
        </Stack>
      ),
      icon: <DeleteOutlined fontSize="small" />,
      action: () => {
        if (!isPremium) {
          dispatch(setPremiumSubscriptionModal(true));

          return;
        }
        setItemToDelete(item);
      },
    },
  ];

  const optionsMenuPresetShading = [
    {
      text: isPremium ? (
        "Duplicate"
      ) : (
        <Stack alignItems="center" direction="row" justifyContent="center">
          <Stack sx={{ fontSize: 14 }}>{`Duplicate `}</Stack>
          <LockIcon sx={{ width: 14, height: 14, ml: 0.4 }} />
        </Stack>
      ),
      icon: <ContentCopy fontSize="small" />,
      action: () => handleDuplicatePresetShading(item),
    },
    {
      text: "Hide",
      icon: <VisibilityOffOutlined fontSize="small" />,
      action: () => handleHidePresetShading(item),
    },
  ];

  const handleDeleteShading = async () => {
    if (!isPremium) {
      dispatch(setPremiumSubscriptionModal(true));

      return;
    }
    try {
      if (itemToDelete) {
        if (itemToDelete.current) {
          const defaultItemId = depthShadingData?.data?.items.find((item) => item.default_flag)?.id;

          await updateShading({ id: defaultItemId, current: true, user_id: user?.id });
        }
        await deleteShading({ id: itemToDelete.id, user_id: user?.id });
        setItemToDelete(null);
        dispatch(
          setSnackBarMsjSucceded({
            state: true,
            type: "success",
            msj: "Depth Shading deleted",
          }),
        );
      }
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
  };

  return (
    <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack alignItems="center" direction="row" spacing={0}>
          <Radio disableRipple color="primary" size="small" {...props} sx={styles.radio} />
          <Typography variant="body2">{item.name}</Typography>
        </Stack>
        {item.default_flag !== true && (
          <Stack>
            <QuickMenu
              options={item?.preset_flag ? optionsMenuPresetShading : optionsMenuCustomShading}
            />
          </Stack>
        )}
      </Stack>

      <Stack direction="row" sx={styles.depthIndicator}>
        {item?.depth_shader_items && (
          <DepthIndicatorLayout shaderItems={item?.depth_shader_items} />
        )}
      </Stack>
      <AlertDialog
        cancelButtonTitle="Cancel"
        close={() => setItemToDelete(null)}
        confirm={() => handleDeleteShading()}
        message="Are you sure you want to delete this custom shading?"
        open={!!itemToDelete}
        positiveButtonTitle="Delete"
        title="Delete Custom Shading"
      />
    </Stack>
  );
}
