import React, { useState, useEffect } from "react";
import { Paper, Box, Typography, Stack, Button } from "@mui/material";
import { TripOriginOutlined, Place, Lock } from "@mui/icons-material";
import { useUnitSystem } from "@argonav/unit-system";

import { styles } from "./styles";
import PlacePopoverSkeleton from "./PlacePopoverSkeleton";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import usePremiumUser from "@/hooks/usePremiumUser";
import useSearch from "@/hooks/useSearch";
import { addCreatingRoute, addStartPoint, addEndPoint } from "@/store/slices/route";
import { setPremiumSubscriptionModal } from "@/store/slices/user";
import { INavAids } from "@/types/navaids";
import { IMapPois } from "@/types/pois";
import { checkBuoysAndBeaconsStrings, checkRestricedAreaStrings } from "@/utils/poi";
import ServiceNavAids from "@/services/navaids";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";

interface INavigationAidPopover {
  inCaptainsLog?: boolean;
  icon: string;
  navAid: INavAids | IMapPois;
  grownToTop?: boolean;
}

export default function NavigationAidPopover({
  inCaptainsLog,
  icon,
  navAid,
  grownToTop,
}: INavigationAidPopover) {
  const isPremium = usePremiumUser();
  const { logged } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [navAidInfo, setNavAidInfo] = useState<INavAids | IMapPois | null>(null);

  const { coordinatesFormatHandler } = useUnitSystem();
  const { setCleanPoiList, setCleanPoiCategory, setCleanSelectedPoi } = useSearch();

  /**
   * Functionality to get nav Aid info
   *
   * @param {TItem} item - item is a marker on the map with UID, the information in item is reduced.
   */
  const handleGetNavAidInfo = async (item: INavAids | IMapPois) => {
    if (logged) {
      try {
        const { status, data } = await ServiceNavAids.getNavAidById(item?.id);

        if (status === 200) {
          setNavAidInfo(data.data);
        }
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      }
    }
  };

  useEffect(() => {
    if (!navAid) return;

    handleGetNavAidInfo(navAid);
  }, []);

  const handleRouteOpen = (
    navAid?: INavAids | IMapPois,
    inputType?: number,
    event?: React.MouseEvent<HTMLElement>,
  ) => {
    event?.stopPropagation();
    if (logged && isPremium) {
      setCleanPoiList();
      setCleanPoiCategory();
      dispatch(addCreatingRoute(true));

      /**
       * In this object we set the necessary params if the selected marker
       * to create a route is a poi or aid.
       */
      const navAidByType = {
        lat: navAid?.lat,
        lng: navAid?.lng,
        name: navAid?.name,
      };

      // if (coords) dispatch(setCoords(undefined));
      if (navAid) {
        if (inputType === 1) {
          dispatch(
            addStartPoint({
              lat: navAidByType.lat,
              lng: navAidByType.lng,
              name: `${navAidByType.name}`,
            }),
          );
        } else {
          dispatch(
            addEndPoint({
              lat: navAidByType.lat,
              lng: navAidByType.lng,
              name: `${navAidByType.name}`,
            }),
          );
        }
        setCleanSelectedPoi();
      }
    } else {
      dispatch(setPremiumSubscriptionModal(true));
    }
  };

  const latLngFormattedFromFile = coordinatesFormatHandler({
    latitude: Number(navAid?.latitude),
    longitude: Number(navAid?.longitude),
  });

  if (!navAidInfo) {
    return (
      <Box sx={styles.container}>
        <Paper
          className="placePopover"
          elevation={6}
          sx={grownToTop ? styles.paperToTop : styles.paper}
        >
          <PlacePopoverSkeleton />
        </Paper>
      </Box>
    );
  }

  return (
    <Box>
      <Paper
        className="placePopover"
        elevation={6}
        sx={grownToTop ? styles.paperToTop : styles.paper}
      >
        <Stack direction="row" spacing={1.5}>
          <Box sx={styles.image}>
            <img alt="NavAid" src={icon} />
          </Box>

          <Stack sx={styles.textContainer}>
            {/* RESTRICED AREA / BUOYS AND BECONS INFO */}
            {navAidInfo?.kind === "restricted_area" && (
              <>
                <Stack sx={styles.textContainerAids}>
                  <Typography color="text.primary" variant="subtitle1">
                    {navAidInfo.name && navAidInfo.name !== "undefined"
                      ? navAidInfo.name
                      : "Restricted Area"}
                  </Typography>

                  <Typography gutterBottom color="text.primary" variant="body2">
                    {checkRestricedAreaStrings(
                      navAidInfo.objname && navAidInfo.objname !== "undefined"
                        ? navAidInfo.objname
                        : navAidInfo.kind,
                      navAidInfo.catrea,
                      navAidInfo.restrn,
                    )}
                  </Typography>
                  {navAidInfo.inform && navAidInfo.inform !== "undefined" && (
                    <Typography gutterBottom color="text.primary" variant="body2">
                      {navAidInfo.inform}
                    </Typography>
                  )}
                </Stack>

                <Typography color="text.secondary" variant="body2">
                  {latLngFormattedFromFile}
                </Typography>
              </>
            )}
            {(navAidInfo.kind === "buoy" || navAidInfo.kind === "beacon") && (
              <>
                <Stack sx={styles.textContainerAids}>
                  <Typography color="text.primary" variant="subtitle1">
                    {checkBuoysAndBeaconsStrings(
                      navAidInfo.color,
                      navAidInfo.subkind?.replace(/_/g, " "),
                      navAidInfo?.kind,
                    )}
                  </Typography>
                  {navAidInfo.objname && navAidInfo.objname !== "undefined" && (
                    <Typography gutterBottom color="text.primary" variant="body2">
                      {navAidInfo.objname}
                    </Typography>
                  )}

                  {navAidInfo.light_info && navAidInfo.light_info !== "undefined" && (
                    <Typography gutterBottom color="text.primary" variant="body2">
                      {navAidInfo.light_info}
                    </Typography>
                  )}

                  {navAidInfo.shape && navAidInfo.shape !== "undefined" && (
                    <Typography gutterBottom color="text.primary" variant="body2">
                      Shape: {navAidInfo.shape}
                    </Typography>
                  )}
                </Stack>
                <Typography color="text.secondary" variant="body2">
                  {latLngFormattedFromFile}
                </Typography>
              </>
            )}

            <Stack
              direction="row"
              mt={1}
              spacing={1}
              sx={!inCaptainsLog ? null : styles.hideButtons}
            >
              <Button
                className="buttonFrom"
                disabled={!isPremium}
                endIcon={isPremium ? <TripOriginOutlined /> : <Lock />}
                size="small"
                sx={styles.routeButton}
                variant="outlined"
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  handleRouteOpen(navAidInfo, 1, event)
                }
              >
                Route from
              </Button>
              <Button
                className="buttonTo"
                disabled={!isPremium}
                endIcon={isPremium ? <Place /> : <Lock />}
                size="small"
                sx={styles.routeButton}
                variant="outlined"
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  handleRouteOpen(navAidInfo, 2, event)
                }
              >
                Route to
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
}
