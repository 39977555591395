import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";
import { layoutStyles } from "@/themes/layoutStyles";

export const styles = {
  container: {
    px: 2,
    pb: 2,
    pt: 3,
  },

  mainContainer: {
    "& .coverImage": {
      position: "relative",
      minHeight: 160,
      backgroundSize: "cover",
      backgroundPosition: "center",
      "&:before": {
        content: "''",
        width: "100%",
        height: "100%",
        top: 0,
        position: "absolute",
        backgroundColor: `${alpha(defaultTheme.palette.common.black, 0.3)}`,
      },
    },
    "& .titleContainer": {
      mr: 1,
    },
  },

  mainContainerDrawer: {
    "& .coverImage": {
      position: "relative",
      minHeight: 200,
      backgroundSize: "cover",
      backgroundPosition: "center",
      "&:before": {
        content: "''",
        width: "100%",
        height: "100%",
        top: 0,
        position: "absolute",
        backgroundColor: `${alpha(defaultTheme.palette.common.black, 0.3)}`,
      },
    },
  },

  chip: {
    px: 0.25,
    ml: -0.5,
    backgroundColor: `${alpha(defaultTheme.palette.primary.light, 0.12)}`,
    border: "1px solid",
    borderColor: `${alpha(defaultTheme.palette.primary.light, 0.12)}`,
    color: "primary.main",
    "& .MuiSvgIcon-root": {
      color: "primary.main",
    },
    "& .MuiChip-label": {
      pl: 0.25,
      fontWeight: 700,
    },
  },

  closeButton: {
    position: "absolute",
    right: "12px",
    top: "12px",
  },

  searchBox: {
    position: "absolute",
    width: "calc(100% - 24px)",
    zIndex: 2,
    mt: 1,
    mx: 1.5,
  },

  poiTitle: {
    lineHeight: "normal",
  },

  checkbox: {
    width: "38.5px",
    height: "38.5px",
    color: "primary.main",
    backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.08)}`,
    "& .MuiSvgIcon-root": {
      fontSize: 22,
    },
    "&:hover": {
      backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.12)}`,
    },
  },

  cardMedia: {
    borderRadius: 1,
    border: "1px solid",
    borderColor: "divider",
  },

  fuel: {
    "& .fuelBox": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },

    "& .MuiChip-root": {
      borderRadius: 0.5,
    },
  },

  denseList: {
    pb: 2,
    "& .MuiListItem-root": {
      py: 0,
    },
  },

  routeButton: {
    ...layoutStyles.routeButton,
  },
};
