import {
  Stack,
  Box,
  Typography,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import { styles } from "./styles";

import BaseIcon from "@/components/BaseIcon/BaseIcon";
import { ReactComponent as AppleStoreIcon } from "&/img/icons/AppleStore.svg";
import { ReactComponent as GooglePlayIcon } from "&/img/icons/GooglePlay.svg";

interface IViewportMessage {
  onClose: () => void;
  onDontShowAgain: () => void;
}

function ViewportMessage({ onClose, onDontShowAgain }: IViewportMessage) {
  return (
    <>
      <DialogContent sx={{ py: 0, overflow: "hidden" }}>
        <Stack alignItems="center" sx={styles.argoMockupsContainer}>
          <Box sx={styles.argoMockups} />
        </Stack>
        <Box>
          <Typography gutterBottom color="primary" sx={{ pt: 1 }} textAlign="center" variant="h5">
            Desktop Web App is not supported on small screens.
          </Typography>
          <Typography color="text.secondary" textAlign="center" variant="body2">
            Please switch to a larger screen for a better experience. For use on a phone or tablet,
            check out our mobile app.
          </Typography>
        </Box>
        <Stack direction="row" justifyContent="center" spacing={2} sx={{ py: 3 }}>
          <IconButton
            href="https://play.google.com/store/apps/details?id=com.argonav&referrer=utm_source%3Dwebsite%26utm_medium%3Dlinks"
            sx={styles.blackButton}
            target="_blank"
          >
            <BaseIcon icon={GooglePlayIcon} />
          </IconButton>
          <IconButton
            href="https://apps.apple.com/app/apple-store/id1463869636?mt=8"
            sx={styles.blackButton}
            target="_blank"
          >
            <BaseIcon icon={AppleStoreIcon} />
          </IconButton>
        </Stack>
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <>
          {" "}
          <Button
            size="small"
            onClick={() => {
              onDontShowAgain();
              onClose();
            }}
          >
            Don’t Show Again
          </Button>
          <Button size="small" variant="outlined" onClick={onClose}>
            Dismiss
          </Button>
        </>
      </DialogActions>
    </>
  );
}

export default ViewportMessage;
