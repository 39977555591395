import { TileLayer } from "@deck.gl/geo-layers";
import { BitmapLayer } from "@deck.gl/layers";

import { createEncUrl_old } from "@/config/NOAA.config";

export function getNOAAMapOverlay(tileSize: number) {
  const tileLayer = new TileLayer<ImageBitmap>({
    id: "noaa-layer",
    maxZoom: 18,
    minZoom: 0,
    tileSize,
    pickable: true,
    getTileData: (props) => {
      const { x, y, z } = props.index;

      const zpow = 2 ** z;
      const MapX = -20037508.34789244;
      const MapY = 20037508.34789244;
      const FULL = 20037508.34789244 * 2;
      const tile = FULL / zpow;

      // Get the box coordinates in esriMeters for one tile
      const ulx = MapX + x * tile;
      const uly = MapY - (y + 1) * tile;
      const lrx = MapX + (x + 1) * tile;
      const lry = MapY - y * tile;

      const bbox = `bbox=${ulx},${uly},${lrx},${lry}`;
      const url = createEncUrl_old(bbox);

      return fetch(url)
        .then((response) => response.blob())
        .then((blob) => createImageBitmap(blob));
    },
    onTileError: () => null,
    renderSubLayers: (props) => {
      const [[west, south], [east, north]] = props.tile.boundingBox;
      const { data, ...otherProps } = props;

      return [
        new BitmapLayer(otherProps, {
          image: data,
          bounds: [west, south, east, north],
        }),
      ];
    },
  });

  return tileLayer;
}
