import { layoutStyles } from "@/themes/layoutStyles";

export const styles = {
  cardMedia: {
    borderRadius: 0.5,
    border: "1px solid",
    borderColor: "divider",
    cursor: "pointer",
  },
  voyageDetails: {
    "& .voyageDetailRow div": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },

    "& .voyageDetailRow": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      px: 1.5,
      py: 0.75,
      pt: 1,
      borderRadius: 0.5,
    },

    "& .voyageDetailRow:nth-of-type(odd)": {
      backgroundColor: "grey.100",
    },

    "& .voyageDetailRow h4": {
      lineHeight: "normal",
    },
  },

  voyageDetailsChart: {
    display: "block",
    width: "12px",
    height: "64px",
    my: 0.5,
    flexShrink: 0,
    pointerEvents: "none",
    backgroundColor: "primary.main",
    borderRadius: 2,
    position: "relative",
    "&:before, :after": {
      content: "''",
      position: "absolute",
      display: "block",
      width: "8px",
      height: "8px",
      backgroundColor: "common.white",
      borderRadius: 1,
    },
    "&:before": {
      top: 2,
      left: 2,
    },
    "&:after": {
      bottom: 2,
      left: 2,
    },
  },

  ellipsisOneLine: {
    ...layoutStyles.ellipsisOneLine,
  },

  dataFooter: {
    p: 2,
    backgroundColor: "grey.50",
  },
};
