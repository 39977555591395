export const styles = {
  fuel: {
    pb: 1,
    "& .fuelBox": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      maxWidth: "64px",
      "& p": {
        minHeight: "20px",
      },
    },

    "& .MuiChip-root": {
      borderRadius: 0.5,
    },
  },
};
