import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import PremiumRoute from "./PremiumRoute";
import AuthenticatedRoute from "./AuthenticatedRoute";

import Home from "@/pages/home";
import LogIn from "@/pages/login";
import ResetPassword from "@/pages/login/ResetPassword";
import PremiumPayment from "@/pages/premiumpayment";
import CaptainsLog from "@/pages/log";
import Layout from "@/components/Common/Layout";

function PageRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <Layout>
              <Home />
            </Layout>
          }
          path="/:coordsParams?"
        />
        <Route
          element={
            <AuthenticatedRoute>
              <PremiumRoute reverse>
                <Layout>
                  <PremiumPayment />
                </Layout>
              </PremiumRoute>
            </AuthenticatedRoute>
          }
          path="/premium-payment"
        />
        <Route
          element={
            <AuthenticatedRoute>
              <PremiumRoute>
                <Layout>
                  <CaptainsLog />
                </Layout>
              </PremiumRoute>
            </AuthenticatedRoute>
          }
          path="/captains-log"
        />
        <Route element={<LogIn />} path="/login/:id?" />
        <Route element={<ResetPassword />} path="recovery-password" />
        <Route element={<Navigate to="/login/premium" />} path="/premium" />
        <Route element={<Navigate to="/login/lifetime" />} path="/lifetime" />
      </Routes>
    </BrowserRouter>
  );
}

export default PageRoutes;
