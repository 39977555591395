import AdvancedMarker from "../AdvancedMarker";

import { useAppSelector } from "@/hooks/useRedux";
import StartIndicatorIcon from "@/assets/img/markers/location/StartIndicator.png";
import EndIndicator from "@/assets/img/markers/location/EndIndicator.png";

function RouteMarkers() {
  const { startPoint, endPoint } = useAppSelector((state) => state.route);
  const { courses } = useAppSelector((state) => state.captainslog);

  if (courses && courses.length > 0) {
    return (
      <>
        {courses.map((course) => (
          <>
            <AdvancedMarker
              key={`start-indicator-${course.id}`}
              icon={StartIndicatorIcon}
              position={{ lat: Number(course.start_lat), lng: Number(course.start_lng) }}
              selected={false}
              onClick={() => null}
            />
            <AdvancedMarker
              key={`end-indicator-${course.id}`}
              icon={EndIndicator}
              position={{ lat: Number(course.end_lat), lng: Number(course.end_lng) }}
              selected={false}
              onClick={() => null}
            />
          </>
        ))}
      </>
    );
  }

  return (
    <>
      {startPoint && (
        <AdvancedMarker
          icon={StartIndicatorIcon}
          position={{ lat: Number(startPoint.lat), lng: Number(startPoint.lng) }}
          selected={false}
          onClick={() => null}
        />
      )}
      {endPoint && (
        <AdvancedMarker
          icon={EndIndicator}
          position={{ lat: Number(endPoint.lat), lng: Number(endPoint.lng) }}
          selected={false}
          onClick={() => null}
        />
      )}
    </>
  );
}

export default RouteMarkers;
