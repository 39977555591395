import { IconButton, Box, Stack, Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material";

import { styles } from "./styles";

import DepthIndicatorLayout from "@/components/DepthShading/DepthIndicatorLayout";
import BaseModal from "@/components/Modals/BaseModal";
import useDisclosure from "@/hooks/useDisclosure";
import DepthShadingModal from "@/components/Modals/DepthShadingModal";
import { IDepthShadingData } from "@/types/map";
import { useAppDispatch } from "@/hooks/useRedux";
import { setDepthShadingDrawerOpen } from "@/store/slices/depthshading";
import { addOpenMapOptions } from "@/store/slices/map";
import usePremiumUser from "@/hooks/usePremiumUser";

interface IDepthIndicator {
  disabled?: boolean;
  depthShadingData: IDepthShadingData;
}

function DepthIndicator({ depthShadingData, disabled = false }: IDepthIndicator) {
  const dispatch = useAppDispatch();
  const { isOpen, onClose } = useDisclosure();
  const isPremium = usePremiumUser();

  return (
    <Box sx={disabled ? styles.depthIndicatorDisabled : styles.depthIndicator}>
      <Stack className="overlay" direction="row" sx={styles.overlay}>
        {depthShadingData.data.items
          .filter((item) => {
            if (!isPremium) {
              return item.default_flag;
            }

            return item.current;
          })
          .map((item) => (
            <DepthIndicatorLayout key={item.id} shaderItems={item.depth_shader_items} />
          ))}

        {depthShadingData.data.items.length > 1 && (
          <Tooltip arrow placement="top" title="Change Depth Shading">
            <IconButton
              className="editButton"
              color="secondary"
              size="small"
              sx={styles.editButton}
              onClick={() => {
                dispatch(setDepthShadingDrawerOpen(true));
                dispatch(addOpenMapOptions(false));
              }}
            >
              <Edit sx={{ fontSize: 20 }} />
            </IconButton>
          </Tooltip>
        )}

        <BaseModal open={isOpen} size="xs" title="Depth Shading" onClose={onClose}>
          <DepthShadingModal shadingItems={depthShadingData.data.items} onClose={onClose} />
        </BaseModal>
      </Stack>
    </Box>
  );
}

export default DepthIndicator;
