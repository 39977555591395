import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Radio,
  RadioGroup,
  FormControl,
} from "@mui/material";
import { CoordinatesFormats } from "@argonav/unit-system";

import { styles } from "./styles";

interface ISettingsModalCoordinate {
  coordinateSettings: CoordinatesFormats;
  onSetCoordinatesSettings: (value: CoordinatesFormats) => void;
}

function SettingsModalCoordinate({
  onSetCoordinatesSettings,
  coordinateSettings,
}: ISettingsModalCoordinate) {
  return (
    <FormControl sx={{ width: "100%" }}>
      <RadioGroup
        aria-labelledby="radio settings"
        name="coordinate-settings"
        sx={styles.radioGroup}
      >
        <List disablePadding sx={{ width: "100%" }}>
          <ListItem disablePadding divider>
            <ListItemButton selected sx={styles.listItemButton}>
              <ListItemText
                primary="Decimal Degrees (DD)"
                primaryTypographyProps={{
                  variant: "subtitle1",
                }}
              />
              <ListItemIcon>
                <Radio
                  checked={coordinateSettings === CoordinatesFormats.DD}
                  size="small"
                  onClick={() => onSetCoordinatesSettings(CoordinatesFormats.DD)}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding divider>
            <ListItemButton sx={styles.listItemButton}>
              <ListItemText
                primary="Degrees and Decimal Minutes (DDM)"
                primaryTypographyProps={{
                  variant: "subtitle1",
                }}
              />
              <ListItemIcon>
                <Radio
                  checked={coordinateSettings === CoordinatesFormats.DDM}
                  size="small"
                  onClick={() => onSetCoordinatesSettings(CoordinatesFormats.DDM)}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton sx={styles.listItemButton}>
              <ListItemText
                primary="Degrees, Minutes and Seconds (DMS)"
                primaryTypographyProps={{
                  variant: "subtitle1",
                }}
              />
              <ListItemIcon>
                <Radio
                  checked={coordinateSettings === CoordinatesFormats.DMS}
                  size="small"
                  onClick={() => onSetCoordinatesSettings(CoordinatesFormats.DMS)}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </RadioGroup>
    </FormControl>
  );
}

export default SettingsModalCoordinate;
