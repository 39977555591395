import { alpha } from "@mui/material/styles";

import { layoutStyles } from "@/themes/layoutStyles";
import defaultTheme from "@/themes/theme";

export const styles = {
  mainContainer: {
    display: "flex",
    height: "100vh",
    width: "100vw",
    alignItems: "center",
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center",
    p: 4,
  },

  paper: {
    width: "460px",
    minWidth: "460px",
    overflow: "hidden",
    height: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  scrollContainer: {
    maxHeight: "100%",
    width: "100%",
    overflowY: "auto",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start",
    p: 4,
    ...layoutStyles.scroll,
  },

  goBackButton: {
    position: "absolute",
    top: "32px",
    left: "32px",
    border: "2px solid",
    bordercolor: `${alpha(defaultTheme.palette.secondary.main, 0.3)}`,
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
  },

  argoLogo: {
    fontSize: 96,
    mb: 3,
    mt: 1,
    cursor: "pointer",
  },

  textContainer: {
    p: 8,
    maxWidth: "960px",
  },
};
