import { Stack, Skeleton } from "@mui/material";

export default function RouteResultSkeleton() {
  return (
    <Stack px={2} py={2.5} spacing={3} sx={{ width: "100%" }}>
      <Skeleton animation="wave" sx={{ fontSize: "16px" }} variant="text" width="60%" />
      <Stack alignItems="center" direction="row" spacing={2.5}>
        <Skeleton
          animation="wave"
          height={32}
          style={{ flexShrink: 0 }}
          variant="circular"
          width={32}
        />
        <Stack sx={{ width: "100%" }}>
          <Skeleton animation="wave" sx={{ fontSize: "16px" }} variant="text" width="60%" />
          <Skeleton animation="wave" sx={{ fontSize: "16px" }} variant="text" width="40%" />
        </Stack>
      </Stack>
      <Stack alignItems="center" direction="row" spacing={2.5}>
        <Skeleton
          animation="wave"
          height={32}
          style={{ flexShrink: 0 }}
          variant="circular"
          width={32}
        />
        <Stack sx={{ width: "100%" }}>
          <Skeleton animation="wave" sx={{ fontSize: "16px" }} variant="text" width="60%" />
          <Skeleton animation="wave" sx={{ fontSize: "16px" }} variant="text" width="40%" />
        </Stack>
      </Stack>
      <Stack alignItems="center" direction="row" spacing={2.5}>
        <Skeleton
          animation="wave"
          height={32}
          style={{ flexShrink: 0 }}
          variant="circular"
          width={32}
        />
        <Stack sx={{ width: "100%" }}>
          <Skeleton animation="wave" sx={{ fontSize: "16px" }} variant="text" width="60%" />
          <Skeleton animation="wave" sx={{ fontSize: "16px" }} variant="text" width="40%" />
        </Stack>
      </Stack>
      <Stack alignItems="center" direction="row" spacing={2.5}>
        <Skeleton
          animation="wave"
          height={32}
          style={{ flexShrink: 0 }}
          variant="circular"
          width={32}
        />
        <Stack sx={{ width: "100%" }}>
          <Skeleton animation="wave" sx={{ fontSize: "16px" }} variant="text" width="60%" />
          <Skeleton animation="wave" sx={{ fontSize: "16px" }} variant="text" width="40%" />
        </Stack>
      </Stack>
      <Stack alignItems="center" direction="row" spacing={2.5}>
        <Skeleton
          animation="wave"
          height={32}
          style={{ flexShrink: 0 }}
          variant="circular"
          width={32}
        />
        <Stack sx={{ width: "100%" }}>
          <Skeleton animation="wave" sx={{ fontSize: "16px" }} variant="text" width="60%" />
          <Skeleton animation="wave" sx={{ fontSize: "16px" }} variant="text" width="40%" />
        </Stack>
      </Stack>
      <Skeleton animation="wave" height={40} variant="rounded" width={180} />
    </Stack>
  );
}
