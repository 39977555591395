export const styles = {
  dialogActions: {
    px: 3,
    py: 2,
  },

  routePointsBox: {
    border: "1px solid",
    borderColor: "divider",
    borderRadius: 1,
    "& .routePointsItem": {
      borderBottom: "1px solid",
      borderColor: "divider",
      px: 2,
      py: 1.5,
    },
    "& .routePointsItem:last-of-type": {
      borderBottom: "none",
    },
  },
};
