import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";

export const styles = {
  poiTitle: {
    lineHeight: "normal",
    color: "primary.dark",
  },

  header: {
    p: 3,
    borderRadius: 1,
    backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.04)}`,
    border: "1px solid",
    borderColor: `${alpha(defaultTheme.palette.primary.main, 0.1)}`,
  },
};
