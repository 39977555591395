import React from "react";
import { alpha, styled } from "@mui/system";
import {
  Avatar,
  Stack,
  Typography,
  Box,
  IconButton,
  List,
  ListItem,
  Link,
  Tooltip,
} from "@mui/material";
import { UploadFile, InsertDriveFileOutlined, Delete } from "@mui/icons-material";

import { styles } from "./styles";

const DropZoneButton = styled((props: any) => <Box {...props} />)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  width: "100%",
  border: "1px dashed",
  backgroundColor: `${alpha(theme.palette.primary.main, 0.04)}`,
  borderColor: `${alpha(theme.palette.primary.main, 0.3)}`,
  transition: theme.transitions.create(["all", "transform"], {
    duration: theme.transitions.duration.standard,
  }),
  "& .MuiAvatar-root": {
    color: theme.palette.primary.main,
    backgroundColor: `${alpha(theme.palette.primary.main, 0.12)}`,
  },

  "&:hover": {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.12)}`,
    borderColor: theme.palette.primary.main,
  },
}));

interface IDropZone {
  files: File[];
  action?: (e: any) => void;
}

export default function GpxDropzone({
  action,
  files,
  multiple,
}: IDropZone & { multiple?: boolean }) {
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { files } = e.dataTransfer;

    if (files && files.length > 0) {
      if (action) action(files);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files && files.length > 0) {
      if (action) action(files);
    }
  };

  const handleDeleteFile = (name: string) => {
    const updatedFiles = Array.from(files).filter((file: File) => file.name !== name);

    if (action) action(updatedFiles);
  };

  return (
    <Box>
      <DropZoneButton
        onDragOver={(e: React.DragEvent<HTMLDivElement>) => e.preventDefault()}
        onDrop={(e: React.DragEvent<HTMLDivElement>) => handleDrop(e)}
      >
        <Stack alignItems="center" spacing={0.5} sx={styles.container}>
          <Avatar color="primary.main">
            <UploadFile />
          </Avatar>
          <Stack direction="row">
            <label htmlFor="gpxFile">
              <input
                hidden
                accept=".gpx"
                id="gpxFile"
                multiple={multiple}
                name="gpxFile"
                type="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileChange(e)}
              />
              <Link sx={styles.uploadTitle} variant="subtitle1">
                Click to upload{" "}
              </Link>
            </label>

            <Typography sx={styles.dragTitle} variant="subtitle1">
              or drag and drop
            </Typography>
          </Stack>
          <Typography color="text.secondary" variant="body2">
            GPX Format Only (max. 3MB)
          </Typography>
          {files && files.length > 0 && (
            <List sx={styles.list}>
              {files.map((file: File) => (
                <ListItem key={file.name} divider sx={styles.listItem}>
                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Stack alignItems="center" direction="row" spacing={2}>
                      <Avatar
                        src={file.type === ".gpx" ? URL.createObjectURL(file) : ""}
                        sx={styles.avatar}
                        variant="square"
                      >
                        {file.type !== ".gpx" && <InsertDriveFileOutlined />}
                      </Avatar>
                      <Box>
                        <Typography lineHeight="normal" sx={styles.fileName} variant="body2">
                          {file.name}
                        </Typography>
                        <Typography color="text.secondary" variant="body2">
                          {(file.size / 1024).toFixed(2)} kb
                        </Typography>
                      </Box>
                    </Stack>
                    <Tooltip arrow placement="top" title="Delete Upload">
                      <IconButton onClick={() => handleDeleteFile(file.name)}>
                        <Delete sx={{ fontSize: 20 }} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </ListItem>
              ))}
            </List>
          )}
        </Stack>
      </DropZoneButton>
    </Box>
  );
}
