export const styles = {
  listItemAvatar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    mr: 1.5,
    "& .MuiSvgIcon-root": {
      fontSize: 20,
      color: "action.active",
      mb: 0.25,
    },
    "& .MuiTypography-caption": {
      color: "text.secondary",
      lineHeight: "normal",
    },
  },
};
