import React, { useEffect, useState } from "react";
import { Box, Divider } from "@mui/material";
import axios, { CancelToken } from "axios";
import { useMap } from "@vis.gl/react-google-maps";

import SearchAccordion from "../Search/SearchAccordion";
import SearchCategoriesCarousel from "../Search/SearchCategoriesCarousel";

import RouteSearchBox from "./RouteSearchBox";
import RouteResult from "./RouteResult";
import RouteResultSkeleton from "./RouteResultSkeleton";
import { styles } from "./styles";

import SearchResults from "@/components/Search/SearchResults";
import { IMapPois } from "@/types/pois";
import { Position, Route as RouteType, RoutePosition } from "@/types/map";
import { findBounds, evaluateRoutePaddingByResolution } from "@/utils/maps";
import ServicesMap from "@/services/map";
import { useAppSelector, useAppDispatch } from "@/hooks/useRedux";
import {
  addEndPoint,
  addError,
  addRouteCreated,
  addStartPoint,
  setCancelRouteToken,
  setDepthAlert,
  setInputsFocus,
} from "@/store/slices/route";
import { TPOI_TYPE } from "@/utils/poi";
import useSearch from "@/hooks/useSearch";
import { addCenter } from "@/store/slices/map";
import { feetToMeter, vesselDefault, getPriorityCostFactor } from "@/utils/boats";
import { ERouteSettings } from "@/types/route";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { CANCEL_ROUTE_CHANGE_MSG, CANCEL_ROUTE_USER_CANCEL_MSG } from "@/utils/route";
import { getFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from "@/utils/globals";

interface IRoute {
  onDeleteCategory: (value: TPOI_TYPE) => void;
  onSearchCategory: (value: TPOI_TYPE | undefined, action: boolean, clearAll: any) => void;
  onTakeScreenshot: () => Promise<File | null>;
}

export default function Route({ onDeleteCategory, onSearchCategory, onTakeScreenshot }: IRoute) {
  const map = useMap();
  const dispatch = useAppDispatch();
  const routeStore = useAppSelector((state) => state.route);
  const [routeLoading, setRouteLoading] = useState<boolean>(false);
  const [lockedValue, setLockedValue] = useState<boolean>(false);

  const { startPoint, endPoint, routeCreated, inputsFocus, cancelRouteToken } = routeStore;
  const { voyageEditMode } = useAppSelector((state) => state.captainslog);

  const { poiList, loading, selectedCategories } = useAppSelector((state) => state.search);
  const { userInformation, userOptions } = useAppSelector((state) => state.user);

  const { setQuery, setCleanPoiList, setCleanPoiCategory } = useSearch();
  const [startInput, setStartInput] = useState<string>("");
  const [endInput, setEndInput] = useState<string>("");

  const handleSuggestionsOpenPoint = async (
    e: React.ChangeEvent<HTMLInputElement>,
    type: number,
  ) => {
    if (type === 1) {
      if (e.target.value !== "") {
        setStartInput(e.target.value);
      } else {
        setStartInput("");
        dispatch(addStartPoint(null));
        dispatch(addRouteCreated(null));
      }
    } else if (e.target.value !== "") {
      setEndInput(e.target.value);
    } else {
      setEndInput("");
      dispatch(addEndPoint(null));
      dispatch(addRouteCreated(null));
    }

    setQuery(e.target.value);
  };

  const handleSelectPoiRoute = (poi: IMapPois | RoutePosition, inputType: number) => {
    if (inputType === 1) {
      setStartInput("");
      dispatch(
        addStartPoint({
          lat: poi.lat,
          lng: poi.lng,
          name: `${poi.name}`,
        }),
      );
    } else if (inputType === 2) {
      setEndInput("");
      dispatch(
        addEndPoint({
          lat: poi.lat,
          lng: poi.lng,
          name: `${poi.name}`,
        }),
      );
    }
    setCleanPoiList();
    setCleanPoiCategory();
    dispatch(
      addCenter({
        lat: poi && parseFloat(String(poi.lat)),
        lng: poi && parseFloat(String(poi.lng)),
      }),
    );
  };

  const setRoute = async (
    startPoint: Position | null,
    endPoint: Position | null,
    cancelToken?: CancelToken,
  ) => {
    if (startPoint && endPoint) {
      setRouteLoading(true);
      const prioritycostfactor = getPriorityCostFactor(startPoint, endPoint);
      const avoidOcean = userOptions.route_settings === ERouteSettings.Avoid;
      const avoidInland = userOptions.route_settings === ERouteSettings.Outside;
      const hug_shore =
        userOptions.route_settings === ERouteSettings.Outside ? userOptions.hug_shore : undefined;

      try {
        const payloadData = {
          end_lat: endPoint.lat,
          end_lng: endPoint.lng,
          start_lat: startPoint.lat,
          start_lng: startPoint.lng,
          avoid_ocean: avoidOcean,
          avoid_inland: avoidInland,
          min_depth: userInformation?.current_vessel
            ? userInformation.current_vessel.draft * feetToMeter
            : vesselDefault.defaultDraft,
          buffer_depth: userInformation?.current_vessel
            ? userInformation.current_vessel.buffer * feetToMeter
            : vesselDefault.defaultBuffer,
          bridge_vert: userInformation?.current_vessel
            ? userInformation.current_vessel.height * feetToMeter
            : vesselDefault.defaultHeight * feetToMeter,
          bridge_horiz: userInformation?.current_vessel
            ? userInformation.current_vessel.beam * feetToMeter
            : undefined,
          height: userInformation?.current_vessel
            ? userInformation.current_vessel.height * feetToMeter
            : vesselDefault.defaultHeight * feetToMeter,
          prioritycostfactor,
          hug_shore,
          access: "canada", // TODO: Set this conditionally based on valid subscriptions.
        };

        const { status, data } = await ServicesMap.createRoute(payloadData, cancelToken);

        if (status === 200) {
          setRouteLoading(false);

          return data;
        }
      } catch (error: any) {
        if (error.message !== CANCEL_ROUTE_CHANGE_MSG) setRouteLoading(false);
        dispatch(addError(error.toString()));
        dispatch(
          setSnackBarMsjSucceded({ state: true, type: "error", msj: error.response.data.message }),
        );
      }
    } else {
      return null;
    }
  };

  const handleCenterOnRoute = (route: RouteType) => {
    const bounds = findBounds(route.course_locations);
    const screenWidth = window.innerWidth;
    const mapBoundsConfig = evaluateRoutePaddingByResolution(screenWidth);

    if (map && bounds) {
      map.fitBounds(
        {
          east: bounds.eastmost.lng,
          north: bounds.northmost.lat,
          south: bounds.southmost.lat,
          west: bounds.westmost.lng,
        },
        mapBoundsConfig.boundsPadding,
      );
    }
  };

  const handleCreateRoute = async (cancelToken?: CancelToken) => {
    dispatch(addRouteCreated(null));
    const result = await setRoute(startPoint, endPoint, cancelToken);

    if (result) {
      dispatch(addRouteCreated(result));
      handleCenterOnRoute(result);
    }
  };

  useEffect(() => {
    const storedStartPoint = getFromLocalStorage("startPointLocked");

    if (storedStartPoint && !voyageEditMode) {
      setLockedValue(true);
      dispatch(
        addStartPoint({
          lat: storedStartPoint.lat,
          lng: storedStartPoint.lng,
          name: `${storedStartPoint.name}`,
        }),
      );
      dispatch(
        addCenter({
          lat: storedStartPoint && parseFloat(String(storedStartPoint.lat)),
          lng: storedStartPoint && parseFloat(String(storedStartPoint.lng)),
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (startPoint) {
      setStartInput(startPoint.name);
      if (!endPoint) {
        dispatch(setInputsFocus(2));
      }
    }
    if (endPoint) {
      setEndInput(endPoint.name);
      if (!startPoint) {
        dispatch(setInputsFocus(1));
      }
    }

    if (startPoint && endPoint) {
      setRouteLoading(false);
      cancelRouteToken?.cancel(CANCEL_ROUTE_CHANGE_MSG);
      const source = axios.CancelToken.source();

      dispatch(setCancelRouteToken(source));
      handleCreateRoute(source.token);
    }

    return () => {
      setStartInput("");
      setEndInput("");
    };
  }, [startPoint, endPoint, userOptions, userInformation]);

  const switchLocations = () => {
    dispatch(addStartPoint(endPoint));
    dispatch(addEndPoint(startPoint));
  };

  const handleSwitchFocus = (type: number) => {
    if (type === 1) {
      dispatch(setInputsFocus(1));
    } else {
      dispatch(setInputsFocus(2));
    }
  };

  const handleClearRouteInput = (inputNumber: number) => {
    if (startPoint && endPoint) {
      cancelRouteToken?.cancel(CANCEL_ROUTE_USER_CANCEL_MSG);
    }
    if (inputNumber === 1) {
      dispatch(addStartPoint(null));
      dispatch(setInputsFocus(1));
      setStartInput("");
    } else {
      dispatch(addEndPoint(null));
      dispatch(setInputsFocus(2));
      setEndInput("");
    }

    dispatch(addRouteCreated(null));
    dispatch(setDepthAlert(false));
    setCleanPoiList();
    setCleanPoiCategory();
  };

  const handleUnlockStartPoint = () => {
    setLockedValue(false);
    removeFromLocalStorage("startPointLocked");
  };

  const handleLockStartPoint = () => {
    setLockedValue(true);
    saveToLocalStorage("startPointLocked", startPoint);
  };

  return (
    <Box>
      <RouteSearchBox
        clearRouteInput={handleClearRouteInput}
        endInput={endInput}
        inputsFocus={inputsFocus}
        lockedValue={lockedValue}
        setFocus={handleSwitchFocus}
        setPoint={handleSuggestionsOpenPoint}
        startInput={startInput}
        switchLocations={switchLocations}
        voyageEditMode={voyageEditMode}
        onLockStartPoint={handleLockStartPoint}
        onUnlockStartPoint={handleUnlockStartPoint}
      />
      <Box sx={styles.scrollContainer}>
        {selectedCategories.length > 0 && (
          <>
            <SearchCategoriesCarousel
              selectedCategories={selectedCategories}
              onDeleteCategory={onDeleteCategory}
              onSetInputValue={onSearchCategory}
            />
            <Divider />
          </>
        )}
        {routeLoading ? (
          <>
            <RouteResultSkeleton />
            <Divider />
          </>
        ) : (
          <>
            {!routeCreated && !loading && !poiList && (
              <SearchAccordion
                inputType={inputsFocus}
                selectedCategories={selectedCategories}
                setPoiToRoute={handleSelectPoiRoute}
                onSetInputValue={onSearchCategory}
              />
            )}
            <SearchResults inputType={inputsFocus} setPoiToRoute={handleSelectPoiRoute} />
            {!poiList && <RouteResult onTakeScreenshot={onTakeScreenshot} />}
          </>
        )}
      </Box>
    </Box>
  );
}
