import {
  ListItem,
  Box,
  ListItemText,
  Switch,
  Divider,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { styles } from "./styles";
import DepthIndicator from "./DepthIndicator";

import { useUpdateGlobalOpacityMutation } from "@/store/api/argoApi";
import { useAppSelector, useAppDispatch } from "@/hooks/useRedux";
import userService from "@/services/user";
import { setUserOptions } from "@/store/slices/user";

interface IMapOptionDepthShading {
  depthShadingData: any;
  shaderOpacity: number;
  shaderOpacityEnabled: boolean;
}

function MapOptionDepthShading({
  depthShadingData,
  shaderOpacity,
  shaderOpacityEnabled,
}: IMapOptionDepthShading) {
  const dispatch = useAppDispatch();
  const { userOptions } = useAppSelector((state) => state.user);
  const [updateGlobalOpacity] = useUpdateGlobalOpacityMutation();
  const [sliderOpacity, setSliderOpacity] = useState<number | number[]>(shaderOpacity);
  const shadingContourLines = userOptions?.depth_shading_contour_lines_enabled;

  const handleSwitch = async () => {
    if (depthShadingData) {
      updateGlobalOpacity({ depth_shading_enabled: !shaderOpacityEnabled });
    }
  };

  const handleSwitchContourLines = async () => {
    if (depthShadingData) {
      const { status } = await userService.editUserOptions({
        depth_shading_contour_lines_enabled: !shadingContourLines,
      });

      if (status === 200) {
        dispatch(
          setUserOptions({
            ...userOptions,
            depth_shading_contour_lines_enabled: !shadingContourLines,
          }),
        );
      }
    }
  };

  const handleChangeOpacity = (e: Event, newValue: number | number[]) => {
    setSliderOpacity(newValue as number);
  };

  const handleSliderTransparency = () => {
    updateGlobalOpacity({ shader_opacity: sliderOpacity as number });
  };

  return (
    <>
      <Divider />
      <ListItem sx={styles.listItem}>
        <ListItemText
          id="#"
          primary="Depth Shading"
          primaryTypographyProps={{
            variant: "subtitle2",
          }}
        />
        <Switch
          checked={shaderOpacityEnabled}
          color="secondary"
          edge="end"
          size="small"
          onClick={handleSwitch}
        />
      </ListItem>
      <Box pb={2} px={2}>
        <Stack sx={styles.depthShadingContainer}>
          <Box sx={styles.depthIndicatorContainer}>
            <DepthIndicator
              depthShadingData={depthShadingData}
              disabled={!(shaderOpacityEnabled && depthShadingData)}
            />
          </Box>
          <Divider />
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={styles.sliderContainer}
          >
            <Typography
              color={shaderOpacityEnabled && depthShadingData ? "text.primary" : "text.disabled"}
              lineHeight="normal"
              variant="body2"
            >
              Contour Lines
            </Typography>
            <Switch
              checked={shadingContourLines}
              color="secondary"
              edge="end"
              size="small"
              onClick={handleSwitchContourLines}
            />
          </Stack>
          <Divider />
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={styles.sliderContainer}
          >
            <Typography
              color={shaderOpacityEnabled && depthShadingData ? "text.primary" : "text.disabled"}
              lineHeight="normal"
              variant="body2"
            >
              Brightness
            </Typography>
            <Slider
              aria-label="transparency"
              color="secondary"
              disabled={!(shaderOpacityEnabled && depthShadingData)}
              max={255}
              min={0}
              size="small"
              value={sliderOpacity}
              onChange={handleChangeOpacity}
              onChangeCommitted={handleSliderTransparency}
            />
          </Stack>
        </Stack>
      </Box>
    </>
  );
}

export default MapOptionDepthShading;
