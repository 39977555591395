import React, { useEffect } from "react";
import { Paper, Divider, Box, Collapse, Stack, ClickAwayListener } from "@mui/material";

import SearchAccordion from "../SearchAccordion";
import SearchInput from "../SearchInput";
import SearchCategoriesCarousel from "../SearchCategoriesCarousel";

import { styles } from "./styles";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { TPOI_TYPE } from "@/utils/poi";
import useSearch from "@/hooks/useSearch";
import { setPremiumSubscriptionModal } from "@/store/slices/user";
import SearchCategoriesOnMap from "@/components/Search/SearchCategoriesOnMap";
import { addSelectedPoiFromMap, setOpenAccordion, setShowSearchArea } from "@/store/slices/search";
import { setCoords } from "@/store/slices/map";
import { addPointSearchedByCoords } from "@/store/slices/route";
import {
  setReportPinSelectedDrawer,
  setTempPin,
  setTempPinCopy,
  setAllowCoordinates,
  setMyPinsDrawer,
} from "@/store/slices/reportpins";
import { setBoaterInfoDrawer } from "@/store/slices/boaters";
import { setDepthShadingDrawerOpen, setDepthShadingToEdit } from "@/store/slices/depthshading";
import usePremiumUser from "@/hooks/usePremiumUser";
import { argoApi } from "@/store/api/argoApi";

interface ISearchBox {
  action: () => void;
  onSearchCategory: (value: TPOI_TYPE | undefined, action: boolean, clearAll: any) => void;
  onDeleteCategory: (value: TPOI_TYPE) => void;
}

export type TInputValue = {
  value: string;
  keyCode?: string;
};

export default function SearchBox({ onSearchCategory, action, onDeleteCategory }: ISearchBox) {
  const dispatch = useAppDispatch();
  const isPremium = usePremiumUser();
  const { logged, user } = useAppSelector((state) => state.auth);
  const { openAccordion, showSearchArea } = useAppSelector((state) => state.search);
  const { creatingRoute, coordinateValuesSetted } = useAppSelector((state) => state.route);
  const { boaterSelectedInfoDrawer } = useAppSelector((state) => state.boaters);
  const { depthShadingDrawerOpen } = useAppSelector((state) => state.depthshading);
  const { reportPinSelectedDrawer, tempPin, tempPinCopy, allowCoordinates, myPinsDrawer } =
    useAppSelector((state) => state.reportpins);
  const { coords } = useAppSelector((state) => state.map);
  const { selectedPoi, poiList, selectedCategories, loading, selectedPoiFromMap } = useAppSelector(
    (state) => state.search,
  );
  const { query, setQuery, setCleanSelectedPoi, setCleanPoiCategory, setCleanPoiList } =
    useSearch();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    if (depthShadingDrawerOpen) {
      dispatch(setDepthShadingDrawerOpen(false));
      dispatch(setDepthShadingToEdit(null));
    }
  };

  const handleClickAwayListener = () => {
    if (!selectedPoi && !poiList && !coordinateValuesSetted) {
      setQuery("");

      if (!creatingRoute) dispatch(addPointSearchedByCoords(null));
      dispatch(setOpenAccordion(false));
    }
  };

  const handleCloseSearch = () => {
    dispatch(setOpenAccordion(false));
    setCleanSelectedPoi();

    dispatch(addSelectedPoiFromMap(false));
    if (poiList) setCleanPoiList();
    if (selectedCategories) setCleanPoiCategory();
    if (showSearchArea) dispatch(setShowSearchArea(false));

    if (reportPinSelectedDrawer) dispatch(setReportPinSelectedDrawer(null));
    if (boaterSelectedInfoDrawer) dispatch(setBoaterInfoDrawer(null));
    if (tempPin) dispatch(setTempPin(undefined));
    if (tempPinCopy) dispatch(setTempPinCopy(undefined));
    if (allowCoordinates) dispatch(setAllowCoordinates(false));
    if (myPinsDrawer?.open) dispatch(setMyPinsDrawer({ open: false, type: "places" }));
    if (myPinsDrawer?.open) dispatch(setMyPinsDrawer({ open: false, type: "places" }));
    if (depthShadingDrawerOpen) {
      dispatch(setDepthShadingDrawerOpen(false));
      dispatch(setDepthShadingToEdit(null));
      if (!isPremium) {
        dispatch(
          argoApi.util.updateQueryData("getDepthShadings", { user_id: user?.id }, (draft) => {
            if (draft?.data?.items?.length > 0) {
              draft.data.items = draft.data.items.map((item: any) => ({
                ...item,
                current: item.default_flag === true,
              }));
            }
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (selectedCategories.length > 0) {
      dispatch(setOpenAccordion(true));
    }
  }, [selectedCategories]);

  const handleOpenAccordion = () => {
    if (logged) {
      dispatch(setOpenAccordion(true));
      if (coords) dispatch(setCoords(undefined));
    } else {
      dispatch(setPremiumSubscriptionModal(true));
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAwayListener}>
      <Paper elevation={3} sx={styles.paper}>
        <Stack direction="row" sx={styles.searchBox}>
          <SearchInput
            disabled={!logged}
            inputValue={query || ""}
            isLoading={loading}
            readyToBack={!!(poiList || selectedPoiFromMap)}
            readyToClose={
              !!selectedPoi ||
              !!poiList ||
              !!boaterSelectedInfoDrawer ||
              !!reportPinSelectedDrawer ||
              !!tempPin ||
              myPinsDrawer?.open ||
              depthShadingDrawerOpen
            }
            onClose={handleCloseSearch}
            onCreateRoute={action}
            onOpen={handleOpenAccordion}
            onSearch={handleSearch}
          />
        </Stack>
        <Divider />
        {logged && (
          <>
            <Box sx={styles.searchCategoriesOnMap}>
              <SearchCategoriesOnMap
                selectedCategoriesForSearch={selectedCategories}
                onSetInputValue={onSearchCategory}
              />
            </Box>
            <Collapse unmountOnExit in={logged && openAccordion} timeout="auto">
              <Box sx={styles.scrollContainer}>
                {selectedCategories.length > 0 && (
                  <SearchCategoriesCarousel
                    selectedCategories={selectedCategories}
                    onDeleteCategory={onDeleteCategory}
                    onSetInputValue={onSearchCategory}
                  />
                )}
                {!poiList && !selectedPoi && !loading && (
                  <SearchAccordion
                    selectedCategories={selectedCategories}
                    onSetInputValue={onSearchCategory}
                  />
                )}
              </Box>
            </Collapse>
          </>
        )}
      </Paper>
    </ClickAwayListener>
  );
}
