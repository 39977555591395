import { Stack, Paper } from "@mui/material";

import { styles } from "./styles";
import SideMenuButtons from "./SideMenuButtons/SideMenuButtons";

import ArgoLogo from "@/components/ArgoLogo";
import PremiumButton from "@/components/Premium/PremiumButton";

function SideMenu() {
  return (
    <Paper component="nav" elevation={0} sx={styles.paper}>
      <Stack alignItems="center" direction="column" spacing={4}>
        <Stack alignItems="center" direction="column" spacing={1.5}>
          <ArgoLogo color="primary" sx={{ fontSize: 56 }} />
        </Stack>
        <SideMenuButtons />
      </Stack>
      <PremiumButton />
    </Paper>
  );
}

export default SideMenu;
