import { Menu, MenuItem, ListItemIcon, ListItemText, Box, IconButton } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import React, { useState } from "react";

interface IQuickMenu {
  options?: {
    icon: React.ReactElement<React.ElementType>;
    text: string | React.ReactNode;
    action?: () => void;
  }[];
}

function QuickMenu({ options }: IQuickMenu) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        aria-label="more"
        id="long-button"
        size="small"
        onClick={handleClick}
      >
        <MoreVert sx={{ fontSize: 20 }} />
      </IconButton>
      <Menu
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        id="long-menu"
        open={open}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
      >
        {options?.map((option) => (
          <MenuItem
            key={option.text as string}
            onClick={() => {
              if (option?.action) {
                option?.action();
              }
              handleClose();
            }}
          >
            {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
            {option.text && <ListItemText>{option.text}</ListItemText>}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

export default QuickMenu;
