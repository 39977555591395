/* global google */
import TideAndCurrentsList from "./TideAndCurrentsList";

import { useGetTideAndCurrentsQuery } from "@/store/api/argoApi";
import { getRegionFromBounds } from "@/utils/maps";
import { TRegionData } from "@/types/map";
import { useAppSelector } from "@/hooks/useRedux";
import { useThrottle } from "@/hooks/useThrottle";

interface ITideAndCurrents {
  bounds: google.maps.LatLngBounds;
  zoom: number;
}

function TideAndCurrents({ zoom, bounds }: ITideAndCurrents) {
  const region: TRegionData = getRegionFromBounds(bounds);
  const throttledRegion = useThrottle(region);
  const { poisConfigured } = useAppSelector((state) => state.map);

  const { data } = useGetTideAndCurrentsQuery(
    {
      sw_latitude: throttledRegion.southWestLat,
      sw_longitude: throttledRegion.southWestLng,
      ne_latitude: throttledRegion.northEastLat,
      ne_longitude: throttledRegion.northEastLng,
      page_total: 50,
    },
    {
      skip: !throttledRegion.hasRegion,
    },
  );

  if (!data?.data?.items) {
    return null;
  }

  return (
    <TideAndCurrentsList items={data.data.items} poisConfigured={poisConfigured} zoom={zoom} />
  );
}

export default TideAndCurrents;
