import { EFriendshipStatus, EMapVisibility } from "@/types/friends";
import { IFriends } from "@/types/users";

interface PrivacyHandlerProps {
  user?: IFriends;
  noPrivacy: boolean;
}

export const privacyHandler = ({ user, noPrivacy = true }: PrivacyHandlerProps) => {
  if (noPrivacy) {
    return { showMapImage: true, showProfileImage: true };
  }

  if (user === null || user?.user_setting === null) {
    return {
      showMapImage: false,
      showProfileImage: false,
    };
  }

  const friendshipStatus = user?.friendship_status;
  const mapVisibility = user?.user_setting?.map_visibility;

  // Friend
  if (friendshipStatus === EFriendshipStatus.Friends) {
    return { showMapImage: true, showProfileImage: true };
  }

  // Non-Friend Public
  if (mapVisibility === EMapVisibility.Public) {
    return { showMapImage: true, showProfileImage: true };
  }

  // Non-Friend Private
  return { showMapImage: false, showProfileImage: false };
};
