import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";

export const styles = {
  wrapper: {
    "& .Container:first-of-type": {
      borderTop: "none",
    },
  },

  container: {
    p: 1.5,
    borderTop: "1px solid",
    borderColor: "divider",
  },

  chip: {
    border: "1px solid",
    borderColor: "transparent",
  },

  chipSelected: {
    border: "1px solid",
    color: "primary.main",
    backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.08)}`,

    "& .MuiSvgIcon-root": {
      fontSize: "18px",
      color: `${alpha(defaultTheme.palette.primary.main, 0.7)}`,
    },

    "&:hover": {
      backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.12)}`,
      "& .MuiSvgIcon-root": {
        color: "primary.main",
      },
    },
  },
};
