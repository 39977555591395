export const styles = {
  stack: {
    width: "100%",
  },

  divider: {
    mt: 0.5,
  },

  uploadTitle: {
    cursor: "pointer",
  },

  container: {
    width: "100%",
  },

  dragTitle: {
    ml: 0.5,
  },

  fileName: {
    color: "black",
  },

  avatar: {
    borderRadius: 0.5,
    width: 48,
    height: 48,
  },

  list: {
    mt: "16px !important",
    mb: "-8px !important",
    borderRadius: 1,
    border: "1px solid",
    borderColor: "divider",
    overflow: "hidden",
    backgroundColor: "common.white",
    width: "100%",
    py: 0,

    "& .MuiListItem-root:last-of-type": {
      borderBottom: "none",
    },
  },

  listItem: {
    py: 2,
    width: "100%",
    backgroundColor: "background.paper",
  },
};
