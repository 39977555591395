export const styles = {
  listItemAvatar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    mr: 1.5,

    "& .MuiSvgIcon-root": {
      color: "action.active",
      fontSize: 32,
      ml: -0.5,
      mb: 0.5,
      backgroundColor: "grey.300",
      borderRadius: "16px",
    },
    "& .MuiTypography-caption": {
      color: "text.secondary",
      lineHeight: "normal",
    },
  },

  secondaryText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
  },
};
