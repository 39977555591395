import axios from "./config";

export default {
  getPaymentIntent: () =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v3+json" },
      method: "post",
      url: "/create_payment_intent",
    }),
  setSubscriptionIntent: (data: { platform: string; email: string | null; localhost?: boolean }) =>
    axios.request({
      method: "post",
      url: "/api/v1/user_subscriptions",
      data,
    }),
  updateUserPremiumStatus: (idSub: string) =>
    axios.request({
      method: "post",
      url: `/api/v1/user_subscriptions/${idSub}/sync`,
    }),
  canselSubscription: () =>
    axios.request({
      method: "delete",
      url: `/api/v1/user_subscriptions/latest/cancel`,
    }),
  updateSubscription: (id: string, data: { code: string }) =>
    axios.request({
      method: "put",
      url: `/api/v1/user_subscriptions/${id}`,
      data,
    }),
};
