import { Box } from "@mui/material";

import TideAndCurrentsMarker from "./TideAndCurrentsMarker";

import { ITideAndCurrent } from "@/types/tideandcurrents";
import { TpoisConfigured } from "@/types/map";

interface ITideAndCurrentsList {
  items: ITideAndCurrent[];
  poisConfigured: TpoisConfigured;
  zoom: number;
}

function TideAndCurrentsList({ items, zoom, poisConfigured }: ITideAndCurrentsList) {
  return (
    <Box>
      {items
        ?.filter((item: any) => {
          if (poisConfigured.tide && item.kind === "tide") return true;
          if (poisConfigured.current && item.kind === "current") return true;

          return false;
        })
        .map((item: any) => (
          <TideAndCurrentsMarker key={item.id} tideAndCurrent={item} zoom={zoom} />
        ))}
    </Box>
  );
}

export default TideAndCurrentsList;
