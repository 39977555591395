export enum IconKeysPois {
  Anchorage = "Anchorage",
  ArtificialReef = "Artificial Reef",
  Beach = "Beach",
  BoatRamp = "BoatRamp",
  BoatRamp2 = "Boat Ramp",
  Bridge = "Bridge",
  BridgeClearance = "BridgeClearance",
  Business = "Business",
  CityTown = "City/Town",
  ClubAssociation = "Club/Association",
  Dam = "Dam",
  DockBarRestaurant = "Dock Bar/Restaurant",
  FreeDock = "Free Dock",
  FishingSpot = "Fishing Spot",
  Hotel = "Hotel",
  Inlet = "Inlet",
  LightHouse = "Light House",
  Lock = "Lock",
  Marina = "Marina",
  Mooring = "Mooring",
  Other = "Other",
  PointOfInterest = "Point of Interest",
  RestaurantBar = "Restaurant/Bar",
  RestrictedArea = "Restricted Area",
  YachtClub = "Yacht Club",
  MyLocation = "my_location",
  GpsLocation = "gps_location",
  BoatClub = "Boat Club",
  PrivateDock = "Private Dock",
}

export enum IconKeysNavAidsBeacons {
  beacon_black_clearLight = "beacon_black_clearLight",
  beacon_black_greenLight = "beacon_black_greenLight",
  beacon_black_light = "beacon_black_light",
  beacon_black_noLight = "beacon_black_noLight",
  beacon_black_redLight = "beacon_black_redLight",
  beacon_black_whiteLight = "beacon_black_whiteLight",
  beacon_black_yellowLight = "beacon_black_yellowLight",
  beacon_green_clearLight = "beacon_green_clearLight",
  beacon_green_light = "beacon_green_light",
  beacon_green_noLight = "beacon_green_noLight",
  beacon_grey_light = "beacon_grey_light",
  beacon_grey_noLight = "beacon_grey_noLight",
  beacon_GRG_greenLight = "beacon_GRG_greenLight",
  beacon_GRG_noLight = "beacon_GRG_noLight",
  beacon_orange_light = "beacon_orange_light",
  beacon_orange_noLight = "beacon_orange_noLight",
  beacon_orangeWhite_light = "beacon_orangeWhite_light",
  beacon_orangeWhite_noLight = "beacon_orangeWhite_noLight",
  beacon_red_clearLight = "beacon_red_clearLight",
  beacon_red_light = "beacon_red_light",
  beacon_red_noLight = "beacon_red_noLight",
  beacon_RGR_noLight = "beacon_RGR_noLight",
  beacon_RGR_redLight = "beacon_RGR_redLight",
  beacon_undefined = "beacon_undefined",
  beacon_undefined_light = "beacon_undefined_light",
  beacon_white_light = "beacon_white_light",
  beacon_white_noLight = "beacon_white_noLight",
  beacon_yellow_light = "beacon_yellow_light",
  beacon_yellow_noLight = "beacon_yellow_noLight",
}

export enum IconKeysNavAidsBuoys {
  buoy_black_light = "buoy_black_light",
  buoy_black_noLight = "buoy_black_noLight",
  buoy_BRB_light = "buoy_BRB_light",
  buoy_BRB_noLight = "buoy_BRB_noLight",
  buoy_green_light = "buoy_green_light",
  buoy_green_noLight = "buoy_green_noLight",
  buoy_grey_light = "buoy_grey_light",
  buoy_grey_noLight = "buoy_grey_noLight",
  buoy_GRG_light = "buoy_GRG_light",
  buoy_GRG_noLight = "buoy_GRG_noLight",
  buoy_orange_light = "buoy_orange_light",
  buoy_orange_noLight = "buoy_orange_noLight",
  buoy_orangeWhite_light = "buoy_orangeWhite_light",
  buoy_orangeWhite_noLight = "buoy_orangeWhite_noLight",
  buoy_red_light = "buoy_red_light",
  buoy_red_noLight = "buoy_red_noLight",
  buoy_redWhite_light = "buoy_redWhite_light",
  buoy_redWhite_noLight = "buoy_redWhite_noLight",
  buoy_RGR_light = "buoy_RGR_light",
  buoy_RGR_noLight = "buoy_RGR_noLight",
  buoy_white_light = "buoy_white_light",
  buoy_white_noLight = "buoy_white_noLight",
  buoy_yellow_light = "buoy_yellow_light",
  buoy_yellow_noLight = "buoy_yellow_noLight",
}

export enum IconKeysNavAidsOthers {
  restricted_area = "restricted_area",
}

export enum IconKeysPrivatePlaces {
  Anchorage = "Anchorage",
  ArtificialReef = "Artificial Reef",
  Beach = "Beach",
  BoatRamp = "BoatRamp",
  BoatRamp2 = "Boat Ramp",
  Bridge = "Bridge",
  Business = "Business",
  CityTown = "City/Town",
  ClubAssociation = "Club/Association",
  Dam = "Dam",
  DockBarRestaurant = "Dock Bar/Restaurant",
  FreeDock = "Free Dock",
  FishingSpot = "Fishing Spot",
  Hotel = "Hotel",
  Inlet = "Inlet",
  LightHouse = "Light House",
  Lock = "Lock",
  Marina = "Marina",
  Mooring = "Mooring",
  Other = "Other",
  PointOfInterest = "Point of Interest",
  RestaurantBar = "Restaurant/Bar",
  YachtClub = "Yacht Club",
  MyLocation = "my_location",
  MyLocation2 = "Mylocation",
  Places = "Places",
  GpsLocation = "gps_location",
  BoatClub = "Boat Club",
  PrivateDock = "Private Dock",
}

export enum IconKeysPrivatePlacesUnderReview {
  Anchorage = "Anchorage",
  ArtificialReef = "Artificial Reef",
  Beach = "Beach",
  BoatClub = "Boat Club",
  BoatRamp = "BoatRamp",
  Bridge = "Bridge",
  Business = "Business",
  CityTown = "City/Town",
  ClubAssociation = "Club/Association",
  Dam = "Dam",
  DockBarRestaurant = "Dock Bar/Restaurant",
  FreeDock = "Free Dock",
  FishingSpot = "Fishing Spot",
  GpsLocation = "gps_location",
  Hotel = "Hotel",
  Inlet = "Inlet",
  LightHouse = "Light House",
  Lock = "Lock",
  Marina = "Marina",
  Mooring = "Mooring",
  Other = "Other",
  Places = "Places",
  PointOfInterest = "Point of Interest",
  RestaurantBar = "Restaurant/Bar",
  YachtClub = "Yacht Club",
}

export enum IconKeysReportPins {
  noWakeZone = "no_wake_zone",
  sos = "sos",
  police = "police",
  localDevice = "local_advice",
  weather = "weather",
  hazard = "hazard",
  mapIssue = "map_issue",
  photo = "photo",
  socialPost = "social_post",
  marineLife = "marine_life",
  seaLife = "Sea Life",
  // hazard subtypes
  floating = "Floating",
  protruding = "Protruding",
  submerged = "Submerged",
  otherHazard = "OtherHazard",
  // sos subtypes
  outOfFuel = "Out of Fuel",
  engineTrouble = "Engine Trouble",
  ranAground = "Ran Aground",
  accidentCollision = "Accident/Collision",
  boatFire = "Boat Fire",
  medicalEmergency = "Medical Emergency",
  manOverboard = "Man Overboard",
  otherSos = "OtherSos",
}

export enum IconKeysNoaaHazards {
  rock = "rock",
  obstruction = "obstruction",
  wreck = "wreck",
  dangerous_wreck = "dangerous_wreck",
  non_dangerous_wreck = "non_dangerous_wreck",
  exposed_wreck = "exposed_wreck",
}
