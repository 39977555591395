import { Box, Divider, Typography, IconButton, Stack } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";

import { styles } from "./styles";

export default function ProfileSubtitle({ title, action, divider, edit }: any) {
  return (
    <Box sx={styles.container}>
      <Stack alignItems="center" direction="row" justifyContent="space-between" sx={styles.stack}>
        <Typography component="h4" variant="subtitle1">
          {title}
        </Typography>
        {edit && (
          <IconButton aria-label="delete" color="primary" size="small" onClick={action}>
            <EditOutlined sx={{ fontSize: 20 }} />
          </IconButton>
        )}
      </Stack>
      {divider && <Divider light sx={styles.divider} />}
    </Box>
  );
}
