import BoaterMarker from "./BoaterMarker";

import { IFriends } from "@/types/users";

interface IBoatersList {
  items: IFriends[];
}

function BoatersList({ items }: IBoatersList) {
  return (
    <>
      {items.map((boater: IFriends) => (
        <BoaterMarker
          key={boater.id}
          boater={boater}
          hasCommonAffiliation={boater?.affiliated_poi_in_common > 0}
          hasCommonGroup={boater?.groups_in_common > 0}
          position={{ lat: Number(boater.lat), lng: Number(boater.lng) }}
        />
      ))}
    </>
  );
}

export default BoatersList;
