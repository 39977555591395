import axios, { AxiosResponseHeaders } from "axios";
import dayjs from "dayjs";

import { store } from "@/store/store";
import { setLoggedUser, removeUser } from "@/store/slices/auth/actions";
import { fetchUserSucceded } from "@/store/slices/user";
import apiConfig from "@/config/api";

const instance = axios.create({
  baseURL: apiConfig.apiBaseURL,
  timeout: apiConfig.timeout,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Origin": "*",
    Accept: "application/vnd.argo.v2+json",
  },
});

instance.interceptors.request.use(
  async (config) => {
    const userAuth = localStorage.getItem("auth");
    const auth: AxiosResponseHeaders = userAuth ? JSON.parse(userAuth) : null;

    if (auth && config.headers) {
      const currentTime = dayjs();
      const expirationTime = dayjs.unix(auth.expiry);
      const expired = auth.expiry ? currentTime.isAfter(expirationTime) : true;

      if (expired) {
        try {
          const { status, headers } = await axios.request({
            method: "get",
            url: `${import.meta.env.VITE_APP_URL_BASE}friend_requests`,
            headers: {
              uid: auth.uid,
              client: auth.client,
              "access-token": auth["access-token"],
            },
          });

          if (status === 200) {
            localStorage.setItem(
              "auth",
              JSON.stringify({
                client: headers.client,
                uid: headers.uid,
                expiry: headers.expiry,
                "access-token": headers["access-token"],
              }),
            );
            config.headers["access-token"] = headers["access-token"];
            config.headers.client = headers.client;
            config.headers.uid = headers.uid;
            config.headers.expiry = headers.expiry;
          }
        } catch (error: any) {
          store.dispatch(setLoggedUser(false));
          store.dispatch(fetchUserSucceded(undefined));
          localStorage.removeItem("user");
          localStorage.removeItem("auth");
          store.dispatch(removeUser());

          setTimeout(() => {
            if (window) window.location.reload();
          }, 1000);
        }
      } else {
        config.headers["access-token"] = auth["access-token"];
        config.headers.client = auth.client;
        config.headers.uid = auth.uid;
        config.headers.expiry = auth.expiry;
      }
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export default instance;
