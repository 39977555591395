// Icons Import
import { IconKeysNoaaHazards } from "@/types/markers";
import { INOAAHazards } from "@/types/navaids";
import dangerous_wreck from "@/assets/img/markers/noaahazards/3x/dangerous_wreck.png";
import exposed_wreck from "@/assets/img/markers/noaahazards/3x/exposed_wreck.png";
import non_dangerous_wreck from "@/assets/img/markers/noaahazards/3x/non_dangerous_wreck.png";
import obstruction from "@/assets/img/markers/noaahazards/3x/obstruction.png";
import rocks from "@/assets/img/markers/noaahazards/3x/rocks.png";

export const getNoaaHazardIcon = (hazard: INOAAHazards) => {
  switch (hazard.kind) {
    case IconKeysNoaaHazards.rock:
      return rocks;
    case IconKeysNoaaHazards.obstruction:
      if (hazard.icon === IconKeysNoaaHazards.obstruction) {
        return obstruction;
      }
      break;
    case IconKeysNoaaHazards.wreck:
      if (hazard.icon === IconKeysNoaaHazards.dangerous_wreck) {
        return dangerous_wreck;
      }
      if (hazard.icon === IconKeysNoaaHazards.non_dangerous_wreck) {
        return non_dangerous_wreck;
      }
      if (hazard.icon === IconKeysNoaaHazards.exposed_wreck) {
        return exposed_wreck;
      }
      break;
    default:
      return obstruction;
  }
};
