import axios from "./config";

import { TVessel } from "@/types/vessel";

export default {
  getVessels: () => axios.get("/vessels"),
  getVesselById: (id: number) =>
    axios.request({
      method: "get",
      url: `api/v1/vessels/${id}`,
    }),
  editVessel: (id: number | undefined, payload: TVessel) => axios.put(`/vessels/${id}`, payload),
  createVessel: (payload: TVessel) => axios.post(`/vessels`, payload),
};
