import { RootState, AppDispatch } from "@/store/reducers";
import userServices from "@/services/user";
import { EditUserInfoPayload, UserInformation } from "@/types/users";
import { Interest } from "@/types/interest";
import { Affiliation } from "@/types/affiliation";
import { TVessel } from "@/types/vessel";
import { IMapPois } from "@/types/pois";
import assetService from "@/services/assets";
import { getFileName } from "@/utils/uploadAsset";
import ServiceVessel from "@/services/vessel";

const fetchStarted = () => ({
  type: "user/fetchStarted",
});

const fetchFailed = (error: any) => ({
  type: "user/fetchFailed",
  payload: error,
});

const getSearchHistorySuccess = (data: IMapPois[]) => ({
  type: "user/fetchSearchHistorySucceded",
  payload: data,
});

export const getFavoritesSuccess = (data: IMapPois[]) => ({
  type: "user/fetchFavoritesSucceded",
  payload: data,
});

export const getVesselsSuccess = (data: TVessel[]) => ({
  type: "user/fetchVesselsSucceded",
  payload: data,
});

const getAffiliationsSuccess = (data: Affiliation[]) => ({
  type: "user/fetchAffiliationsSucceded",
  payload: data,
});

const getUserSuccess = (data: UserInformation) => ({
  type: "user/fetchUserSucceded",
  payload: data,
});

const getUserInterestsSuccess = (data: Interest[]) => ({
  type: "user/fetchUserInterestsSucceded",
  payload: data,
});

const editUserSuccess = (data: EditUserInfoPayload) => ({
  type: "user/editUserSucceded",
  payload: data,
});

export const editAvatarSuccess = (data: string) => ({
  type: "user/editAvatarSucceded",
  payload: data,
});

export const editCoverSuccess = (data: string) => ({
  type: "user/editCoverSucceded",
  payload: data,
});

export const setUserOptions = (data: string) => ({
  type: "user/setUserOptions",
  payload: data,
});

export const getSearchHistory = () => async (dispatch: AppDispatch) => {
  dispatch(fetchStarted());
  try {
    const searchHistory: any = await userServices.getSearchHistory();

    dispatch(getSearchHistorySuccess(searchHistory.data));

    return true;
  } catch (error: any) {
    dispatch(fetchFailed(error.toString()));

    return false;
  }
};

export const getFavorites = () => async (dispatch: AppDispatch) => {
  dispatch(fetchStarted());
  try {
    const favorites: any = await userServices.getFavorites();

    dispatch(getFavoritesSuccess(favorites.data));

    return true;
  } catch (error: any) {
    dispatch(fetchFailed(error.toString()));

    return false;
  }
};

export const getVessels = () => async (dispatch: AppDispatch) => {
  dispatch(fetchStarted());
  try {
    const vessels: any = await ServiceVessel.getVessels();

    dispatch(getVesselsSuccess(vessels.data));

    return true;
  } catch (error: any) {
    dispatch(fetchFailed(error.toString()));

    return false;
  }
};

export const getAffiliations = () => async (dispatch: AppDispatch) => {
  dispatch(fetchStarted());
  try {
    const affiliations: any = await userServices.getAffiliations();

    dispatch(getAffiliationsSuccess(affiliations.data));

    return true;
  } catch (error: any) {
    dispatch(fetchFailed(error.toString()));

    return false;
  }
};

export const getUserInformation = (userId: number | undefined) => async (dispatch: AppDispatch) => {
  dispatch(fetchStarted());
  let result;

  try {
    if (userId) {
      result = await userServices.getUser(userId);

      dispatch(getUserSuccess(result.data));
    } else {
      dispatch(fetchFailed("Invalid user id"));

      return false;
    }

    return result.data;
  } catch (error: any) {
    dispatch(fetchFailed(error.toString()));

    return false;
  }
};

export const getUserOptions = () => async (dispatch: AppDispatch) => {
  dispatch(fetchStarted());
  try {
    const { status, data } = await userServices.getUserOptions();

    if (status === 200) {
      dispatch(setUserOptions(data.data));

      return true;
    }
  } catch (error: any) {
    dispatch(fetchFailed(error.toString()));

    return false;
  }
};

export const setUserOptionsFavorites = (favoritedData: any) => async (dispatch: AppDispatch) => {
  dispatch(fetchStarted());
  try {
    const { status, data } = await userServices.editUserOptions(favoritedData);

    if (status === 200) {
      dispatch(setUserOptions(data.data));

      return true;
    }
  } catch (error: any) {
    dispatch(fetchFailed(error.toString()));

    return false;
  }
};

export const updateUserOptions = () => async (dispatch: AppDispatch) => {
  dispatch(fetchStarted());
  try {
    const { status, data } = await userServices.getUserOptions();

    if (status === 200) {
      dispatch(setUserOptions(data));

      return true;
    }
  } catch (error: any) {
    dispatch(fetchFailed(error.toString()));

    return false;
  }
};

export const getInterests = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(fetchStarted());
  try {
    const userId = getState().auth.user?.id;

    if (userId) {
      const result: any = await userServices.getInsterests(userId);

      dispatch(getUserInterestsSuccess(result.data));
    } else {
      dispatch(fetchFailed("Invalid user id"));

      return false;
    }

    return true;
  } catch (error: any) {
    dispatch(fetchFailed(error.toString()));

    return false;
  }
};

export const updateUser = (userPayload: EditUserInfoPayload) => async (dispatch: AppDispatch) => {
  dispatch(fetchStarted());
  try {
    await userServices.editUser(userPayload);
    dispatch(editUserSuccess(userPayload));

    return true;
  } catch (error: any) {
    dispatch(fetchFailed(error.toString()));

    return false;
  }
};

export const createAvatar =
  (file: File) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const userId = getState().auth.user?.id;
    const fileName = getFileName(file);

    const {
      asset: { download_url },
    } = await assetService.add({
      entityId: `${userId}`,
      entityType: "userAvatar",
      file,
      fileName,
    });

    if (download_url) {
      dispatch(editAvatarSuccess(download_url));

      return true;
    }

    return false;
  };

export const createCover =
  (file: File) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const userId = getState().auth.user?.id;
    const fileName = getFileName(file);

    const {
      asset: { download_url },
    } = await assetService.add({
      entityId: `${userId}`,
      entityType: "userCover",
      file,
      fileName,
    });

    if (download_url) {
      dispatch(editCoverSuccess(download_url));

      return true;
    }

    return false;
  };

export const createBoatImage = (file: File, boatId: number) => async () => {
  const fileName = getFileName(file);

  const {
    asset: { download_url },
  } = await assetService.add({
    entityId: `${boatId}`,
    entityType: "userBoat",
    file,
    fileName,
  });

  if (download_url) {
    return true;
  }

  return false;
};

export const createPlacePinImage = (file: File, placepinId: number) => async () => {
  const fileName = getFileName(file);

  const {
    asset: { download_url },
  } = await assetService.add({
    entityId: `${placepinId}`,
    entityType: "places",
    file,
    fileName,
  });

  if (download_url) {
    return true;
  }

  return false;
};

export const createReportPinImage = (file: File, reportpinId: number) => async () => {
  const fileName = getFileName(file);

  const { status } = await assetService.addApi({
    entityId: `${reportpinId}`,
    entityType: "reportpins",
    file,
    fileName,
  });

  if (status === "ok") {
    return true;
  }

  return false;
};

export const createVoyageImage = (file: File, voyageId: number) => async () => {
  const fileName = getFileName(file);

  const {
    asset: { download_url },
  } = await assetService.add({
    entityId: `${voyageId}`,
    entityType: "voyage",
    file,
    fileName,
  });

  if (download_url) {
    return true;
  }

  return false;
};

export const deleteAvatar = (id: number | undefined) => async (dispatch: AppDispatch) => {
  if (id) {
    const { status } = await userServices.deleteAvatar(id);

    if (status === 204) {
      dispatch(editAvatarSuccess(""));

      return true;
    }
  }

  return false;
};

export const deleteCover = (id: number | undefined) => async (dispatch: AppDispatch) => {
  if (id) {
    const { status } = await userServices.deleteCover(id);

    if (status === 204) {
      dispatch(editCoverSuccess(""));

      return true;
    }
  }

  return false;
};
