import React from "react";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { Tooltip, CircularProgress, Checkbox } from "@mui/material";
import { SxProps } from "@mui/system";

import { styles } from "./styles";

interface IFavoriteCheckbox {
  checked: boolean;
  onClick: () => void;
  size?: "extrasmall" | "small" | "medium";
  sx?: SxProps;
  tooltipPlacement?: "top" | "bottom" | "left" | "right";
  loading?: boolean;
}

function FavoriteCheckbox({
  checked,
  onClick,
  size = "medium",
  sx,
  tooltipPlacement = "top",
  loading = false,
}: IFavoriteCheckbox) {
  const getSize = () => {
    switch (size) {
      case "extrasmall":
        return 16;
      case "small":
        return 20;
      case "medium":
        return 24;
      default:
        return 24;
    }
  };

  return (
    <Tooltip
      arrow
      placement={tooltipPlacement}
      title={checked ? "Remove from Favorites" : "Add to Favorites"}
    >
      <Checkbox
        checked={checked}
        checkedIcon={loading ? <CircularProgress size={getSize()} /> : <Favorite />}
        icon={loading ? <CircularProgress size={getSize()} /> : <FavoriteBorder />}
        size={size as "small" | "medium"}
        sx={{ ...styles.favoriteCheckbox, ...sx }}
        onClick={onClick}
      />
    </Tooltip>
  );
}

export default FavoriteCheckbox;
