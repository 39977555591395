import {
  SpeedUnits,
  LengthUnits,
  VolumeUnits,
  TemperatureUnits,
  CoordinatesFormats,
  UnitSystemProviderWrapper,
} from "@argonav/unit-system";

import App from "./App";

function ContainerApp() {
  const units = {
    speedUnit: SpeedUnits.KNOT,
    depthUnit: LengthUnits.FEET,
    fuelUnit: VolumeUnits.GALLONS,
    temperatureUnit: TemperatureUnits.FAHRENHEIT,
    coordinatesFormat: CoordinatesFormats.DD,
  };

  return (
    <UnitSystemProviderWrapper units={units}>
      <App />
    </UnitSystemProviderWrapper>
  );
}

export default ContainerApp;
