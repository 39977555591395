import { layoutStyles } from "@/themes/layoutStyles";

export const styles = {
  paper: {
    position: "absolute",
    left: "calc(50% - 8rem)",
    width: "18.5rem",
    bottom: 16,
    zIndex: 20,
    px: 2,
    py: 1.5,
  },

  pinButton: {
    color: "primary.light",
    "& .MuiSvgIcon-root": {
      fontSize: 22,
    },
    width: "30px",
    height: "30px",
  },

  routeButton: {
    ...layoutStyles.routeButton,
  },
};
