import { Avatar, IconButton, Typography } from "@mui/material";
import { useUnitSystem, LengthUnits, SpeedUnits } from "@argonav/unit-system";

import AdvancedMarker from "../AdvancedMarker";

import { styles } from "./styles";

import TideAndCurrentsPopover from "@/components/MapPopovers/TideAndCurrentsPopover";
import { useAppSelector } from "@/hooks/useRedux";
import usePremiumUser from "@/hooks/usePremiumUser";
import { ITideAndCurrent } from "@/types/tideandcurrents";
import CurrentUpIcon from "@/assets/img/markers/tideandcurrents/3x/CurrentUp.png";
import CurrentUpMinIcon from "@/assets/img/markers/tideandcurrents/3x/CurrentUpMin.png";
import TideUpIcon from "@/assets/img/markers/tideandcurrents/3x/TideUp.png";
import TideUpMinIcon from "@/assets/img/markers/tideandcurrents/3x/TideUpMin.png";
import TideDownIcon from "@/assets/img/markers/tideandcurrents/3x/TideDown.png";
import TideDownMinIcon from "@/assets/img/markers/tideandcurrents/3x/TideDownMin.png";
import CurrentUpPopoverIcon from "@/assets/img/markers/tideandcurrents/3x/popover/CurrentUp.png";
import TideDownPopoverIcon from "@/assets/img/markers/tideandcurrents/3x/popover/TideDown.png";
import TideUpPopoverIcon from "@/assets/img/markers/tideandcurrents/3x/popover/TideUp.png";

interface ITideAndCurrentsMarker {
  tideAndCurrent: ITideAndCurrent;
  zoom: number;
}

function TideAndCurrentsMarker({ tideAndCurrent, zoom }: ITideAndCurrentsMarker) {
  const isPremium = usePremiumUser();
  const { logged } = useAppSelector((state) => state.auth);
  const { depthUnitHandler, speedUnitHandler } = useUnitSystem();

  const { value: depth, unit: depthUnit } = depthUnitHandler({
    originalUnit: LengthUnits.FEET,
    originalValue: Number(tideAndCurrent.depth),
  });

  const { value: speed, unit: speedUnit } = speedUnitHandler({
    originalUnit: SpeedUnits.KNOT,
    originalValue: Number(tideAndCurrent.speed),
  });

  const isSmall = Number(zoom) <= 12;
  const isTide = tideAndCurrent.kind === "tide";

  const iconUrlCurrent = isSmall ? CurrentUpMinIcon : CurrentUpIcon;

  const iconUrlTide =
    tideAndCurrent.tide_status === "rising"
      ? isSmall
        ? TideUpMinIcon
        : TideUpIcon
      : isSmall
      ? TideDownMinIcon
      : TideDownIcon;

  const icon = () => (
    <IconButton sx={styles.marker}>
      <Avatar
        alt="Tiden And Current Marker"
        src={isTide ? iconUrlTide : iconUrlCurrent}
        sx={
          isSmall
            ? isTide
              ? styles.avatarSmall
              : {
                  ...styles.avatarSmall,
                  transform: `rotate(${tideAndCurrent.direction || 0}deg)`,
                }
            : isTide
            ? styles.avatar
            : { ...styles.avatar, transform: `rotate(${tideAndCurrent.direction || 0}deg)` }
        }
      />
      {!isSmall && isTide && (
        <>
          <Typography sx={styles.avatarPrimaryText} variant="body2">
            {depth}
          </Typography>
          <Typography sx={styles.avatarSecondaryText} variant="body2">
            {depthUnit}
          </Typography>
        </>
      )}
      {!isSmall && !isTide && (
        <Typography sx={styles.avatarPrimaryTextCurrent} variant="body2">
          {speed}
        </Typography>
      )}
    </IconButton>
  );

  return (
    <AdvancedMarker
      key={tideAndCurrent.id}
      icon={icon()}
      position={{ lat: Number(tideAndCurrent.lat), lng: Number(tideAndCurrent.lng) }}
      selected={false}
      onClick={() => null}
    >
      {logged && isPremium && (
        <TideAndCurrentsPopover
          icon={
            isTide
              ? tideAndCurrent.tide_status === "rising"
                ? TideUpPopoverIcon
                : TideDownPopoverIcon
              : CurrentUpPopoverIcon
          }
          isTide={isTide}
          measureValue={
            isTide ? { value: depth, unit: depthUnit } : { value: speed, unit: speedUnit }
          }
          tideAndCurrent={tideAndCurrent}
        />
      )}
    </AdvancedMarker>
  );
}

export default TideAndCurrentsMarker;
