import { layoutStyles } from "@/themes/layoutStyles";

export const styles = {
  drawer: {
    width: 408,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: 408,
      ml: "80px",
      boxSizing: "border-box",
      boxShadow: 12,
      borderRight: "none",
      overflow: "hidden",
      zIndex: 6,
    },
  },

  container: {
    mt: "61.667px",
  },

  header: {
    pt: 2.5,
    pb: 2,
    px: 2,
  },

  mainContent: {
    ...layoutStyles.scroll,
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "calc(100vh - 120px)",
    px: 2,
    pt: 1,
    pb: 1.5,
  },
};
