export const styles = {
  passwordItems: {
    listStyleType: "disc",
    "& li": {
      pt: 0.5,
      pb: 0,
    },
  },
  listItemIcon: {
    minWidth: "20px",
  },
  icon: {
    color: "black",
    fontSize: "6px",
  },
};
