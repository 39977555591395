import { Stack, Typography, Avatar } from "@mui/material";
import camelCase from "lodash.camelcase";
import { useLocation } from "react-router-dom";
import { useMap } from "@vis.gl/react-google-maps";

import { styles } from "./styles";

// import FavoriteCheckbox from "@/components/FavoriteCheckbox";
import { pinMarkerSubtypeHandler } from "@/utils/reportPinItems";
import dayjs from "@/config/dayjs";
import { getPlaceType, snakeCaseToHumanReadable } from "@/utils/globals";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { setReportPinSelectedDrawer } from "@/store/slices/reportpins";
import { setBoaterInfoDrawer } from "@/store/slices/boaters";
import { addSelectedPoi } from "@/store/slices/search";
import { DEFAULT_ZOOM } from "@/utils/maps";
import servicePins from "@/services/pins";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { EKindReportPin } from "@/types/reportpins";
import { addZoomParam } from "@/store/slices/mapParams";

function PinsCard({ item }: any) {
  const map = useMap();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { logged } = useAppSelector((state) => state.auth);
  const { creatingRoute } = useAppSelector((state) => state.route);
  const { boaterSelectedInfoDrawer } = useAppSelector((state) => state.boaters);
  // const { favorites } = useAppSelector((state) => state.user);
  const { reportPinSelectedDrawer } = useAppSelector((state) => state.reportpins);
  const { selectedPoi } = useAppSelector((state) => state.search);
  const previous: dayjs.Dayjs = dayjs(item.created_at);
  const clogpage: boolean = location.pathname === "/captains-log";

  const iconUrl = item?.place_type
    ? `pois/${item.submit ? "review" : "private"}/${getPlaceType(item?.place_type)}`
    : item?.kind
    ? `reportpins/${camelCase(pinMarkerSubtypeHandler(item) as string)}`
    : `quickpin/QuickPin`;

  const handleGetPinReviewsById = async (id: number) => {
    try {
      const { data, status } = await servicePins.getReportPinReviewsById(id);

      if (status === 200) {
        return data.data.items;
      }

      return null;
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));

      return null;
    }
  };

  const handleSetPin = async () => {
    if (logged && !clogpage) {
      if (!creatingRoute && item) {
        if (item?.kind) {
          const pinReviews = await handleGetPinReviewsById(item.id);

          if (selectedPoi) dispatch(addSelectedPoi(null));
          if (boaterSelectedInfoDrawer) dispatch(setBoaterInfoDrawer(null));
          dispatch(setReportPinSelectedDrawer({ reviews: pinReviews, ...item }));
          dispatch(addZoomParam(DEFAULT_ZOOM));
          if (map) {
            map.moveCamera({
              center: {
                lat: item && parseFloat(String(item.lat)),
                lng: parseFloat(String(item.lng)) - 0.0045,
              },
              zoom: DEFAULT_ZOOM,
            });
          }
        } else {
          if (boaterSelectedInfoDrawer) dispatch(setBoaterInfoDrawer(null));
          if (reportPinSelectedDrawer) dispatch(setReportPinSelectedDrawer(null));

          dispatch(addSelectedPoi(item));
          dispatch(addZoomParam(DEFAULT_ZOOM));
          if (map) {
            map.moveCamera({
              center: {
                lat: item && parseFloat(String(item.lat)),
                lng: parseFloat(String(item.lng)) - 0.0045,
              },
              zoom: DEFAULT_ZOOM,
            });
          }
        }
      }
    }
  };

  const handleGetKindAndSubKing = (pin: any) =>
    pin.subtype
      ? `${snakeCaseToHumanReadable(pin.subtype)} ${snakeCaseToHumanReadable(pin.kind)}`
      : snakeCaseToHumanReadable(pin.kind === EKindReportPin.seaLife ? "Sea Life" : pin.kind);

  return (
    <Stack spacing={1} sx={styles.pinCard} onClick={handleSetPin}>
      <Stack alignItems="center" direction="row" spacing={1.5}>
        <Avatar
          alt="Pin Icon"
          src={`/img/markers/${iconUrl}.svg`}
          sx={styles.pinIcon}
          variant="circular"
        />

        <Stack>
          <Typography variant="caption">{dayjs(previous).fromNow()}</Typography>
          <Typography color="secondary" lineHeight="normal" variant="subtitle2">
            {item?.kind ? handleGetKindAndSubKing(item) : item.name}
          </Typography>
        </Stack>
      </Stack>

      {item.description && (
        <Typography color="text.secondary" sx={styles.descriptionText} variant="body2">
          {item.description}
        </Typography>
      )}
    </Stack>
  );
}

export default PinsCard;
