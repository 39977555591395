import React from "react";
import { Dialog, DialogTitle, IconButton, Box, Stack, Typography } from "@mui/material";
import { Close, ArrowBack } from "@mui/icons-material";

import { styles } from "./styles";

interface IBaseModal {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  ariaDescribedby?: string;
  ariaLabelledby?: string;
  title?: string;
  open: boolean;
  onClose: () => void;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  darkHeader?: boolean;
  onGoBack?: () => void | undefined;
}

function BaseModal({
  children,
  icon,
  ariaDescribedby,
  ariaLabelledby,
  open,
  onClose,
  title,
  size = "sm",
  darkHeader,
  onGoBack,
}: IBaseModal) {
  return (
    <Dialog
      aria-describedby={ariaDescribedby || "modal description"}
      aria-labelledby={ariaLabelledby || "modal label"}
      maxWidth={size}
      open={open}
      sx={darkHeader ? styles.darkHeader : styles.lightHeader}
      onClose={onClose}
    >
      <Box className="titleBox" sx={!title ? styles.dialogNoTitle : styles.dialogTitle}>
        {onGoBack && (
          <Box sx={{ pl: 2 }}>
            <IconButton size="small" onClick={onGoBack}>
              <ArrowBack />
            </IconButton>
          </Box>
        )}

        <DialogTitle id="alert-dialog-title">
          <Stack alignItems="center" direction="row" spacing={1}>
            {icon}
            <Typography variant="h6">{title}</Typography>
          </Stack>
        </DialogTitle>
        <IconButton size="small" onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      {children}
    </Dialog>
  );
}

export default BaseModal;
