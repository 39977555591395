import { layoutStyles } from "@/themes/layoutStyles";

export const styles = {
  modalButton: {
    ml: 1,
    py: 0,
    px: 1,
    color: "error.dark",
  },

  dialogContent: {
    ...layoutStyles.scroll,
    py: 0,
  },

  dialogActions: {
    px: 3,
    py: 2,
  },

  invalidValueContainerInput: {
    width: "100%",
    mb: 2,
    "& p": {
      mb: 1,
    },
  },

  dmsContainerInput: {
    width: "100%",
  },

  containerInput: {
    width: "100%",
    mb: 3,
    "& p": {
      mb: 2,
    },
  },
};
