import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ArgoLogo(props: SvgIconProps) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 64 64">
      <path
        clipRule="evenodd"
        d="M47.0864 35.1732C49.9408 31.6362 48.3765 31.2008 46.5489 31.4588C44.9363 31.6873 42.7861 32.499 39.534 33.7246C30.248 37.232 19.7418 41.199 14.5384 36.7375C12.8398 35.4554 12.0631 33.445 13.1516 34.1035C18.2125 36.9794 27.2996 34.211 37.6499 30.5585C48.4007 26.7581 52.1931 27.4649 48.9383 32.6414L48.8979 32.7059C48.3411 33.5608 47.7354 34.3828 47.0837 35.1679V35.1679L47.0864 35.1732Z"
        fill="#1B9FEE"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M47.0757 35.2002C47.3633 34.8186 47.3982 34.746 47.3874 34.5471C47.0112 34.3133 46.2264 34.5901 42.4501 36.2914C33.9194 40.1187 26.4476 43.1423 21.3733 41.2287C19.7311 40.6912 17.2315 39.3473 20.5669 39.6752C21.4103 39.7684 22.2603 39.7864 23.1068 39.729C27.176 39.4602 32.3659 37.7535 39.2572 35.0524C45.1997 32.7248 50.8143 30.5263 47.0864 35.176L47.0757 35.2002Z"
        fill="#1B9FEE"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M47.0649 35.2005C42.4448 39.5707 37.0855 42.2718 30.67 42.7448C29.009 42.8658 27.4447 42.8765 27.3964 42.5594C27.3399 42.1697 27.8129 42.2691 29.2777 42.0218C30.8533 41.7421 32.4012 41.3239 33.9033 40.772C37.0925 39.619 40.185 38.2143 43.1516 36.5712C46.2855 35.0607 46.8553 34.8215 47.2073 34.8699C47.1848 34.9803 47.1429 35.0858 47.0837 35.1816V35.1816L47.0649 35.2005Z"
        fill="#1B9FEE"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M47.7072 34.4109C51.9 28.7506 51.04 25.8882 45.3689 26.7886C36.5157 28.1996 28.6461 32.9864 19.6155 33.7766C15.3151 34.1582 12.4608 33.188 10.6439 31.3066C9.03132 29.6348 8.52603 26.5171 9.48285 27.3046C11.8615 30.3256 17.9572 30.6777 33.2367 25.6114C41.2998 22.9398 54.7839 18.4702 50.6691 29.2371C50.6099 29.3849 50.5508 29.5327 50.4863 29.6805C50.4191 29.8391 50.3492 29.9977 50.2794 30.1563C50.2068 30.3121 50.1342 30.468 50.059 30.6239C49.9837 30.7798 49.9085 30.933 49.8278 31.0862L49.5859 31.5404V31.5539C49.5026 31.7071 49.4166 31.8576 49.3306 32.0054V32.0215C49.2419 32.172 49.1532 32.3199 49.0618 32.4677V32.4838C48.9705 32.6316 48.8764 32.7794 48.7931 32.9273C48.699 33.0751 48.5996 33.2202 48.5001 33.3654V33.3815C48.4007 33.5239 48.2985 33.6691 48.1937 33.8115C48.0889 33.954 47.9841 34.0937 47.8766 34.2335L47.8604 34.255L47.7879 34.3464"
        fill="#1B9FEE"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M32.0971 24.6759C35.798 13.0677 37.5988 6.15499 29.6405 1.96487C29.06 1.68267 28.7831 1.96487 29.2024 2.31427C33.1318 6.30281 31.1617 14.6347 28.3612 24.2136C28.182 24.8264 28.0189 25.4123 27.872 25.9714C29.2804 25.5494 30.6941 25.1113 32.0971 24.6759"
        fill="#1B9FEE"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M36.4646 23.3401C37.8945 19.3973 38.8835 15.8361 38.7303 12.8984C38.647 11.2858 37.8031 9.15447 36.943 7.74612C36.2738 6.64685 35.5992 5.98568 35.2605 6.35121C35.2041 6.41302 35.2444 6.58772 35.4003 6.83499C35.6701 7.36526 35.8793 7.92424 36.0238 8.50136C36.9484 12.119 35.8142 17.1154 33.4625 24.2513C34.4784 23.9368 35.4809 23.6277 36.4646 23.3401"
        fill="#1B9FEE"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M39.0743 22.6036C39.6892 21.0262 40.0914 19.374 40.2703 17.6905C40.5042 14.9114 39.2786 11.963 39.2786 14.1239C39.3014 14.8303 39.2708 15.5373 39.1872 16.2391C38.9184 18.1205 38.3083 20.3271 37.2762 23.1035C37.8837 22.9288 38.4857 22.7621 39.0743 22.6036"
        fill="#1B9FEE"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M25.9826 26.5218C27.3264 20.4557 29.9335 14.5804 29.8609 8.29921C29.8099 3.77582 28.1435 1.04243 24.9102 0.152806C24.3431 0.00767088 22.0532 -0.349793 23.3674 1.00749C25.7031 3.42642 26.0229 8.94157 22.8299 20.8346C22.2816 22.9041 21.6124 25.3714 21.2065 27.7339C22.7466 27.4033 24.3538 26.9841 25.9853 26.5218"
        fill="#1B9FEE"
        fillRule="evenodd"
      />
      <path
        d="M19.3694 57.193H14.4187L13.4753 60.0178H10.4731L15.5798 46.3105H18.1949L23.3257 60.0178H20.3209L19.3694 57.193ZM15.182 54.9058H18.6088L16.894 49.775L15.182 54.9058Z"
        fill="#08467E"
      />
      <path
        d="M30.6309 52.3824C30.3068 52.3353 29.98 52.3101 29.6525 52.3071C28.6231 52.3071 27.9485 52.6538 27.6287 53.3526V60.0181H24.9088V49.8318H27.4782L27.5534 51.0466C28.091 50.1113 28.8471 49.6436 29.8219 49.6436C30.1085 49.6416 30.3939 49.6823 30.6685 49.7646L30.6309 52.3824Z"
        fill="#08467E"
      />
      <path
        d="M31.8457 54.8499C31.8457 53.2857 32.2175 52.0269 32.9611 51.0737C33.3115 50.6115 33.7679 50.2404 34.2918 49.9915C34.8156 49.7427 35.3917 49.6234 35.9713 49.6438C37.0858 49.6438 37.9548 50.0264 38.5784 50.7915L38.6913 49.832H41.1586V59.6797C41.177 60.4961 40.9666 61.3015 40.5512 62.0045C40.1428 62.6643 39.5479 63.1881 38.8418 63.5097C38.0305 63.8707 37.1494 64.0478 36.2616 64.0284C35.5105 64.0272 34.7673 63.8745 34.0765 63.5795C33.4481 63.3414 32.8925 62.9441 32.4639 62.4265L33.668 60.7709C33.9698 61.1301 34.3472 61.4183 34.7732 61.6148C35.1993 61.8113 35.6634 61.9113 36.1326 61.9078C36.7495 61.9461 37.3565 61.7383 37.8205 61.3299C38.029 61.1128 38.1902 60.8547 38.2939 60.5721C38.3975 60.2894 38.4413 59.9882 38.4225 59.6878V59.1502C38.1153 59.5016 37.7339 59.7804 37.3058 59.9663C36.8777 60.1522 36.4136 60.2407 35.9471 60.2253C35.373 60.2392 34.8039 60.116 34.2869 59.8659C33.7699 59.6158 33.32 59.2461 32.9745 58.7874C32.2202 57.8306 31.8421 56.562 31.8403 54.9816L31.8457 54.8499ZM34.5683 55.0515C34.5683 55.9761 34.7511 56.699 35.122 57.2258C35.2888 57.4758 35.5164 57.6793 35.7835 57.817C36.0505 57.9548 36.3482 58.0223 36.6486 58.0133C37.4764 58.0133 38.0695 57.7016 38.4279 57.078V52.7777C38.2511 52.473 37.9924 52.2241 37.6812 52.0592C37.37 51.8944 37.0187 51.8202 36.6674 51.8451C36.3632 51.8363 36.0617 51.9058 35.792 52.047C35.5224 52.1882 35.2935 52.3963 35.1274 52.6514C34.7529 53.1889 34.5666 53.9872 34.5683 55.0461"
        fill="#08467E"
      />
      <path
        d="M43.306 54.8203C43.2914 53.8915 43.491 52.9718 43.8893 52.1326C44.2549 51.3677 44.8396 50.7287 45.5691 50.2969C46.3428 49.8536 47.2228 49.6297 48.1143 49.6491C48.7313 49.6247 49.3469 49.7236 49.9252 49.94C50.5035 50.1565 51.0327 50.4861 51.482 50.9097C52.3492 51.75 52.8339 52.8923 52.9361 54.3365L52.9549 55.0326C52.9549 56.5968 52.5186 57.8511 51.646 58.7954C50.7734 59.7396 49.6033 60.2064 48.1358 60.1957C46.6666 60.1957 45.4938 59.7253 44.6176 58.7846C43.7414 57.8439 43.3043 56.5637 43.306 54.9439V54.8203ZM46.026 55.0111C46.026 55.9823 46.2052 56.7223 46.5635 57.2311C46.7465 57.4692 46.9818 57.662 47.2511 57.7947C47.5205 57.9274 47.8167 57.9965 48.117 57.9965C48.4173 57.9965 48.7135 57.9274 48.9829 57.7947C49.2523 57.662 49.4875 57.4692 49.6705 57.2311C50.0414 56.7258 50.2269 55.9195 50.2269 54.8122C50.2269 53.8643 50.0414 53.1288 49.6705 52.6056C49.4972 52.3536 49.2628 52.1497 48.9892 52.0129C48.7156 51.8762 48.4119 51.811 48.1063 51.8235C47.8042 51.8108 47.504 51.8755 47.234 52.0114C46.964 52.1474 46.7332 52.35 46.5635 52.6002C46.2052 53.1199 46.026 53.9262 46.026 55.0192"
        fill="#08467E"
      />
      <path
        d="M52.7103 46.4732H52.1055V48.118H51.697V46.4732H51.1003V46.1426H52.7103V46.4732Z"
        fill="#08467E"
      />
      <path
        d="M53.4735 46.1426L53.9815 47.5751L54.4868 46.1426H55.0216V48.118H54.6131V47.5778L54.6534 46.6452L54.1186 48.118H53.839L53.3069 46.6479L53.3472 47.5778V48.118H52.9413V46.1426H53.4735Z"
        fill="#08467E"
      />
    </SvgIcon>
  );
}
