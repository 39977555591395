import RouteOptionsForm from "@/components/Route/RouteOptions/RouteOptionsForm";
import { ERouteSettings } from "@/types/route";

interface ISettingsModalRoute {
  routeSettings?: ERouteSettings;
  onChangeSettings: (value: ERouteSettings) => void;
  hugShore: boolean;
  setHugShore: (value: boolean) => void;
}

function SettingsModalRoute({
  routeSettings,
  hugShore,
  setHugShore,
  onChangeSettings,
}: ISettingsModalRoute) {
  return (
    <RouteOptionsForm
      hugShore={hugShore}
      routeSettings={routeSettings}
      setHugShore={setHugShore}
      onChangeSettings={onChangeSettings}
    />
  );
}

export default SettingsModalRoute;
