import { Stack, Typography, Divider } from "@mui/material";

import { IMapPois } from "@/types/pois";
import BaseIcon from "@/components/BaseIcon";
import { ReactComponent as DockTypeIcon } from "&/img/icons/dock_type.svg";
import { ReactComponent as SlipLengthIcon } from "&/img/icons/slip_length.svg";
import { ReactComponent as SlipWidthIcon } from "&/img/icons/slip_width.svg";
import { ReactComponent as MeanLowWaterIcon } from "&/img/icons/mean_low_water.svg";
import PrivateDockButton from "@/components/PrivateDockButton";

interface IPlaceInfoTransient {
  poiSelected: IMapPois;
}

function PlaceInfoPrivateDock({ poiSelected }: IPlaceInfoTransient) {
  return (
    <>
      <Stack alignItems="center" direction="row" justifyContent="space-between" px={2} py={2}>
        <Typography component="h4" lineHeight="normal" variant="subtitle1">
          About this Property
        </Typography>
      </Stack>

      <Stack px={2} spacing={2}>
        {poiSelected?.dock_type && (
          <Stack alignItems="center" direction="row">
            <BaseIcon icon={DockTypeIcon} />
            <Typography sx={{ ml: 1 }}>{poiSelected?.dock_type} dock</Typography>{" "}
          </Stack>
        )}
        {poiSelected?.length_max && (
          <Stack alignItems="center" direction="row">
            <BaseIcon icon={SlipLengthIcon} />
            <Typography sx={{ ml: 1 }}>Slip length: {poiSelected?.length_max} ft</Typography>{" "}
          </Stack>
        )}
        {poiSelected?.beam_max && (
          <Stack alignItems="center" direction="row">
            <BaseIcon icon={SlipWidthIcon} />
            <Typography sx={{ ml: 1 }}>Slip width: {poiSelected?.beam_max} ft</Typography>{" "}
          </Stack>
        )}
        {poiSelected?.transient && (
          <Stack alignItems="center" direction="row">
            <BaseIcon icon={MeanLowWaterIcon} />
            <Typography sx={{ ml: 1 }}>Mean Low Water: {poiSelected?.transient} ft</Typography>{" "}
          </Stack>
        )}

        {poiSelected.dockshare_url && (
          <>
            <Typography>For availability & booking:</Typography>
            <PrivateDockButton url={poiSelected.dockshare_url} />
          </>
        )}
        {poiSelected.description && (
          <>
            <Typography>Additional Information</Typography>
            <Typography>{poiSelected.description}</Typography>
          </>
        )}
      </Stack>
      <Divider sx={{ mt: 2 }} />
    </>
  );
}

export default PlaceInfoPrivateDock;
