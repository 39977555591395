/* global GeolocationPosition */
import { DeleteOutline, CloseOutlined } from "@mui/icons-material";
import {
  IconButton,
  Stack,
  Typography,
  FormControl,
  Grow,
  InputAdornment,
  OutlinedInput,
  Box,
  List,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useMap } from "@vis.gl/react-google-maps";

import { styles } from "./styles";

import useDebounce from "@/hooks/useDebounce";
import { IMapPois } from "@/types/pois";
import SearchResultListItem from "@/components/Search/SearchResultListItem/SearchResultListItem";
import useDisclosure from "@/hooks/useDisclosure";
import { getPosition } from "@/utils/maps";
import { Position } from "@/types/map";
import { findPoi, getDistanceFromLocation } from "@/utils/poi";
import ServiceMap from "@/services/map";

interface ISettingsModalLocation {
  currentLocationSelected:
    | { initial_address: string; initial_lat: string; initian_lng: string }
    | undefined;
  onSetCurrentLocation: (item: IMapPois | undefined) => void;
}

function SettingsModalLocation({
  currentLocationSelected,
  onSetCurrentLocation,
}: ISettingsModalLocation) {
  const map = useMap();
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<[] | IMapPois[]>([]);
  const [value, setValue] = useState<string>("");
  const debouncedValue = useDebounce(value, 1000);
  const [position, setPosition] = useState<Position | null>(null);
  const { onOpen, isOpen, onClose } = useDisclosure();

  const handleCleanInput = () => {
    setValue("");
    setOptions([]);
    onClose();
  };

  const showPosition = (pos: GeolocationPosition) => {
    setPosition({
      lat: pos.coords.latitude,
      lng: pos.coords.longitude,
    });
  };

  const handleGetPois = async () => {
    try {
      setLoading(true);
      const center = map && map.getCenter();
      const dataRequest = {
        page_total: 20,
        page: 1,
        location: "anywhere",
        distance: 3000,
        keyword: debouncedValue,
        lat: position?.lat || center?.lat,
        lng: position?.lng || center?.lng,
      };
      const { status, data } = await ServiceMap.searchMapPois(dataRequest);

      if (status === 200) {
        setOptions(data);
      }
      setLoading(false);
    } catch (error: any) {
      alert(error.toString());
      setLoading(false);
    }
  };

  useEffect(() => {
    if (debouncedValue !== "") {
      handleGetPois();
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (!position) {
      getPosition(showPosition);
    }
  }, [options, position]);

  const handleInputChange = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Stack>
        <Typography variant="subtitle1">Initial Map Location</Typography>
        <Typography color="text.secondary" variant="body1">
          Set a custom map location when opening the app.
        </Typography>
      </Stack>
      <Box sx={styles.searchBox}>
        <FormControl fullWidth>
          <OutlinedInput
            endAdornment={
              <>
                {loading && (
                  <InputAdornment position="end">
                    <CircularProgress size={20} />
                  </InputAdornment>
                )}
                {!loading && options.length > 0 && (
                  <InputAdornment position="end">
                    <IconButton aria-label="directions" color="primary" onClick={handleCleanInput}>
                      <CloseOutlined />
                    </IconButton>
                  </InputAdornment>
                )}
                {!loading && options.length === 0 && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => onSetCurrentLocation(undefined)}>
                      <DeleteOutline sx={{ fontSize: 20 }} />
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            }
            id="search"
            placeholder="Current Location"
            // startAdornment={
            //   <InputAdornment position="start">
            //     <Button size="small" variant="contained">
            //       Set Location
            //     </Button>
            //   </InputAdornment>
            // }
            value={currentLocationSelected?.initial_address || value || ""}
            onChange={handleInputChange}
            onKeyDown={onOpen}
          />
        </FormControl>
        <Grow unmountOnExit in={isOpen} style={{ transformOrigin: "top left" }} timeout="auto">
          <Paper elevation={4} sx={styles.paper}>
            <Box sx={{ ...styles.scrollContainer, maxHeight: "280px" }}>
              <List disablePadding>
                {options.map((item, index) => (
                  <SearchResultListItem
                    key={item.id}
                    action={() => onSetCurrentLocation(item)}
                    distance={
                      position
                        ? getDistanceFromLocation(position, {
                            lat: Number(item.lat),
                            lng: Number(item.lng),
                          })
                        : undefined
                    }
                    icon={findPoi(item.poi_type)}
                    numberList={index}
                    primaryLabel={item.name}
                    secondaryLabel={item.address || `${item.lat}, ${item.lng}`}
                  />
                ))}
              </List>
            </Box>
          </Paper>
        </Grow>
      </Box>
    </Stack>
  );
}

export default SettingsModalLocation;
