import { styled } from "@mui/system";
import Button, { ButtonProps } from "@mui/material/Button";

import BaseIcon from "@/components/BaseIcon/BaseIcon";
import { ReactComponent as SnagASlipIcon } from "&/img/icons/SnagASlip_white.svg";

const SnagASlipButtonStyled = styled((props: ButtonProps) => <Button {...props} />)(
  ({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    maxWidth: "200px",
    backgroundColor: "#FF6800",
    transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

    "& .MuiSvgIcon-root": {
      width: 166,
      height: 23,
    },

    "&:hover": {
      backgroundColor: "#DF5C02",
    },
  }),
);

interface ISnagASlipButton {
  url?: string;
}
function SnagASlipButton({ url }: ISnagASlipButton) {
  return (
    <SnagASlipButtonStyled onClick={() => window.open(url, "_blank")}>
      <BaseIcon icon={SnagASlipIcon} />
    </SnagASlipButtonStyled>
  );
}

export default SnagASlipButton;
