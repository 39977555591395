import { layoutStyles } from "@/themes/layoutStyles";

export const styles = {
  container: {},

  paper: {
    width: "360px",
    p: 2,
    cursor: "pointer",
  },

  paperToTop: {
    width: "360px",
    p: 2,
    cursor: "pointer",
    bottom: 0,
    transformOrigin: "top",
    position: "absolute",
  },

  hideButtons: {
    display: "none",
  },

  closeButton: {
    position: "absolute",
    right: "4px",
    top: "4px",
  },

  image: {
    flex: 1,
    "& img": {
      width: "100%",
    },
  },

  textContainer: {
    flex: 4,
  },

  title: {
    ...layoutStyles.ellipsisOneLine,
  },

  textContainerAids: {
    mb: 0.5,
  },

  routeButton: {
    ...layoutStyles.routeButton,
  },
};
