export const styles = {
  placePin: {
    position: "relative",
    animation: "pulse 2s infinite",

    "@keyframes pulse": {
      "0%": {
        filter: "drop-shadow(0px 0px 0px rgb(255, 107, 28, 0.95))",
      },
      "70%": {
        filter: "drop-shadow(0px 0px 10px rgb(255, 107, 28, 0))",
      },
      "100%": {
        filter: "drop-shadow(0px 0px 20px rgb(255, 107, 28, 0))",
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: 36,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& span": {
      position: "absolute",
      zIndex: 1,
      color: "common.white",
      fontWeight: 700,
      letterSpacing: "-0.5px",
      top: 2,
      left: "50%",
      transform: "translateX(-50%)",
    },
  },

  tooltip: {
    typography: "subtitle2",
    mt: "5px !important",
  },

  arrow: {
    color: "primary.main",
  },
};
