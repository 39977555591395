import { IconKeysPrivatePlaces, IconKeysPrivatePlacesUnderReview } from "@/types/markers";
// Private Places Icons
import AnchorageIcon from "@/assets/img/markers/pois/private/3x/Anchorage.png";
import ArtificialReefIcon from "@/assets/img/markers/pois/private/3x/ArtificialReef.png";
import BeachIcon from "@/assets/img/markers/pois/private/3x/Beach.png";
import BoatClubIcon from "@/assets/img/markers/pois/private/3x/BoatClub.png";
import BoatRampIcon from "@/assets/img/markers/pois/private/3x/BoatRamp.png";
import BridgeIcon from "@/assets/img/markers/pois/private/3x/Bridge.png";
import BusinessIcon from "@/assets/img/markers/pois/private/3x/Business.png";
import CityTownIcon from "@/assets/img/markers/pois/private/3x/CityTown.png";
import ClubAssociationIcon from "@/assets/img/markers/pois/private/3x/ClubAssociation.png";
import DamIcon from "@/assets/img/markers/pois/private/3x/Dam.png";
import DockBarRestaurantIcon from "@/assets/img/markers/pois/private/3x/DockBarRestaurant.png";
import FishingSpotIcon from "@/assets/img/markers/pois/private/3x/FishingSpot.png";
import FreeDockIcon from "@/assets/img/markers/pois/private/3x/FreeDock.png";
import HotelIcon from "@/assets/img/markers/pois/private/3x/Hotel.png";
import InletIcon from "@/assets/img/markers/pois/private/3x/Inlet.png";
import gps_locationIcon from "@/assets/img/markers/pois/private/3x/gps_location.png";
import LightHouseIcon from "@/assets/img/markers/pois/private/3x/LightHouse.png";
import LockIcon from "@/assets/img/markers/pois/private/3x/Lock.png";
import MarinaIcon from "@/assets/img/markers/pois/private/3x/Marina.png";
import MooringIcon from "@/assets/img/markers/pois/private/3x/Mooring.png";
import MyLocationIcon from "@/assets/img/markers/pois/private/3x/MyLocation.png";
import OtherIcon from "@/assets/img/markers/pois/private/3x/Other.png";
import PointofInterestIcon from "@/assets/img/markers/pois/private/3x/PointofInterest.png";
import PrivateDockIcon from "@/assets/img/markers/pois/private/3x/PrivateDock.png";
import RestaurantBarIcon from "@/assets/img/markers/pois/private/3x/RestaurantBar.png";
import YachtClubIcon from "@/assets/img/markers/pois/private/3x/YachtClub.png";
import PlacesIcon from "@/assets/img/markers/pois/private/3x/Places.png";
// Under Review Places Icons
import AnchorageIconUndereview from "@/assets/img/markers/pois/review/3x/Anchorage.png";
import ArtificialReefIconUndereview from "@/assets/img/markers/pois/review/3x/ArtificialReef.png";
import BeachIconUndereview from "@/assets/img/markers/pois/review/3x/Beach.png";
import BoatClubIconUndereview from "@/assets/img/markers/pois/review/3x/BoatClub.png";
import BoatRampIconUndereview from "@/assets/img/markers/pois/review/3x/BoatRamp.png";
import BridgeIconUndereview from "@/assets/img/markers/pois/review/3x/Bridge.png";
import BusinessIconUndereview from "@/assets/img/markers/pois/review/3x/Business.png";
import CityTownIconUndereview from "@/assets/img/markers/pois/review/3x/CityTown.png";
import ClubAssociationIconUndereview from "@/assets/img/markers/pois/review/3x/ClubAssociation.png";
import DamIconUndereview from "@/assets/img/markers/pois/review/3x/Dam.png";
import DockBarRestaurantIconUndereview from "@/assets/img/markers/pois/review/3x/DockBarRestaurant.png";
import FishingSpotIconUndereview from "@/assets/img/markers/pois/review/3x/FishingSpot.png";
import FreeDockIconUndereview from "@/assets/img/markers/pois/review/3x/FreeDock.png";
import HotelIconUndereview from "@/assets/img/markers/pois/review/3x/Hotel.png";
import InletIconUndereview from "@/assets/img/markers/pois/review/3x/Inlet.png";
import gps_locationIconUndereview from "@/assets/img/markers/pois/review/3x/gps_location.png";
import LightHouseIconUndereview from "@/assets/img/markers/pois/review/3x/LightHouse.png";
import LockIconUndereview from "@/assets/img/markers/pois/review/3x/Lock.png";
import MarinaIconUndereview from "@/assets/img/markers/pois/review/3x/Marina.png";
import MooringIconUndereview from "@/assets/img/markers/pois/review/3x/Mooring.png";
import OtherIconUndereview from "@/assets/img/markers/pois/review/3x/Other.png";
import PointofInterestIconUndereview from "@/assets/img/markers/pois/review/3x/PointofInterest.png";
import RestaurantBarIconUndereview from "@/assets/img/markers/pois/review/3x/RestaurantBar.png";
import PlacesIconUndereview from "@/assets/img/markers/pois/review/3x/Places.png";
import YachtClubIconUndereview from "@/assets/img/markers/pois/review/3x/YachtClub.png";

export const getPrivatePlaceIcon = (type: IconKeysPrivatePlaces) => {
  switch (type) {
    case IconKeysPrivatePlaces.Anchorage:
      return AnchorageIcon;
    case IconKeysPrivatePlaces.ArtificialReef:
      return ArtificialReefIcon;
    case IconKeysPrivatePlaces.Beach:
      return BeachIcon;
    case IconKeysPrivatePlaces.BoatClub:
      return BoatClubIcon;
    case IconKeysPrivatePlaces.BoatRamp:
      return BoatRampIcon;
    case IconKeysPrivatePlaces.BoatRamp2:
      return BoatRampIcon;
    case IconKeysPrivatePlaces.Bridge:
      return BridgeIcon;
    case IconKeysPrivatePlaces.Business:
      return BusinessIcon;
    case IconKeysPrivatePlaces.CityTown:
      return CityTownIcon;
    case IconKeysPrivatePlaces.ClubAssociation:
      return ClubAssociationIcon;
    case IconKeysPrivatePlaces.Dam:
      return DamIcon;
    case IconKeysPrivatePlaces.DockBarRestaurant:
      return DockBarRestaurantIcon;
    case IconKeysPrivatePlaces.FishingSpot:
      return FishingSpotIcon;
    case IconKeysPrivatePlaces.FreeDock:
      return FreeDockIcon;
    case IconKeysPrivatePlaces.Hotel:
      return HotelIcon;
    case IconKeysPrivatePlaces.Inlet:
      return InletIcon;
    case IconKeysPrivatePlaces.GpsLocation:
      return gps_locationIcon;
    case IconKeysPrivatePlaces.LightHouse:
      return LightHouseIcon;
    case IconKeysPrivatePlaces.Lock:
      return LockIcon;
    case IconKeysPrivatePlaces.Marina:
      return MarinaIcon;
    case IconKeysPrivatePlaces.Mooring:
      return MooringIcon;
    case IconKeysPrivatePlaces.MyLocation:
      return MyLocationIcon;
    case IconKeysPrivatePlaces.MyLocation2:
      return MyLocationIcon;
    case IconKeysPrivatePlaces.Other:
      return OtherIcon;
    case IconKeysPrivatePlaces.Places:
      return PlacesIcon;
    case IconKeysPrivatePlaces.PointOfInterest:
      return PointofInterestIcon;
    case IconKeysPrivatePlaces.PrivateDock:
      return PrivateDockIcon;
    case IconKeysPrivatePlaces.RestaurantBar:
      return RestaurantBarIcon;
    case IconKeysPrivatePlaces.YachtClub:
      return YachtClubIcon;
    default:
      return OtherIcon;
  }
};

export const getPrivatePlaceIconUnderReview = (type: IconKeysPrivatePlacesUnderReview) => {
  switch (type) {
    case IconKeysPrivatePlacesUnderReview.Anchorage:
      return AnchorageIconUndereview;
    case IconKeysPrivatePlacesUnderReview.ArtificialReef:
      return ArtificialReefIconUndereview;
    case IconKeysPrivatePlacesUnderReview.Beach:
      return BeachIconUndereview;
    case IconKeysPrivatePlacesUnderReview.BoatClub:
      return BoatClubIconUndereview;
    case IconKeysPrivatePlacesUnderReview.BoatRamp:
      return BoatRampIconUndereview;
    case IconKeysPrivatePlacesUnderReview.Bridge:
      return BridgeIconUndereview;
    case IconKeysPrivatePlacesUnderReview.Business:
      return BusinessIconUndereview;
    case IconKeysPrivatePlacesUnderReview.CityTown:
      return CityTownIconUndereview;
    case IconKeysPrivatePlacesUnderReview.ClubAssociation:
      return ClubAssociationIconUndereview;
    case IconKeysPrivatePlacesUnderReview.Dam:
      return DamIconUndereview;
    case IconKeysPrivatePlacesUnderReview.DockBarRestaurant:
      return DockBarRestaurantIconUndereview;
    case IconKeysPrivatePlacesUnderReview.FishingSpot:
      return FishingSpotIconUndereview;
    case IconKeysPrivatePlacesUnderReview.FreeDock:
      return FreeDockIconUndereview;
    case IconKeysPrivatePlacesUnderReview.Hotel:
      return HotelIconUndereview;
    case IconKeysPrivatePlacesUnderReview.Inlet:
      return InletIconUndereview;
    case IconKeysPrivatePlacesUnderReview.GpsLocation:
      return gps_locationIconUndereview;
    case IconKeysPrivatePlacesUnderReview.LightHouse:
      return LightHouseIconUndereview;
    case IconKeysPrivatePlacesUnderReview.Lock:
      return LockIconUndereview;
    case IconKeysPrivatePlacesUnderReview.Marina:
      return MarinaIconUndereview;
    case IconKeysPrivatePlacesUnderReview.Mooring:
      return MooringIconUndereview;
    case IconKeysPrivatePlacesUnderReview.Other:
      return OtherIconUndereview;
    case IconKeysPrivatePlacesUnderReview.Places:
      return PlacesIconUndereview;
    case IconKeysPrivatePlacesUnderReview.PointOfInterest:
      return PointofInterestIconUndereview;
    case IconKeysPrivatePlacesUnderReview.RestaurantBar:
      return RestaurantBarIconUndereview;
    case IconKeysPrivatePlacesUnderReview.YachtClub:
      return YachtClubIconUndereview;
    default:
      return OtherIconUndereview;
  }
};
