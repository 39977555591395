export const styles = {
  mainContainer: {
    backgroundColor: "grey.50",
    "& .topBar": {
      backgroundColor: "grey.200",
      justifyContent: "space-between",
    },
  },

  wrapper: {
    backgroundColor: "grey.300",
    height: "212px",
    width: "calc(100% - 80px)",
    mt: "70px",
    ml: "80px",
  },

  boxColumns: {
    p: 4,
  },

  boxSingleColumn: {
    display: "flex",
    justifyContent: "center",
    p: 4,
    "& .MuiPaper-root": {
      width: "700px",
    },
  },
};
