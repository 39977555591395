import { Box } from "@mui/material";

import QuickPinMarker from "./QuickPinMarker";

interface IQuickPinsList {
  items: any[];
  clogpage: boolean;
}

function QuickPinsList({ items, clogpage }: IQuickPinsList) {
  return (
    <Box>
      {items?.map((item: any) => (
        <QuickPinMarker key={item.id} clogpage={clogpage} quickpin={item} />
      ))}
    </Box>
  );
}

export default QuickPinsList;
