import { alpha, styled } from "@mui/system";
import { CardMedia, Stack, Typography } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";

import { mapTypes as typesOfMap } from "@/utils/maps";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { addTypeOfMap, addPoisConfiguration } from "@/store/slices/map";

const NOOA_MAP_TYPE = "noaaLayer";

const MapTypeButton = styled((props: ButtonProps) => <Button {...props} />)(({ theme }) => ({
  padding: theme.spacing(0.5),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  width: "100%",
  color: theme.palette.action.active,

  "& .MuiCardMedia-root": {
    borderRadius: "6px",
    border: `2px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(0.5),
    transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },

  "& .MuiTypography-caption": {
    fontWeight: 700,
  },

  "&.Mui-active": {
    color: theme.palette.primary.main,
    backgroundColor: `${alpha(theme.palette.primary.light, 0.12)}`,
    transform: "scale(1.03)",
    "& .MuiCardMedia-root": {
      border: `2px solid ${alpha(theme.palette.primary.main, 0.75)}`,
    },
  },

  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor: `${alpha(theme.palette.primary.light, 0.12)}`,
    transform: "scale(1.03)",
    "& .MuiCardMedia-root": {
      border: `2px solid ${alpha(theme.palette.primary.main, 0.75)}`,
    },
  },
}));

export default function MapTypes() {
  const dispatch = useAppDispatch();
  const { mapType, poisConfigured } = useAppSelector((state) => state.map);

  const handleSelectMapType = (value: string) => {
    const layersTypes = {
      ...poisConfigured,
      depthLayer: value !== NOOA_MAP_TYPE,
      noaaLayer: value === NOOA_MAP_TYPE,
    };

    dispatch(addTypeOfMap(value));
    dispatch(addPoisConfiguration(layersTypes));
  };

  return (
    <Stack direction="row" justifyContent="space-between" spacing={1.5} sx={{ p: 2 }}>
      {typesOfMap.map((mapTypeItem: any) => (
        <MapTypeButton
          key={mapTypeItem.id}
          className={
            poisConfigured.noaaLayer && mapTypeItem.idName === NOOA_MAP_TYPE
              ? "Mui-active"
              : mapType === mapTypeItem.idName && !poisConfigured.noaaLayer
              ? "Mui-active"
              : ""
          }
          onClick={() => handleSelectMapType(mapTypeItem.idName)}
        >
          <CardMedia alt={mapTypeItem.alt} component="img" height="77" image={mapTypeItem.image} />
          <Typography component="span" variant="caption">
            {mapTypeItem.name}
          </Typography>
        </MapTypeButton>
      ))}
    </Stack>
  );
}
