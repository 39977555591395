import { Marker } from "@vis.gl/react-google-maps";

import { Position } from "@/types/map";

interface IPosition {
  userPosition: Position;
}

function Poistion({ userPosition }: IPosition) {
  return (
    <Marker
      icon={{
        url: `./img/markers/location/LocationIndicator.png`,
        scaledSize: new window.google.maps.Size(36, 36),
      }}
      position={userPosition}
    />
  );
}

export default Poistion;
