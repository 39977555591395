import { LayersList } from "@deck.gl/core";

export const handleLayersController = (layers: LayersList, mapType: string) => {
  if (mapType === "noaaLayer") {
    const layersWithoutDepth = layers.slice(1);

    return {
      layers: layersWithoutDepth,
      mapType: "roadmap",
    };
  }

  const layersWithoutNoaa = layers.slice(0, 1).concat(layers.slice(2));

  return {
    layers: layersWithoutNoaa,
    mapType,
  };
};
