export const styles = {
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dialogContent: {
    py: 2,
  },

  dialogActions: {
    px: 3,
    py: 2,
  },
};
