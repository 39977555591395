import { layoutStyles } from "@/themes/layoutStyles";

export const styles = {
  container: {
    // pb: 3.5,
    // opacity: 0.5,
    // "&:after": {
    //   content: "''",
    //   height: "60px",
    //   width: "100%",
    //   background: "blue",
    // },
  },

  paper: {
    width: "360px",
    p: 2,
    cursor: "pointer",
  },

  paperToTop: {
    width: "360px",
    p: 2,
    cursor: "pointer",
    bottom: 0,
    transformOrigin: "top",
    position: "absolute",
  },

  hideButtons: {
    display: "none",
  },

  closeButton: {
    position: "absolute",
    right: "4px",
    top: "4px",
  },

  image: {
    flex: 1,
    position: "relative",
    "& img": {
      width: "100%",
    },
    "& .BridgeClearanceImage": {
      height: "100%",
    },

    "& .BridgeClearanceCaption": {
      position: "absolute",
      top: 19,
      left: 13,
      fontSize: "16px",
      color: "text.primary",
      fontFamily: "sans-serif",
      fontWeight: "bold",
      width: "38px",
      height: "24px",
      textAlign: "center",
    },
  },

  textContainer: {
    flex: 4,
  },

  title: {
    ...layoutStyles.ellipsisOneLine,
  },

  textContainerAids: {
    mb: 0.5,
  },

  routeButton: {
    ...layoutStyles.routeButton,
  },
};
