import { List, ListItem, ListItemButton, ListItemText, Switch } from "@mui/material";

import { styles } from "./styles";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { KEY_USER_FRIENDS, KEY_USER_NON_FRIENDS } from "@/utils/keys";
import { setFilters } from "@/store/slices/boaters";

function MapOptionsBoaters() {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.boaters);

  const handleSetFilter = (key: string) => {
    if (key === KEY_USER_FRIENDS) {
      dispatch(setFilters({ ...filters, friends: !filters.friends }));
    } else {
      dispatch(setFilters({ ...filters, others: !filters.others }));
    }
  };

  return (
    <List disablePadding>
      <ListItem disablePadding>
        <ListItemButton
          sx={styles.listItemButton}
          onClick={() => handleSetFilter(KEY_USER_FRIENDS)}
        >
          <ListItemText
            id="#"
            primary="Friends"
            primaryTypographyProps={{
              variant: "body1",
            }}
          />
          <Switch checked={filters.friends} color="secondary" edge="end" size="small" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          sx={styles.listItemButton}
          onClick={() => handleSetFilter(KEY_USER_NON_FRIENDS)}
        >
          <ListItemText
            id="#"
            primary="Other Boaters"
            primaryTypographyProps={{
              variant: "body1",
            }}
          />
          <Switch checked={filters.others} color="secondary" edge="end" size="small" />
        </ListItemButton>
      </ListItem>
    </List>
  );
}

export default MapOptionsBoaters;
