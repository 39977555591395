import React from "react";
import { ListItem, ListItemIcon, ListItemText, Divider } from "@mui/material";

import { styles } from "./styles";

interface IAmenitiesListItem {
  // eslint-disable-next-line no-undef
  icon?: JSX.Element;
  primary: string;
  secondary?: string;
  divider?: boolean;
}

export default function AmenitiesListItem({
  icon,
  primary,
  secondary,
  divider,
}: IAmenitiesListItem) {
  return (
    <>
      <ListItem sx={{ py: 1, pr: 3 }}>
        {icon && <ListItemIcon sx={styles.listItemIcon}>{icon}</ListItemIcon>}
        <ListItemText
          id="#"
          primary={secondary ? `${primary} - ${secondary}` : primary}
          primaryTypographyProps={{
            variant: "body1",
          }}
        />
      </ListItem>
      {divider && <Divider />}
    </>
  );
}
