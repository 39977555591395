import {
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  List,
  Button,
  ListItem,
  Box,
  ListSubheader,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import { VisibilityOffOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import LockIcon from "@mui/icons-material/Lock";

import { styles } from "./styles";

import DepthShadingRadio from "@/components/DepthShading/DepthShadingRadio";
import { IDepthShading } from "@/types/map";
import EmptyState from "@/components/EmptyState";
import { useUpdateShadingMutation, useAddShadingMutation, argoApi } from "@/store/api/argoApi";
import { useAppSelector, useAppDispatch } from "@/hooks/useRedux";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { defaultDepthShadingColors } from "@/utils/maps";
import usePremiumUser from "@/hooks/usePremiumUser";
import { setPremiumSubscriptionModal } from "@/store/slices/user";

function DepthUserShadings({
  depthShadingData,
}: {
  depthShadingData: { data: { items: IDepthShading[] } };
}) {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const isPremium = usePremiumUser();
  const [selectedShading, setSelectedShading] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [updateShading] = useUpdateShadingMutation();
  const [addShading] = useAddShadingMutation();

  const handleUndoPresetShading = (item: IDepthShading) => {
    updateShading({ id: item.id, visible: true, user_id: user?.id });
  };

  useEffect(() => {
    setSelectedShading(
      depthShadingData?.data?.items?.find(
        (item: IDepthShading) => item.current ?? item.name === "Default",
      )?.id || undefined,
    );
  }, [depthShadingData]);

  const handleSetShadings = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isPremium) {
      dispatch(
        argoApi.util.updateQueryData("getDepthShadings", { user_id: user?.id }, (draft) => {
          if (draft?.data?.items?.length > 0) {
            const selectedId = parseInt(event.target.value, 10);

            draft.data.items = draft.data.items.map((item: any) => ({
              ...item,
              current: item.id === selectedId,
            }));
          }
        }),
      );

      return;
    }
    setSelectedShading(parseInt(event.target.value, 10));
    updateShading({ id: parseInt(event.target.value, 10), current: true, user_id: user?.id });
  };

  const handleAddNewDepthShading = async () => {
    if (!isPremium) {
      dispatch(setPremiumSubscriptionModal(true));

      return;
    }
    setLoading(true);
    try {
      const newCustomShading = {
        name: `Custom Shading ${(depthShadingData?.data?.items?.length ?? 0) - 1}`,
        current: false,
        depth_shader_items_attributes: [...defaultDepthShadingColors.depth_shader_items],
        user_id: user?.id,
      };

      await addShading(newCustomShading);
      setLoading(false);
      dispatch(
        setSnackBarMsjSucceded({
          state: true,
          type: "success",
          msj: "Depth Shading added successfully",
        }),
      );
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      setLoading(false);
    }
  };

  const {
    defaultShading,
    customShadings,
  }: { defaultShading: IDepthShading[]; customShadings: IDepthShading[] } =
    depthShadingData?.data?.items?.reduce(
      (
        acc: { defaultShading: IDepthShading[]; customShadings: IDepthShading[] },
        item: IDepthShading,
      ) => {
        if (item?.name === "Default" || item?.preset_flag) {
          if (item.default_flag) {
            acc.defaultShading = [item, ...acc.defaultShading.filter((i) => !i.default_flag)];
          } else {
            acc.defaultShading.push(item);
          }
        } else {
          acc.customShadings.push(item);
        }

        return acc;
      },
      { defaultShading: [] as IDepthShading[], customShadings: [] as IDepthShading[] },
    ) || { defaultShading: [], customShadings: [] };

  if (!selectedShading) return null;

  return (
    <FormControl sx={styles.formControl}>
      <RadioGroup
        aria-labelledby="shadings-types-group-radio"
        value={selectedShading}
        onChange={handleSetShadings}
      >
        <>
          <List>
            {defaultShading?.map((item: IDepthShading) =>
              item.visible ? (
                <ListItem key={item.id} sx={styles.listItems}>
                  <FormControlLabel
                    control={<DepthShadingRadio depthShadingData={depthShadingData} item={item} />}
                    label={null}
                    sx={styles.formControlLabel}
                    value={item.id}
                  />
                </ListItem>
              ) : (
                <ListItem key={item.id} sx={styles.listItems}>
                  <Alert
                    action={
                      <Button
                        color="primary"
                        size="small"
                        onClick={() => handleUndoPresetShading(item)}
                      >
                        Undo
                      </Button>
                    }
                    icon={<VisibilityOffOutlined />}
                    severity="info"
                    sx={{ width: "100%" }}
                  >
                    Hidden preset depth shadings
                  </Alert>
                </ListItem>
              ),
            )}
          </List>
          <Stack direction="column" spacing={1}>
            <List>
              <ListSubheader disableGutters disableSticky sx={styles.listItemSubHeader}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  Custom Depth Shading{" "}
                  {isPremium ? "" : <LockIcon sx={{ width: 20, height: 20, ml: 1 }} />}
                </Box>
                <Box>
                  <LoadingButton
                    color="secondary"
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<AddIcon />}
                    onClick={handleAddNewDepthShading}
                  >
                    Add Shading
                  </LoadingButton>
                </Box>
              </ListSubheader>
              <ListItem sx={styles.listItemContainer}>
                {customShadings.length <= 0 ? (
                  <Stack alignItems="center" direction="column" spacing={1}>
                    <EmptyState
                      imageURL="img/placeholders/EmptyStateIllus.svg"
                      subtitle="Looks like you haven't added any custom depth shadings yet."
                      sx={{
                        height: "calc(100% - 125px)",
                        px: 3,
                        pb: 1,
                        "& h6": { mt: 2, pt: 1 },
                      }}
                      title="No Depth Shading"
                    />
                    <Box>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleAddNewDepthShading}
                      >
                        Add New Depth Shading
                      </Button>
                    </Box>
                  </Stack>
                ) : (
                  <List sx={styles.listContainer}>
                    {customShadings?.map((item: IDepthShading) => (
                      <ListItem key={item.id} sx={styles.listItems}>
                        <FormControlLabel
                          control={
                            <DepthShadingRadio depthShadingData={depthShadingData} item={item} />
                          }
                          label={null}
                          sx={styles.formControlLabel}
                          value={item.id}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </ListItem>
            </List>
          </Stack>
        </>
      </RadioGroup>
    </FormControl>
  );
}

export default DepthUserShadings;
