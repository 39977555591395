export const styles = {
  textField: {
    minWidth: "240px",
  },

  listItem: {
    "& .MuiListItemSecondaryAction-root": {
      right: "8px",
    },
  },

  listItemButton: {
    py: 1.5,
  },
};
