import { styled } from "@mui/system";
import Button, { ButtonProps } from "@mui/material/Button";

import BaseIcon from "@/components/BaseIcon/BaseIcon";
import { ReactComponent as DockShareIcon } from "&/img/icons/BookDockShare.svg";

const PrivateDockButtonStyled = styled((props: ButtonProps) => <Button {...props} />)(
  ({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    maxWidth: "230px",
    backgroundColor: "#0C3B4A",
    transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

    "& .MuiSvgIcon-root": {
      width: 166,
      height: 23,
    },

    "&:hover": {
      backgroundColor: "#0C3B4A",
    },
  }),
);

interface ISnagASlipButton {
  url?: string;
}
function PrivateDockButton({ url }: ISnagASlipButton) {
  return (
    <PrivateDockButtonStyled onClick={() => window.open(url, "_blank")}>
      <BaseIcon icon={DockShareIcon} />
    </PrivateDockButtonStyled>
  );
}

export default PrivateDockButton;
