export const styles = {
  stack: {
    height: 70,
    ml: "80px",
    width: "calc(100% - 80px)",
    position: "absolute",
    top: 0,
    px: 1.5,
    py: 1,
    zIndex: 4,
    pointerEvents: "none",
    "& .MuiButtonBase-root:not(.Mui-disabled)": {
      pointerEvents: "auto",
    },
  },
};
