import { alpha } from "@mui/material/styles";

import { layoutStyles } from "@/themes/layoutStyles";
import theme from "@/themes/theme";

export const styles = {
  drawer: {
    width: 408,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: 408,
      ml: "80px",
      boxSizing: "border-box",
      boxShadow: 12,
      borderRight: "none",
      overflow: "hidden",
      zIndex: 6,
    },
  },

  likeButton: {
    minWidth: 54,
    color: "action.active",
    boxShadow: 1,
    "&:hover": {
      boxShadow: 3,
    },
    "&.Mui-active .MuiButton-startIcon": {
      color: "success.main",
    },
  },

  editCoordinates: {
    position: "absolute",
    width: "calc(100% - 488px)",
    top: "64px",
    left: 488,
    display: "flex",
    justifyContent: "center",
    zIndex: 10,
    "& .MuiButton-root": {
      boxShadow: 4,
      typography: "subtitle2",
      px: 2,
    },
  },

  locationInput: {
    "& input": {
      color: "text.primary",
      "-webkit-text-fill-color": "unset !important",
    },
  },

  image: {
    width: "240px",
    height: "100px",
  },

  header: {
    px: 2,
    py: 1.5,
    "& .closeButton": {
      boxShadow: 4,
    },
  },

  dislikeButton: {
    minWidth: 54,
    boxShadow: 1,
    color: "action.active",
    "&:hover": {
      boxShadow: 3,
    },
    "&.Mui-active .MuiButton-startIcon": {
      color: "error.main",
    },
  },

  poiTitle: {
    lineHeight: "normal",
  },

  cardMedia: {
    borderRadius: 1,
    border: "1px solid",
    borderColor: "divider",
  },

  list: {
    "& .reportInfoListItem": {
      py: 0.5,
    },
    "& .MuiDivider-root": {
      display: "none",
    },
  },

  ownAvatar: {
    border: "2px solid",
    borderColor: "background.paper",
    boxShadow: 4,
  },

  addComment: {
    px: 2,
    py: 2.5,
    backgroundColor: "grey.50",
    "& .MuiIconButton-root": {
      mt: 0.5,
    },
  },

  dataFooter: {
    p: 2,
    backgroundColor: "grey.50",
  },

  formContainer: {
    ...layoutStyles.scroll,
    p: 2,
    overflowY: "auto",
    height: `calc(100vh - 216px)`,
  },

  container: {
    overflow: "hidden",
    mt: 2,
    width: 368,
    border: `1px solid`,
    borderColor: `${alpha(theme.palette.primary.main, 0.5)}`,
    borderRadius: 1,

    "& form": {
      pt: 1,
    },

    "& .argoDivider": {
      backgroundColor: `${alpha(theme.palette.primary.main, 0.5)}`,
      height: "1px",
    },
  },

  headerIcon: {
    color: "primary",
  },
};
