import {
  IconKeysNavAidsBeacons,
  IconKeysNavAidsBuoys,
  IconKeysNavAidsOthers,
} from "@/types/markers";
import beacon_black_clearLight from "@/assets/img/markers/navaids/beacons/3x/beacon_black_clearLight.png";
import beacon_black_greenLight from "@/assets/img/markers/navaids/beacons/3x/beacon_black_greenLight.png";
import beacon_black_light from "@/assets/img/markers/navaids/beacons/3x/beacon_black_light.png";
import beacon_black_noLight from "@/assets/img/markers/navaids/beacons/3x/beacon_black_noLight.png";
import beacon_black_redLight from "@/assets/img/markers/navaids/beacons/3x/beacon_black_redLight.png";
import beacon_black_whiteLight from "@/assets/img/markers/navaids/beacons/3x/beacon_black_whiteLight.png";
import beacon_black_yellowLight from "@/assets/img/markers/navaids/beacons/3x/beacon_black_yellowLight.png";
import beacon_green_clearLight from "@/assets/img/markers/navaids/beacons/3x/beacon_green_clearLight.png";
import beacon_green_light from "@/assets/img/markers/navaids/beacons/3x/beacon_green_light.png";
import beacon_green_noLight from "@/assets/img/markers/navaids/beacons/3x/beacon_green_noLight.png";
import beacon_grey_light from "@/assets/img/markers/navaids/beacons/3x/beacon_grey_light.png";
import beacon_grey_noLight from "@/assets/img/markers/navaids/beacons/3x/beacon_grey_noLight.png";
import beacon_GRG_greenLight from "@/assets/img/markers/navaids/beacons/3x/beacon_GRG_greenLight.png";
import beacon_GRG_noLight from "@/assets/img/markers/navaids/beacons/3x/beacon_GRG_noLight.png";
import beacon_orange_light from "@/assets/img/markers/navaids/beacons/3x/beacon_orange_light.png";
import beacon_orange_noLight from "@/assets/img/markers/navaids/beacons/3x/beacon_orange_noLight.png";
import beacon_orangeWhite_light from "@/assets/img/markers/navaids/beacons/3x/beacon_orangeWhite_light.png";
import beacon_orangeWhite_noLight from "@/assets/img/markers/navaids/beacons/3x/beacon_orangeWhite_noLight.png";
import beacon_red_clearLight from "@/assets/img/markers/navaids/beacons/3x/beacon_red_clearLight.png";
import beacon_red_light from "@/assets/img/markers/navaids/beacons/3x/beacon_red_light.png";
import beacon_red_noLight from "@/assets/img/markers/navaids/beacons/3x/beacon_red_noLight.png";
import beacon_RGR_noLight from "@/assets/img/markers/navaids/beacons/3x/beacon_RGR_noLight.png";
import beacon_RGR_redLight from "@/assets/img/markers/navaids/beacons/3x/beacon_RGR_redLight.png";
import beacon_undefined from "@/assets/img/markers/navaids/beacons/3x/beacon_undefined.png";
import beacon_undefined_light from "@/assets/img/markers/navaids/beacons/3x/beacon_undefined_light.png";
import beacon_white_light from "@/assets/img/markers/navaids/beacons/3x/beacon_white_light.png";
import beacon_white_noLight from "@/assets/img/markers/navaids/beacons/3x/beacon_white_noLight.png";
import beacon_yellow_light from "@/assets/img/markers/navaids/beacons/3x/beacon_yellow_light.png";
import beacon_yellow_noLight from "@/assets/img/markers/navaids/beacons/3x/beacon_yellow_noLight.png";
import buoy_black_light from "@/assets/img/markers/navaids/buoys/3x/buoy_black_light.png";
import buoy_black_noLight from "@/assets/img/markers/navaids/buoys/3x/buoy_black_noLight.png";
import buoy_BRB_light from "@/assets/img/markers/navaids/buoys/3x/buoy_BRB_light.png";
import buoy_BRB_noLight from "@/assets/img/markers/navaids/buoys/3x/buoy_BRB_noLight.png";
import buoy_green_light from "@/assets/img/markers/navaids/buoys/3x/buoy_green_light.png";
import buoy_green_noLight from "@/assets/img/markers/navaids/buoys/3x/buoy_green_noLight.png";
import buoy_grey_light from "@/assets/img/markers/navaids/buoys/3x/buoy_grey_light.png";
import buoy_grey_noLight from "@/assets/img/markers/navaids/buoys/3x/buoy_grey_noLight.png";
import buoy_GRG_light from "@/assets/img/markers/navaids/buoys/3x/buoy_GRG_light.png";
import buoy_GRG_noLight from "@/assets/img/markers/navaids/buoys/3x/buoy_GRG_noLight.png";
import buoy_orange_light from "@/assets/img/markers/navaids/buoys/3x/buoy_orange_light.png";
import buoy_orange_noLight from "@/assets/img/markers/navaids/buoys/3x/buoy_orange_noLight.png";
import buoy_orangeWhite_light from "@/assets/img/markers/navaids/buoys/3x/buoy_orangeWhite_light.png";
import buoy_orangeWhite_noLight from "@/assets/img/markers/navaids/buoys/3x/buoy_orangeWhite_noLight.png";
import buoy_red_light from "@/assets/img/markers/navaids/buoys/3x/buoy_red_light.png";
import buoy_red_noLight from "@/assets/img/markers/navaids/buoys/3x/buoy_red_noLight.png";
import buoy_redWhite_light from "@/assets/img/markers/navaids/buoys/3x/buoy_redWhite_light.png";
import buoy_redWhite_noLight from "@/assets/img/markers/navaids/buoys/3x/buoy_redWhite_noLight.png";
import buoy_RGR_light from "@/assets/img/markers/navaids/buoys/3x/buoy_RGR_light.png";
import buoy_RGR_noLight from "@/assets/img/markers/navaids/buoys/3x/buoy_RGR_noLight.png";
import buoy_white_light from "@/assets/img/markers/navaids/buoys/3x/buoy_white_light.png";
import buoy_white_noLight from "@/assets/img/markers/navaids/buoys/3x/buoy_white_noLight.png";
import buoy_yellow_light from "@/assets/img/markers/navaids/buoys/3x/buoy_yellow_light.png";
import buoy_yellow_noLight from "@/assets/img/markers/navaids/buoys/3x/buoy_yellow_noLight.png";
import restricted_area from "@/assets/img/markers/navaids/others/3x/restricted_area.png";

export const getNavAidsIcon = (
  type: IconKeysNavAidsBeacons | IconKeysNavAidsBuoys | IconKeysNavAidsOthers,
) => {
  switch (type) {
    case IconKeysNavAidsBeacons.beacon_black_clearLight:
      return beacon_black_clearLight;
    case IconKeysNavAidsBeacons.beacon_black_greenLight:
      return beacon_black_greenLight;
    case IconKeysNavAidsBeacons.beacon_black_light:
      return beacon_black_light;
    case IconKeysNavAidsBeacons.beacon_black_noLight:
      return beacon_black_noLight;
    case IconKeysNavAidsBeacons.beacon_black_redLight:
      return beacon_black_redLight;
    case IconKeysNavAidsBeacons.beacon_black_whiteLight:
      return beacon_black_whiteLight;
    case IconKeysNavAidsBeacons.beacon_black_yellowLight:
      return beacon_black_yellowLight;
    case IconKeysNavAidsBeacons.beacon_green_clearLight:
      return beacon_green_clearLight;
    case IconKeysNavAidsBeacons.beacon_green_light:
      return beacon_green_light;
    case IconKeysNavAidsBeacons.beacon_green_noLight:
      return beacon_green_noLight;
    case IconKeysNavAidsBeacons.beacon_grey_light:
      return beacon_grey_light;
    case IconKeysNavAidsBeacons.beacon_grey_noLight:
      return beacon_grey_noLight;
    case IconKeysNavAidsBeacons.beacon_GRG_greenLight:
      return beacon_GRG_greenLight;
    case IconKeysNavAidsBeacons.beacon_GRG_noLight:
      return beacon_GRG_noLight;
    case IconKeysNavAidsBeacons.beacon_orange_light:
      return beacon_orange_light;
    case IconKeysNavAidsBeacons.beacon_orange_noLight:
      return beacon_orange_noLight;
    case IconKeysNavAidsBeacons.beacon_orangeWhite_light:
      return beacon_orangeWhite_light;
    case IconKeysNavAidsBeacons.beacon_orangeWhite_noLight:
      return beacon_orangeWhite_noLight;
    case IconKeysNavAidsBeacons.beacon_red_clearLight:
      return beacon_red_clearLight;
    case IconKeysNavAidsBeacons.beacon_red_light:
      return beacon_red_light;
    case IconKeysNavAidsBeacons.beacon_red_noLight:
      return beacon_red_noLight;
    case IconKeysNavAidsBeacons.beacon_RGR_noLight:
      return beacon_RGR_noLight;
    case IconKeysNavAidsBeacons.beacon_RGR_redLight:
      return beacon_RGR_redLight;
    case IconKeysNavAidsBeacons.beacon_undefined:
      return beacon_undefined;
    case IconKeysNavAidsBeacons.beacon_undefined_light:
      return beacon_undefined_light;
    case IconKeysNavAidsBeacons.beacon_white_light:
      return beacon_white_light;
    case IconKeysNavAidsBeacons.beacon_white_noLight:
      return beacon_white_noLight;
    case IconKeysNavAidsBeacons.beacon_yellow_light:
      return beacon_yellow_light;
    case IconKeysNavAidsBeacons.beacon_yellow_noLight:
      return beacon_yellow_noLight;
    case IconKeysNavAidsBuoys.buoy_black_light:
      return buoy_black_light;
    case IconKeysNavAidsBuoys.buoy_black_noLight:
      return buoy_black_noLight;
    case IconKeysNavAidsBuoys.buoy_BRB_light:
      return buoy_BRB_light;
    case IconKeysNavAidsBuoys.buoy_BRB_noLight:
      return buoy_BRB_noLight;
    case IconKeysNavAidsBuoys.buoy_green_light:
      return buoy_green_light;
    case IconKeysNavAidsBuoys.buoy_green_noLight:
      return buoy_green_noLight;
    case IconKeysNavAidsBuoys.buoy_grey_light:
      return buoy_grey_light;
    case IconKeysNavAidsBuoys.buoy_grey_noLight:
      return buoy_grey_noLight;
    case IconKeysNavAidsBuoys.buoy_GRG_light:
      return buoy_GRG_light;
    case IconKeysNavAidsBuoys.buoy_GRG_noLight:
      return buoy_GRG_noLight;
    case IconKeysNavAidsBuoys.buoy_orange_light:
      return buoy_orange_light;
    case IconKeysNavAidsBuoys.buoy_orange_noLight:
      return buoy_orange_noLight;
    case IconKeysNavAidsBuoys.buoy_orangeWhite_light:
      return buoy_orangeWhite_light;
    case IconKeysNavAidsBuoys.buoy_orangeWhite_noLight:
      return buoy_orangeWhite_noLight;
    case IconKeysNavAidsBuoys.buoy_red_light:
      return buoy_red_light;
    case IconKeysNavAidsBuoys.buoy_red_noLight:
      return buoy_red_noLight;
    case IconKeysNavAidsBuoys.buoy_redWhite_light:
      return buoy_redWhite_light;
    case IconKeysNavAidsBuoys.buoy_redWhite_noLight:
      return buoy_redWhite_noLight;
    case IconKeysNavAidsBuoys.buoy_RGR_light:
      return buoy_RGR_light;
    case IconKeysNavAidsBuoys.buoy_RGR_noLight:
      return buoy_RGR_noLight;
    case IconKeysNavAidsBuoys.buoy_white_light:
      return buoy_white_light;
    case IconKeysNavAidsBuoys.buoy_white_noLight:
      return buoy_white_noLight;
    case IconKeysNavAidsBuoys.buoy_yellow_light:
      return buoy_yellow_light;
    case IconKeysNavAidsBuoys.buoy_yellow_noLight:
      return buoy_yellow_noLight;
    case IconKeysNavAidsOthers.restricted_area:
      return restricted_area;
    default:
      return beacon_grey_noLight;
  }
};
