// Icons Import
import AnchorageIcon from "@/assets/img/markers/pois/place/3x/Anchorage.png";
import ArtificialReefIcon from "@/assets/img/markers/pois/place/3x/ArtificialReef.png";
import BeachIcon from "@/assets/img/markers/pois/place/3x/Beach.png";
import BoatClubIcon from "@/assets/img/markers/pois/place/3x/BoatClub.png";
import BoatRampIcon from "@/assets/img/markers/pois/place/3x/BoatRamp.png";
import BridgeIcon from "@/assets/img/markers/pois/place/3x/Bridge.png";
import BridgeClearanceIcon from "@/assets/img/markers/pois/place/3x/BridgeClearance.png";
import BusinessIcon from "@/assets/img/markers/pois/place/3x/Business.png";
import CityTownIcon from "@/assets/img/markers/pois/place/3x/CityTown.png";
import ClubAssociationIcon from "@/assets/img/markers/pois/place/3x/ClubAssociation.png";
import DamIcon from "@/assets/img/markers/pois/place/3x/Dam.png";
import DockBarRestaurantIcon from "@/assets/img/markers/pois/place/3x/DockBarRestaurant.png";
import FishingSpotIcon from "@/assets/img/markers/pois/place/3x/FishingSpot.png";
import FreeDockIcon from "@/assets/img/markers/pois/place/3x/FreeDock.png";
import HotelIcon from "@/assets/img/markers/pois/place/3x/Hotel.png";
import InletIcon from "@/assets/img/markers/pois/place/3x/Inlet.png";
import LightHouseIcon from "@/assets/img/markers/pois/place/3x/LightHouse.png";
import LockIcon from "@/assets/img/markers/pois/place/3x/Lock.png";
import MarinaIcon from "@/assets/img/markers/pois/place/3x/Marina.png";
import MooringIcon from "@/assets/img/markers/pois/place/3x/Mooring.png";
import MyLocationIcon from "@/assets/img/markers/pois/place/3x/MyLocation.png";
import OtherIcon from "@/assets/img/markers/pois/place/3x/Other.png";
import PointofInterestIcon from "@/assets/img/markers/pois/place/3x/PointofInterest.png";
import PrivateDockIcon from "@/assets/img/markers/pois/place/3x/PrivateDock.png";
import RestaurantBarIcon from "@/assets/img/markers/pois/place/3x/RestaurantBar.png";
import YachtClubIcon from "@/assets/img/markers/pois/place/3x/YachtClub.png";
import { IconKeysPois } from "@/types/markers";

export const getPoiIcon = (type: IconKeysPois) => {
  switch (type) {
    case IconKeysPois.Anchorage:
      return AnchorageIcon;
    case IconKeysPois.ArtificialReef:
      return ArtificialReefIcon;
    case IconKeysPois.Beach:
      return BeachIcon;
    case IconKeysPois.BoatClub:
      return BoatClubIcon;
    case IconKeysPois.BoatRamp:
      return BoatRampIcon;
    case IconKeysPois.BoatRamp2:
      return BoatRampIcon;
    case IconKeysPois.Bridge:
      return BridgeIcon;
    case IconKeysPois.BridgeClearance:
      return BridgeClearanceIcon;
    case IconKeysPois.Business:
      return BusinessIcon;
    case IconKeysPois.CityTown:
      return CityTownIcon;
    case IconKeysPois.ClubAssociation:
      return ClubAssociationIcon;
    case IconKeysPois.Dam:
      return DamIcon;
    case IconKeysPois.DockBarRestaurant:
      return DockBarRestaurantIcon;
    case IconKeysPois.FishingSpot:
      return FishingSpotIcon;
    case IconKeysPois.FreeDock:
      return FreeDockIcon;
    case IconKeysPois.Hotel:
      return HotelIcon;
    case IconKeysPois.Inlet:
      return InletIcon;
    case IconKeysPois.LightHouse:
      return LightHouseIcon;
    case IconKeysPois.Lock:
      return LockIcon;
    case IconKeysPois.Marina:
      return MarinaIcon;
    case IconKeysPois.Mooring:
      return MooringIcon;
    case IconKeysPois.MyLocation:
      return MyLocationIcon;
    case IconKeysPois.Other:
      return OtherIcon;
    case IconKeysPois.PointOfInterest:
      return PointofInterestIcon;
    case IconKeysPois.PrivateDock:
      return PrivateDockIcon;
    case IconKeysPois.RestaurantBar:
      return RestaurantBarIcon;
    case IconKeysPois.YachtClub:
      return YachtClubIcon;
    default:
      return OtherIcon;
  }
};
