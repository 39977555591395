import * as React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField,
  Divider,
  ListItemButton,
} from "@mui/material";

import { styles } from "./styles";

interface IEditAmenitiesListItem {
  label: string;
  valueName: string;
  values: any;
  onChangeValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export default function EditAmenitiesListItem({
  label,
  valueName,
  values,
  onChangeValue,
}: IEditAmenitiesListItem) {
  return (
    <>
      <ListItem
        disablePadding
        secondaryAction={
          <TextField
            fullWidth
            color="secondary"
            disabled={!values[valueName]}
            id={`${valueName}_info`}
            name={`${valueName}_info`}
            placeholder={`${label} Info`}
            size="small"
            sx={styles.textField}
            value={values[`${valueName}_info`]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeValue(`${valueName}_info`, e.target.value);
            }}
          />
        }
        sx={styles.listItem}
      >
        <ListItemButton
          sx={styles.listItemButton}
          onClick={() => onChangeValue(valueName, !values[valueName])}
        >
          <ListItemIcon>
            <Switch
              checked={values[valueName]}
              color="secondary"
              id={valueName}
              name={valueName}
              size="small"
              onChange={() => onChangeValue(valueName, !values[valueName])}
            />
          </ListItemIcon>
          <ListItemText
            primary={label}
            primaryTypographyProps={{
              component: "h4",
              variant: "body1",
            }}
            sx={{ ml: 1.5 }}
          />
        </ListItemButton>
      </ListItem>
      <Divider />
    </>
  );
}
