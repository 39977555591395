import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { FormikProps } from "formik";
import { useState } from "react";
import Radio, { RadioProps } from "@mui/material/Radio";
import { SelectChangeEvent } from "@mui/material/Select";
import { useUnitSystem } from "@argonav/unit-system";

import ReportPinCoordinatesPanel from "./ReportPinCoordinatesPanel";
import { TTempPin } from "./ReportPinCreationInfo";
import { styles } from "./styles";

import Dropzone from "@/components/Dropzone";
import { REPORTPINS_HAZARD_SUB_TYPE, REPORTPINS_SOS_SUB_TYPE } from "@/utils/poi";
import { useAppSelector, useAppDispatch } from "@/hooks/useRedux";
import { setAllowCoordinates, setTempPinCopy } from "@/store/slices/reportpins";

interface ICreatePinForm {
  formData: FormikProps<TTempPin>;
  placeType: boolean;
}

const BYTE_SIZE_LIMIT = 2e6;

type TRadioProps = {
  title: string;
  description: string;
};

function CreatePinFormRadio(props: RadioProps & { item: TRadioProps }) {
  const { item }: { item: TRadioProps } = props;

  return (
    <Stack direction="row" sx={{ width: "100%", py: 1 }}>
      <Stack sx={{ width: "100%" }}>
        <Typography variant="body1">{item.title}</Typography>
        <Typography color="text.secondary" variant="body2">
          {item.description}
        </Typography>
      </Stack>

      <Radio {...props} />
    </Stack>
  );
}

function CreatePinForm({ formData, placeType }: ICreatePinForm) {
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<File[]>([]);
  const { coordinatesFormatHandler } = useUnitSystem();
  const { tempPin, tempPinCopy, allowCoordinates } = useAppSelector((state) => state.reportpins);

  const handelBoatImage = (files: File[]) => {
    setFiles([...files]);
    const imgFile = files[0];

    if (["image/jpeg", "image/png"].includes(imgFile?.type)) {
      if (imgFile.size < BYTE_SIZE_LIMIT) {
        formData.setFieldValue("image", imgFile);
      }
    } else {
      formData.setFieldValue("image", null);
    }
  };

  const handleSetCoordsToPinCreation = () => {
    dispatch(setAllowCoordinates(true));
    dispatch(setTempPinCopy(tempPin));
  };

  const handleClose = () => {
    dispatch(setAllowCoordinates(false));
    dispatch(setTempPinCopy(undefined));
  };

  const latLngFormatted = coordinatesFormatHandler({
    latitude: Number(tempPin?.location.lat),
    longitude: Number(tempPin?.location.lng),
  });

  const latLngFormattedCoordinates = coordinatesFormatHandler({
    latitude: Number(tempPinCopy?.location.lat),
    longitude: Number(tempPinCopy?.location.lng),
  });

  const subCategory = formData.values.valueType === "hazard" || formData.values.valueType === "sos";

  const itemsOptions =
    formData.values.valueType === "hazard" ? REPORTPINS_HAZARD_SUB_TYPE : REPORTPINS_SOS_SUB_TYPE;

  return (
    <Stack spacing={2} sx={styles.formContainer}>
      {!placeType && subCategory && (
        <FormControl>
          <InputLabel htmlFor="beam-max">Select Category</InputLabel>
          <Select
            id="subtype"
            label="subtype"
            labelId="subtype"
            value={formData.values.subtype}
            onChange={(e: SelectChangeEvent) =>
              formData.setFieldValue("subtype", e.target.value as string)
            }
          >
            {itemsOptions.map((item) => (
              <MenuItem value={item.value}>{item.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {placeType && (
        <FormControl>
          <InputLabel htmlFor="name">Name</InputLabel>
          <OutlinedInput
            defaultValue={formData.values.name}
            error={formData.touched.name && Boolean(formData.errors.name)}
            id="name"
            label="Name"
            onChange={formData.handleChange}
          />
          <FormHelperText id="name-text" sx={{ color: "error.main" }}>
            {formData.touched.name && formData.errors.name}
          </FormHelperText>
        </FormControl>
      )}

      <FormControl>
        <InputLabel htmlFor="description">Description</InputLabel>
        <OutlinedInput
          multiline
          defaultValue={formData.values.description}
          error={formData.touched.description && Boolean(formData.errors.description)}
          id="description"
          label="Description"
          minRows={3}
          onChange={formData.handleChange}
        />
        <FormHelperText id="name-text" sx={{ color: "error.main" }}>
          {formData.touched.description && formData.errors.description}
        </FormHelperText>
      </FormControl>

      <FormControl>
        <InputLabel htmlFor="location">Location</InputLabel>
        <OutlinedInput
          disabled
          endAdornment={
            <Box>
              <Button
                color="primary"
                disabled={allowCoordinates}
                size="small"
                sx={{ fontSize: "13px", width: "128px" }}
                variant="outlined"
                onClick={handleSetCoordsToPinCreation}
              >
                Edit Coordinates
              </Button>
            </Box>
          }
          error={formData.touched.location && Boolean(formData.errors.location)}
          id="location"
          label="Location"
          sx={allowCoordinates ? null : styles.locationInput}
          value={latLngFormatted}
        />
        <FormHelperText id="name-text">Click on the map to change location</FormHelperText>
        {tempPin && tempPinCopy && allowCoordinates && (
          <ReportPinCoordinatesPanel
            coordinates={latLngFormattedCoordinates}
            isOpen={tempPin && tempPinCopy && allowCoordinates}
            tempPin={tempPin}
            tempPinCopy={tempPinCopy}
            onClose={handleClose}
          />
        )}
      </FormControl>

      {placeType && (
        <Box>
          <Typography color="text.primary" variant="subtitle1">
            Place Pin Type
          </Typography>
          <RadioGroup
            aria-labelledby="demo-row-radio-buttons-group-label"
            defaultValue={formData.values.type}
            name="type"
            onChange={formData.handleChange}
          >
            <FormControlLabel
              control={
                <CreatePinFormRadio
                  item={{
                    title: "Private",
                    description: "Only you can view and edit.",
                  }}
                />
              }
              label=""
              sx={{ mx: 0 }}
              value="private"
            />
            <FormControlLabel
              control={
                <CreatePinFormRadio
                  item={{
                    title: "Public",
                    description:
                      "This place will be reviewed by the Argo Team and if approved will be added to the community database.",
                  }}
                />
              }
              label=""
              sx={{ mx: 0 }}
              value="public"
            />
          </RadioGroup>
        </Box>
      )}

      <Dropzone action={handelBoatImage} files={files} />
    </Stack>
  );
}

export default CreatePinForm;
