import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";

export const styles = {
  dialogTitle: {
    pt: 2,
  },

  radioGroup: {
    border: "1px solid",
    borderColor: "divider",
    borderRadius: 1,
    overflow: "hidden",
  },

  fabButton: {
    boxShadow: 3,
    backgroundColor: "background.paper",
    color: "primary.light",
    "& .MuiSvgIcon-root": {
      fontSize: 22,
    },
  },

  listItemButton: {
    py: 1.5,
    pl: 3,
    pr: 2.5,
  },

  chip: {
    color: "primary.main",
    backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.08)}`,
  },
};
