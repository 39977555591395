import { Stack, Button, TextField, InputAdornment } from "@mui/material";
import React from "react";

import { handleShowCoordinates } from "../utils";

import { styles } from "./styles";

import { TDDM, TDMS } from "@/types/map";
import ArgoDivider from "@/components/ArgoDivider";

export type TCoordinates = {
  activeTab: string;
  latValue: string;
  lngValue: string;
  latDDM: TDDM;
  lngDDM: TDDM;
  latDMS: TDMS;
  lngDMS: TDMS;
};

interface ISearchCoordinatesInput {
  coordinates: TCoordinates;
  isToggledYAxis: boolean;
  isToggledXAxis: boolean;
  onPaste: (event: React.ClipboardEvent) => void;
  onCopy: () => void;
}

function SearchCoordinatesInput({
  coordinates,
  isToggledYAxis,
  isToggledXAxis,
  onPaste,
  onCopy,
}: ISearchCoordinatesInput) {
  const showCoordinates = handleShowCoordinates(
    coordinates,
    isToggledYAxis ? "S" : "N",
    isToggledXAxis ? "E" : "W",
  );

  return (
    <Stack>
      <ArgoDivider />
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                size="small"
                variant="outlined"
                onClick={showCoordinates ? (onCopy as any) : (onPaste as any)}
              >
                {showCoordinates ? "Copy" : "Paste"}
              </Button>
            </InputAdornment>
          ),
        }}
        placeholder="Paste coordinates here"
        sx={styles.textfield}
        value={showCoordinates || ""}
        variant="outlined"
      />
    </Stack>
  );
}

export default SearchCoordinatesInput;
