import { Warning, Info, LocalPolice, LocationOn, Map, Cloud } from "@mui/icons-material";

import SealifeFilled from "@/components/MarkersIcon/SealifeFilled";
import { IPin } from "@/types/reportpins";

export const ReportPinsItems = [
  {
    id: 1,
    icon: <Warning />,
    color: "#F5A623",
  },
  {
    id: 2,
    icon: <Info />,
    color: "#009197",
  },
  {
    id: 3,
    icon: <Map />,
    color: "#D52B1E",
  },
  {
    id: 4,
    icon: <LocationOn />,
    color: "#06631E",
  },
  {
    id: 5,
    icon: <SealifeFilled />,
    color: "#16B592",
  },
  {
    id: 6,
    icon: <LocalPolice />,
    color: "#2196F3",
  },
  {
    id: 7,
    icon: <Cloud />,
    color: "#6196D4",
  },
];

export const pinMarkerSubtypeHandler = (marker: IPin) => {
  if (!marker) return undefined;

  const isNavAid = "kind" in marker;

  if (isNavAid) {
    if (
      (marker as { subtype?: string }).subtype &&
      (marker as { subtype?: string }).subtype !== "Other"
    ) {
      return (marker as { subtype?: string }).subtype;
    }

    return marker.kind;
  }

  if (
    (marker as { sub_type?: string }).sub_type &&
    (marker as { sub_type?: string }).sub_type !== "Other"
  ) {
    return (marker as { sub_type?: string }).sub_type;
  }

  return (
    (marker as { place_type?: string }).place_type || (marker as { category?: string }).category
  );
};
