export const styles = {
  alertMarkerContainer: {
    width: 24,
    height: 24,
    transform: "translate(-50%,-50%)",
  },

  wrapper: {
    position: "relative",

    "& .popover": {
      display: "none",
    },
    "&:hover section": {
      display: "flex",
    },
    "& section": {
      position: "absolute",
      left: "-180px",
      bottom: "calc(100% + 24px)",
    },
  },

  alertMarker: {
    width: 24,
    height: 24,
  },
};
