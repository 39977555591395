import { List, ListSubheader, Button, Box, Divider } from "@mui/material";
import {
  LinearScaleOutlined,
  TimelapseOutlined,
  TimerOutlined,
  ImportContactsOutlined,
  LocalGasStation,
  DirectionsBoatFilledOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { LengthUnits, VolumeUnits, useUnitSystem } from "@argonav/unit-system";

import RouteResultListItem from "../RouteResultListItem";
import SaveRouteForm from "../RouteSaveForm";

import {
  voyageDistance,
  voyageEstimatedDuration,
  durationToString,
  voyageEstimatedTimeArrival,
  voyageEstimatedFuelConsumption,
} from "@/utils/maps";
import { getVesselName } from "@/utils/route";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { Route } from "@/types/map";
import useDisclosure from "@/hooks/useDisclosure";
import { setSelectedVoyage } from "@/store/slices/captainslog/actions";
import { addCourses } from "@/store/slices/captainslog";
import useSearch from "@/hooks/useSearch";
import { addCategory } from "@/store/slices/search";
import { resetRouteParams, initialState } from "@/store/slices/route";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { capitalizeWord } from "@/utils/globals";

export default function RouteResult({
  onTakeScreenshot,
}: {
  onTakeScreenshot: () => Promise<File | null>;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { setCleanPoiList } = useSearch();

  const { routeCreated, startPoint, endPoint } = useAppSelector((state) => state.route);
  const { userInformation } = useAppSelector((state) => state.user);
  const { voyageEditMode, selectedVoyage, courses } = useAppSelector((state) => state.captainslog);
  const { logged } = useAppSelector((state) => state.auth);
  const { distanceUnitHandler } = useUnitSystem();
  const fuelUnit = useAppSelector((state) =>
    capitalizeWord(state.user.userOptions.volume_unit?.toLowerCase()),
  );
  const fuelUnitLabel = fuelUnit === VolumeUnits.GALLONS ? "gal" : "L";

  const distance = distanceUnitHandler({
    originalUnit: LengthUnits.METERS,
    originalValue: Number(voyageDistance(routeCreated).toFixed(1)),
  });

  const hasNotFuel =
    userInformation?.current_vessel?.fuel_consumption === "" ||
    userInformation?.current_vessel?.fuel_consumption === null ||
    userInformation?.current_vessel?.fuel_consumption === "0" ||
    Number(userInformation?.current_vessel?.fuel_consumption) === 0 ||
    userInformation?.current_vessel?.fuel_consumption === undefined;

  const isElectric = userInformation?.current_vessel?.fuel_type === "electric";
  const routeResults = (routeCreated: Route) => [
    {
      id: 1,
      icon: <DirectionsBoatFilledOutlined />,
      secondaryLabel: getVesselName(userInformation?.current_vessel),
      primaryLabel: "Boat Name / Model",
    },
    {
      id: 2,
      icon: <LinearScaleOutlined />,
      secondaryLabel: `${distance.value} ${distance.unit}`,
      primaryLabel: "Distance",
    },
    {
      id: 3,
      icon: <TimelapseOutlined />,
      secondaryLabel: `${durationToString(
        voyageEstimatedDuration(routeCreated, userInformation?.current_vessel?.average_speed),
      )}`,
      primaryLabel: "Duration",
    },
    {
      id: 4,
      icon: <TimerOutlined />,
      secondaryLabel: voyageEstimatedTimeArrival(
        routeCreated,
        userInformation?.current_vessel?.average_speed,
      ),
      primaryLabel: "Estimated Time of Arrival",
    },

    {
      id: 5,
      icon: hasNotFuel ? undefined : <LocalGasStation />,
      secondaryLabel: hasNotFuel
        ? undefined
        : `${voyageEstimatedFuelConsumption(routeCreated, userInformation?.current_vessel)} ${
            userInformation?.current_vessel ? (isElectric ? "kW" : fuelUnitLabel) : ""
          }`,
      primaryLabel: hasNotFuel ? undefined : `Estimated ${isElectric ? "Power" : "Fuel"} Used`,
    },
  ];

  const handleCloseRoute = () => {
    setCleanPoiList();
    dispatch(addCategory([]));
    dispatch(
      resetRouteParams({
        ...initialState,
      }),
    );
  };

  const handleSaveRoute = () => {
    if (logged) {
      onOpen();
    } else {
      navigate("/login");
    }
  };

  const handleConfirmNewRoute = () => {
    if (logged) {
      dispatch(
        setSelectedVoyage({
          ...selectedVoyage,
          courses: [
            {
              ...selectedVoyage?.courses?.[0],
              start_lat: startPoint?.lat,
              start_lng: startPoint?.lng,
              end_lat: endPoint?.lat,
              end_lng: endPoint?.lng,
              distance: routeCreated?.distance,
              course_locations: routeCreated?.course_locations,
              course_bridges: routeCreated?.course_bridges,
            },
          ],
        }),
      );

      dispatch(
        addCourses([
          {
            ...(courses && courses[0]),
            start_lat: startPoint?.lat,
            start_lng: startPoint?.lng,
            end_lat: endPoint?.lat,
            end_lng: endPoint?.lng,
            distance: routeCreated?.distance,
            course_locations: routeCreated?.course_locations,
            course_bridges: routeCreated?.course_bridges,
          },
        ]),
      );
      dispatch(
        setSnackBarMsjSucceded({
          state: true,
          type: "success",
          msj: "Route points updated.",
        }),
      );
      navigate("/captains-log");
      handleCloseRoute();
    } else {
      navigate("/login");
    }
  };

  const routeEditedCoordinates =
    selectedVoyage?.courses?.[0].start_lat !== startPoint?.lat ||
    selectedVoyage?.courses?.[0].start_lng !== startPoint?.lng ||
    selectedVoyage?.courses?.[0].end_lat !== endPoint?.lat ||
    selectedVoyage?.courses?.[0].end_lng !== endPoint?.lng;

  return (
    routeCreated && (
      <Box data-testid="route-result">
        <List disablePadding>
          <ListSubheader>Route Details</ListSubheader>
          {routeResults(routeCreated).map((item) => (
            <RouteResultListItem
              key={item.id}
              icon={item.icon}
              primaryLabel={item.primaryLabel}
              secondaryLabel={item.secondaryLabel}
            />
          ))}
        </List>
        <Box p={2}>
          {voyageEditMode ? (
            <Button
              color="primary"
              disabled={!routeEditedCoordinates}
              size="large"
              startIcon={<ImportContactsOutlined />}
              variant="contained"
              onClick={handleConfirmNewRoute}
            >
              Confirm Change
            </Button>
          ) : (
            <Button
              color="primary"
              size="large"
              startIcon={<ImportContactsOutlined />}
              variant="contained"
              onClick={handleSaveRoute}
            >
              Save to Captain&apos;s Log
            </Button>
          )}

          {isOpen && (
            <SaveRouteForm
              open={isOpen}
              onCleanRouteStatus={handleCloseRoute}
              onClose={onClose}
              onTakeScreenshot={onTakeScreenshot}
            />
          )}
        </Box>
        <Divider />
      </Box>
    )
  );
}
