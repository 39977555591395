/* eslint-disable react/jsx-props-no-spreading */

import * as React from "react";
import { Typography, Box, Grid, Badge, Stack } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { ExpandMoreOutlined } from "@mui/icons-material";

import { Interest, EInterestCategories } from "@/types/interest";
import ToogleChip from "@/components/ToogleChip/ToogleChip";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters square elevation={0} {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:first-of-type": {
    borderTop: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  boxShadow: "inset 0 1px 10px rgb(0 0 0 / 5%)",
  "& .MuiIconButton-root": {
    pointerEvents: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreOutlined />} {...props} />
))(({ theme }) => ({
  "&.MuiButtonBase-root:hover": {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.12)}`,
    color: theme.palette.primary.main,
  },
  "& .MuiAccordionSummary-content": {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexDirection: "column",
  },
  "&.MuiButtonBase-root.Mui-expanded": {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.12)}`,
    color: theme.palette.primary.main,
  },
}));

interface IInterestsAccordion {
  listOfInterests: Interest[];
  selectedInterests: Interest[];
  onSetInterests: (item: Interest) => void;
}

function accordionDetailsWithInterests(
  list: Interest[],
  selectedList: Interest[],
  category: string,
  onSetInterests: (item: Interest) => void,
) {
  return (
    <AccordionDetails>
      <Grid container columnSpacing={1} rowSpacing={1.5}>
        {list
          .filter((item: Interest) => item.category === category)
          .map((item: Interest) => (
            <Grid key={item.id} item xs="auto" onClick={() => onSetInterests(item)}>
              <ToogleChip
                isActive={!!selectedList.find((selectedItem) => selectedItem.id === item.id)}
                label={item.name}
              />
            </Grid>
          ))}
      </Grid>
    </AccordionDetails>
  );
}

function selectedCountBadge(list: Interest[], category: string) {
  const itemsCounted = list.filter((item: Interest) => item.category === category).length;

  if (itemsCounted) return <Badge badgeContent={itemsCounted} color="primary" />;

  return null;
}

export default function InterestsAccordion({
  listOfInterests,
  selectedInterests,
  onSetInterests,
}: IInterestsAccordion) {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: "divider",
        overflow: "hidden",
        borderRadius: "8px",
      }}
    >
      <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <AccordionSummary
          aria-controls="panel1a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel1a-header"
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography sx={{ lineHeight: "normal" }} variant="subtitle1">
              Boat Know How
            </Typography>
            {selectedCountBadge(selectedInterests, EInterestCategories.BoatingKnowHow)}
          </Stack>
        </AccordionSummary>
        {accordionDetailsWithInterests(
          listOfInterests,
          selectedInterests,
          EInterestCategories.BoatingKnowHow,
          onSetInterests,
        )}
      </Accordion>
      <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <AccordionSummary
          aria-controls="panel2a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel2a-header"
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography sx={{ lineHeight: "normal" }} variant="subtitle1">
              Boating Types
            </Typography>
            {selectedCountBadge(selectedInterests, EInterestCategories.BoatTypes)}
          </Stack>
        </AccordionSummary>
        {accordionDetailsWithInterests(
          listOfInterests,
          selectedInterests,
          EInterestCategories.BoatTypes,
          onSetInterests,
        )}
      </Accordion>
      <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
        <AccordionSummary
          aria-controls="panel3a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel3a-header"
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography sx={{ lineHeight: "normal" }} variant="subtitle1">
              Events
            </Typography>
            {selectedCountBadge(selectedInterests, EInterestCategories.Events)}
          </Stack>
        </AccordionSummary>
        {accordionDetailsWithInterests(
          listOfInterests,
          selectedInterests,
          EInterestCategories.Events,
          onSetInterests,
        )}
      </Accordion>

      <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
        <AccordionSummary
          aria-controls="panel4a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel4a-header"
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography sx={{ lineHeight: "normal" }} variant="subtitle1">
              Fishing
            </Typography>
            {selectedCountBadge(selectedInterests, EInterestCategories.Fishing)}
          </Stack>
        </AccordionSummary>
        {accordionDetailsWithInterests(
          listOfInterests,
          selectedInterests,
          EInterestCategories.Fishing,
          onSetInterests,
        )}
      </Accordion>

      <Accordion expanded={expanded === "panel5"} onChange={handleChange("panel5")}>
        <AccordionSummary
          aria-controls="panel4a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel4a-header"
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography sx={{ lineHeight: "normal" }} variant="subtitle1">
              Here to There
            </Typography>
            {selectedCountBadge(selectedInterests, EInterestCategories.HereToThere)}
          </Stack>
        </AccordionSummary>
        {accordionDetailsWithInterests(
          listOfInterests,
          selectedInterests,
          EInterestCategories.HereToThere,
          onSetInterests,
        )}
      </Accordion>

      <Accordion expanded={expanded === "panel6"} onChange={handleChange("panel6")}>
        <AccordionSummary
          aria-controls="panel4a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel4a-header"
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography sx={{ lineHeight: "normal" }} variant="subtitle1">
              Leisure Activities
            </Typography>
            {selectedCountBadge(selectedInterests, EInterestCategories.LeisureActivities)}
          </Stack>
        </AccordionSummary>
        {accordionDetailsWithInterests(
          listOfInterests,
          selectedInterests,
          EInterestCategories.LeisureActivities,
          onSetInterests,
        )}
      </Accordion>

      <Accordion expanded={expanded === "panel7"} onChange={handleChange("panel7")}>
        <AccordionSummary
          aria-controls="panel4a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel4a-header"
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography sx={{ lineHeight: "normal" }} variant="subtitle1">
              Local
            </Typography>
            {selectedCountBadge(selectedInterests, EInterestCategories.Local)}
          </Stack>
        </AccordionSummary>
        {accordionDetailsWithInterests(
          listOfInterests,
          selectedInterests,
          EInterestCategories.Local,
          onSetInterests,
        )}
      </Accordion>

      <Accordion expanded={expanded === "panel8"} onChange={handleChange("panel8")}>
        <AccordionSummary
          aria-controls="panel4a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel4a-header"
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography sx={{ lineHeight: "normal" }} variant="subtitle1">
              Racing
            </Typography>
            {selectedCountBadge(selectedInterests, EInterestCategories.Racing)}
          </Stack>
        </AccordionSummary>
        {accordionDetailsWithInterests(
          listOfInterests,
          selectedInterests,
          EInterestCategories.Racing,
          onSetInterests,
        )}
      </Accordion>

      <Accordion expanded={expanded === "panel9"} onChange={handleChange("panel9")}>
        <AccordionSummary
          aria-controls="panel4a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel4a-header"
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography sx={{ lineHeight: "normal" }} variant="subtitle1">
              Water Rec
            </Typography>
            {selectedCountBadge(selectedInterests, EInterestCategories.WaterRec)}
          </Stack>
        </AccordionSummary>
        {accordionDetailsWithInterests(
          listOfInterests,
          selectedInterests,
          EInterestCategories.WaterRec,
          onSetInterests,
        )}
      </Accordion>
    </Box>
  );
}
