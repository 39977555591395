import { Chip, Avatar } from "@mui/material";

interface IIconChip {
  icon: string;
  label: string;
}

function IconChip({ icon, label }: IIconChip) {
  return (
    <Chip
      avatar={<Avatar alt={label} src={icon} />}
      label={label}
      sx={{ border: "2px solid rgba(0, 0, 0, 0.12)", cursor: "pointer" }}
      variant="outlined"
    />
  );
}

export default IconChip;
