import { useState } from "react";

const useInfiniteScroll = () => {
  const [items, setItems] = useState<any>([]);
  const [isLoadingScroll, setIsLoadingScroll] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);

  const handleScroll = (callback: any) => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      isLoadingScroll
    ) {
      return;
    }

    callback();
  };

  return {
    items,
    isLoadingScroll,
    error,
    page,
    setItems,
    setIsLoadingScroll,
    setError,
    setPage,
    handleScroll,
  };
};

export default useInfiniteScroll;
