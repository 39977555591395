import React from "react";
import {
  Stack,
  Typography,
  FormControl,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

interface IDeleteAccountModal {
  onClose: () => void;
}

function DeleteAccountModal({ onClose }: IDeleteAccountModal) {
  return (
    <>
      <DialogContent>
        <Stack component="form" spacing={2}>
          <Typography>
            We are sorry to see you go. Please take a moment to let us know why you are deleting
            your account. (required)
          </Typography>

          <FormControl>
            <RadioGroup aria-labelledby="demo-reasons-label" name="reasons">
              <FormControlLabel
                control={<Radio size="small" />}
                label="App does not work as expected"
                value="#notExpected"
              />
              <FormControlLabel
                control={<Radio size="small" />}
                label="Using another navigation app"
                value="#"
              />
              <FormControlLabel
                control={<Radio size="small" />}
                label="Created second account"
                value="secondAccount"
              />
              <FormControlLabel
                control={<Radio size="small" />}
                label="Privacy concerns"
                value="privacyConcerns"
              />
              <FormControlLabel
                control={<Radio size="small" />}
                label="No longer boating"
                value="noBoating"
              />
              <FormControlLabel control={<Radio size="small" />} label="Other" value="other" />
            </RadioGroup>
          </FormControl>

          <TextField
            multiline
            id="outlined-multiline-static"
            label="Additional Feedback"
            rows={4}
          />

          <Typography color="text.secondary">
            You will have 10 days to restore vour account before all vour data are removed,
            including saved voyages, places, favorites, report pins, messages and social posts.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button color="error" onClick={onClose}>
          Delete My Account
        </Button>
        <Button autoFocus variant="contained" onClick={onClose}>
          Oops! Changed my mind
        </Button>
      </DialogActions>
    </>
  );
}

export default DeleteAccountModal;
