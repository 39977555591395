import React, { useEffect, useState } from "react";
import {
  Stack,
  DialogActions,
  DialogContent,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Box,
  Typography,
} from "@mui/material";
// import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";

import { styles } from "./styles";

import { IDepthShading } from "@/types/map";
import DepthIndicatorLayout from "@/components/MapOptions/MapOptionDepthShading/DepthIndicatorLayout";
import { useUpdateShadingMutation } from "@/store/api/argoApi";
import { useAppSelector } from "@/hooks/useRedux";

interface IDepthShadingModal {
  onClose: () => void;
  shadingItems: IDepthShading[];
}

function DepthShadingRadio(props: RadioProps & { item: IDepthShading }) {
  const { item }: { item: IDepthShading } = props;

  return (
    <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
      <Stack alignItems="center" direction="row" spacing={0.5}>
        <Radio disableRipple color="primary" {...props} sx={styles.radio} />
        <Typography variant="body1">{item.name}</Typography>
      </Stack>

      <Stack direction="row" sx={styles.depthIndicator}>
        <DepthIndicatorLayout shaderItems={item.depth_shader_items} />
      </Stack>
    </Stack>
  );
}

function DepthShadingModal({ onClose, shadingItems }: IDepthShadingModal) {
  const [selectedShading, setSelectedShading] = useState<number | undefined>(undefined);
  const [updateShading] = useUpdateShadingMutation();
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    setSelectedShading(shadingItems.find((item) => item.current)?.id || undefined);
  }, []);

  const {
    defaultShading,
    customShadings,
  }: { defaultShading: IDepthShading[]; customShadings: IDepthShading[] } = shadingItems.reduce(
    (acc, item) => {
      if (item.name === "Default") {
        acc.defaultShading.push(item);
      } else {
        acc.customShadings.push(item);
      }

      return acc;
    },
    { defaultShading: [] as IDepthShading[], customShadings: [] as IDepthShading[] },
  );

  const handleSetShadings = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedShading(parseInt(event.target.value, 10));
  };

  const handleSaveDepthShading = () => {
    updateShading({ id: selectedShading, current: true, user_id: user?.id });
    onClose();
  };

  if (!selectedShading) return null;

  return (
    <Box>
      <DialogContent sx={{ py: 1 }}>
        <FormControl sx={styles.formControl}>
          <RadioGroup
            aria-labelledby="demo-customized-radios"
            defaultValue={selectedShading}
            onChange={handleSetShadings}
          >
            <>
              {defaultShading.map((item) => (
                <FormControlLabel
                  key={item.id}
                  control={<DepthShadingRadio item={item} />}
                  label={null}
                  sx={item.current ? styles.formControlLabelSelected : styles.formControlLabel}
                  value={item.id}
                />
              ))}
              <Stack direction="column" spacing={1}>
                <Typography sx={{ mt: 2 }} variant="subtitle1">
                  Custom Depth Shading
                </Typography>
                <Stack direction="column" spacing={1} sx={styles.customShadings}>
                  {customShadings.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      control={<DepthShadingRadio item={item} />}
                      label={null}
                      sx={styles.formControlLabel}
                      value={item.id}
                    />
                  ))}
                </Stack>
              </Stack>
            </>
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="button" variant="contained" onClick={handleSaveDepthShading}>
          Save
        </Button>
      </DialogActions>
    </Box>
  );
}

export default DepthShadingModal;
