import { findPoi } from "./poi";

import { Affiliation, IAffiliationItem } from "@/types/affiliation";

/**
 * Convert a list of Affiliation items to a list of IAffiliationItem
 * @param affiliations - List of Affiliations to be converted
 * @returns a list of IAffiliationItem
 */
export const fromAffiliationsToAffiliationItems = (affiliations: Affiliation[]) => {
  let items: IAffiliationItem[] = [];

  if (affiliations) {
    items = affiliations.map((item: Affiliation) => ({
      label: item.name,
      icon: findPoi(item.poi_type),
    }));
  }

  return items;
};
