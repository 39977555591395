import { TVessel } from "./vessel";

export type Props = {
  [key: string]: string | number | boolean | any;
};

export type LoginEmail = {
  email: string;
  password: string;
};

export type UserRegisterF = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirm: string;
};

export type UserRegisterData = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
};

export type UserLoginGoogle = {
  auth_token: string;
  email: string;
  first_name: string;
  last_name: string;
  image: string;
  uid: string;
};

export type UserLoginApple = {
  fullName: string;
  user: string;
  identityToken: string;
};

export type UserSettings = {
  accepted_friend_request: boolean;
  all_messages: boolean;
  captains_log_visability: number;
  comment_like: boolean;
  comment_pins: boolean;
  comment_posts: boolean;
  direct_messages: boolean;
  group_accept: boolean;
  group_invite: boolean;
  id: number;
  like_pins: boolean;
  like_posts: boolean;
  map_visibility: number;
  message_requests: boolean;
  newsfeed_content_visability: number;
  pause_notifications: boolean;
  push_notification_ahoys: boolean;
  sent_friend_request: boolean;
  share_destination_eta: boolean;
  shared_places: boolean;
  shared_voyages: boolean;
  toast_notification_ahoys: boolean;
  user_id: number;
  user_profile_visibility: number;
  visibility: any;
};

export type UserInformation = {
  affiliated_poi_in_common: number;
  cover: string;
  created_at: string;
  depth: any;
  email: string;
  first_name: string;
  current_vessel: TVessel | null;
  friend_request_id: any;
  friendship_status: string;
  heading: any;
  id: number;
  image: string;
  last_name: string;
  lat: any;
  lng: any;
  mutual_friends: any;
  provider: string;
  source: string;
  uid: string;
  updated_at: string;
  payment_date: string;
  payment_due_date: string;
  user_setting: UserSettings;
  username: string;
  vessel_name: string;
  user_subscription: string;
  apply_for_trial: boolean;
  latest_user_subscription: any;
};

export type EditUserInfoPayload = {
  username: string;
  first_name: string;
  last_name: string;
};

export enum EProvider {
  email = "email",
  apple = "apple",
  google = "google",
}

export interface IUserSubscription {
  user_subscription: string;
  platform_os: string;
  payment_date: Date;
}

export interface IFriends {
  id: number;
  uid: string;
  first_name: string;
  last_name: string;
  username: string;
  image: string;
  cover: string | null;
  email: string;
  friendship_status: string;
  friend_request_id: number | null;
  mutual_friends: number;
  popoverDirection?: string;
  lat: string;
  lng: string;
  affiliated_poi_in_common: number;
  groups_in_common: number;
  user_setting: UserSettings;
  current_vessel: TVessel;
}

export interface IBoatersFilters {
  friends: boolean;
  others: boolean;
  affiliationsId: number[];
}
