import { alpha } from "@mui/material/styles";

import { layoutStyles } from "@/themes/layoutStyles";
import defaultTheme from "@/themes/theme";

export const styles = {
  loadingText: {
    color: `${alpha(defaultTheme.palette.common.black, 0.6)}`,
  },

  Container: {
    ...layoutStyles.scroll,

    overflowY: "auto",
    background: defaultTheme.palette.grey[100],
    height: "calc(100vh - 310px)",
  },
};
