import { layoutStyles } from "@/themes/layoutStyles";

export const styles = {
  scrollContainer: {
    ...layoutStyles.scroll,

    paddingTop: "68px",
    overflowY: "auto",
    maxHeight: "100vh",
  },
};
