import React from "react";
import { DialogContent, DialogActions, Box, Stack, Button, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { styles } from "./styles";

import { setPremiumSubscriptionModal, setSubscriptionInfoModal } from "@/store/slices/user";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import PremiumModal from "@/components/Modals/PremiumModal";

interface IGreetingModal {
  onClose: () => void;
  action?: () => void;
  isLogged: boolean;
  isPremium: boolean;
  freeTrialUrl?: string | undefined;
}

function GreetingModal({ onClose, action, isLogged, isPremium, freeTrialUrl }: IGreetingModal) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { outDateOfTrial } = useAppSelector((state) => state.premium);
  const handleDontShowAgain = () => {
    if (action) action();
    onClose();
  };

  const handleOpenSubscriptionModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (isPremium) {
      onClose();
      dispatch(setSubscriptionInfoModal(true));
      dispatch(setPremiumSubscriptionModal(false));
    }
  };

  return (
    <>
      {isLogged && !isPremium ? (
        <PremiumModal />
      ) : (
        <DialogContent sx={isPremium ? styles.PremiumDialogContent : styles.NonUserDialogContent}>
          <Stack alignItems="center">
            <Box
              sx={{
                ...styles.image,
                backgroundImage: isPremium
                  ? `url(${"img/illustrations/PremiumGreeting.svg"})`
                  : `url(${"img/illustrations/NonUserGreeting.svg"})`,
              }}
            />
            <Stack alignItems="center" spacing={0.75} sx={styles.text}>
              {isPremium ? (
                <>
                  <Typography color="common.white" textAlign="center" variant="h5">
                    Argo Premium Member
                  </Typography>
                  <Typography color="common.white" textAlign="center" variant="body1">
                    With Premium, you get all the interactive chart features for both web and
                    mobile. Explore, plan, document your trips and sync effortlessly with your
                    mobile devices.
                  </Typography>
                </>
              ) : (
                <>
                  <Typography textAlign="center" variant="h5">
                    Welcome to Argo&apos;s New Web App
                  </Typography>
                  <Typography textAlign="center" variant="body1">
                    To access our interactive chart features and sync with your mobile devices,{" "}
                    <Link
                      component="button"
                      variant="body1"
                      onClick={() =>
                        navigate(
                          `/login${freeTrialUrl && !outDateOfTrial ? `/${freeTrialUrl}` : ""}`,
                        )
                      }
                    >
                      please sign in.
                    </Link>
                  </Typography>
                </>
              )}
            </Stack>
            {isPremium && (
              <Button
                color="whiteBg"
                size="large"
                sx={styles.dialogButton}
                variant="contained"
                onClick={handleOpenSubscriptionModal}
              >
                See Subscription Details
              </Button>
            )}
          </Stack>
        </DialogContent>
      )}
      {action && isPremium && (
        <DialogActions
          sx={
            isPremium
              ? styles.PremiumDialogActions
              : isLogged
              ? styles.LoggedDialogActions
              : styles.NonUserDialogActions
          }
        >
          <Button size="small" onClick={handleDontShowAgain}>
            Don’t show again
          </Button>

          <Button
            size="small"
            variant={isPremium ? "contained" : isLogged ? "outlined" : "contained"}
            onClick={onClose}
          >
            Dismiss
          </Button>
        </DialogActions>
      )}
    </>
  );
}

export default GreetingModal;
