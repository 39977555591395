import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import PremiumPaymentLayout from "@/components/Premium/PremiumPaymentLayout";
import { setSnackBarMsj, setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import CustomizedSnackbar from "@/components/CustomizedSnackbar";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { getUserInformation } from "@/store/slices/user/actions";
import PremiumThanksLayout from "@/components/Premium/PremiumThanksLayout";
import ServicePremium from "@/services/premium";
import { setPremiumSubscriptionModalSuccess } from "@/store/slices/user";
import {
  membership_name_yearly,
  membership_name_monthly,
  membership_name_lifetime,
} from "@/components/Premium/PremiumPaymentCard/PremiumPaymentCard";

function PremiumPayment() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { snackBarMsj } = useAppSelector((state) => state.auth);
  const { userInformation } = useAppSelector((state) => state.user);

  const isMonthlyOrYearly =
    userInformation?.latest_user_subscription?.membership_name === membership_name_yearly ||
    userInformation?.latest_user_subscription?.membership_name === membership_name_monthly ||
    userInformation?.latest_user_subscription?.membership_name === membership_name_lifetime ||
    !userInformation?.latest_user_subscription ||
    (userInformation?.latest_user_subscription &&
      !userInformation?.latest_user_subscription?.membership_activated);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [readyToPay, setReadyToPay] = useState<boolean>(false);
  const [checkSubscription, setCheckSubscription] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const handlePaymentSucceded = () => {
    dispatch(setPremiumSubscriptionModalSuccess(true));
  };

  const handleSetSubscription = async (idSub: string) => {
    const { status } = await ServicePremium.updateUserPremiumStatus(idSub);

    try {
      if (status === 200) {
        await dispatch(getUserInformation(userInformation?.id));
        setCheckSubscription(true);
        handlePaymentSucceded();
      }
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
  };

  const handleReadyToPay = async () => {
    const user = await dispatch(getUserInformation(userInformation?.id));
    const idSub = searchParams.get("user_subscription_id");

    if (user) {
      if (user.user_subscription !== "premium") {
        if (idSub) {
          handleSetSubscription(idSub);
        } else {
          setReadyToPay(true);
        }
      } else if (isMonthlyOrYearly) {
        if (idSub) {
          handleSetSubscription(idSub);
        } else {
          setReadyToPay(true);
        }
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleReadyToPay();
  }, []);

  const handleSnackBarClose = (reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(setSnackBarMsj(false, "", ""));
  };

  if (isLoading) return null;

  return (
    <>
      {readyToPay && <PremiumPaymentLayout />}
      {checkSubscription && <PremiumThanksLayout />}
      <CustomizedSnackbar
        close={handleSnackBarClose}
        message={snackBarMsj.msj}
        open={snackBarMsj.state}
        severity={snackBarMsj.type}
      />
    </>
  );
}

export default PremiumPayment;
