import { Stack, Typography, Switch, Divider, Box } from "@mui/material";
import { useState } from "react";

import BaseDrawer from "../BaseDrawer";

import { styles } from "./styles";

import DepthBrightness from "@/components/DepthShading/DepthBrightness";
import DepthUserShadings from "@/components/DepthShading/DepthUserShadings";
import DepthShadingEditingPanel from "@/components/DepthShading/DepthShadingEditingPanel";
import {
  useUpdateGlobalOpacityMutation,
  useGetUserOptionsQuery,
  useGetDepthShadingsQuery,
} from "@/store/api/argoApi";
import { useAppSelector, useAppDispatch } from "@/hooks/useRedux";
import userService from "@/services/user";
import { setUserOptions } from "@/store/slices/user";

interface IDepthShadingDrawer {
  open: boolean;
}

function DepthShadingDrawer({ open }: IDepthShadingDrawer) {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { userOptions } = useAppSelector((state) => state.user);
  const { data: depthShadingData } = useGetDepthShadingsQuery({ user_id: user?.id });
  const { data: userOptionsData } = useGetUserOptionsQuery(undefined);
  const [updateGlobalOpacity] = useUpdateGlobalOpacityMutation();
  const [sliderOpacity, setSliderOpacity] = useState<number>(userOptionsData?.data?.shader_opacity);
  const { depthShadingDrawerOpen, depthShadingToEdit } = useAppSelector(
    (state) => state.depthshading,
  );

  const shaderOpacityEnabled = userOptionsData?.data?.depth_shading_enabled;
  const shadingContourLines = userOptions?.depth_shading_contour_lines_enabled;

  const handleSwitch = async () => {
    if (depthShadingData) {
      updateGlobalOpacity({ depth_shading_enabled: !shaderOpacityEnabled });
    }
  };

  const handleSwitchContourLines = async () => {
    if (depthShadingData) {
      const { status } = await userService.editUserOptions({
        depth_shading_contour_lines_enabled: !shadingContourLines,
      });

      if (status === 200) {
        dispatch(
          setUserOptions({
            ...userOptions,
            depth_shading_contour_lines_enabled: !shadingContourLines,
          }),
        );
      }
    }
  };

  const handleChangeOpacity = (e: Event, newValue: number | number[]) => {
    setSliderOpacity(newValue as number);
  };

  const handleSliderTransparency = () => {
    updateGlobalOpacity({ shader_opacity: sliderOpacity as number });
  };

  return (
    <Box>
      <BaseDrawer open={open} styles={styles.drawer}>
        <Stack component="main" sx={styles.container}>
          <Stack
            alignItems="center"
            component="section"
            direction="row"
            justifyContent="space-between"
            sx={styles.header}
          >
            <Typography variant="h5">Depth Shading</Typography>
          </Stack>
          <Stack component="section" spacing={1} sx={{ px: 2 }}>
            <Stack alignItems="center" direction="row" justifyContent="space-between">
              <Typography variant="subtitle2">Depth Shading</Typography>
              <Switch
                checked={shaderOpacityEnabled}
                color="secondary"
                edge="end"
                size="small"
                onClick={handleSwitch}
              />
            </Stack>
            <Divider />
            <Stack alignItems="center" direction="row" justifyContent="space-between">
              <Typography variant="subtitle2">Contour Lines</Typography>
              <Switch
                checked={shadingContourLines}
                color="secondary"
                edge="end"
                size="small"
                onClick={handleSwitchContourLines}
              />
            </Stack>
            <Divider />

            <DepthBrightness
              isDisabled={!(shaderOpacityEnabled && depthShadingData)}
              valueOpacity={sliderOpacity}
              onChange={handleChangeOpacity}
              onChangeCommitted={handleSliderTransparency}
            />
          </Stack>

          <Stack component="section" sx={styles.mainContent}>
            <DepthUserShadings depthShadingData={depthShadingData} />
          </Stack>
        </Stack>
      </BaseDrawer>
      {depthShadingDrawerOpen && depthShadingToEdit && <DepthShadingEditingPanel />}
    </Box>
  );
}

export default DepthShadingDrawer;
