import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";

export const styles = {
  chip: {
    cursor: "pointer",
    transition: defaultTheme.transitions.create(["background-color", "transform"], {
      duration: defaultTheme.transitions.duration.standard,
    }),
    "&.MuiChip-filledPrimary": {
      border: "1px solid",
      color: "primary.main",
      backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.08)}`,
      "&:hover": {
        backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.12)}`,
      },
    },
    "&:hover": {
      backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.08)}`,
      color: "primary.main",
    },

    "& .MuiSvgIcon-root": {
      fontSize: "18px",
    },
  },
};
