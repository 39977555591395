import { Grid, Chip, Box, Typography } from "@mui/material";

import { styles } from "./styles";

import {
  TPOI_TYPE,
  PLACES_TYPE,
  AMENITIES_TYPE,
  PARTNERS_TYPE,
  PLACES_KEY,
  AMENITIES_KEY,
  PARTNERS_KEY,
} from "@/utils/poi";

interface ISearchCategories {
  selectedCategories: TPOI_TYPE[];
  setSelectedCategories: (values: any, action?: boolean) => void;
}
const categoriesList = [
  {
    items: PLACES_TYPE,
    categoryType: PLACES_KEY,
    title: "Places",
  },
  {
    items: AMENITIES_TYPE,
    categoryType: AMENITIES_KEY,
    title: "Amenities",
  },
  {
    items: PARTNERS_TYPE,
    categoryType: PARTNERS_KEY,
    title: "Partners",
  },
];

export default function SearchCategories({
  selectedCategories,
  setSelectedCategories,
}: ISearchCategories) {
  const handleSetAction = (item: TPOI_TYPE, action?: boolean) => {
    setSelectedCategories(item, action);
  };

  return (
    <Box sx={styles.wrapper}>
      {categoriesList?.map((category) => (
        <Box key={category.title} className="Container" sx={styles.container}>
          <Typography gutterBottom variant="subtitle2">
            {category.title}
          </Typography>
          <Grid key={category.categoryType} container columnSpacing={1} rowSpacing={1}>
            {category?.items?.map((item: TPOI_TYPE) => {
              const categorySelected = selectedCategories?.some(
                (checkedItem: TPOI_TYPE) => checkedItem.value === item.value,
              );

              return (
                <Grid key={item.value} item>
                  <Chip
                    key={item.value}
                    label={item.label}
                    sx={categorySelected ? styles.chipSelected : styles.chip}
                    onClick={() => handleSetAction(item, !categorySelected)}
                    onDelete={
                      categorySelected ? () => handleSetAction(item, !categorySelected) : undefined
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ))}
    </Box>
  );
}
