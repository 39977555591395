import { alpha } from "@mui/material/styles";

import { layoutStyles } from "@/themes/layoutStyles";
import defaultTheme from "@/themes/theme";

export const styles = {
  mainContainer: {
    display: "flex",
    height: "100vh",
    width: "100vw",
    position: "relative",
    flexDirection: "column",
    backgroundColor: "grey.50",
    "& .topBar": {
      backgroundColor: "grey.300",
      justifyContent: "space-between",
    },
  },

  mapContent: {
    left: "0",
    bottom: "0",
    height: "100%",
    width: "100%",
    position: "relative",
    zIndex: "1",
    borderRadius: 1,
    overflow: "hidden",
    border: "1px solid",
    borderColor: "divider",
    backgroundColor: "grey.200",
    "& .mapOptionsScroll": {
      maxHeight: "calc(100vh - 184px)",
    },
    "& .cluster:hover div:not(img)": {
      transition: "all .1s linear",
      opacity: 1,
      transform: "translateY(0)",
    },
    "& .cluster:hover img": {
      backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.65)}`,
      transition: "all .3s linear",
    },
    "& .cluster img": {
      backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0)}`,
      borderRadius: "20px",
      p: "2px",
    },
    "& .cluster div:not(img)": {
      backgroundColor: "primary.dark",
      color: `${defaultTheme.palette.common.white} !important`,
      borderRadius: "4px",
      height: "18px !important",
      width: "18px !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      bottom: "100% !important",
      left: "30px !important",
      boxShadow: 1,
      opacity: 0,
      transform: "translateY(16px)",
    },
  },

  mapContainer: {
    left: "0",
    bottom: "0",
    height: "100%",
    width: "100%",
    position: "relative",
  },

  mapContainerOpened: {
    left: "0",
    bottom: "0",
    height: "100%",
    width: "calc(100% - 408px)",
    position: "relative",
    ml: "408px",
  },

  wrapper: {
    height: "calc(100vh - 70px)",
    ml: "80px",
    p: 2,
    display: "grid",
    gridTemplateColumns: "1fr 4fr",
    gridGap: "24px",
    transition: "all .1s linear",
  },

  wrapperExpanded: {
    height: "calc(100vh - 70px)",
    ml: "80px",
    p: 2,
  },

  boxColumns: {
    height: "100%",
    width: "100%",
    p: 3,
    display: "grid",
    gridTemplateColumns: "1fr 4fr",
    gridGap: "24px",
  },

  mapOverlays: {
    position: "absolute",
    top: 64,
    right: 0,
    p: 2,
    display: "flex",
    zIndex: 2,
  },

  mapOptions: {
    zIndex: 3,
    position: "absolute",
    bottom: "12px",
    left: "12px",
  },

  zoomControls: {
    zIndex: 3,
    position: "absolute",
    bottom: "12px",
    right: "12px",
  },

  drawer: {
    width: 408,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: 408,
      position: "absolute",
      boxSizing: "border-box",
      boxShadow: 12,
      borderRight: "none",
      // borderRadius: "0 8px 8px 0",
      // overflow: "hidden",
      zIndex: 6,
    },
  },

  drawerHeader: {
    pl: 2,
    pr: 8,
    pt: 3.5,
    pb: 3,
    position: "relative",
    borderBottom: "1px solid",
    borderColor: "divider",
  },

  drawerHeaderButtons: {
    position: "absolute",
    top: 20,
    right: 16,
  },

  iconButton: {
    width: "40px",
    height: "40px",
    color: "primary.main",
    backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.08)}`,
    "&:hover": {
      backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.12)}`,
    },
  },

  scrollContainer: {
    ...layoutStyles.scroll,

    overflowY: "auto",
    maxHeight: "100vh",
  },

  searchBoxDrawer: {
    position: "relative",
    py: 1,
    px: 1.5,
  },

  fab: {
    position: "absolute",
    bottom: 16,
    right: 16,
  },

  rightControls: {
    zIndex: 3,
    position: "absolute",
    bottom: 0,
    right: 0,
    p: 1.5,
    pb: 3,
    pt: 0,
  },

  expandMapButton: {
    position: "absolute",
    top: "14px",
    left: "-12px",
    zIndex: 60,
    border: "1px solid",
    borderColor: "divider",
    color: "text.secondary",
    p: "3px",
    "& .MuiSvgIcon-root": {
      fontSize: 16,
    },
    "&:hover": {
      transform: "scale(1.03)",
      boxShadow: 4,
      borderColor: "transparent",
    },
  },
};
