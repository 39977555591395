// Icons Import
import { IconKeysReportPins } from "@/types/markers";
import accidentCollisionIcon from "@/assets/img/markers/reportpins/3x/accidentCollision.png";
import boatFireIcon from "@/assets/img/markers/reportpins/3x/boatFire.png";
import engineTroubleIcon from "@/assets/img/markers/reportpins/3x/engineTrouble.png";
import floatingIcon from "@/assets/img/markers/reportpins/3x/floating.png";
import hazardIcon from "@/assets/img/markers/reportpins/3x/hazard.png";
import localAdviceIcon from "@/assets/img/markers/reportpins/3x/localAdvice.png";
import manOverboardIcon from "@/assets/img/markers/reportpins/3x/manOverboard.png";
import mapIssueIcon from "@/assets/img/markers/reportpins/3x/mapIssue.png";
import marineLifeIcon from "@/assets/img/markers/reportpins/3x/marineLife.png";
import medicalEmergencyIcon from "@/assets/img/markers/reportpins/3x/medicalEmergency.png";
import noWakeZoneIcon from "@/assets/img/markers/reportpins/3x/noWakeZone.png";
import outOfFuelIcon from "@/assets/img/markers/reportpins/3x/outOfFuel.png";
import photoIcon from "@/assets/img/markers/reportpins/3x/photo.png";
import policeIcon from "@/assets/img/markers/reportpins/3x/police.png";
import protrudingIcon from "@/assets/img/markers/reportpins/3x/protruding.png";
import ranAgroundIcon from "@/assets/img/markers/reportpins/3x/ranAground.png";
import socialPostIcon from "@/assets/img/markers/reportpins/3x/socialPost.png";
import sosIcon from "@/assets/img/markers/reportpins/3x/sos.png";
import submergedIcon from "@/assets/img/markers/reportpins/3x/submerged.png";
import weatherIcon from "@/assets/img/markers/reportpins/3x/weather.png";

export const getReportPinIcon = (type: IconKeysReportPins) => {
  switch (type) {
    case IconKeysReportPins.accidentCollision:
      return accidentCollisionIcon;
    case IconKeysReportPins.boatFire:
      return boatFireIcon;
    case IconKeysReportPins.engineTrouble:
      return engineTroubleIcon;
    case IconKeysReportPins.floating:
      return floatingIcon;
    case IconKeysReportPins.hazard:
      return hazardIcon;
    case IconKeysReportPins.localDevice:
      return localAdviceIcon;
    case IconKeysReportPins.manOverboard:
      return manOverboardIcon;
    case IconKeysReportPins.mapIssue:
      return mapIssueIcon;
    case IconKeysReportPins.marineLife:
      return marineLifeIcon;
    case IconKeysReportPins.medicalEmergency:
      return medicalEmergencyIcon;
    case IconKeysReportPins.noWakeZone:
      return noWakeZoneIcon;
    case IconKeysReportPins.outOfFuel:
      return outOfFuelIcon;
    case IconKeysReportPins.photo:
      return photoIcon;
    case IconKeysReportPins.police:
      return policeIcon;
    case IconKeysReportPins.protruding:
      return protrudingIcon;
    case IconKeysReportPins.ranAground:
      return ranAgroundIcon;
    case IconKeysReportPins.socialPost:
      return socialPostIcon;
    case IconKeysReportPins.sos:
      return sosIcon;
    case IconKeysReportPins.submerged:
      return submergedIcon;
    case IconKeysReportPins.weather:
      return weatherIcon;
    default:
      return localAdviceIcon;
  }
};
