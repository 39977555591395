/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { List, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { ExpandMoreOutlined } from "@mui/icons-material";

import MapOptionsListItem from "../MapOptionListItem";
import MapOptionsAffiliations from "../MapOptionsAffiliations";

import MapOptionsBoaters from "./MapOptionsBoaters";

import BaseIcon from "@/components/BaseIcon/BaseIcon";
import { ReactComponent as BuoyIcon } from "&/img/markers/pois/iconFilled/buoy_iconFilled.svg";
import { ReactComponent as RestrictedAreaIcon } from "&/img/markers/pois/place/Restrictedarea.svg";
import { ReactComponent as MarinaIcon } from "&/img/markers/pois/iconFilled/marina_iconFilled.svg";
import { ReactComponent as YachtClubIcon } from "&/img/markers/pois/iconFilled/yachtClub_iconFilled.svg";
import { ReactComponent as RestaurantBarIcon } from "&/img/markers/pois/iconFilled/restaurantBar_iconFilled.svg";
import { ReactComponent as BoatRampIcon } from "&/img/markers/pois/iconFilled/boatRamp_iconFilled.svg";
import { ReactComponent as ClubAssociationIcon } from "&/img/markers/pois/iconFilled/clubAssociation_iconFilled.svg";
import { ReactComponent as AnchorageIcon } from "&/img/markers/pois/iconFilled/anchorage_iconFilled.svg";
import { ReactComponent as BridgeIcon } from "&/img/markers/pois/iconFilled/bridge_iconFilled.svg";
import { ReactComponent as ArtificialReefIcon } from "&/img/markers/pois/iconFilled/artificialReef_iconFilled.svg";
import { ReactComponent as BusinessIcon } from "&/img/markers/pois/iconFilled/business_iconFilled.svg";
import { ReactComponent as CityTownIcon } from "&/img/markers/pois/iconFilled/cityTown_iconFilled.svg";
import { ReactComponent as DamIcon } from "&/img/markers/pois/iconFilled/dam_iconFilled.svg";
import { ReactComponent as InletIcon } from "&/img/markers/pois/iconFilled/inlet_iconFilled.svg";
import { ReactComponent as LockIcon } from "&/img/markers/pois/iconFilled/lock_iconFilled.svg";
import { ReactComponent as OtherIcon } from "&/img/markers/pois/iconFilled/other_iconFilled.svg";
import { ReactComponent as HotelIcon } from "&/img/markers/pois/iconFilled/hotel_iconFilled.svg";
import { ReactComponent as BeachIcon } from "&/img/markers/pois/iconFilled/beach_iconFilled.svg";
import { ReactComponent as DockBarIcon } from "&/img/markers/pois/iconFilled/dockBar_iconFilled.svg";
import { ReactComponent as DockIcon } from "&/img/markers/pois/iconFilled/docking_iconFilled.svg";
import { ReactComponent as FishingSpotIcon } from "&/img/markers/pois/iconFilled/fishingSpot_iconFilled.svg";
import { ReactComponent as LightHouseIcon } from "&/img/markers/pois/iconFilled/lightHouse_iconFilled.svg";
import { ReactComponent as MooringIcon } from "&/img/markers/pois/iconFilled/mooring_iconFilled.svg";
import { ReactComponent as PoiIcon } from "&/img/markers/pois/iconFilled/pointOfInterest_iconFilled.svg";
import { ReactComponent as PrivateDock } from "&/img/markers/pois/place/PrivateDock.svg";
import { ReactComponent as HazardReportPin } from "&/img/markers/reportpins/hazard.svg";
import { ReactComponent as LocalAdviceReportPin } from "&/img/markers/reportpins/localAdvice.svg";
import { ReactComponent as MapIssueReportPin } from "&/img/markers/reportpins/mapIssue.svg";
import { ReactComponent as MarineLifeReportPin } from "&/img/markers/reportpins/marineLife.svg";
import { ReactComponent as PoliceReportPin } from "&/img/markers/reportpins/police.svg";
import { ReactComponent as WeatherReportPin } from "&/img/markers/reportpins/weather.svg";
import { ReactComponent as NoWakeZoneReportPin } from "&/img/markers/reportpins/noWakeZone.svg";
import { ReactComponent as BoatClubIcon } from "&/img/markers/pois/iconFilled/boatClub_iconFilled.svg";
import { ReactComponent as RockIcon } from "&/img/markers/noaahazards/rocks_mapOptions.svg";
import { ReactComponent as ObstructionIcon } from "&/img/markers/noaahazards/obstruction_mapOptions.svg";
import { ReactComponent as ExposedWreckIcon } from "&/img/markers/noaahazards/exposed_wreck_mapOptions.svg";
import { ReactComponent as DangerousWreckIcon } from "&/img/markers/noaahazards/dangerous_wreck_mapOptions.svg";
import { ReactComponent as NonDangerousWreckIcon } from "&/img/markers/noaahazards/non_dangerous_wreck_mapOptions.svg";
import { ReactComponent as TideUpIcon } from "&/img/markers/tideandcurrents/TideUpIconFilled.svg";
import { ReactComponent as CurrentIcon } from "&/img/markers/tideandcurrents/CurrentIconFilled.svg";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters square elevation={0} {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "&.Mui-disabled": {
    backgroundColor: "transparent",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  borderTop: `1px solid ${theme.palette.divider}`,
  boxShadow: "inset 0 1px 10px rgb(0 0 0 / 5%)",
  "& .MuiIconButton-root": {
    pointerEvents: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreOutlined />} {...props} />
))(({ theme }) => ({
  "&.MuiButtonBase-root:hover": {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.12)}`,
    color: theme.palette.primary.main,
  },
  "& .MuiAccordionSummary-content": {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    flexDirection: "column",
  },
  "&.MuiButtonBase-root.Mui-expanded": {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.12)}`,
    color: theme.palette.primary.main,
  },
}));

const BUOY_NAME_ITEM = "Buoy";
const RESTRICTED_NAME_ITEM = "Restricted area";

const navigationItems = [
  {
    icon: <BaseIcon icon={BuoyIcon} sx={{ color: "#026F32" }} />,
    label: "Buoys & Beacons",
    name: BUOY_NAME_ITEM,
  },
  {
    icon: <BaseIcon icon={RockIcon} />,
    label: "Rocks",
    name: "rock",
  },
  {
    icon: <BaseIcon icon={ObstructionIcon} />,
    label: "Obstructions",
    name: "obstruction",
  },
  {
    icon: <BaseIcon icon={ExposedWreckIcon} />,
    label: "Exposed Wrecks",
    name: "exposed_wreck",
  },
  {
    icon: <BaseIcon icon={DangerousWreckIcon} />,
    label: "Dangerous Wrecks",
    name: "dangerous_wreck",
  },
  {
    icon: <BaseIcon icon={NonDangerousWreckIcon} />,
    label: "Non-Dangerous Wrecks",
    name: "non_dangerous_wreck",
  },
  {
    icon: <BaseIcon icon={RestrictedAreaIcon} />,
    label: "Restricted Areas",
    name: RESTRICTED_NAME_ITEM,
  },
  {
    icon: <BaseIcon icon={CurrentIcon} />,
    label: "Currents",
    name: "current",
  },
  {
    icon: <BaseIcon icon={TideUpIcon} />,
    label: "Tides",
    name: "tide",
  },
];

const placeItems = [
  {
    icon: <BaseIcon icon={AnchorageIcon} sx={{ color: "#04AE5C" }} />,
    label: "Anchorages",
    name: "Anchorage",
  },
  {
    icon: <BaseIcon icon={ArtificialReefIcon} sx={{ color: "#F2B414" }} />,
    label: "Artificial Reefs",
    name: "ArtificialReef",
  },
  {
    icon: <BaseIcon icon={BeachIcon} sx={{ color: "#F2B414" }} />,
    label: "Beaches",
    name: "Beach",
  },
  {
    icon: <BaseIcon icon={BoatClubIcon} sx={{ color: "#04AE5C" }} />,
    label: "Boat Clubs",
    name: "Boat Club",
  },
  {
    icon: <BaseIcon icon={BoatRampIcon} sx={{ color: "#005215" }} />,
    label: "Boat Ramps",
    name: "BoatRamp",
  },
  {
    icon: <BaseIcon icon={BridgeIcon} sx={{ color: "#005215" }} />,
    label: "Bridges",
    name: "Bridge",
  },
  {
    icon: <BaseIcon icon={BusinessIcon} sx={{ color: "#04AE5C" }} />,
    label: "Businesses",
    name: "Business",
  },
  {
    icon: <BaseIcon icon={CityTownIcon} sx={{ color: "#04AE5C" }} />,
    label: "Cities/Towns",
    name: "CityTown",
  },
  {
    icon: <BaseIcon icon={ClubAssociationIcon} sx={{ color: "#116AD3" }} />,
    label: "Clubs/Associations",
    name: "Club/Association",
  },
  {
    icon: <BaseIcon icon={DamIcon} sx={{ color: "#890323" }} />,
    label: "Dams",
    name: "Dam",
  },
  {
    icon: <BaseIcon icon={DockBarIcon} sx={{ color: "#116AD3" }} />,
    label: "Dock Bars/Restaurants",
    name: "Dock Bar/Restaurant",
  },
  {
    icon: <BaseIcon icon={FishingSpotIcon} sx={{ color: "#04AE5C" }} />,
    label: "Fishing Spots",
    name: "Fishing Spot",
  },
  {
    icon: <BaseIcon icon={DockIcon} sx={{ color: "#116AD3" }} />,
    label: "Free Docks",
    name: "Free Dock",
  },
  {
    icon: <BaseIcon icon={HotelIcon} sx={{ color: "#8514BE" }} />,
    label: "Hotels",
    name: "Hotel",
  },
  {
    icon: <BaseIcon icon={InletIcon} sx={{ color: "#116AD3" }} />,
    label: "Inlets",
    name: "Inlet",
  },
  {
    icon: <BaseIcon icon={LightHouseIcon} sx={{ color: "#F2B414" }} />,
    label: "Light Houses",
    name: "Light House",
  },
  {
    icon: <BaseIcon icon={LockIcon} sx={{ color: "#890323" }} />,
    label: "Locks",
    name: "Lock",
  },
  {
    icon: <BaseIcon icon={MarinaIcon} sx={{ color: "#116AD3" }} />,
    label: "Marinas",
    name: "Marina",
  },
  {
    icon: <BaseIcon icon={MooringIcon} sx={{ color: "#04AE5C" }} />,
    label: "Moorings",
    name: "Mooring",
  },
  {
    icon: <BaseIcon icon={OtherIcon} sx={{ color: "#005215" }} />,
    label: "Other",
    name: "Other",
  },
  {
    icon: <BaseIcon icon={PoiIcon} sx={{ color: "#04AE5C" }} />,
    label: "Points of Interest",
    name: "Point of Interest",
  },
  {
    icon: <BaseIcon icon={PrivateDock} />,
    label: "Private Docks",
    name: "Private Dock",
  },
  {
    icon: <BaseIcon icon={RestaurantBarIcon} sx={{ color: "#F27C05" }} />,
    label: "Restaurants/Bars",
    name: "Restaurant/Bar",
  },
  {
    icon: <BaseIcon icon={YachtClubIcon} sx={{ color: "#116AD3" }} />,
    label: "Yacht Clubs",
    name: "Yacht Club",
  },
];

const ReportPinsItems = [
  {
    icon: <BaseIcon icon={HazardReportPin} />,
    label: "Hazards",
    name: "hazard",
  },
  {
    icon: <BaseIcon icon={LocalAdviceReportPin} />,
    label: "Local Advice",
    name: "local_advice",
  },
  {
    icon: <BaseIcon icon={MapIssueReportPin} />,
    label: "Map Issues",
    name: "map_issue",
  },
  {
    icon: <BaseIcon icon={MarineLifeReportPin} />,
    label: "Marine Life",
    name: "marine_life",
  },
  {
    icon: <BaseIcon icon={PoliceReportPin} />,
    label: "Police",
    name: "police",
  },
  {
    icon: <BaseIcon icon={WeatherReportPin} />,
    label: "Weather",
    name: "weather",
  },
  {
    icon: <BaseIcon icon={NoWakeZoneReportPin} />,
    label: "No Wake Zone",
    name: "no_wake_zone",
  },
];

export default function MapOptionsAccordion() {
  const [expanded, setExpanded] = React.useState<string | false>("");

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <AccordionSummary
          aria-controls="panel1a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel1a-header"
        >
          <Typography sx={{ lineHeight: "normal" }} variant="subtitle1">
            Navigation Aids
          </Typography>
          <Typography variant="caption">All options on</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {navigationItems.map((item) => (
            <MapOptionsListItem
              key={item.name}
              icon={item.icon}
              idName={item.name}
              name={item.label}
            />
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <AccordionSummary
          aria-controls="panel2a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel2a-header"
        >
          <Typography sx={{ lineHeight: "normal" }} variant="subtitle1">
            Places
          </Typography>
          <Typography variant="caption">All options on</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List disablePadding>
            {placeItems.map((item) => (
              <MapOptionsListItem
                key={item.name}
                icon={item.icon}
                idName={item.name}
                name={item.label}
              />
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      {/* Report pins should commented after Design resolution */}
      {/* <Accordion disabled expanded={expanded === "panel3"} onChange={handleChange("panel3")}> */}
      <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
        <AccordionSummary
          aria-controls="panel3a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel3a-header"
        >
          <Typography sx={{ lineHeight: "normal" }} variant="subtitle1">
            Report Pins
          </Typography>
          <Typography variant="caption">All options on</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List disablePadding>
            {ReportPinsItems.map((item) => (
              <MapOptionsListItem
                key={item.label}
                icon={item.icon}
                idName={item.name}
                name={item.label}
              />
            ))}
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
        <AccordionSummary
          aria-controls="panel4a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel4a-header"
        >
          <Typography sx={{ lineHeight: "normal" }} variant="subtitle1">
            Boaters
          </Typography>
          <Typography variant="caption">All options on</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MapOptionsBoaters />
          <MapOptionsAffiliations />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
