import { TextField, Typography, Button, Stack } from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";

import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { useAppDispatch } from "@/hooks/useRedux";
import ServiceAuth from "@/services/auth";
import PasswordInput from "@/components/PasswordInput";
import { TPasswordReset } from "@/types/auth";

const formSchema = yup.object({
  password: yup
    .string()
    .required("Password is a required field")
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter"),
  passwordConfirm: yup
    .string()
    .required("Password is a required field")
    .oneOf([yup.ref("password"), null], 'Must match "Password" field value'),
});

export default function RecoverPasswordForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");

  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("access-token");
  const clientId = searchParams.get("client_id");
  const uid = searchParams.get("uid");

  const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const { status } = await ServiceAuth.startRecoveryPassword({ email });

      if (status === 200) {
        setEmailSent(true);
      }
    } catch (error: any) {
      dispatch(
        setSnackBarMsjSucceded({ state: true, type: "error", msj: error.response.data.message }),
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirm: "",
    } as TPasswordReset,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      const { status } = await ServiceAuth.resetPassword(
        {
          password: values.password,
          password_confirmation: values.passwordConfirm,
        },
        { token, clientId, uid },
      );

      if (status === 200) setPasswordChanged(true);
    },
  });

  if (passwordChanged) {
    return (
      <Stack component="form" spacing={3} sx={{ width: "100%" }} onSubmit={formik.handleSubmit}>
        <Stack spacing={1.5}>
          <Typography component="h3" textAlign="center" variant="h6">
            Password Successfully Changed
          </Typography>
          <Typography component="h4" textAlign="center" variant="body1">
            We invite you to sign in using your new password and continue enjoying our services.
          </Typography>
        </Stack>
        <Stack spacing={1.5}>
          <Button fullWidth size="large" variant="contained" onClick={() => navigate("/login")}>
            Sign In
          </Button>
        </Stack>
      </Stack>
    );
  }

  if (token && clientId && uid) {
    return (
      <Stack component="form" spacing={3} sx={{ width: "100%" }} onSubmit={formik.handleSubmit}>
        <Stack spacing={1.5}>
          <Typography component="h3" textAlign="center" variant="h6">
            Reset Password
          </Typography>
          <Typography component="h4" textAlign="center" variant="body1">
            Enter a new password
          </Typography>
          <PasswordInput formikData={formik} label="Password" name="password" />
          <PasswordInput formikData={formik} label="Re-enter password" name="passwordConfirm" />
        </Stack>
        <Stack spacing={1.5}>
          <Button fullWidth size="large" type="submit" variant="contained">
            Reset my password
          </Button>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack component="form" spacing={3} sx={{ width: "100%" }} onSubmit={handleResetPassword}>
      {emailSent ? (
        <div>Check your email to reset your password.</div>
      ) : (
        <>
          {" "}
          <Stack spacing={1.5}>
            <Typography component="h3" textAlign="center" variant="h6">
              Recover your Password
            </Typography>
            <TextField
              fullWidth
              label="Email"
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
          </Stack>
          <Stack spacing={1.5}>
            <Button fullWidth size="large" type="submit" variant="contained">
              Reset my password
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
}
