import React from "react";

import AdvancedMarker from "../AdvancedMarker";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { setPremiumSubscriptionModal } from "@/store/slices/user";
import useDisclosure from "@/hooks/useDisclosure";
import PinsModal from "@/components/Pins/PinsModal";
import QuickPinIcon from "@/assets/img/markers/quickpin/3x/QuickPin.png";

interface IQuickPinMarker {
  quickpin: any;
  clogpage: boolean;
}

function QuickPinMarker({ quickpin, clogpage }: IQuickPinMarker) {
  const dispatch = useAppDispatch();
  const { logged } = useAppSelector((state) => state.auth);
  const { tempPin } = useAppSelector((state) => state.reportpins);
  const { onOpen, onClose, isOpen } = useDisclosure();

  const handleOpen = () => {
    if (!clogpage) {
      if (logged) {
        onOpen();
      } else {
        dispatch(setPremiumSubscriptionModal(true));
      }
    }
  };

  if (tempPin?.quickpinId === quickpin?.id) return null;

  return (
    <>
      <AdvancedMarker
        key={quickpin.id}
        icon={QuickPinIcon}
        position={{ lat: Number(quickpin.lat), lng: Number(quickpin.lng) }}
        selected={false}
        onClick={handleOpen}
      />
      {isOpen && (
        <PinsModal
          isOpen={isOpen}
          pinsType="both"
          quickpin={quickpin}
          title="Create a Pin"
          onClose={onClose}
        />
      )}
    </>
  );
}

export default QuickPinMarker;
