import axios from "./config";

export default {
  getReportPinsByZone: (params: any) =>
    axios.request({
      method: "get",
      url: `/api/v1/report_pins`,
      params,
    }),
  getReportPinReviewsById: (id: number) =>
    axios.request({
      method: "get",
      url: `/api/v1/user_comments`,
      params: {
        commentable_type: "ReportPin",
        commentable_id: id,
      },
    }),
  getReportPinById: (id: number) =>
    axios.request({
      method: "get",
      url: `/api/v1/report_pins/${id}`,
    }),
  addReportPinVote: (report_pin_id: number, vote_type: string) =>
    axios.request({
      method: "post",
      url: `/api/v1/report_pin_votes`,
      data: {
        report_pin_id,
        vote_type,
      },
    }),
  removeReportPinVote: (report_pin_id: number) =>
    axios.request({
      method: "delete",
      url: `/api/v1/report_pin_votes/${report_pin_id}`,
    }),
  addCommentToPin: (message: string, commentable_type: string, commentable_id: number) =>
    axios.request({
      method: "post",
      url: `/api/v1/user_comments`,
      data: {
        message,
        commentable_type,
        commentable_id,
      },
    }),
  createPin: (data: any) =>
    axios.request({
      method: "post",
      url: "/api/v1/places",
      data,
    }),
  editPin: (data: any, pinId?: number) =>
    axios.request({
      method: "put",
      url: `/api/v1/places/${pinId}`,
      data,
    }),
  editReportPin: (data: any, reportPinId?: number) =>
    axios.request({
      method: "put",
      url: `/api/v1/report_pins/${reportPinId}`,
      data,
    }),
  createReportPin: (data: any) =>
    axios.request({
      method: "post",
      url: `/api/v1/report_pins`,
      data,
    }),
  deleteReportPin: (reportPinId: number) =>
    axios.request({
      method: "delete",
      url: `/api/v1/report_pins/${reportPinId}`,
    }),
  getPlaces: (params: any) =>
    axios.request({
      method: "get",
      url: `/api/v1/places`,
      params,
    }),
  deletePlace: (placeId: number) =>
    axios.request({
      method: "delete",
      url: `/api/v1/places/${placeId}`,
    }),
  uploadGpxPlaces: (formData: any) =>
    axios.request({
      method: "post",
      url: `/api/v1/places/import`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  createQuickPin: (data: any) =>
    axios.request({
      method: "post",
      url: `/api/v1/quick_pins`,
      data,
    }),
  getQuickPins: (params: any) =>
    axios.request({
      method: "get",
      url: `/api/v1/quick_pins`,
      params,
    }),
  deleteQuickPin: (quickPinId: any) =>
    axios.request({
      method: "delete",
      url: `/api/v1/quick_pins/${quickPinId}`,
    }),
};
