import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";

export const layoutStyles = {
  scroll: {
    "&::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "grey.200",
    },

    "&::-webkit-scrollbar-track": {
      width: "8px",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "grey.600",
      borderRadius: 1,
    },

    "&::-webkit-scrollbar:horizontal, &::-webkit-scrollbar-track:horizontal": {
      height: "8px",
    },
  },

  routeButton: {
    "&:hover": {
      color: "primary.main",
      "& .MuiSvgIcon-root": {
        color: "unset",
      },
    },
    "& .MuiSvgIcon-root": {
      color: `${alpha(defaultTheme.palette.primary.main, 0.5)}`,
    },
    "& .MuiButton-startIcon": {
      mr: 0.75,
    },
    "&.buttonFrom": {
      "& .MuiSvgIcon-root": {
        fontSize: 14,
      },
    },
    "&.buttonTo": {
      "&:hover": {
        "& .MuiSvgIcon-root": {
          color: "error.main",
        },
      },
    },
    "&.Mui-disabled .MuiSvgIcon-root": {
      color: "inherit",
    },
    "&.Mui-disabled.buttonFrom, &.Mui-disabled.buttonTo": {
      "& .MuiSvgIcon-root": {
        fontSize: 14,
      },
    },
  },

  ellipsisOneLine: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
  },
};
