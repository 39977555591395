import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";

export const styles = {
  tableCell: {
    px: 0,
    py: 1,
  },

  inactiveChip: {
    fontWeight: 700,
  },

  chip: {
    fontWeight: 700,
    color: "primary.main",
    backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.08)}`,

    "& .MuiSvgIcon-root": {
      fontSize: "18px",
      color: `${alpha(defaultTheme.palette.primary.main, 0.7)}`,
    },

    "&:hover": {
      "& .MuiSvgIcon-root": {
        color: "primary.main",
      },
    },
  },
};
