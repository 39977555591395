import { Chip } from "@mui/material";
import { alpha, darken } from "@mui/material/styles";

import { styles } from "./styles";

import BaseIcon from "@/components/BaseIcon/BaseIcon";
import { ReactComponent as BusinessIcon } from "&/img/markers/pois/iconFilled/business_iconFilled.svg";
import { ReactComponent as AnchorageIcon } from "&/img/markers/pois/iconFilled/anchorage_iconFilled.svg";
import { ReactComponent as ArtificialReefIcon } from "&/img/markers/pois/iconFilled/artificialReef_iconFilled.svg";
import { ReactComponent as BoatRampIcon } from "&/img/markers/pois/iconFilled/boatRamp_iconFilled.svg";
import { ReactComponent as Beach } from "&/img/markers/pois/iconFilled/beach_iconFilled.svg";
import { ReactComponent as BridgeIcon } from "&/img/markers/pois/iconFilled/bridge_iconFilled.svg";
import { ReactComponent as CityTownIcon } from "&/img/markers/pois/iconFilled/cityTown_iconFilled.svg";
import { ReactComponent as ClubAssociationIcon } from "&/img/markers/pois/iconFilled/clubAssociation_iconFilled.svg";
import { ReactComponent as DamIcon } from "&/img/markers/pois/iconFilled/dam_iconFilled.svg";
import { ReactComponent as DockBarIcon } from "&/img/markers/pois/iconFilled/dockBar_iconFilled.svg";
import { ReactComponent as DockIcon } from "&/img/markers/pois/iconFilled/docking_iconFilled.svg";
import { ReactComponent as FishingSpotIcon } from "&/img/markers/pois/iconFilled/fishingSpot_iconFilled.svg";
import { ReactComponent as LightHouseIcon } from "&/img/markers/pois/iconFilled/lightHouse_iconFilled.svg";
import { ReactComponent as MooringIcon } from "&/img/markers/pois/iconFilled/mooring_iconFilled.svg";
import { ReactComponent as PoiIcon } from "&/img/markers/pois/iconFilled/pointOfInterest_iconFilled.svg";
import { ReactComponent as HotelIcon } from "&/img/markers/pois/iconFilled/hotel_iconFilled.svg";
import { ReactComponent as InletIcon } from "&/img/markers/pois/iconFilled/inlet_iconFilled.svg";
import { ReactComponent as LockIcon } from "&/img/markers/pois/iconFilled/lock_iconFilled.svg";
import { ReactComponent as MarinaIcon } from "&/img/markers/pois/iconFilled/marina_iconFilled.svg";
import { ReactComponent as OtherIcon } from "&/img/markers/pois/iconFilled/other_iconFilled.svg";
import { ReactComponent as RestaurantBarIcon } from "&/img/markers/pois/iconFilled/restaurantBar_iconFilled.svg";
import { ReactComponent as YachtClubIcon } from "&/img/markers/pois/iconFilled/yachtClub_iconFilled.svg";
import { ReactComponent as BoatClubIcon } from "&/img/markers/pois/iconFilled/boatClub_iconFilled.svg";
import { ReactComponent as PrivateDockIcon } from "&/img/markers/pois/iconFilled/privateDock_iconFilled.svg";
import { IconKeysPois } from "@/types/markers";

interface IPlaceInfoIconType {
  poiType: IconKeysPois;
}

const icons = new Map([
  [
    IconKeysPois.Anchorage,
    { icon: <BaseIcon icon={AnchorageIcon} />, name: IconKeysPois.Anchorage, color: "#04AE5C" },
  ],
  [
    IconKeysPois.ArtificialReef,
    {
      icon: <BaseIcon icon={ArtificialReefIcon} />,
      name: IconKeysPois.ArtificialReef,
      color: "#F2B414",
    },
  ],
  [
    IconKeysPois.Beach,
    { icon: <BaseIcon icon={Beach} />, name: IconKeysPois.Beach, color: "#116AD3" },
  ],
  [
    IconKeysPois.BoatRamp,
    { icon: <BaseIcon icon={BoatRampIcon} />, name: "Boat Ramp", color: "#005215" },
  ],
  [
    IconKeysPois.BoatRamp2,
    { icon: <BaseIcon icon={BoatRampIcon} />, name: "Boat Ramp", color: "#005215" },
  ],
  [
    IconKeysPois.Bridge,
    { icon: <BaseIcon icon={BridgeIcon} />, name: IconKeysPois.Bridge, color: "#005215" },
  ],
  [
    IconKeysPois.Business,
    { icon: <BaseIcon icon={BusinessIcon} />, name: IconKeysPois.Business, color: "#04AE5C" },
  ],
  [
    IconKeysPois.BoatClub,
    { icon: <BaseIcon icon={BoatClubIcon} />, name: IconKeysPois.BoatClub, color: "#04AE5C" },
  ],
  [
    IconKeysPois.CityTown,
    { icon: <BaseIcon icon={CityTownIcon} />, name: IconKeysPois.CityTown, color: "#04AE5C" },
  ],
  [
    IconKeysPois.ClubAssociation,
    {
      icon: <BaseIcon icon={ClubAssociationIcon} />,
      name: IconKeysPois.ClubAssociation,
      color: "#116AD3",
    },
  ],
  [
    IconKeysPois.Dam,
    { icon: <BaseIcon icon={DamIcon} />, name: IconKeysPois.Dam, color: "#890323" },
  ],
  [
    IconKeysPois.DockBarRestaurant,
    {
      icon: <BaseIcon icon={DockBarIcon} />,
      name: IconKeysPois.DockBarRestaurant,
      color: "#116AD3",
    },
  ],
  [
    IconKeysPois.PrivateDock,
    { icon: <BaseIcon icon={PrivateDockIcon} />, name: IconKeysPois.PrivateDock, color: "#116AD3" },
  ],
  [
    IconKeysPois.PointOfInterest,
    { icon: <BaseIcon icon={PoiIcon} />, name: IconKeysPois.PointOfInterest, color: "#04AE5C" },
  ],
  [
    IconKeysPois.FreeDock,
    { icon: <BaseIcon icon={DockIcon} />, name: IconKeysPois.FreeDock, color: "#116AD3" },
  ],
  [
    IconKeysPois.FishingSpot,
    { icon: <BaseIcon icon={FishingSpotIcon} />, name: IconKeysPois.FishingSpot, color: "#3ED3B2" },
  ],
  [
    IconKeysPois.LightHouse,
    { icon: <BaseIcon icon={LightHouseIcon} />, name: IconKeysPois.LightHouse, color: "#DF8302" },
  ],
  [
    IconKeysPois.Mooring,
    { icon: <BaseIcon icon={MooringIcon} />, name: IconKeysPois.Mooring, color: "#04AE5C" },
  ],
  [
    IconKeysPois.Hotel,
    { icon: <BaseIcon icon={HotelIcon} />, name: IconKeysPois.Hotel, color: "#8514BE" },
  ],
  [
    IconKeysPois.Inlet,
    { icon: <BaseIcon icon={InletIcon} />, name: IconKeysPois.Inlet, color: "#116AD3" },
  ],
  [
    IconKeysPois.Lock,
    { icon: <BaseIcon icon={LockIcon} />, name: IconKeysPois.Lock, color: "#890323" },
  ],
  [
    IconKeysPois.Marina,
    { icon: <BaseIcon icon={MarinaIcon} />, name: IconKeysPois.Marina, color: "#116AD3" },
  ],
  [
    IconKeysPois.Other,
    { icon: <BaseIcon icon={OtherIcon} />, name: IconKeysPois.Other, color: "#005215" },
  ],
  [
    IconKeysPois.GpsLocation,
    { icon: <BaseIcon icon={OtherIcon} />, name: "GPS Location", color: "#005215" },
  ],

  [
    IconKeysPois.RestaurantBar,
    {
      icon: <BaseIcon icon={RestaurantBarIcon} />,
      name: IconKeysPois.RestaurantBar,
      color: "#DF8302",
    },
  ],
  [
    IconKeysPois.YachtClub,
    { icon: <BaseIcon icon={YachtClubIcon} />, name: IconKeysPois.YachtClub, color: "#116AD3" },
  ],
  [
    IconKeysPois.MyLocation,
    { icon: <BaseIcon icon={OtherIcon} />, name: IconKeysPois.Other, color: "#005215" },
  ],
]);

function PlaceInfoIconType({ poiType }: IPlaceInfoIconType) {
  const poiIcon = icons.get(poiType);

  const backgroundColor = poiIcon?.color ? alpha(poiIcon.color, 0.12) : "grey.200";
  const color = poiIcon?.color ? darken(poiIcon.color, 0.12) : "grey.200";

  return (
    <Chip
      icon={poiIcon?.icon}
      label={poiIcon?.name}
      size="small"
      sx={{
        ...styles.chip,
        color,
        backgroundColor,
        "& .MuiSvgIcon-root": {
          color,
        },
      }}
    />
  );
}

export default PlaceInfoIconType;
