import { Box, Grow, Paper, Button, Stack, TextField, Divider } from "@mui/material";
import OpacityIcon from "@mui/icons-material/Opacity";
import React, { useState } from "react";
import isEmpty from "lodash.isempty";

import DepthColors from "../DepthColors";

import { styles } from "./styles";

import PanelHeader from "@/components/PanelHeader/PanelHeader";
import AlertDialog from "@/components/AlertDialog/AlertDialog";
import { useAppSelector, useAppDispatch } from "@/hooks/useRedux";
import { setDepthShadingToEdit } from "@/store/slices/depthshading";
import { useUpdateShadingMutation } from "@/store/api/argoApi";
import useDisclosure from "@/hooks/useDisclosure";
import { depth_shading_items } from "@/types/map";

function DepthShadingEditingPanel() {
  const dispatch = useAppDispatch();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { onOpen: onOpenNumber, isOpen: isOpenNumber, onClose: onCloseNumber } = useDisclosure();

  const { user } = useAppSelector((state) => state.auth);
  const { depthShadingDrawerOpen, depthShadingToEdit } = useAppSelector(
    (state) => state.depthshading,
  );
  const [updateShading] = useUpdateShadingMutation();

  const [name, setName] = useState(depthShadingToEdit?.name || "");

  const handleChageName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const canSaveColors = (depthShaderObject: depth_shading_items[] | undefined) => {
    if (!depthShaderObject) return false;
    // Check for blank distances
    const hasBlankDistance = depthShaderObject.find(
      (item: depth_shading_items) => item.distance === "",
    );

    // Check for duplicate distances
    const hasDuplicateDistance = depthShaderObject.find(
      (item: depth_shading_items, index: number) =>
        depthShaderObject.findIndex(
          (i: depth_shading_items) => Number(i.distance) === Number(item.distance),
        ) !== index,
    );

    return isEmpty(hasDuplicateDistance) && isEmpty(hasBlankDistance);
  };

  const handleSaveDepthShading = () => {
    if (!canSaveColors(depthShadingToEdit?.depth_shader_items)) {
      onOpenNumber();

      return;
    }

    updateShading({
      ...depthShadingToEdit,
      name,
      user_id: user?.id,
    });
    dispatch(setDepthShadingToEdit(null));
  };

  const handleDiscardChanges = () => {
    onClose();
    dispatch(setDepthShadingToEdit(null));
  };

  return (
    <Grow
      unmountOnExit
      in={!!(depthShadingDrawerOpen && depthShadingToEdit)}
      style={{ transformOrigin: "top left" }}
      timeout="auto"
    >
      <Paper elevation={3} sx={styles.container}>
        <PanelHeader
          close={onOpen}
          icon={<OpacityIcon sx={styles.headerIcon} />}
          title="  Edit Depth Shading"
        />
        <Stack component="section" sx={styles.mainContent}>
          <Box>
            <TextField
              fullWidth
              id="depthName"
              label="Depth Shading Name"
              name="depthName"
              value={name}
              onChange={handleChageName}
            />
          </Box>
          <Stack component="section" sx={styles.colorsSection}>
            {depthShadingToEdit && <DepthColors depthShadingToEdit={depthShadingToEdit} />}
          </Stack>
        </Stack>
        <Divider />
        <Stack alignItems="flex-end" component="section" sx={styles.footerPanel}>
          <Box>
            <Button variant="contained" onClick={handleSaveDepthShading}>
              Save
            </Button>
          </Box>
        </Stack>
        <AlertDialog
          cancelButtonTitle="Cancel"
          close={onClose}
          confirm={() => handleDiscardChanges()}
          message="By leaving, you will lose all changes you've made to this custom shading."
          open={isOpen}
          positiveButtonTitle="Discard"
          title="Discard Changes"
        />
        <AlertDialog
          close={onCloseNumber}
          confirm={onCloseNumber}
          message="You must enter a unique depth number for each color shading."
          open={isOpenNumber}
          positiveButtonTitle="Ok"
          title="Check Your Entries"
        />
      </Paper>
    </Grow>
  );
}

export default DepthShadingEditingPanel;
