import React from "react";
import {
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIconProps,
} from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";

import { styles } from "./styles";

interface IMenuListItem {
  icon: React.ReactElement<SvgIconProps>;
  name: string;
  action: () => void;
  className?: string;
  hasArrow: boolean;
  disabled?: boolean;
}

export default function MenuListItem({
  icon,
  name,
  action,
  className,
  hasArrow,
  disabled = false,
}: IMenuListItem) {
  return (
    <>
      <ListItem disablePadding className={className} sx={styles.listItem}>
        <ListItemButton disabled={disabled} onClick={action}>
          {icon && <ListItemIcon sx={styles.icon}>{icon}</ListItemIcon>}
          <ListItemText
            id="#"
            primary={name}
            primaryTypographyProps={{
              variant: "body2",
            }}
          />
          {hasArrow && <KeyboardArrowRight sx={styles.arrowIcon} />}
        </ListItemButton>
      </ListItem>
      <Divider />
    </>
  );
}
