import axios from "./config";

import { TRating } from "@/types/pois";

export default {
  getPoiByUid: (uid: string) =>
    axios.request({
      method: "get",
      url: `/api/v1/pois/${uid}`,
    }),
  getPoiById: (id: number) =>
    axios.request({
      method: "get",
      url: `/api/v1/pois/${id}`,
    }),
  getPoiRatings: (id: string) => axios.get(`/pois/${id}/poi_ratings`),
  setPoiRating: (id: string, payload: TRating) => axios.post(`/pois/${id}/poi_ratings`, payload),
  updatePoiInfo: (id: number, data: any) =>
    axios.request({
      method: "put",
      url: `/api/v1/pois/${id}`,
      data,
    }),
};
