import { Box, DialogContent, DialogTitle, Typography, Stack } from "@mui/material";
import { ArrowDownwardRounded, Circle, Verified } from "@mui/icons-material";

import { styles } from "./styles";

import PremiumSubscriptionGroup from "@/components/Premium/PremiumSubscriptionsGroup/PremiumSubscriptionsGroup";

function PremiumModal() {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.dialogHeader}>
        <Verified sx={styles.dialogHeaderIcon} />
        <Typography sx={styles.dialogHeaderTitle} variant="h5">
          UNLOCK PREMIUM FEATURES
        </Typography>
      </Box>
      <DialogContent sx={styles.dialogContent}>
        <DialogTitle id="alert-dialog-title" sx={styles.dialogTitle}>
          Try Argo Premium for free
        </DialogTitle>

        <Stack direction="row" spacing={1.5}>
          <Stack alignItems="center" alignSelf="stretch" spacing={0.75} sx={styles.lineContainer}>
            <Circle sx={{ fontSize: 10, color: "primary.main" }} />
            <Box sx={styles.line1} />
            <Circle sx={{ fontSize: 10, color: "primary.main" }} />
            <Box sx={styles.line2} />
            <Circle sx={{ fontSize: 10, color: "primary.main" }} />
            <Box sx={styles.line3}>
              <ArrowDownwardRounded />
            </Box>
          </Stack>
          <Stack spacing={1.5}>
            <Stack spacing={0}>
              <Typography variant="subtitle1">Today</Typography>
              <Typography color="text.secondary" variant="body2">
                Unlock subscription features including route planning, Captain&apos;s Log, export to
                GPX, custom depth shading, and Premium upgrade for mobile devices.
              </Typography>
            </Stack>
            <Stack spacing={0}>
              <Typography variant="subtitle1">2 days before</Typography>
              <Typography color="text.secondary" variant="body2">
                Get a reminder email that your trial is ending.
              </Typography>
            </Stack>
            <Stack spacing={0}>
              <Typography variant="subtitle1">In 14 days</Typography>
              <Typography color="text.secondary" variant="body2">
                You will be charged the subscription amount. Cancel before end of trial period.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>

      <Box sx={styles.dialogFooter}>
        <PremiumSubscriptionGroup />
      </Box>
    </Box>
  );
}

export default PremiumModal;
