import { Box, styled } from "@mui/material";

interface ExpandMoreProps {
  expand?: boolean;
}

const MapContentBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expand",
})<ExpandMoreProps>(({ theme, expand }) => ({
  width: expand ? "calc(100% - 488px)" : "calc(100% - 80px)",
  marginLeft: expand ? "488px" : "80px",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.enteringScreen,
  }),
}));

export default MapContentBox;
