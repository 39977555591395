import React from "react";
import { Paper, Box, Typography, Stack, Button } from "@mui/material";
import { TripOriginOutlined, Place, Lock } from "@mui/icons-material";
import { useUnitSystem } from "@argonav/unit-system";

import { styles } from "./styles";

import useSearch from "@/hooks/useSearch";
import usePremiumUser from "@/hooks/usePremiumUser";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { addCreatingRoute, addStartPoint, addEndPoint } from "@/store/slices/route";
import { setCoords } from "@/store/slices/map";
import { setPremiumSubscriptionModal } from "@/store/slices/user";

interface IPrivatePlacePopover {
  place: any;
  inCaptainsLog?: boolean;
  icon: string;
  grownToTop?: boolean;
  onClick: (e: any, place: any) => void;
}

function PrivatePlacePopover({
  place,
  inCaptainsLog,
  icon,
  grownToTop,
  onClick,
}: IPrivatePlacePopover) {
  const isPremium = usePremiumUser();
  const dispatch = useAppDispatch();
  const { coords } = useAppSelector((state) => state.map);
  const { logged } = useAppSelector((state) => state.auth);
  const { setCleanPoiList, setCleanPoiCategory, setCleanSelectedPoi } = useSearch();
  const { coordinatesFormatHandler } = useUnitSystem();

  const handleRouteOpen = (
    place: any,
    inputType?: number,
    event?: React.MouseEvent<HTMLElement>,
  ) => {
    event?.stopPropagation();
    if (logged && isPremium) {
      setCleanPoiList();
      setCleanPoiCategory();
      dispatch(addCreatingRoute(true));

      if (coords) dispatch(setCoords(undefined));
      if (place) {
        if (inputType === 1) {
          dispatch(
            addStartPoint({
              lat: place.lat,
              lng: place.lng,
              name: `${place.name}`,
            }),
          );
        } else {
          dispatch(
            addEndPoint({
              lat: place.lat,
              lng: place.lng,
              name: `${place.name}`,
            }),
          );
        }
        setCleanSelectedPoi();
      }
    } else {
      dispatch(setPremiumSubscriptionModal(true));
    }
  };

  const latLngFormatted = coordinatesFormatHandler({
    latitude: Number(place?.lat),
    longitude: Number(place?.lng),
  });

  return (
    <Box onClick={(e: any) => onClick(e, place)}>
      <Paper
        className="placePopover"
        elevation={6}
        sx={grownToTop ? styles.paperToTop : styles.paper}
      >
        <Stack direction="row" spacing={1.5}>
          <Box sx={styles.image}>
            <img alt="Poi" src={icon} />
          </Box>

          <Stack sx={styles.textContainer}>
            <>
              <Typography color="text.primary" sx={styles.title} variant="subtitle1">
                {place?.name}
              </Typography>

              <Typography gutterBottom variant="body2">
                {latLngFormatted}
              </Typography>

              <Typography color="text.secondary" variant="body2">
                {place?.address}
              </Typography>
            </>

            <Stack
              direction="row"
              mt={1}
              spacing={1}
              sx={!inCaptainsLog ? null : styles.hideButtons}
            >
              <Button
                className="buttonFrom"
                disabled={!isPremium}
                endIcon={isPremium ? <TripOriginOutlined /> : <Lock />}
                size="small"
                sx={styles.routeButton}
                variant="outlined"
                onClick={(event: React.MouseEvent<HTMLElement>) => handleRouteOpen(place, 1, event)}
              >
                Route from
              </Button>
              <Button
                className="buttonTo"
                disabled={!isPremium}
                endIcon={isPremium ? <Place /> : <Lock />}
                size="small"
                sx={styles.routeButton}
                variant="outlined"
                onClick={(event: React.MouseEvent<HTMLElement>) => handleRouteOpen(place, 2, event)}
              >
                Route to
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
}

export default PrivatePlacePopover;
