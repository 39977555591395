import { Avatar, IconButton, Box } from "@mui/material";
import React from "react";

import AdvancedMarker from "../AdvancedMarker";

import { styles } from "./styles";

import { Position } from "@/types/map";
import { IFriends } from "@/types/users";
import BoaterPopover from "@/components/MapPopovers/BoaterPopover";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { setBoaterInfo, setBoaterInfoDrawer } from "@/store/slices/boaters";
import { privacyHandler } from "@/utils/friends";
import { addSelectedPoi } from "@/store/slices/search";
import { setReportPinSelectedDrawer } from "@/store/slices/reportpins";
import defaultUserMarkerSquare from "@/assets/img/markers/boaters/defaultUserMarkerSquare.png";
import captainHatMarkerSquare from "@/assets/img/markers/boaters/captainHatMarkerSquare.png";

interface IBoaterMarker {
  hasCommonAffiliation?: boolean;
  hasCommonGroup?: boolean;
  position: Position;
  boater: IFriends;
}

function BoaterMarker({ position, hasCommonAffiliation, hasCommonGroup, boater }: IBoaterMarker) {
  const dispatch = useAppDispatch();
  const { logged } = useAppSelector((state) => state.auth);
  const { reportPinSelectedDrawer } = useAppSelector((state) => state.reportpins);
  const { selectedPoi } = useAppSelector((state) => state.search);

  const { showProfileImage } = privacyHandler({ user: boater, noPrivacy: false });

  const iconUrl = showProfileImage
    ? boater.image || defaultUserMarkerSquare
    : captainHatMarkerSquare;

  const handleShowFullProfile = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (selectedPoi) dispatch(addSelectedPoi(null));
    if (reportPinSelectedDrawer) dispatch(setReportPinSelectedDrawer(null));
    dispatch(setBoaterInfoDrawer(boater));
    dispatch(setBoaterInfo(null));
  };

  // return (
  //   <OverlayView
  //     mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  //     position={{
  //       lat: position.lat,
  //       lng: position.lng,
  //     }}
  //   >
  //     <>
  // <IconButton
  //   sx={styles.iconButton}
  //   onMouseOut={handleOnMouseOut}
  //   onMouseOver={(e: React.MouseEvent<HTMLElement>) => handleBoaterSelected(e, boater)}
  //       >
  // <Avatar
  //   alt={`${boater.first_name} ${boater.last_name}`}
  //   src={iconUrl}
  //   sx={
  //     hasCommonGroup
  //       ? styles.avatarCommonGroup
  //       : hasCommonAffiliation
  //       ? styles.avatarCommonAffiliation
  //       : styles.avatar
  //   }
  // />;
  //         {hasCommonGroup && <Box sx={styles.avatarCommonGroupIcon} />}
  //       </IconButton>
  //       {boaterSelectedInfo && boaterSelectedInfo.id === boater.id && (
  //         <BoaterPopover
  //           boater={boaterSelectedInfo}
  //           showFullProfile={(e: React.MouseEvent<HTMLElement>) => handleShowFullProfile(e)}
  //           onClose={(e: React.MouseEvent<HTMLElement>) => handleBoaterSelected(e)}
  //         />
  //       )}
  //     </>
  //   </OverlayView>
  // );

  const boaterIcon = () => (
    <IconButton sx={styles.iconButton}>
      <Avatar
        alt={`${boater.first_name} ${boater.last_name}`}
        src={iconUrl}
        sx={
          hasCommonGroup
            ? styles.avatarCommonGroup
            : hasCommonAffiliation
            ? styles.avatarCommonAffiliation
            : styles.avatar
        }
      />
      {hasCommonGroup && <Box sx={styles.avatarCommonGroupIcon} />}
    </IconButton>
  );

  return (
    <AdvancedMarker
      icon={boaterIcon()}
      position={{ lat: Number(position.lat), lng: Number(position.lng) }}
      selected={false}
      onClick={() => null}
    >
      {logged && (
        <BoaterPopover
          boater={boater}
          showFullProfile={(e: React.MouseEvent<HTMLElement>) => handleShowFullProfile(e)}
        />
      )}
    </AdvancedMarker>
  );
}

export default BoaterMarker;
