import { createSlice } from "@reduxjs/toolkit";

import { ITempPin } from "@/utils/poi";

export interface IReportPinsState {
  reportPinSelected: any | null;
  reportPinSelectedDrawer: any | null;
  tempPin: ITempPin | undefined;
  tempPinCopy: ITempPin | undefined;
  congratsModal: { open: boolean; type: string };
  allowCoordinates: boolean;
  myPinsDrawer: { open: boolean; type: string };
  quickPinModal: boolean;
}

const initialState: IReportPinsState = {
  reportPinSelected: null,
  reportPinSelectedDrawer: null,
  tempPin: undefined,
  tempPinCopy: undefined,
  congratsModal: { open: false, type: "places" },
  allowCoordinates: false,
  myPinsDrawer: { open: false, type: "places" },
  quickPinModal: false,
};

export const reportPinsSlice = createSlice({
  name: "reportPins",
  initialState,
  reducers: {
    setReportPinSelected: (state, action) => {
      state.reportPinSelected = action.payload;
    },
    setReportPinSelectedDrawer: (state, action) => {
      state.reportPinSelectedDrawer = action.payload;
    },
    setTempPin: (state, action) => {
      state.tempPin = action.payload;
    },
    setTempPinCopy: (state, action) => {
      state.tempPinCopy = action.payload;
    },
    setCongratsModal: (state, action) => {
      state.congratsModal = action.payload;
    },
    setAllowCoordinates: (state, action) => {
      state.allowCoordinates = action.payload;
    },
    setMyPinsDrawer: (state, action) => {
      state.myPinsDrawer = action.payload;
    },
    setQuickPinModal: (state, action) => {
      state.quickPinModal = action.payload;
    },
  },
});

export const {
  setReportPinSelected,
  setReportPinSelectedDrawer,
  setTempPin,
  setTempPinCopy,
  setCongratsModal,
  setAllowCoordinates,
  setMyPinsDrawer,
  setQuickPinModal,
} = reportPinsSlice.actions;

export default reportPinsSlice.reducer;
