import * as React from "react";
import { Stack, Typography, Link } from "@mui/material";

export default function MenuInfo() {
  return (
    <Stack px={2} py={1}>
      <Typography sx={{ fontStyle: "italic" }} variant="caption">
        Argo’s{" "}
        <Link href="https://argonav.io/terms-of-use/" target="_blank" underline="none">
          Terms of Use
        </Link>{" "}
        and{" "}
        <Link href="https://www.argonav.io/privacy/" target="_blank" underline="none">
          Privacy Policy
        </Link>
      </Typography>
    </Stack>
  );
}
