export type TMarker = {
  LIGHT_INFO?: string;
  LIGHT_SIGPER?: string;
  LIGHT_LITCHR?: string;
  LIGHT_COLOR?: string;
  BOYSHP?: string;
  BCNSHP?: string;
  BEACON_TYPE?: string;
  BUOY_TYPE?: string;
  COLOR?: string;
  INFORM?: string;
  RESTRN?: string;
  CATREA?: string;
  OBJNAME?: string;
  LATITUDE?: string;
  LONGITUDE?: string;
  NAME?: string;
  ICON?: string;
  TYPE: string;
  UID: string;
  id: string;
};

export enum EMarkerPopoverPositions {
  Top = "top",
  Bottom = "bottom",
  TopLeft = "top-left",
  TopRight = "top-right",
  BottomLeft = "bottom-left",
  BottomRight = "bottom-right",
}
