import { Box } from "@mui/system";
import React, { useEffect } from "react";

import ViewportMessage from "../ViewportMessage";

import UserBar from "@/components/Common/UserBar";
import SideMenu from "@/components/Common/SideMenu";
import useDisclosure from "@/hooks/useDisclosure";
import BaseModal from "@/components/Modals/BaseModal/BaseModal";
import { useAppSelector } from "@/hooks/useRedux";
import { KEY_VMODAL_NON_USER, KEY_VMODAL_USER } from "@/utils/keys";

interface ILayout {
  children: React.ReactElement;
}

function Layout({ children }: ILayout) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { user, logged } = useAppSelector((state) => state.auth);
  const vmodalKey = logged && user ? KEY_VMODAL_USER + user.email : KEY_VMODAL_NON_USER;
  const vmodalData = localStorage.getItem(vmodalKey);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024 && !vmodalData) {
        onOpen();
      } else {
        onClose();
      }
    };

    const initialCheck = () => {
      if (window.innerWidth < 1024 && !vmodalData) {
        onOpen();
      }
    };

    window.addEventListener("resize", handleResize);
    initialCheck();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [vmodalData]);

  const handleSetDonShowAgain = () => {
    localStorage.setItem(vmodalKey, "true");
  };

  return (
    <>
      <Box>
        <Box component="header">
          <UserBar />
        </Box>
        <Box component="main">
          <Box component="section">
            <SideMenu />
          </Box>
          <Box component="section">{children}</Box>
        </Box>
      </Box>
      {isOpen && (
        <BaseModal
          ariaDescribedby="viewport-message"
          ariaLabelledby="viewport-message"
          open={isOpen}
          size="xs"
          onClose={onClose}
        >
          <ViewportMessage onClose={onClose} onDontShowAgain={handleSetDonShowAgain} />
        </BaseModal>
      )}
    </>
  );
}

export default Layout;
