import { Box } from "@mui/material";
import React from "react";

import AdvancedMarker from "../AdvancedMarker";

import { styles } from "./styles";

import { IPositionBridgeAlert } from "@/types/map";
import BaseIcon from "@/components/BaseIcon";
import { ReactComponent as WarningIconBlue } from "&/img/markers/alerts/WarningIcon_blue.svg";
import { ReactComponent as WarningIconDarkRed } from "&/img/markers/alerts/WarningIcon_darkred.svg";
import { ReactComponent as WarningIconGreen } from "&/img/markers/alerts/WarningIcon_green.svg";
import { ReactComponent as WarningIconRed } from "&/img/markers/alerts/WarningIcon_red.svg";
import { useAppSelector } from "@/hooks/useRedux";
import BridgeWarningPopover from "@/components/MapPopovers/BridgeWarningPopover";
import { TVessel } from "@/types/vessel";
import { metersToFeet } from "@/utils/route";

export type TAlertInfo = {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  text: string;
  textColor: string;
  alert: IPositionBridgeAlert;
};

interface IAlertMarker {
  alertInfo: TAlertInfo;
}

export function AlertMaker({ alertInfo }: IAlertMarker) {
  return (
    <Box sx={styles.wrapper}>
      <Box data-testid="StartPin" sx={styles.alertMarkerContainer}>
        <BaseIcon icon={alertInfo.icon} sx={styles.alertMarker} />
      </Box>
      <Box className="popover" component="section">
        <BridgeWarningPopover alertInfo={alertInfo} />
      </Box>
    </Box>
  );
}
function BridgesAlertMarkers() {
  const { userInformation } = useAppSelector((state) => state.user);
  const { routeCreated } = useAppSelector((state) => state.route);

  const courseBridges = routeCreated?.course_bridges ?? null;

  if (!courseBridges) return null;

  const handleSelectMarker = (
    alert: IPositionBridgeAlert,
    userVessel: TVessel | null | undefined,
  ) => {
    let alertInfo: TAlertInfo = {
      icon: WarningIconDarkRed,
      title: "No Bridge Data",
      text: "Proceed with caution",
      textColor: "error.dark",
      alert,
    };

    const verclr = metersToFeet(alert.verclr) ?? 0;

    if (!userVessel?.height) {
      alertInfo = {
        ...alertInfo,
        icon: WarningIconBlue,
        title: "No Boat Height",
        text: "Update Your Boat Info",
        textColor: "#0B79D0",
        alert,
      };
    } else if (verclr === 0) {
      alertInfo = {
        ...alertInfo,
        icon: WarningIconDarkRed,
        title: "No Bridge Data",
        text: "Proceed with caution",
        textColor: "error.dark",
        alert,
      };
    } else if (verclr > 0) {
      if (userVessel?.height < verclr) {
        alertInfo = {
          ...alertInfo,
          icon: WarningIconGreen,
          title: "Fixed Bridge",
          text: "Clearence is good",
          textColor: "success.main",
          alert,
        };
      }
      if (userVessel?.height > verclr) {
        alertInfo = {
          ...alertInfo,
          icon: WarningIconRed,
          title: "Low Bridge",
          text: "Do not proceed",
          textColor: "error.main",
          alert,
        };
      }
    }

    return <AlertMaker alertInfo={alertInfo} />;
  };

  return (
    <>
      {courseBridges.map((alert: IPositionBridgeAlert) => (
        <AdvancedMarker
          key={alert.lat}
          icon={handleSelectMarker(alert, userInformation?.current_vessel)}
          position={{ lat: Number(alert.lat), lng: Number(alert.lng) }}
          selected={false}
          onClick={() => null}
        />
      ))}
    </>
  );
}

export default BridgesAlertMarkers;
