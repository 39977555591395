/* global GeolocationPosition */
import { Box, Typography, Stack, Divider, Button, IconButton, Avatar } from "@mui/material";
import { CloseOutlined, DeleteOutline, EditOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useUnitSystem, LengthUnits } from "@argonav/unit-system";

import { styles } from "./styles";

import BaseModal from "@/components/Modals/BaseModal";
import { IMapPois } from "@/types/pois";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { getPosition } from "@/utils/maps";
import { Props } from "@/types/users";
import { Position } from "@/types/map";
import { getDistanceFromLocation } from "@/utils/poi";
import { useAppDispatch } from "@/hooks/useRedux";
import dayjs from "@/config/dayjs";
import { setTempPin } from "@/store/slices/reportpins";
import useDisclosure from "@/hooks/useDisclosure";
import servicePins from "@/services/pins";
import { useGetPlacesQuery, useGetQuickPinsQuery } from "@/store/api/argoApi";
import { addSelectedPoi } from "@/store/slices/search";
import PinsModal from "@/components/Pins/PinsModal";

export type TParamsLocation = {
  poi_id?: number;
  place_id?: number;
  name?: string;
  pin_type?: string;
  lat?: string;
  lng?: string;
  description?: string;
};

export default function QuickPinDrawer({ close, poiSelected }: Props) {
  const dispatch = useAppDispatch();
  const [position, setPosition] = useState<Position | null>(null);
  const { refetch: refetchQuickPins } = useGetQuickPinsQuery({});

  const { distanceUnitHandler, coordinatesFormatHandler } = useUnitSystem();
  const { refetch: refetchPlaces } = useGetPlacesQuery({});
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { onOpen: onOpenEdit, isOpen: isOpenEdit, onClose: onCloseEdit } = useDisclosure();

  const distanceAway = distanceUnitHandler({
    originalUnit: LengthUnits.METERS,
    originalValue: Number(
      getDistanceFromLocation(position || { lat: 0, lng: 0 }, {
        lat: poiSelected?.lat,
        lng: poiSelected?.lng,
      }),
    ),
  });

  const showPosition = (pos: GeolocationPosition) => {
    setPosition({
      lat: pos.coords.latitude,
      lng: pos.coords.longitude,
    });
  };

  useEffect(() => {
    if (!position) {
      getPosition(showPosition);
    }
  }, [poiSelected]);

  const previous: dayjs.Dayjs = dayjs(poiSelected.created_at);

  const latLngFormatted = coordinatesFormatHandler({
    latitude: Number(poiSelected?.lat),
    longitude: Number(poiSelected?.lng),
  });

  const handleEditPin = (poiSelected: IMapPois | undefined) => {
    if (poiSelected) {
      onOpenEdit();
    }
  };

  const handleDeletePlacePin = async (placePinId: number) => {
    if (placePinId) {
      try {
        const { status } = await servicePins.deleteQuickPin(placePinId);

        if (status === 200) {
          refetchQuickPins();
          refetchPlaces();
          dispatch(addSelectedPoi(null));
          dispatch(setTempPin(null));
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "New Pin was deleted.",
            }),
          );
          onClose();
        }
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      }
    }
  };

  return (
    <Box sx={styles.mainContainer}>
      <Button
        aria-label="delete"
        color="whiteBg"
        size="small"
        sx={styles.closeButton}
        variant="iconButtonContained"
        onClick={close}
      >
        <CloseOutlined />
      </Button>

      <Stack alignItems="center" direction="row" pb={1} pl={2} pr={4} pt={1} spacing={0}>
        <Box mr={2}>
          <Avatar
            alt="Pin Icon"
            src="/img/markers/quickpin/QuickPin.svg"
            sx={styles.pinIcon}
            variant="circular"
          />
        </Box>
        <Stack alignItems="flex-start" className="titleContainer">
          <Typography component="h2" sx={styles.poiTitle} variant="h5">
            {poiSelected?.name}
          </Typography>
          <Stack alignItems="center" direction="row" spacing={1}>
            <Typography component="h3" variant="body2">
              {dayjs(previous).fromNow()}
            </Typography>

            {position && (
              <Typography component="h3" variant="body2">
                •
              </Typography>
            )}
            {position && (
              <Typography component="h3" variant="body2">
                {`${distanceAway.value} ${distanceAway.unit} away  `}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack pb={2} pl={2}>
        <Typography
          color="text.secondary"
          component="h3"
          variant="body2"
        >{`${latLngFormatted}`}</Typography>
      </Stack>
      <Divider />
      <Stack alignItems="center" direction="row" p={2} spacing={1}>
        <IconButton color="primary" sx={styles.actionButton} onClick={onOpen}>
          <DeleteOutline fontSize="small" />
        </IconButton>
        <IconButton
          color="primary"
          sx={styles.actionButton}
          onClick={() => handleEditPin(poiSelected)}
        >
          <EditOutlined fontSize="small" />
        </IconButton>
      </Stack>
      <Divider />

      {isOpen && (
        <BaseModal
          ariaDescribedby="Delete New Pin"
          ariaLabelledby="Delete New Pin"
          icon={
            <Avatar alt="Pin Icon" src="/img/markers/quickpin/QuickPin.svg" variant="circular" />
          }
          open={isOpen}
          size="xs"
          title="Delete New Pin"
          onClose={onClose}
        >
          <Stack pb={2} pt={1} px={3} spacing={2}>
            <Typography color="text.primary" variant="body1">
              Are you sure you want to delete this new pin?
            </Typography>

            <Stack direction="row" justifyContent="end" spacing={1}>
              <Button type="submit" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={() => handleDeletePlacePin(poiSelected?.id)}
              >
                Delete
              </Button>
            </Stack>
          </Stack>
        </BaseModal>
      )}

      {isOpenEdit && (
        <PinsModal
          isOpen={isOpenEdit}
          pinsType="both"
          quickpin={poiSelected}
          title="Create a Pin"
          onClose={onCloseEdit}
        />
      )}
    </Box>
  );
}
