import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Stack,
  Grid,
  Chip,
  IconButtonProps,
  IconButton,
  styled,
  Collapse,
} from "@mui/material";
import { CloseOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import { LengthUnits, VolumeUnits, SpeedUnits, useUnitSystem } from "@argonav/unit-system";

import ProfileSubtitle from "./ProfileSubtitle";
import { styles } from "./styles";

import EmptyState from "@/components/EmptyState";
import useDisclosure from "@/hooks/useDisclosure";
import { TVessel } from "@/types/vessel";
import { IAffiliationItem } from "@/types/affiliation";
import { Interest } from "@/types/interest";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import vesselsServices from "@/services/vessel";
import userServices from "@/services/user";
import { fromAffiliationsToAffiliationItems } from "@/utils/affiliations";
import { privacyHandler } from "@/utils/friends";
import { KEY_USER_FRIENDS } from "@/utils/keys";
import { IFriends } from "@/types/users";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean | string;
}

interface IUserProfileDrawer {
  edit?: boolean;
  isDrawer?: boolean;
  user: IFriends;
  onClose: () => void;
}

const handleBoatTypeName = (value: string | undefined) => {
  if (value === "power") return "Power";
  if (value === "jet_ski") return "Jet Ski";
  if (value === "sail") return "Sail";
  if (value === "paddle") return "Paddle";
  if (value === "other") return "Other";

  return undefined;
};

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;

  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const nullValue = "No Data";

export default function UserProfileDrawer({ edit, onClose, isDrawer, user }: IUserProfileDrawer) {
  const { onToggle, isOpen } = useDisclosure();
  const dispatch = useAppDispatch();
  const { logged } = useAppSelector((state) => state.auth);
  const [vessel, setVessel] = useState<TVessel | null>(user?.current_vessel);
  const [affiliations, setAffiliations] = useState<IAffiliationItem[] | null>(null);
  const [interests, setInterests] = useState<Interest[] | null>(null);
  const { showProfileImage } = privacyHandler({ user, noPrivacy: false });
  const { lengthUnitHandler, consumptionUnitHandler, speedUnitHandler } = useUnitSystem();

  useEffect(() => {
    if (logged) {
      const handleGetVesselInfo = async (showProfileImage: boolean) => {
        try {
          if (user?.current_vessel?.id) {
            const { data } = await vesselsServices.getVesselById(user?.current_vessel.id);

            setVessel(data.data);
          }

          if (showProfileImage) {
            const { data: dataAffiliations } = await userServices.getAffiliationsByUserId(user?.id);
            const { data: dataInterests } = await userServices.getInsterests(user?.id);

            setAffiliations(fromAffiliationsToAffiliationItems(dataAffiliations));
            setInterests(dataInterests);
          }
        } catch (error: any) {
          dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
        }
      };

      handleGetVesselInfo(showProfileImage);
    }
  }, [logged, showProfileImage]);

  const hasAffiliations = affiliations && affiliations.length > 0;
  const hasInterests = interests && interests.length > 0;
  const avatarFallbackSrc = showProfileImage
    ? user.image || "/img/placeholders/defaultUserMarkerSquare.png"
    : "/img/placeholders/captainHatMarkerSquare.png";
  const coverFallbackSrc = showProfileImage
    ? user.cover || "img/placeholders/argoBG.jpg"
    : "img/placeholders/argoBG.jpg";

  const boatBeam = lengthUnitHandler({
    originalUnit: LengthUnits.FEET,
    originalValue: vessel?.beam || 0,
  });
  const boatDraft = lengthUnitHandler({
    originalUnit: LengthUnits.FEET,
    originalValue: vessel?.draft || 0,
  });
  const boatBuffer = lengthUnitHandler({
    originalUnit: LengthUnits.FEET,
    originalValue: vessel?.buffer || 0,
  });
  const boatLength = lengthUnitHandler({
    originalUnit: LengthUnits.FEET,
    originalValue: vessel?.length || 0,
  });
  const boatHeight = lengthUnitHandler({
    originalUnit: LengthUnits.FEET,
    originalValue: vessel?.height || 0,
  });
  const boatAvgSpeed = speedUnitHandler({
    originalUnit: SpeedUnits.KNOT,
    originalValue: vessel?.average_speed || 0,
  });
  const boatFuelConsumption = consumptionUnitHandler({
    originalUnit: VolumeUnits.GALLONS,
    originalValue: Number(vessel?.fuel_consumption) || 0,
  });

  return (
    <Box sx={isDrawer ? styles.mainContainerDrawer : styles.mainContainer}>
      <Box
        className="coverImage"
        sx={{
          backgroundImage: `url(${coverFallbackSrc})`,
        }}
      >
        {!isDrawer && (
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}
            sx={styles.header}
          >
            <Stack alignItems="center" direction="row" spacing={1.5}>
              <Button
                aria-label="delete"
                className="closeButton"
                color="whiteBg"
                size="small"
                variant="iconButtonContained"
                onClick={onClose}
              >
                <CloseOutlined />
              </Button>
            </Stack>
          </Stack>
        )}
      </Box>
      <Stack alignItems="flex-end" direction="row" spacing={2} sx={styles.infoContainer}>
        <Avatar
          alt={`${user?.first_name || ""} ${user?.last_name || ""}`}
          src={avatarFallbackSrc}
          sx={styles.avatar}
        />
        <Box>
          <Typography sx={styles.userFirstName} variant="h5">
            {showProfileImage ? `${user.first_name} ${user.last_name}` : "Fellow Boater"}
          </Typography>
          <Typography color="text.secondary" sx={{ minHeight: "22px" }} variant="subtitle2">
            {vessel?.name ? vessel?.name : ""}
          </Typography>
        </Box>
      </Stack>
      <Stack spacing={3} sx={styles.container}>
        <Box>
          <ProfileSubtitle edit={edit} title="Boat Information" />
          <Card variant="outlined">
            {vessel?.assets && vessel?.assets[0]?.url ? (
              <CardMedia
                alt={vessel?.manufacturer_model || "boat model"}
                component="img"
                height="160"
                image={vessel?.assets[0]?.url}
              />
            ) : (
              <EmptyState
                imageURL="img/placeholders/EmptyStateBoat.svg"
                sx={{ width: "100%", height: "160px", backgroundColor: "grey.50" }}
                title="No Boat Image Yet"
              />
            )}
            <CardContent sx={styles.boatinfoCardContent}>
              <Stack alignItems="center" direction="row" justifyContent="space-between">
                <Box>
                  <Typography variant="subtitle1">{vessel?.name || nullValue}</Typography>
                  <Typography color="text.secondary" variant="body2">
                    {vessel?.manufacturer_model}
                  </Typography>
                </Box>
                <ExpandMore
                  aria-expanded={isOpen}
                  aria-label="show more"
                  expand={isOpen}
                  size="small"
                  onClick={onToggle}
                >
                  <ExpandMoreOutlined />
                </ExpandMore>
              </Stack>
              <Collapse unmountOnExit in={isOpen} timeout="auto">
                <Stack mt={2} spacing={0} sx={styles.boatInfo}>
                  <Stack className="boatInfoRow">
                    <Box>
                      <Typography color="text.secondary" variant="caption">
                        Boat Type
                      </Typography>
                      <Typography component="h4" variant="body2">
                        {handleBoatTypeName(vessel?.boat_type) || nullValue}
                      </Typography>
                    </Box>
                  </Stack>
                  {user.friendship_status === KEY_USER_FRIENDS && (
                    <Stack className="boatInfoRow">
                      <Box>
                        <Typography color="text.secondary" variant="caption">
                          Home Port
                        </Typography>
                        <Typography component="h4" variant="body2">
                          {vessel?.port_name || nullValue}
                        </Typography>
                      </Box>
                    </Stack>
                  )}
                  <Stack className="boatInfoRow">
                    <Box>
                      <Typography color="text.secondary" variant="caption">
                        Draft
                      </Typography>
                      <Typography component="h4" variant="body2">
                        {vessel?.draft ? `${boatDraft.value} ${boatDraft.unit}` : nullValue}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography color="text.secondary" variant="caption">
                        Buffer
                      </Typography>
                      <Typography component="h4" variant="body2">
                        {vessel?.buffer ? `${boatBuffer.value} ${boatBuffer.unit}` : nullValue}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack className="boatInfoRow">
                    <Box>
                      <Typography color="text.secondary" variant="caption">
                        Length
                      </Typography>
                      <Typography component="h4" variant="body2">
                        {vessel?.length ? `${boatLength.value} ${boatLength.unit}` : nullValue}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography color="text.secondary" variant="caption">
                        Beam
                      </Typography>
                      <Typography component="h4" variant="body2">
                        {vessel?.beam ? `${boatBeam.value} ${boatBeam.unit}` : nullValue}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack className="boatInfoRow">
                    <Box>
                      <Typography color="text.secondary" variant="caption">
                        Height
                      </Typography>
                      <Typography component="h4" variant="body2">
                        {vessel?.height ? `${boatHeight.value} ${boatHeight.unit}` : nullValue}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography color="text.secondary" variant="caption">
                        Est. Avg. Speed
                      </Typography>
                      <Typography variant="body2">
                        {vessel?.average_speed
                          ? `${boatAvgSpeed.value} ${boatAvgSpeed.unit}`
                          : nullValue}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack className="boatInfoRow">
                    <Box>
                      <Typography color="text.secondary" variant="caption">
                        Fuel/Power
                      </Typography>
                      <Typography variant="body2">
                        {vessel?.fuel_type ? `${boatFuelConsumption.value}` : nullValue}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography color="text.secondary" variant="caption">
                        Fuel/Power Usage
                      </Typography>
                      <Typography variant="body2">
                        {vessel?.fuel_consumption
                          ? `${boatFuelConsumption.value} ${
                              vessel?.fuel_type === "electric" ? "kWh" : boatFuelConsumption.unit
                            }`
                          : nullValue}
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Collapse>
            </CardContent>
          </Card>
        </Box>
        <Box>
          <ProfileSubtitle divider edit={edit} title="Affiliations" />
          {showProfileImage && hasAffiliations && (
            <Grid container columnSpacing={1.25} rowSpacing={1}>
              {affiliations?.map((item) => (
                <Grid key={item.label} item xs="auto">
                  <Chip icon={item.icon} label={item.label} sx={styles.chipWithIcon} />
                </Grid>
              ))}
            </Grid>
          )}
          {(!showProfileImage || !hasAffiliations) && (
            <Typography>No affiliations to show</Typography>
          )}
        </Box>
        <Box>
          <ProfileSubtitle divider edit={edit} title="Interests" />
          {showProfileImage && hasInterests && (
            <Grid container columnSpacing={1.5} rowSpacing={1}>
              {interests?.map((interest: Interest) => (
                <Grid key={interest.id} item xs="auto">
                  <Chip label={interest.name} />
                </Grid>
              ))}
            </Grid>
          )}
          {(!showProfileImage || !hasInterests) && <Typography>No interests to show</Typography>}
        </Box>
      </Stack>
    </Box>
  );
}
