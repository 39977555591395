import ContextMenuMap from "@/components/ContextMenu/ContextMenuMap";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import useSearch from "@/hooks/useSearch";
import { setCoords } from "@/store/slices/map";
import { addStartPoint, addEndPoint, addCreatingRoute } from "@/store/slices/route";

interface RoutePosition {
  lat: number;
  lng: number;
  name: string;
}

function ContextMenuCard() {
  const dispatch = useAppDispatch();
  const { creatingRoute } = useAppSelector((state) => state.route);
  const { coords } = useAppSelector((state) => state.map);
  const { setCleanPoiList, setCleanPoiCategory } = useSearch();

  const handleSetRoute = (inputType: number) => {
    if (coords) {
      setCleanPoiList();
      setCleanPoiCategory();
      dispatch(addCreatingRoute(true));
      if (inputType === 1) {
        dispatch(
          addStartPoint({
            lat: coords.lat,
            lng: coords.lng,
            name: `${coords.name}`,
          }),
        );
      } else {
        dispatch(
          addEndPoint({
            lat: coords.lat,
            lng: coords.lng,
            name: `${coords.name}`,
          }),
        );
      }
      dispatch(setCoords(undefined));
    }
  };

  if (!coords || creatingRoute) return null;

  return (
    <ContextMenuMap
      action={handleSetRoute}
      pointData={coords}
      setCoords={(coords: RoutePosition | undefined) => dispatch(setCoords(coords))}
    />
  );
}

export default ContextMenuCard;
