import React from "react";
import {
  Stack,
  DialogContent,
  DialogActions,
  Button,
  List,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  TextField,
  Box,
  Alert,
} from "@mui/material";
import { useFormik } from "formik";

import { styles } from "./styles";
import EditTransientListItem from "./EditTransientListItem";

import servicePoi from "@/services/poi";
import { useAppDispatch } from "@/hooks/useRedux";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { addSelectedPoi } from "@/store/slices/search";
import { IMapPois } from "@/types/pois";

type TTransientForm = {
  transient_available: boolean | null | undefined;
  transient_available_info: string | null;
  moorings_available: boolean | null | undefined;
  moorings_available_info: string | null;
  length_max: string | null;
  beam_max: string | null;
  amp_30: boolean | null | undefined;
  amp_50: boolean | null | undefined;
  amp_100: boolean | null | undefined;
  other_transient_info: string | null;
};

interface IEditTransientModal {
  onClose: () => void;
  poiSelected: IMapPois;
}

const slipTypes = [
  {
    label: "Slips Available",
    placeholder: "Transient price (per ft)",
    valueName: "transient_available",
  },
  {
    label: "Moorings Available",
    placeholder: "Transient price (per night)",
    valueName: "moorings_available",
  },
];

function EditTransientModal({ onClose, poiSelected }: IEditTransientModal) {
  const dispatch = useAppDispatch();

  const handleForm = async (values: TTransientForm) => {
    if (poiSelected.boaters_can_update_transients) {
      try {
        const { status, data } = await servicePoi.updatePoiInfo(poiSelected?.id, {
          ...values,
          transient_price: values.transient_available_info,
          transient_price_night: values.moorings_available_info,
        });

        if (status === 200) {
          dispatch(addSelectedPoi(data.data));
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Transient Info was edited.",
            }),
          );
          onClose();
        }
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      transient_available: poiSelected?.transient_available,
      transient_available_info: poiSelected?.transient_price,
      moorings_available: poiSelected?.moorings_available,
      moorings_available_info: poiSelected?.transient_price_night,
      length_max: poiSelected?.length_max,
      beam_max: poiSelected?.beam_max,
      amp_30: poiSelected?.amp_30,
      amp_50: poiSelected?.amp_50,
      amp_100: poiSelected?.amp_100,
      other_transient_info: poiSelected?.other_transient_info,
    } as TTransientForm,
    onSubmit: handleForm,
  });

  return (
    <>
      {!poiSelected.boaters_can_update_transients && (
        <Box sx={{ pl: 3, pr: 3, mb: 2 }}>
          <Alert severity="error">
            Editing transient data has been disabled by the facility owner.
          </Alert>
        </Box>
      )}
      <Box
        component="form"
        sx={
          !poiSelected.boaters_can_update_transients
            ? { opacity: 0.6, pointerEvents: "none" }
            : null
        }
        onSubmit={formik.handleSubmit}
      >
        <DialogContent sx={{ py: 0 }}>
          <Stack spacing={2}>
            <Stack mb={0.5} sx={styles.card}>
              <List disablePadding sx={styles.list}>
                {slipTypes.map((item) => (
                  <EditTransientListItem
                    key={item.label}
                    label={item.label}
                    placeholder={item.placeholder}
                    valueName={item.valueName}
                    values={formik.values}
                    onChangeValue={formik.setFieldValue}
                  />
                ))}
              </List>
            </Stack>
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth>
                <InputLabel htmlFor="length-max">Length Max</InputLabel>
                <OutlinedInput
                  endAdornment={<InputAdornment position="end">ft</InputAdornment>}
                  id="length_max"
                  label="Length Max"
                  name="length_max"
                  value={formik.values.length_max}
                  onChange={formik.handleChange}
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor="beam-max">Beam Max</InputLabel>
                <OutlinedInput
                  endAdornment={<InputAdornment position="end">ft</InputAdornment>}
                  id="beam_max"
                  label="Beam Max"
                  name="beam_max"
                  value={formik.values.beam_max}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </Stack>
            <Stack spacing={0.5}>
              <Typography color="text.secondary" variant="body1">
                Power Available:
              </Typography>
              <FormGroup row sx={styles.formGroup}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!formik.values.amp_30}
                      color="secondary"
                      id="amp_30"
                      name="amp_30"
                      onChange={() => formik.setFieldValue("amp_30", !formik.values.amp_30)}
                    />
                  }
                  label="30 amp"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!formik.values.amp_50}
                      color="secondary"
                      id="amp_50"
                      name="amp_50"
                      onChange={() => formik.setFieldValue("amp_50", !formik.values.amp_50)}
                    />
                  }
                  label="50 amp"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!formik.values.amp_100}
                      color="secondary"
                      id="amp_100"
                      name="amp_100"
                      onChange={() => formik.setFieldValue("amp_100", !formik.values.amp_100)}
                    />
                  }
                  label="100 amp"
                />
              </FormGroup>
            </Stack>
            <TextField
              fullWidth
              multiline
              id="other_transient_info"
              label="Other info"
              minRows={3}
              name="other_transient_info"
              size="small"
              value={formik.values.other_transient_info}
              onChange={formik.handleChange}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Box>
    </>
  );
}

export default EditTransientModal;
