import { TVessel } from "@/types/vessel";
import { Position } from "@/types/map";

export const START_POINT_TYPE = "start";
export const END_POINT_TYPE = "end";
export const CANCEL_ROUTE_CHANGE_MSG = "Route changed by the user.";
export const CANCEL_ROUTE_USER_CANCEL_MSG = "Routing canceled by the user.";

export const colorForDepth = (
  vessel: TVessel | null | undefined,
  manualRoute: boolean,
  depth = 1,
) => {
  // If route is manual.
  if (manualRoute) return "#777777";
  // line.depth is in meters, depth value converted to feet
  // defaults if no vessel info present: buffer 2ft, draft 3ft

  const depthInFeet = depth * 3.281;

  // TODO: Replace for the default value of Buffer inside Config file
  let buffer = 1.524;

  // TODO: Replace for the default value of Draft inside Config file
  let draft = 1.524;

  if (vessel) {
    buffer = vessel.buffer;
    // Subtract 0.01 to allow for margin of error
    draft = vessel.draft - 0.01;
  }

  const clearance = buffer + draft;

  if (depthInFeet < draft) {
    return "#d85a42";
  }
  if (depthInFeet < clearance) {
    return "#ffff40";
  }

  return "#009688";
};

export const getVesselName = (vessel: any) => {
  const isVesselDefined = !vessel?.name && !vessel?.manufacturer_model;

  if (isVesselDefined) {
    return "-";
  }
  if (vessel?.manufacturer_model) {
    return `${vessel?.name} / ${vessel?.manufacturer_model}`;
  }

  return vessel?.name;
};

/**
 * validateNoDepthRoute: checks if a route has depth.
 * @param {Array} courseLocations (with depth attribute)
 */
export const validateNoDepthRoute = (courseLocations: Position[]) => {
  const depthUndefined = courseLocations?.filter?.(
    (course: Position) => course?.depth === undefined,
  );

  if (depthUndefined?.length === courseLocations?.length) return false;

  const depth = courseLocations?.filter?.(
    (course: Position) => course?.depth && course?.depth > 0.22,
  );

  return depth?.length === 0 || false;
};

export function metersToFeet(meters: number) {
  if (meters) {
    return meters * 3.28084;
  }

  return null;
}
