/* global google */
import { memo } from "react";
import { useMap } from "@vis.gl/react-google-maps";

import MarkersConstants from "../Utils/Markers";

import Poistion from "./Position";
import Pois from "./Pois";
import NavAids from "./NavAids";
import PoisSearched from "./PoisSearched";
import PrivatePlaces from "./PrivatePlaces";
import QuickPins from "./QuickPins";
import TideAndCurrents from "./TideAndCurrents";
import ReportPins from "./ReportPins";
import NoaaHazards from "./NoaaHazards";
import Boaters from "./Boaters";
import MyPin from "./ReportPins/MyPin";
import ContextMenuMarker from "./ContextMenuMarker";
import RouteMarkers from "./Route";
import BridgesAlertMarkers from "./BridgesAlertMarkers";

import { Position } from "@/types/map";
import { useAppSelector } from "@/hooks/useRedux";
import usePremiumUser from "@/hooks/usePremiumUser";

interface IMarkers {
  userPosition?: Position | null;
  zoom: number;
  clogpage: boolean;
  bounds: google.maps.LatLngBounds | undefined;
  onCleanPoiList: () => void;
}

const Markers = memo(({ userPosition, zoom = 13, clogpage, bounds, onCleanPoiList }: IMarkers) => {
  const map = useMap();
  const isPremium = usePremiumUser();
  const { poiList, selectedPoi } = useAppSelector((state) => state.search);
  const { tempPin, tempPinCopy } = useAppSelector((state) => state.reportpins);
  const REGULAR_ZOOM_TO_RENDER = bounds && zoom >= MarkersConstants.detailsMinZoomLevel;
  const REGULAR_ZOOM_TO_RENDER_NAVAIDS =
    bounds && zoom >= MarkersConstants.detailsMinZoomLevelNavAids;
  const REGULAR_ZOOM_TO_RENDER_TIDE_AND_CURRENTS =
    isPremium && bounds && zoom >= MarkersConstants.detailsMinZoomLevelTideAndCurrents;
  const REGULAR_ZOOM_TO_RENDER_NOAA_HAZARDS =
    bounds && zoom >= MarkersConstants.noaaHazardsMinZoomLvl;
  const REGULAR_ZOOM_TO_RENDER_BOATERS =
    bounds && zoom >= MarkersConstants.detailsMinZoomLevelBoaters;
  const REGULAR_ZOOM_TO_RENDER_BRIDGES_ALERTS = zoom >= MarkersConstants.bridgesAlertsMinZoomLvl;

  if (!map) return null;

  return (
    <>
      <ContextMenuMarker />
      <RouteMarkers />
      {userPosition && <Poistion userPosition={userPosition} />}
      {poiList && !clogpage && (
        <PoisSearched poiList={poiList} selectedPoi={selectedPoi} onCleanPoiList={onCleanPoiList} />
      )}
      {REGULAR_ZOOM_TO_RENDER && !poiList && (
        <Pois bounds={bounds} clogpage={clogpage} zoom={zoom} />
      )}
      {REGULAR_ZOOM_TO_RENDER_NAVAIDS && !poiList && (
        <NavAids bounds={bounds} clogpage={clogpage} zoom={zoom} />
      )}
      {REGULAR_ZOOM_TO_RENDER && !poiList && <PrivatePlaces clogpage={clogpage} />}
      {!poiList && <QuickPins clogpage={clogpage} />}
      {REGULAR_ZOOM_TO_RENDER && !poiList && (
        <ReportPins bounds={bounds} clogpage={clogpage} userPosition={userPosition} />
      )}
      {REGULAR_ZOOM_TO_RENDER_NOAA_HAZARDS && !poiList && <NoaaHazards bounds={bounds} />}
      {REGULAR_ZOOM_TO_RENDER_TIDE_AND_CURRENTS && !poiList && (
        <TideAndCurrents bounds={bounds} zoom={zoom} />
      )}
      {REGULAR_ZOOM_TO_RENDER_BOATERS && !poiList && !clogpage && <Boaters bounds={bounds} />}
      {tempPin && !tempPinCopy && <MyPin pin={tempPin} />}
      {tempPinCopy && <MyPin pin={tempPinCopy} />}
      {REGULAR_ZOOM_TO_RENDER_BRIDGES_ALERTS && !poiList && <BridgesAlertMarkers />}
    </>
  );
});

export default Markers;
