import { Chip } from "@mui/material";
import { CheckOutlined } from "@mui/icons-material";

import { styles } from "./styles";

type ToggleChipProps = {
  label: string;
  isActive?: boolean | undefined;
};

const PRIMARY_COLOR = "primary";
const DEFAULT_COLOR = "default";

export default function ToogleChip({ label, isActive }: ToggleChipProps) {
  return (
    <Chip
      color={isActive ? PRIMARY_COLOR : DEFAULT_COLOR}
      icon={isActive ? <CheckOutlined /> : undefined}
      label={label}
      sx={styles.chip}
    />
  );
}
