import React from "react";
import { Stack, Box, Typography } from "@mui/material";

import { styles } from "./styles";

interface IEmptyState {
  sx?: object;
  imageSize?: object;
  imageURL?: string;
  title?: string;
  subtitle?: string;
}

function EmptyState({ sx, imageURL, imageSize, title, subtitle }: IEmptyState) {
  return (
    <Stack
      alignItems="center"
      direction="column"
      justifyContent="center"
      px={2}
      py={3}
      spacing={0}
      sx={sx}
    >
      <Stack alignItems="center" direction="column" spacing={0.5}>
        <Box
          sx={{
            ...(imageSize || styles.image),
            backgroundImage: `url(${imageURL})`,
          }}
        />
        {title && (
          <Typography color="primary" textAlign="center" variant="subtitle1">
            {title}
          </Typography>
        )}
      </Stack>
      {subtitle && (
        <Typography color="text.secondary" textAlign="center" variant="body2">
          {subtitle}
        </Typography>
      )}
    </Stack>
  );
}

export default EmptyState;
