export const styles = {
  listItem: {
    "& .MuiListItemSecondaryAction-root": {
      right: "8px",
    },
  },

  listItemButton: {
    py: 1.5,
  },

  textField: {
    maxWidth: "160px",
  },
};
