import { Stack, Skeleton } from "@mui/material";

export default function PlaceInfoSkeleton() {
  return (
    <Stack p={2} spacing={1} sx={{ width: "100%" }}>
      <Skeleton animation="wave" sx={{ fontSize: "32px" }} variant="text" width="60%" />
      <Skeleton animation="wave" sx={{ fontSize: "16px" }} variant="text" width="50%" />
      <Skeleton animation="wave" sx={{ fontSize: "16px" }} variant="text" width="40%" />

      <Stack direction="row" justifyContent="space-between" py={2}>
        <Skeleton animation="wave" height={40} variant="rounded" width={180} />
        <Stack alignItems="center" direction="row" spacing={1}>
          <Skeleton animation="wave" height={40} variant="circular" width={40} />
        </Stack>
      </Stack>
      <Skeleton animation="wave" height={140} variant="rounded" />
      <Stack alignItems="center" direction="row" pt={2} spacing={2.5}>
        <Skeleton
          animation="wave"
          height={32}
          style={{ flexShrink: 0 }}
          variant="circular"
          width={32}
        />
        <Skeleton animation="wave" sx={{ fontSize: "16px" }} variant="text" width="80%" />
      </Stack>
      <Stack alignItems="center" direction="row" pt={2} spacing={2.5}>
        <Skeleton
          animation="wave"
          height={32}
          style={{ flexShrink: 0 }}
          variant="circular"
          width={32}
        />
        <Skeleton animation="wave" sx={{ fontSize: "16px" }} variant="text" width="60%" />
      </Stack>
    </Stack>
  );
}
