export default {
  // Vessel / boating defaults / knots (nm)
  vessel: {
    averageSpeed: 20.0,
    defaultFuelConsumption: 20,
    defaultBuffer: 1.524,
    defaultDepth: 1.524,
    defaultSpeeds: {
      jet_Ski: 50,
      power: 20,
      sail: 5,
      paddle: 3,
      other: 10,
    },
  },

  knotsToMilesPH: 1.151,
  distancePrecision: 0.001,
  detailsMinZoomLevel: 12,
  detailsMinZoomLevelBoaters: 8,
  detailsMinZoomLevelTideAndCurrents: 8,
  bridgesAlertsMinZoomLvl: 11,
  noaaHazardsMinZoomLvl: 13,
};
