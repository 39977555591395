import { List, ListItem, ListItemButton, Divider, Stack, Button } from "@mui/material";

import PinsCard from "./PinsCard";
import { styles } from "./styles";

import { Props } from "@/types/users";
import EmptyState from "@/components/EmptyState";
import { useAppDispatch } from "@/hooks/useRedux";
import { setMyPinsDrawer } from "@/store/slices/reportpins";
import useDisclosure from "@/hooks/useDisclosure";
import PinsModal from "@/components/Pins/PinsModal";

function PinsList({ items, type }: Props) {
  const dispatch = useAppDispatch();
  const {
    isOpen: isOpenPinsModal,
    onClose: onClosePinsModal,
    onOpen: onOpenPinsModal,
  } = useDisclosure();

  if (items.length === 0) {
    const title =
      type === "places" ? "No Place Pins" : type === "reportpins" ? "No Report Pins" : "No Pins";
    const suubtitle =
      type === "places"
        ? "Looks like you haven't added any place pin yet."
        : type === "reportpins"
        ? "Looks like you haven't added any report pin yet."
        : "Looks like you haven't added any pin yet.";

    return (
      <Stack alignItems="center" sx={styles.emptyContainer}>
        <EmptyState
          imageURL="img/placeholders/EmptyPins.svg"
          subtitle={suubtitle}
          sx={styles.emptyState}
          title={title}
        />
        <Stack sx={{ width: "200px" }}>
          {type === "places" ? (
            <Button color="primary" variant="contained" onClick={onOpenPinsModal}>
              Add New Place Pin
            </Button>
          ) : type === "reportpins" ? (
            <Button color="primary" variant="contained" onClick={onOpenPinsModal}>
              Add New Report Pin
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={() => dispatch(setMyPinsDrawer({ open: false, type: "places" }))}
            >
              Go to Main Map
            </Button>
          )}
        </Stack>
        {isOpenPinsModal && (
          <PinsModal
            isOpen={isOpenPinsModal}
            pinsType={type === "places" ? "places" : "reportpins"}
            title={type === "places" ? "Add a Place" : "Add a Report Pin"}
            onClose={onClosePinsModal}
          />
        )}
      </Stack>
    );
  }

  return (
    <List sx={{ padding: 0, paddingLeft: 0 }}>
      {items.map((item: any) => (
        <>
          <ListItem key={item.id} disablePadding sx={{ padding: 0 }}>
            <ListItemButton sx={{ p: 0 }}>
              <PinsCard item={item} />
            </ListItemButton>
          </ListItem>
          <Divider />
        </>
      ))}
    </List>
  );
}

export default PinsList;
