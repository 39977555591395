import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";

export const styles = {
  wrapper: {
    position: "relative",
    width: "100%",
  },

  sliderContainer: {
    maxWidth: "calc(100vw - 664px)",
    p: 1,
    m: -1,
    maskImage: "linear-gradient(to left, transparent, black 60px)",
    "& .slick-slider .slick-track": {
      width: "max-content !important",
    },
  },

  button: {
    boxShadow: 2,
    flexShrink: 0,
    width: "max-content",
    "&:hover": {
      color: "primary.main",
      boxShadow: 2,
    },
  },

  buttonSelected: {
    boxShadow: 2,
    flexShrink: 0,
    width: "max-content",
    color: "primary.main",
    overflow: "hidden",
    // border: "1px solid",
    // borderColor: "primary.main",
    pr: 1.5,
    "& .MuiSvgIcon-root": {
      fontSize: "18px",
      color: `${alpha(defaultTheme.palette.primary.main, 0.7)}`,
    },
    "&:before": {
      content: "''",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      position: "absolute",
      backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.08)}`,
    },
    "&:hover": {
      boxShadow: 2,
      "& .MuiSvgIcon-root": {
        color: "primary.main",
      },
    },
  },

  roundedButton: {
    boxShadow: 2,
    width: 28,
    height: 28,
    px: 1.5,
    minWidth: "unset",
    "&:hover": {
      color: "primary.main",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "18px",
    },
  },

  dialogContent: {
    p: 0,
    "& .Container": {
      px: 3,
      py: 2,
    },
    "& .Container .MuiTypography-root": {
      typography: "subtitle1",
      mb: 1,
    },
    "& .Container:first-of-type": {
      pt: 0,
    },
    "& .Container:last-of-type": {
      pb: 0,
    },
  },
};
