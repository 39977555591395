import {
  List,
  Button,
  ListItem,
  Box,
  ListSubheader,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/system";
import { HexColorPicker, HexColorInput } from "react-colorful";
import React, { useState } from "react";

import { styles } from "./styles";

import useDisclosure from "@/hooks/useDisclosure";
import AlertDialog from "@/components/AlertDialog";
import { useAppDispatch } from "@/hooks/useRedux";
import { setDepthShadingToEdit } from "@/store/slices/depthshading";
import { rgbStringToHex, hexToRgbString } from "@/utils/globals";
import { IDepthShading, depth_shading_items } from "@/types/map";

interface IDepthColors {
  depthShadingToEdit: IDepthShading;
}

function DepthColors({ depthShadingToEdit }: IDepthColors) {
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [color, setColor] = useState("#aabbcc");
  const [selectedDepthShadingIndex, setSelectedDepthShadingIndex] = useState<number | null>(null);

  const handleOpenEditColor = (color: string, index: number) => {
    const colorHex = rgbStringToHex(color);

    setSelectedDepthShadingIndex(index);
    setColor(colorHex);
    onOpen();
  };

  const handleSaveColor = (color: string, selectedIndex: number | null) => {
    if (selectedIndex === null) return;

    const colorRgb = hexToRgbString(color);
    const updatedColors = depthShadingToEdit?.depth_shader_items?.map(
      (item: depth_shading_items, index: number) =>
        index === selectedIndex ? { ...item, color: colorRgb } : item,
    );

    dispatch(
      setDepthShadingToEdit({
        ...depthShadingToEdit,
        depth_shader_items: updatedColors,
      }),
    );

    onClose();
  };

  const handleAddDepthColor = () => {
    dispatch(
      setDepthShadingToEdit({
        ...depthShadingToEdit,
        depth_shader_items: [
          ...depthShadingToEdit.depth_shader_items,
          {
            distance: 0,
            color: "51,167,255",
          },
        ],
      }),
    );
  };

  const handleDeleteDepthColor = (depthIndex: number) => {
    const filteredItems = depthShadingToEdit?.depth_shader_items?.filter(
      (item: depth_shading_items, index: number) => index !== depthIndex,
    );

    dispatch(
      setDepthShadingToEdit({
        ...depthShadingToEdit,
        depth_shader_items: filteredItems,
      }),
    );
  };

  const handleChangeDistance = (e: React.ChangeEvent<HTMLInputElement>, selectedIndex: number) => {
    if (selectedIndex === null) return;

    const updatedDistance = depthShadingToEdit?.depth_shader_items?.map(
      (item: depth_shading_items, index: number) =>
        index === selectedIndex ? { ...item, distance: e.target.value } : item,
    );

    dispatch(
      setDepthShadingToEdit({
        ...depthShadingToEdit,
        depth_shader_items: updatedDistance,
      }),
    );
  };

  return (
    <Box>
      <List sx={styles.listMainContainer}>
        <ListSubheader disableGutters disableSticky sx={styles.listItemSubHeader}>
          Colors
          <Box>
            <Button color="secondary" startIcon={<AddIcon />} onClick={handleAddDepthColor}>
              Add Color
            </Button>
          </Box>
        </ListSubheader>
        <ListItem sx={styles.listItemContainer}>
          <List sx={styles.listContainer}>
            {depthShadingToEdit?.depth_shader_items?.map(
              (item: depth_shading_items, index: number) => (
                <ListItem key={`items-${item.id + index}`} sx={styles.listItems}>
                  <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                    <TextField
                      InputProps={{
                        endAdornment: <InputAdornment position="start">ft</InputAdornment>,
                      }}
                      disabled={index === 0 && (item.distance === 0 || item.distance === "0")}
                      id="feet"
                      name="feet"
                      sx={styles.textField}
                      value={item.distance}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeDistance(e, index)
                      }
                    />
                    <Stack
                      direction="row"
                      sx={{
                        ...styles.overlay,
                        "&:before": { display: "none" },
                      }}
                    >
                      <Stack alignItems="center" direction="row" spacing={1} sx={{ width: "100%" }}>
                        <Box
                          sx={
                            item.color
                              ? { ...styles.colorBox, background: `rgb(${item.color})` }
                              : { ...styles.colorBox }
                          }
                        />
                        {index > 2 && (
                          <IconButton
                            className="closeIcon"
                            size="small"
                            onClick={() => handleDeleteDepthColor(index)}
                          >
                            <CloseIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        )}
                      </Stack>
                      <Tooltip arrow placement="top" title="Change Depth Shading">
                        <IconButton
                          className="editButton"
                          color="secondary"
                          size="small"
                          sx={styles.editButton}
                          onClick={() => handleOpenEditColor(item.color, index)}
                        >
                          <EditIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </ListItem>
              ),
            )}
          </List>
        </ListItem>
      </List>
      <AlertDialog
        cancelButtonTitle="Cancel"
        close={onClose}
        confirm={() => handleSaveColor(color, selectedDepthShadingIndex)}
        dialogContentStyles={{ p: 0 }}
        open={isOpen}
        positiveButtonTitle="Confirm"
        title="Select Color"
      >
        <Stack
          alignItems="center"
          component="section"
          justifyContent="center"
          sx={styles.colorPickerContainer}
        >
          <HexColorPicker color={color} onChange={setColor} />
          <Box mt={1.5}>
            <HexColorInput color={color} onChange={setColor} />
          </Box>
        </Stack>
      </AlertDialog>
    </Box>
  );
}

export default DepthColors;
