export const styles = {
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    pr: 3,
  },

  dialogNoTitle: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    px: 3,
    py: 2,
  },

  dialogActions: {
    px: 3,
    py: 2,
  },

  darkHeader: {
    "& .titleBox": {
      zIndex: 2,
      pr: 2,
    },

    "& .MuiIconButton-root": {
      color: "common.white",
    },
  },

  lightHeader: {},
};
