import { Box } from "@mui/material";

import { styles } from "./styles";

import { IMapPois } from "@/types/pois";
import PlaceInfo from "@/components/PlaceInfo";

interface ISelectedPoiDrawer {
  selectedPoi: IMapPois;
  onCreateOpenRoute: () => void;
}
function SelectedPoiDrawer({ selectedPoi, onCreateOpenRoute }: ISelectedPoiDrawer) {
  return (
    <Box sx={styles.scrollContainer}>
      <PlaceInfo isDrawer poiSelected={selectedPoi} onCreateOpenRoute={onCreateOpenRoute} />
    </Box>
  );
}

export default SelectedPoiDrawer;
