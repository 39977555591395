export const styles = {
  bulletList: {
    listStyleType: "disc",
    pl: 2,
    pt: 1,
    pb: 0,
    "& .MuiListItem-root": {
      display: "list-item",
      px: 0,
      pt: 0,
      pb: 0.75,
    },
  },
};
