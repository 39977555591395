import { alpha } from "@mui/material/styles";

import { layoutStyles } from "@/themes/layoutStyles";
import defaultTheme from "@/themes/theme";

export const styles = {
  PremiumDialogContent: {
    ...layoutStyles.scroll,
    mt: "-66px",
    px: 3,
    pt: 6,
    pb: 3,
    backgroundImage: `linear-gradient(180deg, ${defaultTheme.palette.primary.main}, ${defaultTheme.palette.primary.light})`,
  },

  NonUserDialogContent: {
    ...layoutStyles.scroll,
    mt: "-66px",
    px: 3,
    pt: 6,
    pb: 1,
    backgroundColor: "grey.200",
  },

  image: {
    width: "260px",
    height: "124px",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${"img/placeholders/GreetingIllustration.svg"})`,
  },

  text: {
    "& .MuiLink-root": {
      fontWeight: 900,
    },
  },

  dialogContent: {
    ...layoutStyles.scroll,
    mt: "-66px",
    px: 3,
    pt: 0,
  },

  dialogHeader: {
    display: "flex",
    height: "180px",
    width: "calc(100% + 48px)",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    backgroundImage: `url(${"img/premium-bg.png"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    p: 6,
    mx: -3,
  },

  dialogHeaderIcon: {
    color: "common.white",
    textAlign: "center",
    fontSize: 52,
    mb: 1,
    mt: 1,
  },

  dialogHeaderTitle: {
    color: "common.white",
    textAlign: "center",
    px: 4,
  },

  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    pt: 2,
    pb: 1,
    px: 0,
    color: "primary.main",
  },

  PremiumDialogActions: {
    px: 3,
    py: 2,
    borderTop: "1px solid",
    borderColor: "divider",
    backgroundColor: "primary.light",
    position: "relative",
    "& .MuiButton-text": {
      color: "primary.dark",
      zIndex: 2,
    },
    "& .MuiButton-contained": {
      backgroundColor: "primary.dark",
      zIndex: 2,
    },
    "&:before": {
      content: "''",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      position: "absolute",
      backgroundColor: `${alpha(defaultTheme.palette.common.black, 0.08)}`,
    },
  },

  LoggedDialogActions: {
    px: 3,
    py: 2,
    backgroundColor: `${alpha(defaultTheme.palette.primary.light, 0.04)}`,
    borderTop: "1px solid",
    borderColor: "divider",
  },

  NonUserDialogActions: {
    px: 3,
    py: 2,
    backgroundColor: "grey.200",
  },

  list: {
    listStyleType: "disc",
    pl: 2,
    pt: 0,
    pb: 1,
    "& .MuiListItem-root": {
      display: "list-item",
    },
  },

  mainButton: {
    mt: 1,
    fontSize: "16px",
  },

  dialogButton: {
    color: "primary.main",
    mt: 2,
  },
};
