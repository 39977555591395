import * as React from "react";
import { Paper, Box, Typography, Stack } from "@mui/material";

import { styles } from "./styles";

import BaseIcon from "@/components/BaseIcon";
import { TAlertInfo } from "@/components/ArgoMap/Markers/BridgesAlertMarkers/BridgesAlertMarkers";
import { metersToFeet } from "@/utils/route";

interface IBridgeWarningPopover {
  alertInfo: TAlertInfo;
}

export default function BridgeWarningPopover({ alertInfo }: IBridgeWarningPopover) {
  return (
    <Paper elevation={6} sx={styles.paper}>
      <Stack direction="row" spacing={1.5}>
        <Box sx={styles.image}>
          <BaseIcon icon={alertInfo.icon} sx={styles.alertMarker} />
        </Box>

        <Stack sx={styles.textContainer}>
          <Typography color="text.primary" variant="subtitle1">
            {alertInfo.title}
          </Typography>

          <Typography gutterBottom color={alertInfo.textColor} variant="body2">
            {alertInfo.text}
          </Typography>

          {alertInfo.alert.verclr > 0 && (
            <Typography color="text.secondary" variant="body2">
              Vert. Clearance: {metersToFeet(alertInfo.alert.verclr)?.toFixed(0)} ft
            </Typography>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
}
