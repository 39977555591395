import { SvgIcon } from "@mui/material";
import React, { ElementType } from "react";

interface IBaseIcon {
  icon?: ElementType<any>; // Change the type of icon prop to ElementType<any>
  src?: string;
  sx?: object;
  type?: string;
}

function BaseIcon({ icon, sx, type, src }: IBaseIcon) {
  if (type === "png") return <img alt="Icon" height={28} src={src} width={28} />;

  return <SvgIcon inheritViewBox component={icon || "svg"} sx={sx} />; // Provide a default value for the component prop
}

export default BaseIcon;
