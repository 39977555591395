import React from "react";
import { ListItem, ListItemIcon, ListItemText, Divider } from "@mui/material";

import { styles } from "./styles";

interface IPlaceInfoListItem {
  // eslint-disable-next-line no-undef
  icon?: JSX.Element;
  primary: string;
  secondary?: string;
  divider?: boolean;
}

export default function PlaceInfoListItem({
  icon,
  primary,
  secondary,
  divider,
}: IPlaceInfoListItem) {
  return (
    <>
      <ListItem sx={{ py: 1, pr: 3 }}>
        {icon && <ListItemIcon sx={styles.listItemIcon}>{icon}</ListItemIcon>}
        <ListItemText
          id="#"
          primary={primary}
          primaryTypographyProps={{
            variant: "body1",
          }}
          secondary={secondary}
        />
      </ListItem>
      {divider && <Divider />}
    </>
  );
}
