import React, { useEffect, useState, useRef } from "react";
import { Button, Stack, Box, Tooltip, DialogContent, DialogActions } from "@mui/material";
import { Cancel, ChevronLeft, ChevronRight, MoreHoriz } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

import SearchCategories from "../SearchCategories/SearchCategories";

// import SearchCategoriesOnMapButton from "./SearchCategoriesOnMapButton";
import { styles } from "./styles";

import { setUserOptionsFavorites } from "@/store/slices/user/actions";
import {
  PLACES_TYPE,
  AMENITIES_TYPE,
  PARTNERS_TYPE,
  // PLACES_KEY,
  // AMENITIES_KEY,
  // PARTNERS_KEY,
  TPOI_TYPE,
  FAVORITES_KEY,
} from "@/utils/poi";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import BaseModal from "@/components/Modals/BaseModal";
import useDisclosure from "@/hooks/useDisclosure";
import Slider from "@/components/Slider";

interface ISearchCategoriesOnMap {
  selectedCategoriesForSearch: TPOI_TYPE[] | null;
  onSetInputValue: (value: TPOI_TYPE | undefined, action: boolean, clearAll?: any) => void;
}

export default function SearchCategoriesOnMap({
  onSetInputValue,
  selectedCategoriesForSearch,
}: ISearchCategoriesOnMap) {
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [settingItem, setSettingItem] = useState<string | undefined>(undefined);
  const [myFavorites, setMyFavorites] = useState({
    items: [],
    categoryType: FAVORITES_KEY,
    title: "My Favorites",
  });

  const { userOptions, loading } = useAppSelector((state) => state.user);
  const sliderRef = useRef<any>(null);

  const handleSetFavorite = (item: TPOI_TYPE) => {
    // setSettingItem(item.value);
    if (myFavorites.items.some((element: TPOI_TYPE) => element.value === item.value)) {
      setMyFavorites({
        ...myFavorites,
        items: myFavorites.items.filter((e: TPOI_TYPE) => e.value !== item.value),
      });
    } else {
      setMyFavorites({
        ...myFavorites,
        items: [...myFavorites.items, item] as any,
      });
    }
  };

  const handleSavedFavoriteCategories = (favorites: string[]) => {
    const items: any = [];

    favorites?.forEach((category: string) => {
      const favoritedItem =
        PLACES_TYPE.find((element: TPOI_TYPE) => element.value === category) ||
        AMENITIES_TYPE.find((element: TPOI_TYPE) => element.value === category) ||
        PARTNERS_TYPE.find((element: TPOI_TYPE) => element.value === category);

      if (favoritedItem) items.push(favoritedItem);
    });

    return items;
  };

  useEffect(() => {
    const myFavoritesItems = {
      items: handleSavedFavoriteCategories(userOptions?.favorites),
      categoryType: FAVORITES_KEY,
      title: "My Favorites",
    };

    setMyFavorites(myFavoritesItems);
    if (!loading) {
      // setSettingItem(undefined);
    }
  }, [userOptions]);

  const handleClose = () => {
    setMyFavorites({
      ...myFavorites,
      items: handleSavedFavoriteCategories(userOptions?.favorites),
    });
    onClose();
  };

  const handleSaveFavorites = () => {
    dispatch(
      setUserOptionsFavorites({
        favorites: myFavorites.items.map((item: TPOI_TYPE) => item.value),
      }),
    );
    onClose();
  };

  const handleSelectCategory = (item: TPOI_TYPE, action: boolean) => {
    onSetInputValue(item, action);
  };

  const handlePrevButtonClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNextButtonClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <>
      <Stack alignItems="center" direction="row" spacing={1} sx={styles.wrapper}>
        <Button
          color="whiteBg"
          size="medium"
          sx={styles.roundedButton}
          variant="iconButtonContained"
          onClick={handlePrevButtonClick}
        >
          <ChevronLeft />
        </Button>
        <Box>
          <Box sx={styles.sliderContainer}>
            <Slider ref={sliderRef}>
              {handleSavedFavoriteCategories(userOptions?.favorites)
                .sort((a: TPOI_TYPE, b: TPOI_TYPE) => a.label.localeCompare(b.label))
                .map((item: TPOI_TYPE) => {
                  const selected = selectedCategoriesForSearch?.some(
                    (checkedItem: TPOI_TYPE) => checkedItem.value === item.value,
                  );

                  return (
                    <Box key={item.label} sx={{ mr: 1 }}>
                      <Button
                        color="whiteBg"
                        endIcon={selected ? <Cancel /> : undefined}
                        size="medium"
                        sx={selected ? styles.buttonSelected : styles.button}
                        type="button"
                        variant="contained"
                        onClick={() => handleSelectCategory(item, !selected)}
                      >
                        {item.label}
                      </Button>
                    </Box>
                  );
                })}
            </Slider>
          </Box>
        </Box>
        <Stack direction="row" spacing={1}>
          <Button
            color="whiteBg"
            size="medium"
            sx={styles.roundedButton}
            variant="iconButtonContained"
            onClick={handleNextButtonClick}
          >
            <ChevronRight />
          </Button>
          <Tooltip arrow placement="bottom" title="Favorite Categories">
            <Button
              color="whiteBg"
              size="medium"
              sx={styles.roundedButton}
              variant="iconButtonContained"
              onClick={onOpen}
            >
              <MoreHoriz />
            </Button>
          </Tooltip>
        </Stack>
      </Stack>
      <BaseModal
        ariaDescribedby="Favorite Categories"
        ariaLabelledby="Favorite Categories"
        open={isOpen}
        title="Favorite Categories"
        onClose={handleClose}
      >
        <DialogContent sx={styles.dialogContent}>
          <SearchCategories
            selectedCategories={myFavorites.items}
            setSelectedCategories={handleSetFavorite}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={!isOpen}
            type="button"
            variant="contained"
            onClick={handleSaveFavorites}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </BaseModal>
    </>
  );
}
