import dayjs from "dayjs";
import React from "react";

import { TVessel } from "@/types/vessel";
import { EMarkerPopoverPositions } from "@/types/marker";

/** Regex for lat or lng strings */
export const coordPartRegex = /^-?\d*(\.)?\d*/g;
export const coordPartRegexDD = /^-?\d{0,3}(\.\d{0,6})?$/g;
export const decimalDegreesFormatRegex = /^([-+]?\d*(?:\.\d+)?)\s*,\s*([-+]?\d*(?:\.\d+)?)?$/;
export const degreesDecimalsMinutesFormatRegex =
  /^(\d+)°?\s*([\d.]+)'?\s*([NSWE])?,?\s*(\d+)\s*°?\s*([\d.]+)'?\s*([NSWE])?$/;
export const degreesMinutesSecondsFormatRegex =
  /^(\d+)°(\d+)'([\d.]+)"\s*([NSWE]),?\s*(\d+)°(\d+)'([\d.]+)"\s*([NSWE])$/;

export const hemisphereParsing = (input?: string) => {
  if (input === "N" || input === "E") return 1;
  if (input === "S" || input === "W") return -1;

  return undefined;
};

export const isNaNZero = (value: any) => {
  if (Number.isNaN(value) || value === "0" || value === "0.00" || value === "0.0000") return "";

  return value;
};

export const decimalHandler = (value: string | number, nDecimals = 6) =>
  String(value).split(".")?.[1]?.length > nDecimals ? Number(value).toFixed(nDecimals) : value;

export const convertToLatLngFromDMS = (latitude?: any, longitude?: any) => {
  const lat =
    Math.trunc(Number(latitude?.degrees)) +
    Number(latitude?.minutes ?? 0) / 60 +
    Number(latitude?.seconds ?? 0) / 3600;

  const lng =
    Math.trunc(Number(longitude?.degrees)) +
    Number(longitude?.minutes ?? 0) / 60 +
    Number(longitude?.seconds ?? 0) / 3600;

  return { lat, lng };
};

export const convertToDMSFromLatLng = (latitude: number, longitude: number) => {
  const degreeLat = Math.trunc(latitude);
  const minutesLat = Number((latitude - Math.trunc(latitude)) * 60);
  const secondsLat = Number((minutesLat - Math.trunc(minutesLat)) * 60);

  const degreeLng = Math.abs(Math.trunc(longitude));
  const minutesLng = Number((longitude - Math.trunc(longitude)) * 60);
  const secondsLng = Number((minutesLng - Math.trunc(minutesLng)) * 60);

  return {
    lat: { degrees: degreeLat, minutes: minutesLat, seconds: secondsLat },
    lng: { degrees: degreeLng, minutes: minutesLng, seconds: secondsLng },
  };
};

/** Regex that allow just numbers and Dot. */
export const numbersRegex = /^[0-9.]+$/;
export const handleCheckInputs = (value: string) => {
  if (value === "" || /^[0-9.]*$/.test(value)) {
    return value;
  }
};

export function debounce(callback: any, wait: number) {
  let timerId: any;

  return (...args: any) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      callback(...args);
    }, wait);
  };
}

export function getFirstVessel(listItem: TVessel[]) {
  return listItem.slice(0, 1).shift();
}

export function convertTimeIsoToUs(isoDate?: string) {
  if (!isoDate) return "-";
  const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
  const date: Date = new Date(isoDate);

  return date.toLocaleDateString("en-US", options);
}

export const validateEmail = (email: string | undefined) => {
  let errors = { isRequired: "", format: "" };
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  if (!email) {
    errors = {
      ...errors,
      isRequired: "Email is required",
    };
  } else if (!regex.test(email)) {
    errors = {
      ...errors,
      format: "Invalid email format",
    };
  }

  return errors;
};

export function alphaToNumber(alpha: number) {
  const convertedOpacity = (100 * Number(alpha)) / 255;

  return convertedOpacity / 100;
}

export function numberToAlpha(value: number) {
  const convertedOpacity = (255 * (value * 100)) / 100;

  return `${Math.floor(convertedOpacity)}`;
}

export function snakeCaseToHumanReadable(text: string): string {
  // Split the text by underscores and capitalize each word
  if (!text) return "";
  const words = text.split("_").map((word) => {
    const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);

    return capitalizedWord;
  });

  // Join the words with spaces to form the human-readable text
  const humanReadableText = words.join(" ");

  return humanReadableText;
}

export const capitalizeWord = (text: string | undefined) => {
  if (!text) return "";

  return text.charAt(0).toUpperCase() + text.slice(1);
};

export function getTimeAgo(current: dayjs.Dayjs, previous: dayjs.Dayjs): string {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerWeek = msPerDay * 7;
  const elapsed = current.diff(previous);

  if (elapsed < msPerMinute) {
    return "Just now";
  }
  if (elapsed < msPerHour) {
    const value = Math.floor(elapsed / msPerMinute);

    return `${value} minute${value === 1 ? "" : "s"} ago`;
  }
  if (elapsed < msPerDay) {
    const value = Math.floor(elapsed / msPerHour);

    return `${value} hour${value === 1 ? "" : "s"} ago`;
  }
  if (elapsed < msPerWeek) {
    const value = Math.floor(elapsed / msPerDay);

    return `${value} day${value === 1 ? "" : "s"} ago`;
  }

  return `${previous.format("MMMM D, YYYY")} at ${previous.format("h:mm A")}`;
}

export const getPlaceType = (placeType: string | undefined): string => {
  if (!placeType) return "";
  if (placeType.includes("/")) {
    return placeType.replace("/", "");
  }

  return placeType;
};

export const setPopoverPosition = (e: React.MouseEvent<HTMLElement>) => {
  const popoverWidth = 380;
  const popoverHeight = 232;

  /* Function screen */
  // Get the window dimensions
  const windowWidth = window.innerWidth;
  // const windowHeight = window.innerHeight;

  // Calculate the position of the cursor relative to the window
  const cursorX = e.clientX;
  const cursorY = e.clientY;

  // Calculate the distance from the cursor to each edge of the window
  const distanceToTop = cursorY;
  // const distanceToBottom = windowHeight - cursorY;
  const distanceToLeft = cursorX;
  const distanceToRight = windowWidth - cursorX;

  // Determine the direction to open the popover based on the distances
  let popoverDirection: string = EMarkerPopoverPositions.Top;

  if (distanceToTop < popoverHeight) {
    popoverDirection = EMarkerPopoverPositions.Bottom;
  }

  if (distanceToRight < popoverWidth) {
    popoverDirection += "-left";
  } else if (distanceToLeft < popoverWidth) {
    popoverDirection += "-right";
  } else {
    popoverDirection += "";
  }

  return popoverDirection;
};

export function saveToLocalStorage(key: string, value: any) {
  try {
    // Convert the value to a JSON string
    const valueStr = JSON.stringify(value);

    // Save the string to local storage under the given key
    localStorage.setItem(key, valueStr);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Error saving to local storage", e);
  }
}

export function getFromLocalStorage(key: string) {
  try {
    // Get the value from local storage
    const valueStr = localStorage.getItem(key);

    // If the value was found, parse it from JSON and return it
    if (valueStr) {
      return JSON.parse(valueStr);
    }

    // If the value was not found, return null
    return null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Error reading from local storage", e);

    return null;
  }
}

export function removeFromLocalStorage(key: string) {
  try {
    // Remove the value from local storage
    localStorage.removeItem(key);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Error removing from local storage", e);
  }
}

export function extractLatLngZoom(
  param: string | null | undefined,
): { lat: number; lng: number; zoom: number } | null {
  if (!param) return null;
  const match = param.match(/@(-?\d+\.\d+),(-?\d+\.\d+),(\d+)z/);

  if (match) {
    return {
      lat: parseFloat(match[1]),
      lng: parseFloat(match[2]),
      zoom: parseInt(match[3], 10),
    };
  }

  return null;
}

export function rgbStringToHex(rgbString: string) {
  const [r, g, b] = rgbString.split(",").map(Number);

  const toHex = (value: number) => {
    const hex = value.toString(16);

    return hex.length === 1 ? `0${hex}` : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export function hexToRgbString(hex: string) {
  hex = hex.replace(/^#/, "");

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  return `${r},${g},${b}`;
}
