import React, { useEffect } from "react";
import { Divider, List, Stack, Button, Typography, Avatar } from "@mui/material";
import { Add, EditOutlined } from "@mui/icons-material";

import AffiliationListItem from "./AffiliationListItem";
import { styles } from "./styles";

import EditAffiliationsModal from "@/components/CollapsedProfile/EditAffiliationsModal";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import useUserAffiliations from "@/hooks/useUserAffiliations";
import { getAffiliations } from "@/store/slices/user/actions";
import { setFilters } from "@/store/slices/boaters";

export default function MapOptionsAffiliations() {
  const dispatch = useAppDispatch();
  const { logged } = useAppSelector((state) => state.auth);
  const { filters } = useAppSelector((state) => state.boaters);
  const { userAffiliations: affiliations } = useUserAffiliations();

  const [openAffiliationsModal, setOpenAffiliationsModal] = React.useState(false);

  const handleAffiliationsModalOpen = () => {
    setOpenAffiliationsModal(true);
  };

  const handleAffiliationsModalClose = () => {
    setOpenAffiliationsModal(false);
  };

  const handleSetAffiliationFilter = (affiliationId: number) => {
    if (filters.affiliationsId.includes(affiliationId)) {
      dispatch(
        setFilters({
          ...filters,
          affiliationsId: filters.affiliationsId.filter((id) => id !== affiliationId),
        }),
      );
    } else {
      dispatch(
        setFilters({ ...filters, affiliationsId: [...filters.affiliationsId, affiliationId] }),
      );
    }
  };

  useEffect(() => {
    if (logged) {
      dispatch(getAffiliations());
    }
  }, [logged]);

  const hasOwnAffiliations = affiliations.length > 0;

  return (
    <>
      <Divider />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        pl={2}
        pr={1}
        py={1}
      >
        <Typography component="h4" lineHeight="normal" variant="subtitle2">
          Filter by Affiliations
        </Typography>

        <Button
          color="secondary"
          startIcon={hasOwnAffiliations ? <EditOutlined /> : <Add />}
          onClick={handleAffiliationsModalOpen}
        >
          {hasOwnAffiliations ? "Edit Affiliations" : "Add Affiliations"}
        </Button>
      </Stack>
      <List disablePadding sx={styles.affiliationList}>
        {affiliations.map((item) => (
          <AffiliationListItem
            key={item.id}
            checked={filters.affiliationsId.includes(item.id)}
            name={item.name}
            onSetAffiliation={() => handleSetAffiliationFilter(item.id)}
          />
        ))}
      </List>
      <Divider />
      <Stack p={2}>
        <Typography gutterBottom variant="body2">
          Boaters with an affiliation the same as you will have a green border on their map profile
          icon
        </Typography>
        <Stack alignItems="center" direction="row" spacing={1}>
          <Typography color="text.secondary" variant="body2">
            Example:
          </Typography>

          <Avatar
            alt="Mike Harper"
            src="/img/placeholders/captainHatMarkerSquare.png"
            sx={styles.avatar}
          />
          <Avatar
            alt="Mike Harper"
            src="/img/placeholders/defaultUserMarkerSquare.png"
            sx={styles.avatar}
          />
        </Stack>
      </Stack>

      {openAffiliationsModal && (
        <EditAffiliationsModal close={handleAffiliationsModalClose} open={openAffiliationsModal} />
      )}
    </>
  );
}
