import { Stack, Box, Typography, Divider, CardMedia } from "@mui/material";

import { IMapPois } from "@/types/pois";
import PhotoModal from "@/components/Modals/PhotoModal";
import useDisclosure from "@/hooks/useDisclosure";

interface IPlaceInfoDescription {
  poiSelected: IMapPois;
}

function PlaceInfoDescription({ poiSelected }: IPlaceInfoDescription) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      {(poiSelected?.assets?.[0]?.url ||
        poiSelected?.assets?.[0]?.download_url ||
        poiSelected?.asset_links?.[0]) && (
        <Stack pb={poiSelected?.poi_type !== "Private Dock" ? 0 : 2} pt={2} px={2}>
          <CardMedia
            alt={`${poiSelected?.name}`}
            component="img"
            height="180"
            image={
              `${
                poiSelected?.assets?.[0]?.url ||
                poiSelected?.assets?.[0]?.download_url ||
                poiSelected?.asset_links[0]
              }` || "/img/placeholders/Image.png"
            }
            sx={{
              borderRadius: 0.5,
              border: "1px solid",
              borderColor: "divider",
              cursor: "pointer",
            }}
            onClick={onOpen}
          />
        </Stack>
      )}
      {poiSelected?.description && poiSelected?.poi_type !== "Private Dock" && (
        <Box p={2}>
          <Typography variant="body1">{poiSelected?.description}</Typography>
        </Box>
      )}
      {(poiSelected?.number_of_slips || poiSelected?.clean_marina) && (
        <Box p={2}>
          {poiSelected.number_of_slips && (
            <Typography variant="body1">
              Total Number of Slips: {poiSelected?.number_of_slips}
            </Typography>
          )}
          {poiSelected?.clean_marina && (
            <Typography variant="body1">Certified Clean Marina.</Typography>
          )}
        </Box>
      )}
      <Divider />
      <PhotoModal
        open={isOpen}
        url={`${poiSelected?.assets?.[0]?.url}` || "/img/placeholders/Image.png"}
        onClose={onClose}
      />
    </>
  );
}

export default PlaceInfoDescription;
