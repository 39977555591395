import * as React from "react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  IconButton,
  Stack,
  Rating,
  Box,
} from "@mui/material";

import { styles } from "./styles";

import { convertTimeIsoToUs } from "@/utils/globals";
import { IReviewPoi } from "@/types/pois";

interface IPlaceInfoReviewsListItem {
  review: IReviewPoi;
}
export default function PlaceInfoReviewsListItem({ review }: IPlaceInfoReviewsListItem) {
  return (
    <Box component="li" sx={styles.li}>
      <ListItem component="div" sx={styles.listItem}>
        <ListItemAvatar>
          <IconButton sx={styles.iconButton}>
            <Avatar
              alt={`${review?.user?.first_name} ${review?.user?.last_name}`}
              src={review?.user?.image}
              sx={styles.avatar}
            />
          </IconButton>
        </ListItemAvatar>
        <ListItemText
          primary={`${review?.user?.first_name} ${review?.user?.last_name}`}
          primaryTypographyProps={{
            component: "h4",
            variant: "subtitle2",
          }}
          secondary={
            <Stack direction="row" spacing={1}>
              <Rating
                readOnly
                defaultValue={review?.rating}
                name="rating-place"
                precision={0.5}
                size="small"
              />
              <Typography variant="caption">{convertTimeIsoToUs(review?.created_at)}</Typography>
            </Stack>
          }
          secondaryTypographyProps={{
            component: "div",
          }}
          sx={{ ml: 1.5 }}
        />
      </ListItem>
      <Box pb={1} px={2}>
        <Typography variant="body2">{review?.review}</Typography>
      </Box>
    </Box>
  );
}
