export const styles = {
  fabButton: {
    boxShadow: 3,
    backgroundColor: "background.paper",
    color: "primary.light",
    "& .MuiSvgIcon-root": {
      fontSize: 22,
    },
  },
};
