import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";

const formControlLabelBase = {
  mx: 0,
  width: "100%",
  border: "2px solid",
  borderRadius: 1,
  px: 2,
  py: 1.5,
  transition: defaultTheme.transitions.create(["all", "transform"], {
    duration: defaultTheme.transitions.duration.standard,
  }),
};

export const styles = {
  listItem: {
    py: 0.75,
  },

  mainButton: {
    mt: 1,
    fontSize: "15px",
    letterSpacing: 0.5,
  },

  radio: {
    ml: "-9px",
    p: 0,
    pr: "8px",
  },

  discountText: {
    color: `${alpha(defaultTheme.palette.common.black, 0.6)}`,
    typography: "body2",
    textDecorationLine: "line-through",
  },

  description: {
    ml: "20px",
  },

  formControlLabel: {
    ...formControlLabelBase,
    borderColor: "divider",
    "&:hover": {
      borderColor: `${alpha(defaultTheme.palette.primary.main, 0.5)}`,
      backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.04)}`,
    },
  },

  formControlLabelSelected: {
    ...formControlLabelBase,
    borderColor: `${alpha(defaultTheme.palette.primary.main, 0.5)}`,
    backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.04)}`,
    "&:hover": {
      borderColor: "primary.main",
      backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.04)}`,
    },
  },

  discountContainer: {
    position: "relative",
  },

  chip: {
    position: "absolute",
    top: -25.5,
    right: -4,
    minWidth: "80px",
    "& .MuiChip-label": {
      fontWeight: "bold",
      textOverflow: "unset",
    },
  },
};
