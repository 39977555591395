import { Fab, Box, Tooltip } from "@mui/material";
import { useState } from "react";

import { styles } from "./styles";

import BaseIcon from "@/components/BaseIcon";
import { ReactComponent as EditBoatIcon } from "&/img/icons/Union.svg";
import EditBoatModal from "@/components/CollapsedProfile/EditBoatModal";

function RouteOptions() {
  const [openEditBoatModal, setOpenEditBoatModal] = useState<boolean>(false);

  return (
    <Box>
      <Tooltip arrow placement="left" title="Edit Boat Information">
        <Fab size="small" sx={styles.fabButton} onClick={() => setOpenEditBoatModal(true)}>
          <BaseIcon icon={EditBoatIcon} />
        </Fab>
      </Tooltip>
      {openEditBoatModal && (
        <EditBoatModal close={() => setOpenEditBoatModal(false)} open={openEditBoatModal} />
      )}
    </Box>
  );
}

export default RouteOptions;
