import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";

export const styles = {
  likeButton: {
    minWidth: 54,
    color: "action.active",
    boxShadow: 1,
    "&:hover": {
      boxShadow: 3,
    },
    "&.Mui-active .MuiButton-startIcon": {
      color: "success.main",
    },
  },

  header: {
    px: 2,
    py: 1.5,
    "& .closeButton": {
      boxShadow: 4,
    },
  },

  dislikeButton: {
    minWidth: 54,
    boxShadow: 1,
    color: "action.active",
    "&:hover": {
      boxShadow: 3,
    },
    "&.Mui-active .MuiButton-startIcon": {
      color: "error.main",
    },
  },

  poiTitle: {
    lineHeight: "normal",
  },

  cardMedia: {
    borderRadius: 1,
    border: "1px solid",
    borderColor: "divider",
  },

  list: {
    "& .reportInfoListItem": {
      py: 0.5,
    },
    "& .MuiDivider-root": {
      display: "none",
    },
  },

  ownAvatar: {
    border: "2px solid",
    borderColor: "background.paper",
    boxShadow: 4,
  },

  addComment: {
    px: 2,
    py: 2.5,
    backgroundColor: "grey.50",
    "& .MuiIconButton-root": {
      mt: 0.5,
    },
  },

  dataFooter: {
    p: 2,
    backgroundColor: "grey.50",
  },

  mainContainer: {
    mt: 1,
    "& .coverImage": {
      position: "relative",
      minHeight: 160,
      backgroundSize: "cover",
      backgroundPosition: "center",
      "&:before": {
        content: "''",
        width: "100%",
        height: "100%",
        top: 0,
        position: "absolute",
        backgroundColor: `${alpha(defaultTheme.palette.common.black, 0.3)}`,
      },
    },
    "& .titleContainer": {
      mr: 4,
    },
  },

  mainContainerDrawer: {
    position: "relative",
    "& .coverImage": {
      position: "relative",
      minHeight: 200,
      backgroundSize: "cover",
      backgroundPosition: "center",
      "&:before": {
        content: "''",
        width: "100%",
        height: "100%",
        top: 0,
        position: "absolute",
        backgroundColor: `${alpha(defaultTheme.palette.common.black, 0.3)}`,
      },
    },
  },

  cornerActions: {
    position: "absolute",
    right: "12px",
    top: "12px",
    zIndex: 2,
  },

  cornerActionsDrawer: {
    position: "absolute",
    right: "18px",
    top: "80px",
    zIndex: 2,
  },
};
