import React from "react";
import { Stack, Typography, DialogContent, Button, Box, List } from "@mui/material";
import { RateReview } from "@mui/icons-material";

import { styles } from "./styles";

import ReportPinCommentListItem from "@/components/ReportPinInfo/ReportPinCommentListItem";
import { IPin, TReportPinReview } from "@/types/reportpins";

interface ICommentsViewModal {
  reportPinSelected: IPin;
  onSetModal: () => void;
}

function CommentsViewModal({ reportPinSelected, onSetModal }: ICommentsViewModal) {
  return (
    <>
      <Box sx={{ px: 3 }}>
        <Stack component="form" spacing={2}>
          <Box sx={styles.header}>
            <Typography gutterBottom component="h4" sx={styles.title} variant="h6">
              {reportPinSelected.name}
            </Typography>
            <Typography gutterBottom variant="body2">
              {reportPinSelected.description}
            </Typography>
            <Button
              fullWidth
              size="large"
              startIcon={<RateReview />}
              sx={{ mt: 2 }}
              variant="outlined"
              onClick={onSetModal}
            >
              Write a Comment
            </Button>
          </Box>
        </Stack>
        <Typography component="h4" py={2} variant="subtitle1">
          Comments
        </Typography>
      </Box>
      <DialogContent sx={{ mt: -1, pt: 0, px: 1 }}>
        <List disablePadding>
          {reportPinSelected?.reviews?.map((item: TReportPinReview) => (
            <ReportPinCommentListItem
              key={item.id}
              comment={item.message}
              time={item.created_at}
              userAvatar={item.user.image}
              userFirstName={item.user.first_name}
              userLastName={item.user.last_name}
            />
          ))}
        </List>
      </DialogContent>
    </>
  );
}

export default CommentsViewModal;
