import { combineReducers } from "@reduxjs/toolkit";

import auth from "./slices/auth";
import user from "./slices/user";
import map from "./slices/map";
import search from "./slices/search";
import route from "./slices/route";
import captainslog from "./slices/captainslog";
import premium from "./slices/premium";
import boaters from "./slices/boaters";
import reportpins from "./slices/reportpins";
import depthshading from "./slices/depthshading";
import mapParams from "./slices/mapParams";
import { argoApi } from "./api/argoApi";

import { store } from "@/store/store";

const rootReducer = combineReducers({
  auth,
  user,
  map,
  search,
  route,
  captainslog,
  premium,
  boaters,
  reportpins,
  depthshading,
  mapParams,
  [argoApi.reducerPath]: argoApi.reducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default rootReducer;
