import { alpha } from "@mui/material/styles";

import { layoutStyles } from "@/themes/layoutStyles";
import defaultTheme from "@/themes/theme";

export const styles = {
  wrapper: {
    position: "relative",
  },

  container: {
    m: 1.5,
    pr: 6,
    display: "inline-block",
  },

  chip: {
    border: "1px solid",
    color: "primary.main",
    backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.08)}`,

    "& .MuiSvgIcon-root": {
      fontSize: "18px",
      color: `${alpha(defaultTheme.palette.primary.main, 0.7)}`,
    },

    "&:hover": {
      "& .MuiSvgIcon-root": {
        color: "primary.main",
      },
    },
  },

  carouselContainerOpen: {
    position: "sticky",
    top: 0,
    backgroundColor: "background.paper",
    zIndex: 2,
    borderBottom: "1px solid",
    borderColor: "divider",
  },

  carouselContainer: {
    position: "relative",
  },

  carousel: {
    ...layoutStyles.scroll,
    overflowX: "auto",
  },

  buttonContainer: {
    position: "absolute",
    top: 8,
    right: 4,
    zIndex: 4,
    "&:before": {
      content: "''",
      position: "absolute",
      display: "block",
      width: "74px",
      height: "56px",
      background: `linear-gradient(90deg, ${alpha(
        defaultTheme.palette.common.white,
        0,
      )} 0%, ${alpha(defaultTheme.palette.common.white, 1)} 50%)`,
      left: -30,
      top: -8,
      zIndex: -1,
    },
  },

  openButton: {
    transition: defaultTheme.transitions.create(["all", "transform"], {
      duration: defaultTheme.transitions.duration.standard,
    }),
  },

  categoriesContainer: {
    boxShadow: "inset 0 1px 10px rgb(0 0 0 / 5%)",
  },
};
