import { Box, Fab, Stack, Tooltip, Typography, Button } from "@mui/material";
import { AddOutlined, RemoveOutlined, MyLocation } from "@mui/icons-material";
import { useMap } from "@vis.gl/react-google-maps";

import ActivateLocationModal from "../Modals/ActivateLocationModal/ActivateLocationModal";

import { styles } from "./styles";

import BaseModal from "@/components/Modals/BaseModal/BaseModal";
import useDisclosure from "@/hooks/useDisclosure";
import { MAX_ZOOM, MIN_ZOOM, CURRENT_POSITION_ZOOM } from "@/utils/maps";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { setCoords } from "@/store/slices/map";
import useGeoLocation from "@/hooks/useGeoLocation";

export default function MapZoomControls({ zoomControl, zoom }: any) {
  const dispatch = useAppDispatch();
  const map = useMap();
  const { location } = useGeoLocation();
  const { coords } = useAppSelector((state) => state.map);
  const handleZoomIn = () => {
    if (zoom < MAX_ZOOM) {
      zoomControl(zoom + 1.01);
    }
  };

  const handleZoomOut = () => {
    if (zoom > MIN_ZOOM) {
      zoomControl(zoom - 1.01);
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpenLearnMore = () => {
    if (coords) dispatch(setCoords(undefined));
    onOpen();
  };

  const handleSetPositionOnMarker = () => {
    if (location && map) {
      map.setZoom(CURRENT_POSITION_ZOOM);
      map.setCenter(location);
    }
  };

  return (
    <Stack spacing={1} sx={styles.zoomControlsContainer}>
      <Tooltip
        arrow
        placement="left"
        title={
          <>
            <Typography color="inherit" lineHeight="normal" variant="subtitle2">
              {location ? "Your Location" : "Activate your Location"}
            </Typography>
            <Button
              disableRipple
              color="info"
              sx={styles.textButton}
              variant="text"
              onClick={handleOpenLearnMore}
            >
              Learn More
            </Button>
          </>
        }
      >
        <Fab size="small" sx={styles.fabButton} onClick={handleSetPositionOnMarker}>
          <MyLocation />
        </Fab>
      </Tooltip>
      <Box sx={styles.zoomControls}>
        <Fab size="small" onClick={handleZoomIn}>
          <AddOutlined />
        </Fab>
        <Fab size="small" onClick={handleZoomOut}>
          <RemoveOutlined />
        </Fab>
      </Box>
      {isOpen && (
        <BaseModal
          ariaDescribedby="Activate your Location"
          ariaLabelledby="Activate your Location"
          open={isOpen}
          title="Activate your Location"
          onClose={onClose}
        >
          <ActivateLocationModal onClose={onClose} />
        </BaseModal>
      )}
    </Stack>
  );
}
