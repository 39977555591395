import { Drawer } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import React from "react";

interface IBaseDrawer {
  open: boolean;
  children: React.ReactNode;
  styles: SxProps<Theme> | undefined;
}
function BaseDrawer({ open, children, styles }: IBaseDrawer) {
  return (
    <Drawer anchor="left" open={open} sx={styles} variant="persistent">
      {children}
    </Drawer>
  );
}

export default BaseDrawer;
