import AdvancedMarker from "../AdvancedMarker";
import { getPrivatePlaceIcon } from "../Utils/PrivatePlacesIcons";
import { getReportPinIcon } from "../Utils/ReportPinsIcons";

import { ITempPin, PLACES_KEY } from "@/utils/poi";
import { IconKeysPrivatePlaces, IconKeysReportPins } from "@/types/markers";

interface IMyPin {
  pin: ITempPin;
}

function MyPin({ pin }: IMyPin) {
  const iconUrl = pin?.editPinInfo?.place_type || pin?.type === PLACES_KEY ? "pois" : "reportpins";
  const iconName = pin?.editPinInfo?.place_type
    ? pin?.editPinInfo?.place_type.replace("/", "")
    : pin?.value.replace("/", "");
  const iconPin =
    iconUrl === "pois"
      ? getPrivatePlaceIcon(iconName as IconKeysPrivatePlaces)
      : getReportPinIcon(iconName as IconKeysReportPins);

  return (
    <AdvancedMarker
      selected
      icon={iconPin}
      position={{ lat: Number(pin.location.lat), lng: Number(pin.location.lng) }}
      onClick={() => null}
    />
  );
}

export default MyPin;
