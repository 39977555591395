import React from "react";
import { Paper, Box, Typography, Stack, Avatar } from "@mui/material";
import dayjs from "dayjs";

import { styles } from "./styles";

import { ITideAndCurrent } from "@/types/tideandcurrents";

interface ITideAndCurrentsPopover {
  tideAndCurrent: ITideAndCurrent;
  icon: string;
  isTide: boolean;
  measureValue: { value: number | string; unit: string };
  grownToTop?: boolean;
}

export default function TideAndCurrentsPopover({
  tideAndCurrent,
  icon,
  isTide,
  measureValue,
  grownToTop,
}: ITideAndCurrentsPopover) {
  return (
    <Box>
      <Paper elevation={6} sx={grownToTop ? styles.paperToTop : styles.paper}>
        <Stack alignItems="center" direction="row" spacing={4}>
          <Box sx={styles.image}>
            <Avatar
              alt="Tide And Current Popover"
              src={icon}
              sx={
                isTide
                  ? styles.avatar
                  : { ...styles.avatar, transform: `rotate(${tideAndCurrent.direction || 0}deg)` }
              }
            />

            <Typography
              sx={isTide ? styles.avatarPrimaryTextTide : styles.avatarPrimaryTextCurrent}
              variant="body2"
            >
              {measureValue.value}
            </Typography>
            <Typography
              sx={isTide ? styles.avatarSecondaryTextTide : styles.avatarSecondaryTextCurrent}
              variant="body2"
            >
              {measureValue.unit === "KNOTS" ? "Knots" : measureValue.unit}
            </Typography>
          </Box>

          <Stack
            alignItems="flex-start"
            direction="column"
            justifyContent="flex-start"
            sx={styles.textContainer}
          >
            <Typography color="text.primary" sx={styles.textParagraph} variant="body2">
              {tideAndCurrent?.name}
            </Typography>

            <Typography color="text.primary" variant="subtitle1">
              {dayjs(tideAndCurrent?.measure_datetime).format("h:mm A")}
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
}
