import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Stack,
  Box,
  Typography,
  Grid,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormikProps } from "formik";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { LengthUnits, VolumeUnits, SpeedUnits, ShortVolumeUnits } from "@argonav/unit-system";

import EditHomePortModal from "./EditHomePortModal";
import { styles } from "./styles";

import Dropzone from "@/components/Dropzone";
import ToogleChip from "@/components/ToogleChip/ToogleChip";
import { BOAT_TYPE, TBOATTYPE } from "@/utils/boats";
import { TVessel } from "@/types/vessel";
import useDisclosure from "@/hooks/useDisclosure";
import { useAppSelector } from "@/hooks/useRedux";
import { capitalizeWord } from "@/utils/globals";

interface IEditBoatForm {
  formData: FormikProps<TVessel>;
  isEditing: boolean;
  onChangeBoatType: (value: string) => void;
}

const BYTE_SIZE_LIMIT = 2e6;

export default function EditBoatForm({ formData, isEditing, onChangeBoatType }: IEditBoatForm) {
  const [placeHolderSpeed, setPlaceHolderSpeed] = useState<string | null>("20");
  const depthUnit = useAppSelector((state) =>
    capitalizeWord(state.user.userOptions.length_unit?.toLowerCase()),
  );
  const speedUnitLabel = useAppSelector((state) => state.user.userOptions.speed_unit);
  const fuelUnit = useAppSelector((state) =>
    capitalizeWord(state.user.userOptions.volume_unit?.toLowerCase()),
  );

  const fuelUnitLabel =
    fuelUnit === VolumeUnits.GALLONS ? ShortVolumeUnits.GPHString : ShortVolumeUnits.LPHString;

  const depthUnitLabel = depthUnit === LengthUnits.FEET ? "ft" : "m";
  const [files, setFiles] = useState<File[]>([]);
  const { onOpen, onClose, isOpen } = useDisclosure();

  const handlePlaceHolderSpeed = () => {
    if (formData.values.boat_type === "power") setPlaceHolderSpeed("20");
    if (formData.values.boat_type === "jet_ski") setPlaceHolderSpeed("50");
    if (formData.values.boat_type === "sail") setPlaceHolderSpeed("5");
    if (formData.values.boat_type === "paddle") setPlaceHolderSpeed("3");
    if (formData.values.boat_type === "other") setPlaceHolderSpeed("10");
  };

  useEffect(() => {
    handlePlaceHolderSpeed();
  }, [formData]);

  const handelBoatImage = (files: File[]) => {
    setFiles([...files]);
    const imgFile = files[0];

    if (["image/jpeg", "image/png"].includes(imgFile?.type)) {
      if (imgFile.size < BYTE_SIZE_LIMIT) {
        formData.setFieldValue("image", imgFile);
      }
    } else {
      formData.setFieldValue("image", null);
    }
  };

  const handleRemoveHomePort = () => {
    formData.setFieldValue("port", null);
    formData.setFieldValue("port_name", null);
    formData.setFieldValue("lat", null);
    formData.setFieldValue("lng", null);
  };

  const handleOnChange = (field: string, value: string, valueType?: string) => {
    if (valueType === VolumeUnits.GALLONS) {
      if (formData.values.fuel_type === "electric") {
        formData.setFieldValue(field, Number(value).toFixed(1));
      } else {
        formData.setFieldValue(field, value);
      }
    } else if (valueType === SpeedUnits.KNOT) {
      formData.setFieldValue(field, value);
    } else {
      formData.setFieldValue(field, value);
    }
  };

  return (
    <>
      <Stack pt={1} spacing={2}>
        <FormControl sx={{ mb: -1 }}>
          <InputLabel htmlFor="name">Boat Name*</InputLabel>
          <OutlinedInput
            defaultValue={formData.values.name}
            error={formData.touched.name && Boolean(formData.errors.name)}
            id="name"
            label="Boat Name"
            onChange={formData.handleChange}
          />
          <FormHelperText id="name-text" sx={{ color: "error.main" }}>
            {formData.touched.name && formData.errors.name}
          </FormHelperText>
        </FormControl>
        <Box pb={1}>
          <Typography gutterBottom color="text.secondary" variant="body2">
            Boat Type*
          </Typography>
          <Grid container columnSpacing={1} rowSpacing={1.5}>
            {BOAT_TYPE.map((item: TBOATTYPE) => (
              <Grid key={item.value} item xs="auto" onClick={() => onChangeBoatType(item.value)}>
                <ToogleChip
                  isActive={item.value === formData.values.boat_type}
                  label={item.label}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <FormControl>
          <InputLabel htmlFor="manufacturer_model">Boat Manufacturer/Model</InputLabel>
          <OutlinedInput
            defaultValue={formData.values.manufacturer_model}
            id="manufacturer_model"
            label="Boat Manufacturer/Model"
            onChange={formData.handleChange}
          />
        </FormControl>
        <FormControl sx={styles.homeportInput}>
          <InputLabel htmlFor="home-port">Home Port</InputLabel>
          <OutlinedInput
            disabled
            endAdornment={
              <InputAdornment position="end">
                <Stack alignItems="center" direction="row" spacing={1}>
                  {formData.values.lat && formData.values.lng ? (
                    <Button
                      startIcon={<EditIcon sx={{ fontSize: 18 }} />}
                      variant="contained"
                      onClick={onOpen}
                    >
                      Edit
                    </Button>
                  ) : (
                    <Button
                      startIcon={<AddIcon sx={{ fontSize: 18 }} />}
                      variant="contained"
                      onClick={onOpen}
                    >
                      Add
                    </Button>
                  )}
                  {formData.values.lat && formData.values.lng && (
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      sx={{ marginLeft: ".5rem" }}
                      onClick={handleRemoveHomePort}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  )}
                </Stack>
              </InputAdornment>
            }
            id="home-port"
            label="Home Port"
            placeholder="Please add your Home Port"
            value={
              formData.values.port_name
                ? `${formData.values.port_name || `${formData.values.lat},${formData.values.lng}`}`
                : ``
            }
            onChange={formData.handleChange}
          />
        </FormControl>
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth>
            <InputLabel htmlFor="draft">Draft</InputLabel>
            <OutlinedInput
              defaultValue={isEditing ? formData.values.draft : undefined}
              endAdornment={<InputAdornment position="end">{depthUnitLabel}</InputAdornment>}
              error={formData.touched.draft && Boolean(formData.errors.draft)}
              id="draft"
              label="Draft"
              placeholder="5"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnChange("draft", e.target.value)
              }
            />
            <FormHelperText id="draft-text" sx={{ color: "error.main" }}>
              {formData.touched.draft && formData.errors.draft}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="buffer">Buffer</InputLabel>
            <OutlinedInput
              defaultValue={isEditing ? formData.values.buffer : undefined}
              endAdornment={<InputAdornment position="end">{depthUnitLabel}</InputAdornment>}
              id="buffer"
              label="Buffer"
              placeholder="5"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnChange("buffer", e.target.value)
              }
            />
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth>
            <InputLabel htmlFor="length">Length</InputLabel>
            <OutlinedInput
              defaultValue={isEditing ? formData.values.length : undefined}
              endAdornment={<InputAdornment position="end">{depthUnitLabel}</InputAdornment>}
              id="length"
              label="Length"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnChange("length", e.target.value)
              }
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="beam">Beam</InputLabel>
            <OutlinedInput
              defaultValue={isEditing ? formData.values.beam : undefined}
              endAdornment={<InputAdornment position="end">{depthUnitLabel}</InputAdornment>}
              id="beam"
              label="Beam"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnChange("beam", e.target.value)
              }
            />
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth>
            <InputLabel htmlFor="height">Height</InputLabel>
            <OutlinedInput
              defaultValue={isEditing ? formData.values.height : undefined}
              endAdornment={<InputAdornment position="end">{depthUnitLabel}</InputAdornment>}
              id="height"
              label="Height"
              placeholder="16"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnChange("height", e.target.value)
              }
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="average_speed">Est. Avg. Speed</InputLabel>
            <OutlinedInput
              defaultValue={isEditing ? formData.values.average_speed : undefined}
              endAdornment={
                <InputAdornment position="end">
                  {speedUnitLabel === "KNOT" ? "Knots" : speedUnitLabel}
                </InputAdornment>
              }
              id="average_speed"
              label="Est. Avg. Speed"
              placeholder={`${placeHolderSpeed}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnChange("average_speed", e.target.value, SpeedUnits.KNOT)
              }
            />
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Box sx={{ width: "100%" }}>
            <Typography color="text.secondary" variant="body2">
              Fuel/Power Type
            </Typography>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              defaultValue={formData.values.fuel_type}
              name="fuel_type"
              onChange={formData.handleChange}
            >
              <FormControlLabel control={<Radio />} label="Gas" value="gas" />
              <FormControlLabel control={<Radio />} label="Diesel" value="diesel" />
              <FormControlLabel control={<Radio />} label="Electric" value="electric" />
            </RadioGroup>
          </Box>

          <FormControl fullWidth>
            <InputLabel htmlFor="fuel_consumption">Fuel/Power Usage</InputLabel>
            <OutlinedInput
              defaultValue={isEditing ? formData.values.fuel_consumption : undefined}
              endAdornment={
                <InputAdornment position="end">
                  {formData.values.fuel_type === "electric" ? `kWh` : fuelUnitLabel}
                </InputAdornment>
              }
              id="fuel_consumption"
              label="Fuel/Power Usage"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnChange("fuel_consumption", e.target.value, VolumeUnits.GALLONS)
              }
            />
          </FormControl>
        </Stack>
        <Dropzone action={handelBoatImage} files={files} />
      </Stack>
      {isOpen && (
        <EditHomePortModal close={onClose} open={isOpen} onSetHomePort={formData.setFieldValue} />
      )}
    </>
  );
}
