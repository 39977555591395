import { DialogContent, Typography, DialogActions, Button } from "@mui/material";

interface IRouteWarningModal {
  firstOpen: boolean;
  onClose: () => void;
  onDontShowAgain: () => void;
}
function RouteWarningModal({ firstOpen, onClose, onDontShowAgain }: IRouteWarningModal) {
  return (
    <>
      <DialogContent sx={{ py: 0 }}>
        <Typography>Depth data not available in this area.</Typography>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        {firstOpen ? (
          <>
            {" "}
            <Button onClick={onClose}>Dismiss</Button>
            <Button
              autoFocus
              variant="contained"
              onClick={() => {
                onDontShowAgain();
                onClose();
              }}
            >
              Don’t Show Again
            </Button>
          </>
        ) : (
          <Button autoFocus variant="contained" onClick={onClose}>
            Ok
          </Button>
        )}
      </DialogActions>
    </>
  );
}

export default RouteWarningModal;
