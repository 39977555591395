export enum EFriendshipStatus {
  NonFriends = "none",
  Friends = "friends",
  Pending = "pending",
  Blocked = "blocked",
}

export enum EMapVisibility {
  Public = 4,
  FriendsOnly = 3,
  Private = 0,
}

export interface ISearchUsersServiceParams {
  sw_latitude: number | undefined;
  sw_longitude: number | undefined;
  ne_latitude: number | undefined;
  ne_longitude: number | undefined;
  page: number;
  page_total: number;
  kind: string;
  pagination_info: boolean;
  recent: boolean;
  location: string;
  poi_ids: string | undefined;
}
