import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import servicePins from "@/services/pins";
import { KEY_VOTE_DOWN, KEY_VOTE_UP } from "@/utils/keys";
import { AppDispatch } from "@/store/reducers";
import { IPin, TReportPinVote } from "@/types/reportpins";

const setReportPin = (value: IPin) => ({
  type: "reportPins/setReportPinSelectedDrawer",
  payload: value,
});

const handleDeleteVote = async (hasOwnVote: TReportPinVote, dispatch: AppDispatch) => {
  try {
    const { status } = await servicePins.removeReportPinVote(hasOwnVote.id);

    if (status === 200) {
      return true;
    }
  } catch (error: any) {
    dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));

    return false;
  }
};

const handleAddReportPinVote = async (pinId: number, voteType: string, dispatch: AppDispatch) => {
  try {
    const { status, data } = await servicePins.addReportPinVote(pinId, voteType);

    if (status === 200 || status === 201) {
      return data.data;
    }
  } catch (error: any) {
    dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));

    return null;
  }
};

export const handleCheckVoteDrawer =
  (reportPinSelected: IPin, voteType: string) => async (dispatch: AppDispatch) => {
    if (reportPinSelected.own_report_pin_vote) {
      if (voteType === reportPinSelected.own_report_pin_vote.vote_type) {
        const deletedVote = await handleDeleteVote(reportPinSelected.own_report_pin_vote, dispatch);

        if (deletedVote) {
          if (reportPinSelected.own_report_pin_vote.vote_type === KEY_VOTE_DOWN) {
            dispatch(
              setReportPin({
                ...reportPinSelected,
                own_report_pin_vote: null,
                pin_votes_down_count: reportPinSelected.pin_votes_down_count - 1,
              }),
            );
          } else if (reportPinSelected.own_report_pin_vote.vote_type === KEY_VOTE_UP) {
            dispatch(
              setReportPin({
                ...reportPinSelected,
                own_report_pin_vote: null,
                pin_votes_up_count: reportPinSelected.pin_votes_up_count - 1,
              }),
            );
          }
        }
      } else {
        const deletedVote = await handleDeleteVote(reportPinSelected.own_report_pin_vote, dispatch);

        if (deletedVote) {
          const addedVote = await handleAddReportPinVote(reportPinSelected.id, voteType, dispatch);

          if (addedVote) {
            if (voteType === KEY_VOTE_DOWN) {
              dispatch(
                setReportPin({
                  ...reportPinSelected,
                  own_report_pin_vote: addedVote,
                  pin_votes_up_count: reportPinSelected.pin_votes_up_count - 1,
                  pin_votes_down_count: reportPinSelected.pin_votes_down_count + 1,
                }),
              );
            } else {
              dispatch(
                setReportPin({
                  ...reportPinSelected,
                  own_report_pin_vote: addedVote,
                  pin_votes_down_count: reportPinSelected.pin_votes_down_count - 1,
                  pin_votes_up_count: reportPinSelected.pin_votes_up_count + 1,
                }),
              );
            }
          }
        }
      }
    } else {
      const addedVote = await handleAddReportPinVote(reportPinSelected.id, voteType, dispatch);

      if (addedVote) {
        if (voteType === KEY_VOTE_DOWN) {
          dispatch(
            setReportPin({
              ...reportPinSelected,
              own_report_pin_vote: addedVote,
              pin_votes_down_count: reportPinSelected.pin_votes_down_count + 1,
            }),
          );
        } else {
          dispatch(
            setReportPin({
              ...reportPinSelected,
              own_report_pin_vote: addedVote,
              pin_votes_up_count: reportPinSelected.pin_votes_up_count + 1,
            }),
          );
        }
      }
    }
  };

export const handleCheckVote =
  (reportPinSelected: IPin, voteType: string) => async (dispatch: AppDispatch) => {
    if (reportPinSelected.own_report_pin_vote) {
      if (voteType === reportPinSelected.own_report_pin_vote.vote_type) {
        const deletedVote = await handleDeleteVote(reportPinSelected.own_report_pin_vote, dispatch);

        if (deletedVote) {
          const { status, data } = await servicePins.getReportPinById(reportPinSelected.id);

          if (status === 200) return data.data;
        }
      } else {
        const deletedVote = await handleDeleteVote(reportPinSelected.own_report_pin_vote, dispatch);

        if (deletedVote) {
          const addedVote = await handleAddReportPinVote(reportPinSelected.id, voteType, dispatch);

          if (addedVote) {
            const { status, data } = await servicePins.getReportPinById(reportPinSelected.id);

            if (status === 200) return data.data;
          }
        }
      }
    } else {
      const addedVote = await handleAddReportPinVote(reportPinSelected.id, voteType, dispatch);

      if (addedVote) {
        const { status, data } = await servicePins.getReportPinById(reportPinSelected.id);

        if (status === 200) return data.data;
      }
    }
  };
