import { createSlice } from "@reduxjs/toolkit";

const currentDate = new Date();

const targetDate = new Date(2023, 10, 21);

const outDateOfTrialCalculated = !(currentDate.getTime() < targetDate.getTime());

export interface IPremium {
  isLoading: boolean;
  discountPercent: number;
  discount: number;
  outDateOfTrial: boolean;
}

export const initialState: IPremium = {
  isLoading: false,
  discountPercent: 0,
  discount: 0,
  outDateOfTrial: outDateOfTrialCalculated,
};

const premiumSlice = createSlice({
  name: "premiumSlice",
  initialState,
  reducers: {
    addDiscountPercent: (state, action) => {
      state.discountPercent = action.payload;
    },
    addDiscount: (state, action) => {
      state.discount = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { addDiscountPercent, addDiscount, setIsLoading } = premiumSlice.actions;

export default premiumSlice.reducer;
