/* global google */
import { useEffect, useState } from "react";

import BoatersList from "./BoatersList";

import { TRegionData } from "@/types/map";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import serviceBoaters from "@/services/boaters";
import { IFriends } from "@/types/users";
import { KEY_USER_FRIENDS, KEY_USER_NON_FRIENDS } from "@/utils/keys";
import { getRegionFromBounds } from "@/utils/maps";
import { useThrottle } from "@/hooks/useThrottle";

interface IBoaters {
  bounds: google.maps.LatLngBounds;
}

const BOATERS_POLLING_INTERVAL = 20000; // 20 secs

function Boaters({ bounds }: IBoaters) {
  const { logged } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [boatersItems, setBoatersItems] = useState<IFriends[]>([]);
  const [boatersItemsFriends, setBoatersItemsFriends] = useState<IFriends[]>([]);
  const region: TRegionData = getRegionFromBounds(bounds);
  const throttledRegion = useThrottle(region);

  const { filters } = useAppSelector((state) => state.boaters);

  const getUsersByZone = async (kind = "all") => {
    try {
      const { status, data } = await serviceBoaters.searchUsers({
        sw_latitude: throttledRegion.southWestLat,
        sw_longitude: throttledRegion.southWestLng,
        ne_latitude: throttledRegion.northEastLat,
        ne_longitude: throttledRegion.northEastLng,
        page: 1,
        page_total: 50,
        kind,
        pagination_info: false,
        recent: true,
        location: "custom",
        poi_ids: filters.affiliationsId.length > 0 ? filters.affiliationsId.join(",") : undefined,
      });

      if (status === 200) {
        if (kind === KEY_USER_FRIENDS) {
          setBoatersItemsFriends(data.data.items);
        } else {
          setBoatersItems(data.data.items);
        }
      }
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!logged) return null;
      if (filters.friends) {
        getUsersByZone(KEY_USER_FRIENDS);
      } else {
        setBoatersItemsFriends([]);
      }
      if (filters.others) {
        getUsersByZone(KEY_USER_NON_FRIENDS);
      } else {
        setBoatersItems([]);
      }
    }, BOATERS_POLLING_INTERVAL);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (logged) {
      if (filters.friends) {
        getUsersByZone(KEY_USER_FRIENDS);
      } else {
        setBoatersItemsFriends([]);
      }
      if (filters.others) {
        getUsersByZone(KEY_USER_NON_FRIENDS);
      } else {
        setBoatersItems([]);
      }
    }
  }, [filters, throttledRegion]);

  return (
    <>
      {boatersItems.length > 0 && <BoatersList items={boatersItems} />}
      {boatersItemsFriends.length > 0 && <BoatersList items={boatersItemsFriends} />}
    </>
  );
}

export default Boaters;
