import socialService from "./social";

import uploadAsset, { getFileName } from "@/utils/uploadAsset";

interface IAddActionProps {
  entityId: number;
  fileName: string;
  file: File;
}

interface IAssetAction {
  [key: string]: string | number | boolean | any;
}

/**
 * A map of add/remove assets by entity type
 * - add: ({ entityId, fileName }) => ...
 * - remove: ({ entityId, assetId }) => ...
 */
const actionByEntityType: IAssetAction = {
  userAvatar: {
    add: ({ entityId, fileName }: IAddActionProps) =>
      socialService.createAvatar({ userId: entityId, fileName }),
  },
  userCover: {
    add: ({ entityId, fileName }: IAddActionProps) =>
      socialService.createCover({ userId: entityId, fileName }),
  },
  userBoat: {
    add: ({ entityId, fileName }: IAddActionProps) =>
      socialService.createBoatImage({ vesselId: entityId, fileName }),
  },
  voyage: {
    add: ({ entityId, fileName }: IAddActionProps) =>
      socialService.createVoyageImage({ voyageId: entityId, fileName }),
  },
  places: {
    add: ({ entityId, fileName }: IAddActionProps) =>
      socialService.createPlacePinImage({ placepinId: entityId, fileName }),
  },
  reportpins: {
    addApi: ({ entityId, file }: IAddActionProps) =>
      socialService.createReportPinImage({
        reportpinId: entityId,
        file,
        fileName: getFileName(file),
      }),
  },
};

interface IAddProps {
  entityId: string;
  entityType: string;
  file: File;
  fileName: string | undefined;
}

/**
 * Adds an asset to an entity + uploads to s3
 * @param {Object} options
 * @param {string} options.entityId the entity id
 * @param {string} options.entityType the entity type
 * @param {File} options.file the file object
 * @param {string} options.fileName optional file name to store the file as, defaults to last segment of file uri path
 * @returns
 */
const add = async ({ entityId, entityType, file, fileName = getFileName(file) }: IAddProps) => {
  if (!entityType) throw new Error("Missing entityType");
  if (!file) throw new Error("Missing fileUri");

  const fn = actionByEntityType[entityType]?.add;

  if (!fn) {
    throw new Error(`Missing add function for entity type ${entityType}`);
  }

  const response = await fn({
    entityId,
    fileName,
  });

  // fire and forget
  await uploadAsset({ url: response.upload_url, file, fileName });

  return response;
};

const addApi = async ({ entityId, entityType, file }: IAddProps) => {
  if (!entityType) throw new Error("Missing entityType");
  if (!file) throw new Error("Missing fileUri");

  const fn = actionByEntityType[entityType]?.addApi;

  if (!fn) {
    throw new Error(`Missing add function for entity type ${entityType}`);
  }

  const response = await fn({
    entityId,
    file,
  });

  return response;
};

interface IRemoveProps {
  entityId: string;
  assetId: string;
  entityType: string;
}

const remove = async ({ entityId, assetId, entityType }: IRemoveProps) => {
  if (!entityId) throw new Error("Missing entityId");
  if (!assetId) throw new Error("Missing assetId");
  if (!entityType) throw new Error("Missing entityType");

  const fn = actionByEntityType[entityType]?.remove;

  if (!fn) {
    throw new Error(`Missing remove function for entity type ${entityType}`);
  }

  await fn({
    entityId,
    assetId,
  });

  // all delete asset endpoints return void, be nice and return more info about what's been deleted
  return { entityId, assetId, entityType };
};

const service = {
  add,
  addApi,
  remove,
};

export default service;
