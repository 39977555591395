import { useNavigate, useLocation } from "react-router-dom";
import { PersonOutline } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";

import { styles } from "./styles";

import TopBar from "@/components/TopBar";
import MenuPanel from "@/components/MenuPanel";
import { useAppSelector } from "@/hooks/useRedux";

const routesTitle = new Map([["/premium-payment", "Premium Payment"]]);

export default function UserBar() {
  const { user, logged, loading } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const title = routesTitle.get(location.pathname);

  if (loading) return null;

  return (
    <TopBar headerBg={title ? "#eeeeee" : ""}>
      <>
        {title && (
          <Stack alignItems="center" direction="row" ml={1} spacing={5} sx={styles.stack}>
            <Typography variant="h6">{title}</Typography>
          </Stack>
        )}
        {!logged && (
          <Button
            color="secondary"
            startIcon={<PersonOutline />}
            sx={{ flexShrink: 0 }}
            variant="contained"
            onClick={() => navigate("/login")}
          >
            Sign In
          </Button>
        )}

        <MenuPanel user={user} />
      </>
    </TopBar>
  );
}
