import { useState } from "react";
import { Typography, Button, Stack, List } from "@mui/material";
import { RateReview } from "@mui/icons-material";

import PlaceInfoReviewsListItem from "@/components/PlaceInfo/PlaceInfoSections/PlaceInfoReviews/PlaceInfoReviewsListItem";
import ReviewAddModal from "@/components/Modals/ReviewAddModal";
import ReviewsViewModal from "@/components/Modals/ReviewsViewModal";
import BaseModal from "@/components/Modals/BaseModal/BaseModal";
import { IMapPois } from "@/types/pois";
import EmptyState from "@/components/EmptyState";

interface IPlaceInfoReviews {
  poiSelected: IMapPois;
}

enum EReviwModalSteps {
  Reviews = "Reviews",
  Form = "Form",
}

export default function PlaceInfoReviews({ poiSelected }: IPlaceInfoReviews) {
  const [openReviewsViewModal, setOpenReviewsViewModal] = useState<boolean>(false);
  const [openReviewAddModal, setReviewAddModal] = useState<boolean>(false);
  const [reviewModalSteps, setReviewModalSteps] = useState<EReviwModalSteps>(
    EReviwModalSteps.Reviews,
  );

  const handleSetModalStep = () => {
    setReviewModalSteps(
      reviewModalSteps === EReviwModalSteps.Reviews
        ? EReviwModalSteps.Form
        : EReviwModalSteps.Reviews,
    );
  };

  const handleCloseReviewModal = () => {
    setOpenReviewsViewModal(false);
    setReviewModalSteps(EReviwModalSteps.Reviews);
  };

  if (!poiSelected) return null;

  return (
    <>
      <Typography component="h4" p={2} pb={1} variant="subtitle1">
        Reviews
      </Typography>
      {!poiSelected?.poi_ratings_count ? (
        <EmptyState
          imageURL="img/placeholders/EmptyStateRanking.svg"
          subtitle="There are no ratings or reviews yet. Would you like to write a review for this place?"
          sx={{ px: 4 }}
          title="No Reviews Yet"
        />
      ) : (
        <List disablePadding>
          {poiSelected?.poi_ratings &&
            poiSelected?.poi_ratings?.map((review: any) => (
              <PlaceInfoReviewsListItem key={review.id} review={review} />
            ))}
        </List>
      )}

      <Stack p={2} spacing={1}>
        <Button
          fullWidth
          startIcon={<RateReview />}
          variant="outlined"
          onClick={() => setReviewAddModal(true)}
        >
          Write a Review
        </Button>
        {poiSelected?.poi_ratings_count && poiSelected?.poi_ratings_count >= 3 ? (
          <Button fullWidth onClick={() => setOpenReviewsViewModal(true)}>
            See all reviews
          </Button>
        ) : null}
      </Stack>

      {openReviewsViewModal && (
        <BaseModal
          ariaDescribedby="Ratings & Reviews"
          ariaLabelledby="Ratings & Reviews"
          open={openReviewsViewModal}
          size="xs"
          title="Ratings & Reviews"
          onClose={handleCloseReviewModal}
          onGoBack={reviewModalSteps === EReviwModalSteps.Form ? handleSetModalStep : undefined}
        >
          {reviewModalSteps === EReviwModalSteps.Reviews ? (
            <ReviewsViewModal poiSelected={poiSelected} onSetModal={handleSetModalStep} />
          ) : (
            <ReviewAddModal poiSelected={poiSelected} onClose={handleCloseReviewModal} />
          )}
        </BaseModal>
      )}

      {openReviewAddModal && (
        <BaseModal
          ariaDescribedby="Rate & Review"
          ariaLabelledby="Rate & Review"
          open={openReviewAddModal}
          size="xs"
          title="Rate & Review"
          onClose={() => setReviewAddModal(false)}
        >
          <ReviewAddModal poiSelected={poiSelected} onClose={() => setReviewAddModal(false)} />
        </BaseModal>
      )}
    </>
  );
}
