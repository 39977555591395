import React from "react";
import {
  Stack,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  Box,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import * as yup from "yup";
import { useFormik } from "formik";

import { styles } from "./styles";

import ServiceAuth from "@/services/auth";
import PasswordInput from "@/components/PasswordInput";
import { TPasswordChange } from "@/types/auth";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { useAppDispatch } from "@/hooks/useRedux";

const formSchema = yup.object({
  currentPassword: yup.string().required("Current password is a required field"),
  password: yup
    .string()
    .required("Password is a required field")
    .min(8, "Use 8 or more characters")
    .matches(/[0-9]/, "Use at least one number")
    .matches(/[a-z]/, "Use at least one capital letter")
    .matches(/[A-Z]/, "Use at least one lower case letter"),
  passwordConfirm: yup
    .string()
    .required("Password is a required field")
    .oneOf([yup.ref("password"), null], 'Must match "Password" field value'),
});

interface IDeleteAccountModal {
  onClose: () => void;
}

function ChangePasswordModal({ onClose }: IDeleteAccountModal) {
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
      passwordConfirm: "",
    } as TPasswordChange,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      try {
        const { status } = await ServiceAuth.changePassword({
          current_password: values.currentPassword,
          password: values.password,
          password_confirmation: values.passwordConfirm,
        });

        if (status === 200) {
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Password change was successful",
            }),
          );
          onClose();
        }
      } catch (error: any) {
        dispatch(
          setSnackBarMsjSucceded({ state: true, type: "error", msj: error.response.data.message }),
        );
      }
    },
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <DialogContent>
        <Stack component="form" spacing={1}>
          <List
            subheader={
              <ListSubheader>
                <Typography variant="subtitle1">To create a secure password:</Typography>
              </ListSubheader>
            }
            sx={styles.passwordItems}
          >
            <ListItem>
              <ListItemIcon sx={styles.listItemIcon}>
                <CircleIcon sx={styles.icon} />
              </ListItemIcon>
              Use 8 or more characters
            </ListItem>
            <ListItem>
              {" "}
              <ListItemIcon sx={styles.listItemIcon}>
                <CircleIcon sx={styles.icon} />
              </ListItemIcon>
              Use at least one number
            </ListItem>
            <ListItem>
              {" "}
              <ListItemIcon sx={styles.listItemIcon}>
                <CircleIcon sx={styles.icon} />
              </ListItemIcon>
              Use at least one capital letter
            </ListItem>
            <ListItem>
              {" "}
              <ListItemIcon sx={styles.listItemIcon}>
                <CircleIcon sx={styles.icon} />
              </ListItemIcon>
              Use at least one lower case letter
            </ListItem>
          </List>
          <PasswordInput formikData={formik} label="Current Password" name="currentPassword" />
          <PasswordInput formikData={formik} label="New Password" name="password" />
          <PasswordInput
            formikData={formik}
            label="Reenter your new password"
            name="passwordConfirm"
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button autoFocus type="submit" variant="contained">
          Change Password
        </Button>
      </DialogActions>
    </Box>
  );
}

export default ChangePasswordModal;
