import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Typography,
  Radio,
  Stack,
  RadioGroup,
  FormControl,
  Switch,
  Box,
} from "@mui/material";

import { styles } from "./styles";

import { ERouteSettings } from "@/types/route";

interface IRouteOptionsForm {
  routeSettings?: ERouteSettings;
  onChangeSettings: (value: ERouteSettings) => void;
  hugShore: boolean;
  setHugShore: (value: boolean) => void;
}

function RouteOptionsForm({
  routeSettings,
  hugShore,
  setHugShore,
  onChangeSettings,
}: IRouteOptionsForm) {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="radio settings"
        name="route-settings"
        sx={styles.radioGroup}
        value={routeSettings}
      >
        <List disablePadding>
          <ListItem disablePadding divider>
            <ListItemButton
              selected={routeSettings === ERouteSettings.Default}
              sx={styles.listItemButton}
              onClick={() => onChangeSettings(ERouteSettings.Default)}
            >
              <ListItemText
                primary="Shortest"
                primaryTypographyProps={{
                  variant: "subtitle1",
                }}
                secondary="Path based on your boat's draft, buffer and height. Buffer provides a margin of safety above your draft."
                secondaryTypographyProps={{
                  variant: "body2",
                }}
              />
              <ListItemIcon>
                <Radio size="small" value={ERouteSettings.Default} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding divider>
            <ListItemButton
              selected={routeSettings === ERouteSettings.Avoid}
              sx={styles.listItemButton}
              onClick={() => onChangeSettings(ERouteSettings.Avoid)}
            >
              <ListItemText
                primary="Inside"
                primaryTypographyProps={{
                  variant: "subtitle1",
                }}
                secondary="Path that prioritizes inland waterways over open ocean."
                secondaryTypographyProps={{
                  variant: "body2",
                }}
              />
              <ListItemIcon>
                <Radio size="small" value={ERouteSettings.Avoid} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              selected={routeSettings === ERouteSettings.Outside}
              sx={styles.listItemButton}
              onClick={() => onChangeSettings(ERouteSettings.Outside)}
            >
              <Stack direction="column" sx={{ width: "100%" }}>
                <Stack alignItems="center" direction="row" justifyContent="space-between">
                  <ListItemText
                    primary="Outside"
                    primaryTypographyProps={{
                      variant: "subtitle1",
                    }}
                    secondary="Path that prioritizes open ocean versus inland waterways."
                    secondaryTypographyProps={{
                      variant: "body2",
                    }}
                  >
                    <Typography variant="body1">
                      Outside Route (Avoid Inland Waterways) (Coming soon)
                    </Typography>
                  </ListItemText>
                  <ListItemIcon>
                    <Box>
                      <Radio size="small" value={ERouteSettings.Outside} />
                    </Box>
                  </ListItemIcon>
                </Stack>
                <Stack alignItems="center" direction="row" justifyContent="space-between">
                  <ListItemText
                    secondary="Keep route close to shore"
                    secondaryTypographyProps={{
                      variant: "body2",
                    }}
                  />
                  <Switch
                    checked={hugShore}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setHugShore(event.target.checked)
                    }
                  />
                </Stack>
              </Stack>
            </ListItemButton>
          </ListItem>
        </List>
      </RadioGroup>
    </FormControl>
  );
}

export default RouteOptionsForm;
