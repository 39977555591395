/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

import { styles } from "./styles";
import InterestsAccordion from "./InterestsAccordion";

import { Interest } from "@/types/interest";
import ServiceUser from "@/services/user";
import { useAppSelector, useAppDispatch } from "@/hooks/useRedux";
import { fetchUserInterestsSucceded } from "@/store/slices/user";

function EditInterestsModal({ open, close }: any) {
  const dispatch = useAppDispatch();
  const [listInterestCopy, setListInterestCopy] = useState<[] | Interest[]>([]);
  const [listInterest, setListInterest] = useState<[] | Interest[]>([]);
  const { interests, userInformation } = useAppSelector((state) => state.user);

  const handleSetInterests = (item: Interest) => {
    const isExistentItem = interests.find((i) => i.id === item.id);

    if (isExistentItem) {
      dispatch(fetchUserInterestsSucceded(interests.filter((i) => i.id !== item.id)));
    } else {
      dispatch(fetchUserInterestsSucceded([...interests, item]));
    }
  };

  const handleGetInterests = async () => {
    try {
      const { status, data } = await ServiceUser.getAllInsterests();

      if (status === 200) setListInterest(data);
    } catch (error: any) {
      alert(error.toString());
    }
  };

  const handleSaveInterests = async () => {
    let itemsToSave: [] | Interest[] = [];
    let itemsToRemove: [] | Interest[] = [];
    let itemsToSend: [] | Interest[] = [];

    if (userInformation) {
      try {
        const { status: statusInterests, data: userInterests } = await ServiceUser.getInsterests(
          userInformation.id,
        );

        if (statusInterests === 200) {
          interests.forEach((item: Interest) => {
            if (!userInterests.some((ui: Interest) => ui.id === item.id)) {
              itemsToSave = [...itemsToSave, item];
            }
          });

          userInterests.forEach((item: Interest) => {
            if (!interests.some((i: Interest) => i.id === item.id)) {
              itemsToRemove = [...itemsToRemove, item];
            }
          });

          itemsToSend = [...itemsToRemove, ...itemsToSave];
          if (itemsToSend.length > 0) {
            itemsToSend.forEach(async (item: Interest) => {
              await ServiceUser.addUserInterests(item.id);
            });
          }
        }
        close();
      } catch (error: any) {
        alert(error.toString());
      }
    }
  };

  const handleCloseModal = () => {
    dispatch(fetchUserInterestsSucceded(listInterestCopy));
    close();
  };

  useEffect(() => {
    setListInterestCopy(interests);
  }, []);

  useEffect(() => {
    handleGetInterests();
  }, []);

  return (
    <Dialog
      aria-describedby="edit-boat-dialog-description"
      aria-labelledby="edit-boat-dialog-title"
      maxWidth="sm"
      open={open}
    >
      <DialogTitle id="alert-dialog-title" sx={styles.dialogTitle}>
        Edit Interests
        <IconButton size="small" onClick={handleCloseModal}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ ...styles.scrollContainer }}>
        <Stack spacing={2}>
          <InterestsAccordion
            listOfInterests={listInterest}
            selectedInterests={interests}
            onSetInterests={handleSetInterests}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Button onClick={handleCloseModal}>Cancel</Button>
        <Button autoFocus variant="contained" onClick={handleSaveInterests}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(EditInterestsModal);
