import { Box } from "@mui/material";

import NoaaHazardMarker from "./NoaaHazardMarker";

import { INOAAHazards } from "@/types/navaids";
import { TpoisConfigured } from "@/types/map";

interface IPrivatePlacesList {
  items: INOAAHazards[];
  poisConfigured: TpoisConfigured;
}

function NoaaHazardsList({ items, poisConfigured }: IPrivatePlacesList) {
  return (
    <Box>
      {items
        .filter((item: INOAAHazards) => {
          if (poisConfigured.rock && item.kind === "rock") return true;
          if (poisConfigured.obstruction && item.kind === "obstruction") return true;
          if (poisConfigured.exposed_wreck && item.icon === "exposed_wreck") return true;
          if (poisConfigured.dangerous_wreck && item.icon === "dangerous_wreck") return true;
          if (poisConfigured.non_dangerous_wreck && item.icon === "non_dangerous_wreck")
            return true;

          return false;
        })
        .map((item: INOAAHazards) => (
          <NoaaHazardMarker key={item.id} hazard={item} />
        ))}
    </Box>
  );
}

export default NoaaHazardsList;
