import axios from "./config";

import { IFeedbackForm } from "@/types/feedback";
import { EditUserInfoPayload } from "@/types/users";
import { poiIdList } from "@/types/affiliation";
import { TParamsLocation } from "@/components/PlaceInfo/PlaceInfo";

export default {
  getSearchHistory: () => axios.get("/user_search_history"),
  getFavorites: () => axios.get("/user_favorites"),
  getAffiliations: () =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v3+json" },
      method: "get",
      url: "/affiliations",
    }),
  getAffiliationsByUserId: (userId: number) =>
    axios.request({
      method: "get",
      url: `/users/${userId}/affiliations`,
      headers: { Accept: "application/vnd.argo.v2+json" },
    }),
  getAvatar: (userId: number) => axios.get(`/users/${userId}/avatar_image`),
  getCover: (userId: number) => axios.get(`/users/${userId}/cover_image`),
  getInsterests: (userId: number) => axios.get(`/users/${userId}/interests`),
  getAllInsterests: () => axios.get(`/interests`),
  addUserInterests: (id: number) => axios.post(`/interests/${id}/users`),
  getUser: (userId: number) => axios.get(`/users/${userId}`),
  getUserOptions: () =>
    axios.request({
      method: "get",
      url: `/api/v1/user_options`,
    }),
  editUserOptions: (data: any) =>
    axios.request({
      method: "put",
      url: `/api/v1/user_options`,
      data,
    }),
  editUser: (userPayload: EditUserInfoPayload) => axios.put("/auth", userPayload),
  searchAffiliations: (value: string, lat: string, lng: string) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v3+json" },
      method: "get",
      url: `/search?keyword=${value}&page=1&page_total=20&poi_type=Marina%2CYacht%20Club%2CClub%2FAssociation&location=anywhere&distance=3000&lat=${lat}&lng=${lng}`,
    }),
  addUserAffiliations: (poiId: number) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v3+json" },
      method: "post",
      url: `/affiliations/${poiId}/add_affiliation`,
    }),
  removeUserAffiliations: (poiList: poiIdList) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v3+json" },
      method: "post",
      url: `/affiliations/remove_affiliations`,
      data: poiList,
    }),
  sendFeedBack: (values: IFeedbackForm) => axios.post(`/feedback`, values),
  setSearchHistory: (id: number) => axios.put(`/user_search_history?poi_id=${id}`),
  setFavorite: (data: TParamsLocation) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v2+json" },
      method: "put",
      url: `/user_favorites`,
      params: data,
    }),
  removeFavorite: (data: TParamsLocation) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v2+json" },
      method: "delete",
      url: `/user_favorites`,
      params: data,
    }),
  deleteAvatar: (id: number) => axios.delete(`/users/${id}/avatar_image`),
  deleteCover: (id: number) => axios.delete(`/users/${id}/cover_image`),
};
