import { createSlice } from "@reduxjs/toolkit";

import { IDepthShading } from "@/types/map";

export interface IDrawersState {
  depthShadingDrawerOpen: boolean;
  depthShadingToEdit: IDepthShading | null;
}

const initialState: IDrawersState = {
  depthShadingDrawerOpen: false,
  depthShadingToEdit: null,
};

export const depthshadingSlice = createSlice({
  name: "depthshading",
  initialState,
  reducers: {
    setDepthShadingDrawerOpen: (state, action) => {
      state.depthShadingDrawerOpen = action.payload;
    },
    setDepthShadingToEdit: (state, action) => {
      state.depthShadingToEdit = action.payload;
    },
  },
});

export const { setDepthShadingDrawerOpen, setDepthShadingToEdit } = depthshadingSlice.actions;

export default depthshadingSlice.reducer;
