import {
  Stack,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { styles } from "./styles";

import { useAppDispatch } from "@/hooks/useRedux";
import servicePins from "@/services/pins";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { setReportPinSelectedDrawer } from "@/store/slices/reportpins";
import { IPin } from "@/types/reportpins";
import { snakeCaseToHumanReadable } from "@/utils/globals";

const formSchema = yup.object({
  comment: yup.string().required("Comment description is required."),
});

type TCommentPin = {
  comment: string;
};
interface ICommentAddModal {
  reportPinSelected: IPin;
  onClose: () => void;
}

function CommentAddModal({ reportPinSelected, onClose }: ICommentAddModal) {
  const dispatch = useAppDispatch();

  const handleSubmit = async (values: TCommentPin) => {
    try {
      const { status, data } = await servicePins.addCommentToPin(
        values.comment,
        "ReportPin",
        reportPinSelected.id,
      );

      if (status === 201) {
        if (reportPinSelected?.reviews) {
          dispatch(
            setReportPinSelectedDrawer({
              ...reportPinSelected,
              reviews: [data.data, ...reportPinSelected.reviews],
            }),
          );
        } else {
          dispatch(
            setReportPinSelectedDrawer({
              ...reportPinSelected,
              reviews: [data.data],
            }),
          );
        }

        dispatch(
          setSnackBarMsjSucceded({
            state: true,
            type: "success",
            msj: "Your comment was added.",
          }),
        );
        onClose();
      }
    } catch (error: any) {
      dispatch(
        setSnackBarMsjSucceded({
          state: true,
          type: "error",
          msj: error.response.data.message.toString(),
        }),
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      comment: "",
    } as TCommentPin,
    validationSchema: formSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Box sx={{ px: 3 }}>
        <Stack spacing={2}>
          <Box sx={styles.header}>
            <Typography gutterBottom component="h4" sx={styles.title} variant="h6">
              {reportPinSelected.name || snakeCaseToHumanReadable(reportPinSelected.kind)}
            </Typography>
            <Typography gutterBottom variant="body2">
              {reportPinSelected.description}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <DialogContent>
        <FormControl
          error={formik.touched.comment && Boolean(formik.errors.comment)}
          sx={{ width: "100%" }}
        >
          <TextField
            fullWidth
            multiline
            id="comment"
            label="Write your comment"
            minRows={4}
            name="comment"
            placeholder="Share your comment for this pin."
            size="small"
            onChange={formik.handleChange}
          />
          <FormHelperText id="name-text" sx={{ color: "error.main" }}>
            {formik.touched.comment && formik.errors.comment}
          </FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Box>
  );
}

export default CommentAddModal;
