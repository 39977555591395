import { getReportPinIcon } from "../Utils/ReportPinsIcons";

import ReportPinMarker from "./ReportPinMarker";

import { IconKeysReportPins } from "@/types/markers";
import { pinMarkerSubtypeHandler } from "@/utils/reportPinItems";
import { IPin } from "@/types/reportpins";
import { Position, TpoisConfigured } from "@/types/map";

interface IReportPinsList {
  items: IPin[];
  userPosition?: Position | null;
  poisConfigured: TpoisConfigured;
  clogpage: boolean;
}

function ReportPinsList({ items, userPosition, poisConfigured, clogpage }: IReportPinsList) {
  return (
    <>
      {items
        .filter((item: IPin) => {
          if (poisConfigured.no_wake_zone && item.kind === IconKeysReportPins.noWakeZone)
            return true;
          if (poisConfigured.map_issue && item.kind === IconKeysReportPins.mapIssue) return true;
          if (poisConfigured.local_advice && item.kind === IconKeysReportPins.localDevice)
            return true;
          if (poisConfigured.sos && item.kind === IconKeysReportPins.sos) return true;
          if (poisConfigured.police && item.kind === IconKeysReportPins.police) return true;
          if (poisConfigured.weather && item.kind === IconKeysReportPins.weather) return true;
          if (poisConfigured.hazard && item.kind === IconKeysReportPins.hazard) return true;
          if (poisConfigured.marine_life && item.kind === IconKeysReportPins.marineLife)
            return true;

          return false;
        })
        .map((pin: IPin) => (
          <ReportPinMarker
            key={pin.id}
            clogpage={clogpage}
            icon={getReportPinIcon(pinMarkerSubtypeHandler(pin) as IconKeysReportPins)}
            pin={pin}
            userPosition={userPosition}
          />
        ))}
    </>
  );
}

export default ReportPinsList;
