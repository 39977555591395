import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";

export const styles = {
  dialogActions: {
    px: 3,
    py: 2,
  },

  card: {
    border: "1px solid",
    borderColor: "divider",
    borderRadius: 1,
    minWidth: "400px",
    overflow: "hidden",
  },

  cardHeaderGas: {
    px: 2,
    py: 1.5,
    backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.08)}`,
    borderBottom: "1px solid",
    borderColor: "divider",
  },

  cardHeaderDiesel: {
    px: 2,
    py: 1.5,
    backgroundColor: `${alpha(defaultTheme.palette.secondary.main, 0.08)}`,
    borderBottom: "1px solid",
    borderColor: "divider",
  },

  list: {
    "& .MuiDivider-root:last-of-type": {
      display: "none",
    },
  },
};
