import React, { useCallback } from "react";
import { Paper, Box, Typography, Stack, Avatar } from "@mui/material";
import { useUnitSystem, LengthUnits } from "@argonav/unit-system";

import { styles } from "./styles";

import { capitalizeWord } from "@/utils/globals";
import { INOAAHazards } from "@/types/navaids";

interface IHazardPopover {
  hazard: INOAAHazards;
  icon: string;
  grownToTop?: boolean;
}

export default function HazardPopover({ hazard, icon, grownToTop }: IHazardPopover) {
  const { coordinatesFormatHandler, depthUnitHandler } = useUnitSystem();

  const formatVALSOU = useCallback(
    (VALSOU: string | undefined) => {
      if (!VALSOU) return undefined;
      const numberVALSOU = Number(VALSOU);

      if (numberVALSOU === 0) return undefined;
      const { value, unit } = depthUnitHandler({
        originalUnit: LengthUnits.METERS,
        originalValue: numberVALSOU,
      });

      return `${value} ${unit}`;
    },
    [depthUnitHandler],
  );

  const latLngFormatted = coordinatesFormatHandler({
    latitude: Number(hazard?.lat),
    longitude: Number(hazard?.lng),
  });

  let headlineValues;

  if (hazard?.kind === "obstruction") {
    headlineValues = [
      "Obstruction",
      hazard?.catobs,
      hazard?.natsur,
      hazard?.watlev,
      hazard?.name,
      formatVALSOU(hazard?.valsou),
      hazard?.status,
    ].filter((item) => item !== undefined && item !== null && item !== "");
  } else if (hazard?.kind === "rock") {
    headlineValues = [
      capitalizeWord(hazard?.kind),
      hazard?.natsur,
      hazard?.feature,
      hazard?.status,
    ].filter((item) => item !== undefined && item !== null && item !== "");
  } else {
    headlineValues = [
      hazard?.catwrk || hazard?.icon,
      formatVALSOU(hazard?.valsou),
      hazard?.watlev,
      hazard?.status,
    ].filter((item) => item !== undefined && item !== null && item !== "");
  }

  return (
    <Box>
      <Paper elevation={6} sx={grownToTop ? styles.paperToTop : styles.paper}>
        <Stack spacing={1}>
          <Stack direction="row" spacing={2}>
            <Box sx={styles.image}>
              <Avatar alt={hazard?.name} src={icon} sx={styles.reportPinIcon} />
            </Box>

            <Stack
              alignItems="flex-start"
              direction="column"
              justifyContent="flex-start"
              spacing={0.5}
              sx={styles.textContainer}
            >
              <Typography color="text.primary" sx={styles.textParagraph} variant="subtitle1">
                {headlineValues.toString().replaceAll(",", ", ")}
              </Typography>

              <Typography color="text.primary" sx={{ lineHeight: "1.1" }} variant="subtitle1">
                {hazard?.objname && hazard?.objname !== "undefined" && hazard?.objname}
              </Typography>
              <Typography color="text.primary" sx={{ lineHeight: "1.1" }} variant="subtitle1">
                {hazard?.inform && hazard?.inform !== "undefined" && hazard?.inform}
              </Typography>
              <Typography color="text.secondary" variant="body2">
                {latLngFormatted}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
}
