import { Stack, Slider, Typography } from "@mui/material";

import { styles } from "./styles";

interface IDepthBrightness {
  isDisabled: boolean;
  valueOpacity: number;
  onChange: (e: Event, newValue: number | number[]) => void;
  onChangeCommitted: () => void;
}

function DepthBrightness({
  isDisabled,
  valueOpacity,
  onChange,
  onChangeCommitted,
}: IDepthBrightness) {
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      spacing={2}
      sx={styles.sliderContainer}
    >
      <Typography
        color={isDisabled ? "text.disabled" : "text.primary"}
        lineHeight="normal"
        variant="subtitle2"
      >
        Brightness
      </Typography>
      <Slider
        aria-label="transparency"
        color="secondary"
        disabled={isDisabled}
        max={255}
        min={0}
        size="small"
        value={valueOpacity}
        onChange={onChange}
        onChangeCommitted={onChangeCommitted}
      />
    </Stack>
  );
}

export default DepthBrightness;
