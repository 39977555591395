import { Stack } from "@mui/material";
import React from "react";

import { styles } from "./styles";

interface ITopBar {
  headerBg?: string;
  children: React.ReactElement;
}
function TopBar({ headerBg, children }: ITopBar) {
  return (
    <Stack
      alignItems="center"
      className="topBar"
      component="header"
      direction="row"
      justifyContent="flex-end"
      spacing={1.5}
      sx={{ ...styles.stack, background: headerBg || "transparent" }}
    >
      {children}
    </Stack>
  );
}

export default TopBar;
