export const styles = {
  container: {
    position: "relative",
  },

  paper: {
    zIndex: 30,
    width: "360px",
    p: 2,
  },

  closeButton: {
    position: "absolute",
    right: "4px",
    top: "4px",
  },

  image: {
    flex: 1,
    display: "center",
    justifyContent: "center",
    pt: 1,
    "& img": {
      width: "48px",
    },
  },

  textContainer: {
    flex: 4,
  },
  alertMarker: {
    width: 48,
    height: 48,
  },
};
