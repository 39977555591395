import { createSlice } from "@reduxjs/toolkit";

import { ICourse, IVoyages, IVoyage } from "@/types/route";
import { Position } from "@/types/map";
import { DEFAULT_ZOOM } from "@/utils/maps";

export interface IRoutes {
  center: Position;
  zoom: number;
  isLoading: boolean;
  voyages: IVoyages | null;
  courses: ICourse[] | null;
  selectedVoyage: IVoyage | null;
  coursesCopy: ICourse[] | null;
  voyageEditMode: boolean;
  error: string | null;
}

const DEFAULT_CENTER = {
  lat: 37.783,
  lng: -122.403,
};

export const initialState: IRoutes = {
  center: DEFAULT_CENTER,
  zoom: DEFAULT_ZOOM,
  isLoading: false,
  voyages: null,
  selectedVoyage: null,
  coursesCopy: null,
  voyageEditMode: false,
  courses: null,
  error: null,
};

const captainsLogSlice = createSlice({
  name: "captainsLog",
  initialState,
  reducers: {
    fetchStarted: (state, action) => {
      state.isLoading = action.payload;
    },
    addVoyages: (state, action) => {
      state.voyages = action.payload;
    },
    addSelectedVoyage: (state, action) => {
      state.selectedVoyage = action.payload;
    },
    addCoursesCopy: (state, action) => {
      state.coursesCopy = action.payload;
    },
    setVoyageEditMode: (state, action) => {
      state.voyageEditMode = action.payload;
    },
    addCourses: (state, action) => {
      state.courses = action.payload;
    },
    addCenter: (state, action) => {
      state.center = action.payload;
    },
    addZoom: (state, action) => {
      state.zoom = action.payload;
    },
  },
});

export const {
  addVoyages,
  addSelectedVoyage,
  addCoursesCopy,
  addCourses,
  addCenter,
  addZoom,
  setVoyageEditMode,
} = captainsLogSlice.actions;

export default captainsLogSlice.reducer;
