import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Radio,
  RadioGroup,
  FormControl,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import { styles } from "./styles";

import { ReactComponent as PinsIcon } from "@/assets/img/icons/DropGrayPin.svg";
import { ReactComponent as FileExportIcon } from "@/assets/img/icons/FileExportOutlined.svg";
import BaseIcon from "@/components/BaseIcon";
import { KEY_ENTRY_GPX, KEY_ENTRY_PIN } from "@/components/Drawers/MyPinsDrawer/MyPinsDrawer";

interface INewEntryModal {
  entryValue: string;
  setEntryValue: (value: string) => void;
  onClose: () => void;
  onApply: () => void;
}

function NewEntryModal({ entryValue, setEntryValue, onClose, onApply }: INewEntryModal) {
  return (
    <>
      <DialogContent sx={{ pt: 0, pb: 0 }}>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            aria-labelledby="radio settings"
            name="coordinate-settings"
            sx={styles.radioGroup}
          >
            <List disablePadding sx={{ width: "100%" }}>
              <ListItem disablePadding divider>
                <ListItemButton
                  selected
                  sx={styles.listItemButton}
                  onClick={() => setEntryValue(KEY_ENTRY_PIN)}
                >
                  <BaseIcon icon={PinsIcon} sx={{ mr: 1.5 }} />
                  <ListItemText
                    primary="Create Pin"
                    primaryTypographyProps={{
                      variant: "body1",
                    }}
                  />
                  <ListItemIcon>
                    <Radio checked={entryValue === KEY_ENTRY_PIN} size="small" />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding divider>
                <ListItemButton
                  sx={styles.listItemButton}
                  onClick={() => setEntryValue(KEY_ENTRY_GPX)}
                >
                  <BaseIcon icon={FileExportIcon} sx={{ mr: 2 }} />
                  <ListItemText
                    primary="Import GPX Places File"
                    primaryTypographyProps={{
                      variant: "body1",
                    }}
                  />
                  <ListItemIcon>
                    <Radio checked={entryValue === KEY_ENTRY_GPX} size="small" />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </List>
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button autoFocus variant="contained" onClick={onApply}>
          Apply
        </Button>
      </DialogActions>
    </>
  );
}

export default NewEntryModal;
