import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import { styles } from "./styles";

export default function AlertDialog({
  title,
  message,
  open,
  close,
  confirm,
  cancelButtonTitle,
  positiveButtonTitle,
  removeCancelButton,
  dialogContentStyles,
  positiveButtonColor,
  positiveButtonDisabled,
  children,
}: any) {
  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      maxWidth="xs"
      open={open}
      onClose={close}
    >
      <DialogTitle id="alert-dialog-title" sx={styles.dialogTitle}>
        {title}
        <IconButton size="small" onClick={close}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={dialogContentStyles ? { ...dialogContentStyles } : styles.dialogContent}>
        {message ? (
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        ) : (
          children
        )}
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        {!removeCancelButton && <Button onClick={close}>{cancelButtonTitle || "Dismiss"}</Button>}
        <Button
          autoFocus
          color={positiveButtonColor || "primary"}
          disabled={positiveButtonDisabled}
          variant="contained"
          onClick={confirm}
        >
          {positiveButtonTitle || "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
