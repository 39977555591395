import { useNavigate } from "react-router-dom";

import LoginLayout from "../LoginLayout";
import ResetPasswordForm from "../RecoverPasswordForm";

import CustomizedSnackbar from "@/components/CustomizedSnackbar";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { setSnackBarMsj } from "@/store/slices/auth/actions";

function ResetPassword() {
  const navigate = useNavigate();
  const { snackBarMsj } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const handleSnackBarClose = () => {
    dispatch(setSnackBarMsj(false, "", ""));
  };

  return (
    <>
      <LoginLayout action={() => navigate("/login")}>
        <ResetPasswordForm />
      </LoginLayout>

      <CustomizedSnackbar
        close={handleSnackBarClose}
        message={snackBarMsj.msj}
        open={snackBarMsj.state}
        severity={snackBarMsj.type}
      />
    </>
  );
}

export default ResetPassword;
