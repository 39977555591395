import { Chip, Stack, Typography } from "@mui/material";
import Radio, { RadioProps } from "@mui/material/Radio";

import { styles } from "./styles";

function PremiumSubscriptionRadio(
  props: RadioProps & {
    subscriptionInfo: {
      price: string;
      period: string;
      discount?: string;
      discountChip?: string;
      description?: string;
    };
  },
) {
  const { subscriptionInfo } = props;

  return (
    <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
      <Stack alignItems="flex-start" direction="row" justifyContent="space-between">
        <Stack direction="column">
          <Stack alignItems="flex-start" direction="row" spacing={0}>
            <Radio disableRipple color="primary" size="small" {...props} sx={styles.radio} />
            <Typography variant="subtitle2">{subscriptionInfo.period}</Typography>
          </Stack>
          {subscriptionInfo.description && (
            <Typography color="text.secondary" sx={styles.description} variant="body2">
              {subscriptionInfo.description}
            </Typography>
          )}
        </Stack>
        <Stack alignItems="flex-end" sx={styles.discountContainer}>
          <Typography variant="subtitle2">{subscriptionInfo.price}</Typography>
          {subscriptionInfo.discount && (
            <>
              <Typography sx={styles.discountText}>{subscriptionInfo.discount}</Typography>
              <Chip
                color="primary"
                label={subscriptionInfo.discountChip}
                size="small"
                sx={styles.chip}
              />
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default PremiumSubscriptionRadio;
