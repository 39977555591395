import { Stack, Typography, Button, IconButton, Paper, Tooltip } from "@mui/material";
import { CloseOutlined, ContentCopy, TripOriginOutlined, Place, Lock } from "@mui/icons-material";
import Slide from "@mui/material/Slide";
import { useState } from "react";
import dayjs from "dayjs";
import { useUnitSystem } from "@argonav/unit-system";

import { styles } from "./styles";

import { RoutePosition } from "@/types/map";
import usePremiumUser from "@/hooks/usePremiumUser";
import BaseIcon from "@/components/BaseIcon";
import { ReactComponent as PinsIcon } from "&/img/icons/PinsIcon.svg";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { setQuickPinModal } from "@/store/slices/reportpins";
import servicePins from "@/services/pins";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { KEY_PMODAL_NON_USER, KEY_PMODAL_USER } from "@/utils/keys";
import { useGetQuickPinsQuery } from "@/store/api/argoApi";

type IContextMenuMap = {
  pointData?: RoutePosition;
  action: (inputType: number) => void;
  setCoords: (coords: RoutePosition | undefined) => void;
};

function ContextMenuMap({ pointData, action, setCoords }: IContextMenuMap) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const isPremium = usePremiumUser();
  const { coordinatesFormatHandler } = useUnitSystem();
  const { logged, user } = useAppSelector((state) => state.auth);
  const pmodalKey = logged && user ? KEY_PMODAL_USER + user.email : KEY_PMODAL_NON_USER;
  const pmodalData = localStorage.getItem(pmodalKey);
  const { refetch: refetchQuickPins } = useGetQuickPinsQuery({});

  const latLngFormatted = coordinatesFormatHandler({
    latitude: Number(pointData?.lat),
    longitude: Number(pointData?.lng),
  });

  const handleCopy = () => {
    navigator.clipboard.writeText(`${latLngFormatted}`);
    setOpen(true);
    setTimeout(() => setOpen(false), 2000);
  };

  const handleQuickPin = async () => {
    try {
      const { status } = await servicePins.createQuickPin({
        name: `${dayjs().format("YYYY-MM-DD hh:mm:ss A")}`,
        lat: pointData?.lat,
        lng: pointData?.lng,
      });

      if (status === 201) {
        refetchQuickPins();
        setCoords(undefined);
        if (!pmodalData) dispatch(setQuickPinModal(true));
      }
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
  };

  return (
    <Slide direction="up" in={!!pointData}>
      <Paper data-testid="context-map-menu" elevation={8} sx={styles.paper}>
        <Stack spacing={0.75}>
          <Stack alignItems="center" alignSelf="stretch" direction="row" display="flex" gap={0.25}>
            <Tooltip arrow open={open} placement="top" title="Copied to clipboard">
              <IconButton size="small" onClick={handleCopy}>
                <ContentCopy sx={{ fontSize: 18, color: "primary.main" }} />
              </IconButton>
            </Tooltip>
            <Typography
              noWrap
              component="p"
              flex="1 0 0"
              letterSpacing="0.24px"
              textAlign="center"
              variant="subtitle2"
            >
              {latLngFormatted}
            </Typography>
            <IconButton size="small" onClick={() => setCoords(undefined)}>
              <CloseOutlined sx={{ fontSize: 20 }} />
            </IconButton>
          </Stack>
          <Stack alignItems="center" direction="row" spacing={1}>
            <Button
              fullWidth
              className="buttonFrom"
              disabled={!isPremium}
              endIcon={isPremium ? <TripOriginOutlined /> : <Lock />}
              size="small"
              sx={styles.routeButton}
              variant="outlined"
              onClick={() => action(1)}
            >
              Route from
            </Button>
            <Button
              fullWidth
              className="buttonTo"
              disabled={!isPremium}
              endIcon={isPremium ? <Place /> : <Lock />}
              size="small"
              sx={styles.routeButton}
              variant="outlined"
              onClick={() => action(2)}
            >
              Route to
            </Button>
            <IconButton size="small" sx={styles.pinButton} onClick={handleQuickPin}>
              <BaseIcon icon={PinsIcon} />
            </IconButton>
          </Stack>
        </Stack>
      </Paper>
    </Slide>
  );
}

export default ContextMenuMap;
