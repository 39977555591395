import * as React from "react";
import { Paper, Box, Typography, Stack, IconButton, Button, Avatar, Tooltip } from "@mui/material";
import { Message, ThumbUpAlt, ThumbDownAlt } from "@mui/icons-material";
import { useUnitSystem, LengthUnits } from "@argonav/unit-system";

import { styles } from "./styles";

import dayjs from "@/config/dayjs";
import { getDistanceFromLocation } from "@/utils/poi";
import { IPin } from "@/types/reportpins";
import { Position } from "@/types/map";
import { KEY_VOTE_DOWN, KEY_VOTE_UP } from "@/utils/keys";
import { snakeCaseToHumanReadable } from "@/utils/globals";
import { IconKeysReportPins } from "@/types/markers";

interface IReportPinPopover {
  pin: IPin;
  icon: string;
  userPosition?: Position | null;
  inCaptainsLog: boolean;
  grownToTop?: boolean;
  showFullPinInformation: (e: React.MouseEvent<HTMLElement>) => void;
  onAddReportPinVote: (pinId: IPin, voteType: string) => void;
}

export default function ReportPinPopover({
  pin,
  icon,
  userPosition,
  inCaptainsLog,
  grownToTop,
  showFullPinInformation,
  onAddReportPinVote,
}: IReportPinPopover) {
  const previous: dayjs.Dayjs = dayjs(pin?.created_at);

  const categoryHandled = pin?.kind === IconKeysReportPins.seaLife ? "Sea Life" : pin?.kind;
  const { distanceUnitHandler, coordinatesFormatHandler } = useUnitSystem();

  const distanceAway = distanceUnitHandler({
    originalUnit: LengthUnits.METERS,
    originalValue: Number(
      getDistanceFromLocation(userPosition || { lat: 0, lng: 0 }, {
        lat: Number(pin?.lat),
        lng: Number(pin?.lng),
      }),
    ),
  });

  const latLngFormatted = coordinatesFormatHandler({
    latitude: Number(pin?.lat),
    longitude: Number(pin?.lng),
  });

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <Paper
        className="placePopover"
        elevation={6}
        sx={grownToTop ? styles.paperToTop : styles.paper}
      >
        <Stack spacing={1}>
          <Typography variant="caption">
            {dayjs(previous).fromNow()}{" "}
            {userPosition && `• ${distanceAway?.value} ${distanceAway?.unit} away`}
          </Typography>

          <Stack direction="row" spacing={2}>
            <Box sx={styles.image}>
              <Avatar
                alt={pin?.name}
                src={pin?.asset_links?.[0] || icon}
                sx={styles.reportPinIcon}
                variant={pin?.asset_links?.[0] ? "rounded" : "circular"}
              />
            </Box>

            <Stack sx={styles.textContainer}>
              <Typography
                gutterBottom
                color="text.primary"
                sx={{ cursor: "pointer", ...styles.ellipsedText }}
                variant="subtitle1"
                onClick={showFullPinInformation}
              >
                {pin?.name ||
                  (pin?.subtype
                    ? `${snakeCaseToHumanReadable(pin?.subtype)} ${snakeCaseToHumanReadable(
                        pin?.kind,
                      )}`
                    : snakeCaseToHumanReadable(categoryHandled))}
              </Typography>

              <Typography gutterBottom sx={styles.ellipsedText} variant="body2">
                {pin?.description || "No description"}
              </Typography>

              <Typography color="text.secondary" variant="body2">
                {latLngFormatted}
              </Typography>
              {!inCaptainsLog && (
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="flex-end"
                  pt={1}
                  spacing={2}
                >
                  <Stack direction="row" spacing={1}>
                    <Button
                      className="Mui-active"
                      color="whiteBg"
                      size="small"
                      startIcon={<ThumbUpAlt />}
                      sx={styles.likeButton}
                      variant="contained"
                      onClick={() => onAddReportPinVote(pin, KEY_VOTE_UP)}
                    >
                      {pin?.pin_votes_up_count}
                    </Button>
                    <Button
                      className="Mui-active"
                      color="whiteBg"
                      size="small"
                      startIcon={<ThumbDownAlt />}
                      sx={styles.dislikeButton}
                      variant="contained"
                      onClick={() => onAddReportPinVote(pin, KEY_VOTE_DOWN)}
                    >
                      {pin?.pin_votes_down_count}
                    </Button>
                  </Stack>

                  <Tooltip arrow placement="top" title="Show Comments">
                    <IconButton onClick={showFullPinInformation}>
                      <Message sx={{ fontSize: 20 }} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
}
