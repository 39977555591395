import useAuth from "./hooks/useAuth";

import PageRoutes from "@/routes";

function App() {
  const router = PageRoutes();
  const { loading } = useAuth();

  if (loading) return null;

  return router;
}

export default App;
