import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";

export const styles = {
  listItems: {
    pb: 0,
    pl: 0,
    pr: 0,
  },

  listItemContainer: {
    m: 0,
    p: 0,
  },

  listMainContainer: {
    pt: 0,
  },

  listContainer: {
    m: 0,
    p: 0,
    pr: 1,
    width: "100%",
  },

  textField: {
    maxWidth: "100px",
  },

  listItemSubHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  overlay: {
    position: "relative",
    width: "100%",
    "&:before": {
      content: "''",
      width: "374px",
      height: "60px",
      top: -12,
      left: -12,
      position: "absolute",
      borderRadius: "8px 8px 0px 0px",
      opacity: 0,
      zIndex: 1,
      background: `${alpha(defaultTheme.palette.common.black, 0.08)}`,
      transition: defaultTheme.transitions.create(["all", "transform"], {
        duration: defaultTheme.transitions.duration.standard,
      }),
    },
    "&:hover:before": {
      opacity: 1,
    },
  },

  editButton: {
    backgroundColor: "common.white",
    position: "absolute",
    left: "calc(50% - 17px)",
    boxShadow: 4,
    opacity: 0,
    transform: "translateY(20px)",
    transition: defaultTheme.transitions.create(["all", "transform"], {
      duration: defaultTheme.transitions.duration.standard,
    }),
    zIndex: 2,
    "&:hover": {
      backgroundColor: "common.white",
      opacity: 1,
    },
  },

  colorBox: {
    borderRadius: "100px",
    border: "1px solid var(--Light-Other-Divider, rgba(0, 0, 0, 0.12))",
    background: " #33A7FF",
    height: "56px",
    width: "100%",
    maxWidth: "100%",
    minWidth: "204px",
  },

  colorPickerContainer: {
    background: "var(--Blue-Gray-50, #ECEFF1)",
    py: 3,
  },
};
