import React from "react";
import { styled } from "@mui/system";
import { Divider, IconButton, Input, CircularProgress, Box, Typography } from "@mui/material";
import { SearchOutlined, CloseOutlined, ArrowBack, Directions } from "@mui/icons-material";
import Button, { ButtonProps } from "@mui/material/Button";

import { styles } from "./styles";

interface ISearchInput {
  onOpen: () => void;
  onClose: () => void;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCreateRoute: () => void;
  inputValue: string;
  isLoading: boolean;
  readyToClose: boolean;
  readyToBack: boolean;
  disabled: boolean;
}

const RouteButton = styled((props: ButtonProps) => <Button {...props} disableRipple />)(
  ({ theme }) => ({
    padding: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    maxWidth: "48px",
    minWidth: "48px",
    height: "100%",
    paddingTop: theme.spacing(0.5),
    transform: "scale(.96)",
    transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

    "& .MuiSvgIcon-root": {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "100px",
      color: theme.palette.primary.contrastText,
      fontSize: "24px",
      padding: theme.spacing(0.5),
      transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },

    "& .MuiTypography-caption": {
      marginTop: theme.spacing(0.25),
      lineHeight: "normal",
      width: "100%",
      fontWeight: 700,
      color: theme.palette.primary.main,
      transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },

    "&:hover": {
      "& .MuiTypography-caption": {
        color: theme.palette.primary.dark,
      },
      "& .MuiSvgIcon-root": {
        backgroundColor: theme.palette.primary.dark,
      },
      backgroundColor: "transparent",
      transform: "scale(1)",
    },

    "&:focus": {
      backgroundColor: "transparent",
    },
  }),
);

function SearchInput({
  onOpen,
  disabled,
  onClose,
  onSearch,
  onCreateRoute,
  inputValue,
  readyToClose,
  readyToBack,
  isLoading,
}: ISearchInput) {
  return (
    <>
      <Input
        disabled={disabled}
        placeholder="Search Argo"
        size="medium"
        startAdornment={
          readyToBack ? (
            <ArrowBack
              sx={styles.arrowBackIcon}
              onClick={() => {
                onClose();
                onOpen();
              }}
            />
          ) : (
            <SearchOutlined />
          )
        }
        sx={styles.input}
        type="text"
        value={inputValue}
        onChange={onSearch}
        onClick={onOpen}
      />
      <Divider orientation="vertical" sx={{ height: 32, m: 0.5 }} />

      {isLoading ? (
        <Box p={1}>
          <CircularProgress color="primary" size={24} />
        </Box>
      ) : !isLoading && readyToClose ? (
        <IconButton aria-label="directions" color="primary" onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      ) : (
        <RouteButton onClick={() => onCreateRoute()}>
          <Directions />
          <Typography component="span" variant="caption">
            Route
          </Typography>
        </RouteButton>
      )}
    </>
  );
}
export default SearchInput;
