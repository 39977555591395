export const styles = {
  marker: {
    zIndex: 1,
    p: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  avatarSmall: {
    width: 26,
    height: 26,
  },

  avatar: {
    width: 40,
    height: 40,
  },

  avatarPrimaryText: {
    top: 8.5,
    position: "absolute",
    fontSize: 10,
    color: "black",
    fontFamily: "sans-serif",
    fontWeight: "bold",
  },

  avatarPrimaryTextCurrent: {
    position: "absolute",
    fontSize: 10,
    color: "black",
    fontFamily: "sans-serif",
    fontWeight: "bold",
  },

  avatarSecondaryText: {
    bottom: 11.7,
    position: "absolute",
    fontSize: 6,
    color: "white",
    fontWeight: 700,
  },

  selectedMarker: {
    p: 0,
    position: "relative",
    bottom: "32px",
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
    },
    "&:before": {
      content: "''",
      width: "48px",
      height: "74px",
      top: -8,
      left: -8,
      position: "absolute",
      backgroundImage: `url(${"/img/markers/common/markerSelectedBg.svg"})`,
    },
  },
};
