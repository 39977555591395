import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab, Typography, Stack, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { styles } from "./styles";
import PinsList from "./PinsList";

import servicePins from "@/services/pins";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import CaptainsLogListSkeleton from "@/components/CaptainsLogList/CaptainsLogListSkeleton";
import { addSelectedPoi } from "@/store/slices/search";
import { setReportPinSelectedDrawer } from "@/store/slices/reportpins";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import NewEntryModal from "@/components/Modals/MyPinsModals/NewEntryModal";
import useDisclosure from "@/hooks/useDisclosure";
import BaseModal from "@/components/Modals/BaseModal";
import PinsModal from "@/components/Pins/PinsModal";
import ImportGpxModal from "@/components/Modals/MyPinsModals/ImportGpxModal";

export const KEY_ENTRY_PIN = "pin";
export const KEY_ENTRY_GPX = "gpx";
const BYTE_SIZE_LIMIT = 2e6;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      aria-labelledby={`vertical-tab-${index}`}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      role="tabpanel"
      {...other}
      sx={{ width: "100%" }}
    >
      {value === index && <Box sx={{ pl: 0 }}>{children}</Box>}
    </Box>
  );
}

function MyPinsDrawers() {
  const dispatch = useAppDispatch();
  const {
    onClose: onCloseNewEntry,
    onOpen: onOpenNewEntry,
    isOpen: isOpenNewEntry,
  } = useDisclosure();
  const {
    onClose: onClosePinsModal,
    onOpen: onOpenPinsModal,
    isOpen: isOpenPinsModal,
  } = useDisclosure();
  const {
    onClose: onCloseGpxModal,
    onOpen: onOpenGpxModal,
    isOpen: isOpenGpxModal,
  } = useDisclosure();
  const [newEntry, setNewEntry] = useState(KEY_ENTRY_GPX);
  const [loading, setLoading] = useState(false);
  const [places, setPlaces] = useState(undefined);
  const [reportpins, setReportPins] = useState(undefined);
  const [quickPins, setQuickPins] = useState(undefined);
  const [files, setFiles] = useState<File[]>([]);
  const { favorites, userInformation } = useAppSelector((state) => state.user);
  const { selectedPoi } = useAppSelector((state) => state.search);
  const { reportPinSelectedDrawer, myPinsDrawer } = useAppSelector((state) => state.reportpins);
  const [value, setValue] = useState(
    myPinsDrawer?.type === "places" ? 0 : myPinsDrawer?.type === "reportpins" ? 1 : 2,
  );

  const fetchData = async () => {
    try {
      const { status: placesStatus, data: placesData } = await servicePins.getPlaces({
        user_id: userInformation?.id,
        order: "updated_at_desc",
        page_total: 100,
      });
      const { status, data } = await servicePins.getReportPinsByZone({
        user_id: userInformation?.id,
        page_total: 100,
      });

      const { status: statusQuickPins, data: dataQuickPins } = await servicePins.getQuickPins({});

      if (placesStatus === 200) {
        setPlaces(placesData?.data?.items);
      }

      if (status === 200) {
        setReportPins(data?.data?.items);
      }

      if (statusQuickPins === 200) {
        setQuickPins(dataQuickPins?.data?.items);
      }
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
  };

  useEffect(() => {
    if (!selectedPoi) fetchData();
  }, [selectedPoi, favorites]);

  useEffect(() => {
    if (!selectedPoi) fetchData();
  }, [myPinsDrawer]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 1) {
      if (selectedPoi) dispatch(addSelectedPoi(null));
    } else if (newValue === 2) {
      if (selectedPoi) dispatch(addSelectedPoi(null));
      if (reportPinSelectedDrawer) dispatch(setReportPinSelectedDrawer(null));
    } else if (newValue === 0) {
      if (selectedPoi) dispatch(addSelectedPoi(null));
      if (reportPinSelectedDrawer) dispatch(setReportPinSelectedDrawer(null));
    }
    setValue(newValue);
  };

  const handleOpenAdd = () => {
    onOpenNewEntry();
  };

  const handleApplyEntry = () => {
    onCloseNewEntry();
    if (newEntry === KEY_ENTRY_PIN) {
      onOpenPinsModal();
    } else {
      onOpenGpxModal();
    }
  };

  const handelUploadGpxFile = (files: File[]) => {
    setFiles([...files]);
    // const { status } = pinsService.uploadGpxPlaces(files[0]);
    const imgFile = files[0];

    if ([".gpx"].includes(imgFile?.type)) {
      if (imgFile.size < BYTE_SIZE_LIMIT) {
        setFiles([...files]);
      }
    }
  };

  const handleSaveGpxFile = async () => {
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("file", files[0]);

      const { status } = await servicePins.uploadGpxPlaces(formData);

      if (status === 200) {
        dispatch(
          setSnackBarMsjSucceded({
            state: true,
            type: "success",
            msj: "GPX file was imported successfully",
          }),
        );
        fetchData();
        setFiles([]);
        onCloseGpxModal();
      }
      setLoading(false);
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      setLoading(false);
    }
  };

  return (
    <Stack sx={styles.drawerContainer}>
      <Typography pl={2} pt={2} variant="h5">
        My Pins
      </Typography>
      <Tabs
        aria-label="icon position tabs example"
        sx={styles.tabs}
        value={value}
        variant="fullWidth"
        onChange={handleChange}
      >
        <Tab label="Places" {...a11yProps(0)} />
        <Tab label="Reports" {...a11yProps(1)} />
        <Tab label="Pins" {...a11yProps(2)} />
      </Tabs>
      <TabPanel index={0} value={value}>
        {places ? (
          <Box sx={styles.scrollContainer}>
            <PinsList items={places} type="places" />
            <IconButton sx={styles.addPlacesButton} onClick={handleOpenAdd}>
              <AddIcon />
            </IconButton>
          </Box>
        ) : (
          <CaptainsLogListSkeleton />
        )}
      </TabPanel>
      <TabPanel index={1} value={value}>
        {reportpins ? (
          <Box sx={styles.scrollContainer}>
            <PinsList items={reportpins} type="reportpins" />
          </Box>
        ) : (
          <CaptainsLogListSkeleton />
        )}
      </TabPanel>
      <TabPanel index={2} value={value}>
        <Box sx={styles.scrollContainer}>
          <PinsList items={quickPins} type="quickpins" />
        </Box>
      </TabPanel>

      {isOpenNewEntry && (
        <BaseModal
          ariaDescribedby="New Entry"
          ariaLabelledby="New Entry"
          icon={<AddIcon />}
          open={isOpenNewEntry}
          size="xs"
          title="New Entry"
          onClose={onCloseNewEntry}
        >
          <NewEntryModal
            entryValue={newEntry}
            setEntryValue={setNewEntry}
            onApply={handleApplyEntry}
            onClose={onCloseNewEntry}
          />
        </BaseModal>
      )}
      {isOpenGpxModal && (
        <BaseModal
          ariaDescribedby="Import GPX Places File"
          ariaLabelledby="Import GPX Places File"
          open={isOpenGpxModal}
          size="xs"
          title="Import GPX Places File"
          onClose={onCloseGpxModal}
        >
          <ImportGpxModal
            files={files}
            loading={loading}
            onClose={onCloseGpxModal}
            onSave={handleSaveGpxFile}
            onUploadFile={handelUploadGpxFile}
          />
        </BaseModal>
      )}
      {isOpenPinsModal && (
        <PinsModal
          isOpen={isOpenPinsModal}
          pinsType="both"
          title="Create a Pin"
          onClose={onClosePinsModal}
        />
      )}
    </Stack>
  );
}

export default MyPinsDrawers;
