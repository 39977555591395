import { useSelector } from "react-redux";

import { useAppDispatch, useAppSelector } from "./useRedux";

import { RootState } from "@/store/reducers";
import { EditUserInfoPayload, UserInformation } from "@/types/users";
import {
  createAvatar,
  createCover,
  updateUser,
  createBoatImage,
  createPlacePinImage,
  deleteAvatar,
  deleteCover,
  createVoyageImage,
  createReportPinImage,
} from "@/store/slices/user/actions";

const useUserInformation = () => {
  const dispatch = useAppDispatch();
  const userInformation: UserInformation | undefined = useAppSelector(
    (state) => state.user.userInformation,
  );

  const userError: string = useSelector((state: RootState) => state.user.error);

  const editUser = (user: EditUserInfoPayload) => dispatch(updateUser(user));
  const uploadAvatar = (file: File) => dispatch(createAvatar(file));
  const uploadCover = (file: File) => dispatch(createCover(file));
  const uploadBoatImage = (file: File, boatId: number) => dispatch(createBoatImage(file, boatId));
  const uploadPlacePinImage = (file: File, placepinId: number) =>
    dispatch(createPlacePinImage(file, placepinId));
  const uploadReportPinImage = (file: File, reportpinId: number) =>
    dispatch(createReportPinImage(file, reportpinId));
  const uploadVoyageImage = (file: File, voyageId: number) =>
    dispatch(createVoyageImage(file, voyageId));
  const destroyAvatar = () => dispatch(deleteAvatar(userInformation?.id));
  const destroyCover = () => dispatch(deleteCover(userInformation?.id));

  return {
    userInformation,
    editUser,
    uploadAvatar,
    uploadCover,
    uploadBoatImage,
    uploadVoyageImage,
    uploadPlacePinImage,
    uploadReportPinImage,
    destroyAvatar,
    destroyCover,
    userError,
  };
};

export default useUserInformation;
