import { alpha } from "@mui/material/styles";

import { layoutStyles } from "@/themes/layoutStyles";
import defaultTheme from "@/themes/theme";

const lines = {
  display: "flex",
  width: "2px",
  borderRadius: "2px",
  backgroundColor: "primary.main",
};

export const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "calc(100vh - 64px)",
    backgroundColor: "grey.100",
    mt: "-66px",
  },

  dialogContent: {
    ...layoutStyles.scroll,
    backgroundColor: "grey.100",
    p: 3,
  },

  dialogHeader: {
    display: "flex",
    height: "148px",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    backgroundImage: `url(${"img/premium-bg.png"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    pt: 1.5,
  },

  dialogHeaderIcon: {
    color: "common.white",
    textAlign: "center",
    fontSize: 40,
    mb: 0.5,
    mt: 1,
  },

  dialogHeaderTitle: {
    color: "common.white",
    textAlign: "center",
    letterSpacing: 0.75,
    fontSize: "22px",
  },

  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    pt: 0,
    pb: 1,
    px: 0,
    color: "primary.main",
    fontSize: "18px",
  },

  PremiumDialogActions: {
    px: 3,
    py: 2,
    borderTop: "1px solid",
    borderColor: "divider",
    backgroundColor: "primary.light",
    position: "relative",
    "& .MuiButton-text": {
      color: "primary.dark",
      zIndex: 2,
    },
    "& .MuiButton-contained": {
      backgroundColor: "primary.dark",
      zIndex: 2,
    },
    "&:before": {
      content: "''",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      position: "absolute",
      backgroundColor: `${alpha(defaultTheme.palette.common.black, 0.08)}`,
    },
  },

  LoggedDialogActions: {
    px: 3,
    py: 2,
    backgroundColor: `${alpha(defaultTheme.palette.primary.light, 0.04)}`,
    borderTop: "1px solid",
    borderColor: "divider",
  },

  NonUserDialogActions: {
    px: 3,
    py: 2,
    backgroundColor: "grey.200",
  },

  list: {
    listStyleType: "disc",
    pl: 2,
    pt: 0,
    pb: 1,
    "& .MuiListItem-root": {
      display: "list-item",
    },
  },

  mainButton: {
    mt: 1,
    fontSize: "16px",
  },

  dialogButton: {
    color: "primary.main",
    mt: 2,
  },

  lineContainer: {
    py: 0.75,
    px: 1,
  },

  line1: {
    ...lines,
    flex: "1 0 0",
  },

  line2: {
    ...lines,
    height: "40px",
  },

  line3: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    width: "2px",
    height: "36px",
    position: "relative",
    "& .MuiSvgIcon-root": {
      color: "grey.300",
      fontSize: 28,
      mb: -0.75,
    },
    "&:before": {
      ...lines,
      content: "''",
      position: "absolute",
      display: "block",
      height: "36px",
      transform: "translateX(50%)",
      backgroundColor: "grey.300",
      right: "50%",
      bottom: 0,
    },
  },

  dialogFooterContainer: {
    backgroundColor: "grey.300",
  },

  dialogFooter: {
    p: 3,
    borderRadius: "8px 8px 0px 0px",
    backgroundColor: "background.paper",
    boxShadow: 4,
  },
};
