/* global google */
import { Box } from "@mui/material";

import { styles } from "./styles";
import PoisList from "./PoisList";

import { useGetOptimizedPoisQuery } from "@/store/api/argoApi";
import { getRegionFromBounds } from "@/utils/maps";
import { TRegionData } from "@/types/map";
import { useThrottle } from "@/hooks/useThrottle";

interface IPois {
  bounds: google.maps.LatLngBounds;
  zoom?: number;
  clogpage: boolean;
}

function Pois({ bounds, zoom, clogpage }: IPois) {
  const region: TRegionData = getRegionFromBounds(bounds);
  const throttledRegion = useThrottle(region);

  const { data: poisData } = useGetOptimizedPoisQuery(
    {
      sw_latitude: throttledRegion.southWestLat,
      sw_longitude: throttledRegion.southWestLng,
      ne_latitude: throttledRegion.northEastLat,
      ne_longitude: throttledRegion.northEastLng,
      page_total: 1000,
    },
    { skip: !throttledRegion.hasRegion },
  );

  return (
    <Box sx={styles.container}>
      {poisData?.data?.items && (
        <PoisList clogpage={clogpage} items={poisData?.data?.items} zoom={zoom} />
      )}
    </Box>
  );
}

export default Pois;
