import QuickPinsList from "./QuickPinsList";

import { useGetQuickPinsQuery } from "@/store/api/argoApi";

function QuickPins({ clogpage }: { clogpage: boolean }) {
  const { data } = useGetQuickPinsQuery({});

  if (!data?.data?.items) {
    return null;
  }

  return <QuickPinsList clogpage={clogpage} items={data.data.items} />;
}

export default QuickPins;
