import { useState } from "react";
import {
  IconButton,
  TextField,
  Typography,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
  Stack,
  FormHelperText,
  Box,
  Button,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import BaseModal from "@/components/Modals/BaseModal";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { UserRegisterF, UserRegisterData } from "@/types/users";
import { emailRegister } from "@/store/slices/auth/actions";
import useDisclosure from "@/hooks/useDisclosure";

const formSchema = yup.object({
  firstName: yup.string().required("First Name is a required field"),
  lastName: yup.string().required("Last Name is a required field"),
  email: yup
    .string()
    .required("Email is a required field")
    .email("Valid email is a required field"),
  password: yup
    .string()
    .required("Password is a required field")
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter"),
  passwordConfirm: yup
    .string()
    .required("Password is a required field")
    .oneOf([yup.ref("password"), null], 'Must match "Password" field value'),
});

interface ISignUpForm {
  setOptionValue: (value: number) => void;
}

export default function SignUpForm({ setOptionValue }: ISignUpForm) {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.auth);
  const [showPwd, setShowPwd] = useState<boolean>(false);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [accountCreated, setAccountCreated] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPwd(!showPwd);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirm: "",
    } as UserRegisterF,
    validationSchema: formSchema,
    onSubmit: () => {
      onOpen();
    },
  });

  const handleCreateAccount = async () => {
    const res = await dispatch(
      emailRegister({
        first_name: formik.values.firstName,
        last_name: formik.values.lastName,
        email: formik.values.email,
        password: formik.values.password,
      } as UserRegisterData),
    );

    if (res) setAccountCreated(true);
  };

  if (accountCreated)
    return (
      <Box component="section">
        <Stack spacing={1.5}>
          <Typography component="h3" textAlign="center" variant="h5">
            Welcome Aboard!
          </Typography>
          <Typography component="h4" textAlign="center" variant="subtitle1">
            We&apos;re glad you&apos;re here.
          </Typography>
          <Typography component="h4" textAlign="center" variant="body1">
            We sent you an email to confirm your account. Once confirmed, come back and
          </Typography>
          <Stack spacing={1.5}>
            <Button
              fullWidth
              endIcon={<ArrowForwardIcon />}
              size="large"
              type="submit"
              variant="contained"
              onClick={() => setOptionValue(1)}
            >
              Sign In
            </Button>
          </Stack>
        </Stack>
      </Box>
    );

  return (
    <Box component="form">
      <Stack spacing={3} sx={{ width: "100%" }}>
        <Stack spacing={1.5}>
          <Typography component="h3" textAlign="center" variant="h6">
            Sign Up
          </Typography>

          <TextField
            fullWidth
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            id="firstName"
            label="First Name"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
          />
          <TextField
            fullWidth
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            id="lastName"
            label="Last Name"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
          />
          <TextField
            fullWidth
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            id="email"
            label="Email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          <FormControl
            fullWidth
            error={formik.touched.password && Boolean(formik.errors.password)}
            variant="outlined"
          >
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              aria-describedby="password-text"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={handleClickShowPassword}
                  >
                    {showPwd ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              id="password"
              label="Password"
              name="password"
              type={showPwd ? "text" : "password"}
              onChange={formik.handleChange}
            />
            <FormHelperText id="password-text">
              {formik.touched.password && formik.errors.password}
            </FormHelperText>
          </FormControl>
          <FormControl
            fullWidth
            error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
            variant="outlined"
          >
            <InputLabel htmlFor="passwordConfirm">Repeat Password</InputLabel>
            <OutlinedInput
              aria-describedby="passwordConfirm-text"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={handleClickShowPassword}
                  >
                    {showPwd ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              id="passwordConfirm"
              label="Repeat Password"
              name="passwordConfirm"
              type={showPwd ? "text" : "password"}
              onChange={formik.handleChange}
            />
            <FormHelperText id="passwordConfirm-text">
              {formik.touched.passwordConfirm && formik.errors.passwordConfirm}
            </FormHelperText>
          </FormControl>

          <Typography color="text.secondary" textAlign="center" variant="body2">
            Password should contain 8 or more characters, at least one number, one capital letter,
            one lower case letter.
          </Typography>
        </Stack>
        <Stack spacing={1.5}>
          <LoadingButton
            fullWidth
            endIcon={<ArrowForwardIcon />}
            loading={loading}
            size="large"
            variant="contained"
            onClick={() => formik.handleSubmit()}
          >
            Sign Up
          </LoadingButton>
        </Stack>
      </Stack>
      <BaseModal
        ariaDescribedby="Confirmation Email"
        ariaLabelledby="Confirmation Email"
        open={isOpen}
        size="xs"
        title="Confirmation Email"
        onClose={onClose}
      >
        <Stack spacing={2} sx={{ px: 3, pb: 2 }}>
          <Box>
            <Typography gutterBottom variant="body1">
              We plan to send a confirmation email to: <b>{formik.values.email}</b>
            </Typography>

            <Typography variant="body1">Is that correct?</Typography>
          </Box>
          <Stack direction="row" justifyContent="end" spacing={1} sx={{ marginTop: "1rem" }}>
            <Button type="submit" onClick={onClose}>
              Go Back
            </Button>
            <Button type="submit" variant="contained" onClick={handleCreateAccount}>
              Send
            </Button>
          </Stack>
        </Stack>
      </BaseModal>
    </Box>
  );
}
