import { Box, Typography } from "@mui/material";
import { useUnitSystem, LengthUnits } from "@argonav/unit-system";

import { getPoiIcon } from "../Utils/PoiIcons";

import { styles } from "./styles";

import { IconKeysPois } from "@/types/markers";
import { IMapPois } from "@/types/pois";

interface IBridgeMapIcon {
  infoIcon: IMapPois;
}

function BridgeMapIcon({ infoIcon }: IBridgeMapIcon) {
  const { depthUnitHandler } = useUnitSystem();
  const bridgeClearance = (() => {
    if (
      infoIcon?.info?.ver_clr &&
      infoIcon?.info?.ver_clr !== "0" &&
      infoIcon?.info?.ver_clr !== "0.0"
    ) {
      const { value: verticalClearanceConverted } = depthUnitHandler({
        originalUnit: LengthUnits.FEET,
        originalValue: Number(infoIcon?.info?.ver_clr),
      });

      return verticalClearanceConverted;
    }

    return null;
  })();

  return (
    <Box>
      <img
        alt="Bridge Clearance"
        className="BridgeClearanceImage"
        height={36}
        src={getPoiIcon(IconKeysPois.BridgeClearance)}
        width={36}
      />
      {bridgeClearance && (
        <Typography className="BridgeClearanceCaption" sx={styles.clearance} variant="caption">
          {Number(bridgeClearance).toFixed(0)}
        </Typography>
      )}
    </Box>
  );
}

export default BridgeMapIcon;
