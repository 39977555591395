import { UserInformation } from "./users";

import { IconKeysReportPins } from "@/types/markers";

export enum EKindReportPin {
  noWakeZone = "no_wake_zone",
  sos = "sos",
  police = "police",
  localDevice = "local_advice",
  weather = "weather",
  hazard = "hazard",
  mapIssue = "map_issue",
  marineLife = "marine_life",
  seaLife = "Sea Life",
}

export type TReportPinVote = {
  created_at: string;
  id: number;
  report_pin_id: number;
  updated_at: string;
  user_id: number;
  vote_type: string;
};

export type TReportPinReview = {
  commentable_id: number;
  commentable_type: string;
  created_at: string;
  id: number;
  likes_count: number;
  message: string;
  own_user_like: null;
  updated_at: string;
  user: UserInformation;
  user_id: number;
};
export interface IPin {
  id: number;
  lat: number | string;
  lng: number | string;
  pin_votes_up_count: number;
  pin_votes_down_count: number;
  asset_links: string[];
  likes: number;
  dislikes: number;
  icon?: string;
  name?: string;
  email?: string;
  popoverDirection: string;
  phone?: string;
  address: string;
  website?: string;
  bookmark?: boolean;
  submit?: boolean;
  category: string;
  poi_type?: string;
  sub_type?: string;
  subtype?: string;
  place_type?: string;
  description?: string;
  user_id?: number;
  created_at: string;
  updated_at: string;
  user_vote: string;
  kind: IconKeysReportPins;
  cleared?: boolean;
  show?: boolean;
  reviews?: TReportPinReview[];
  own_report_pin_vote: TReportPinVote | null;
  assets: {
    id: number;
    created_at: string;
    download_url: string;
  }[];
}
