export const styles = {
  listItem: {
    pb: 0,
  },

  li: {
    "&::marker": { content: "''" },
  },

  iconButton: {
    p: 0,
  },

  avatar: {
    width: 40,
    height: 40,
  },
};
