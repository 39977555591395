import { Box } from "@mui/material";

import PrivatePlaceMarker from "./PrivatePlaceMarker";

import { IPin } from "@/types/reportpins";

interface IPrivatePlacesList {
  items: IPin[];
  clogpage: boolean;
}

function PrivatePlacesList({ items, clogpage }: IPrivatePlacesList) {
  return (
    <Box>
      {items?.map((item: IPin) => (
        <PrivatePlaceMarker key={item.id} clogpage={clogpage} place={item} />
      ))}
    </Box>
  );
}

export default PrivatePlacesList;
