import { useAppSelector } from "./useRedux";

import { UserInformation } from "@/types/users";

const usePremiumUser = () => {
  const user: UserInformation | undefined = useAppSelector((state) => state.user.userInformation);

  /*
   * For the New Backend the logic should be the same.
   * Just we should change the properties
   * user_subscription  -- become --> subscription_kind
   * user_subscription_last_change -- become --> subscription_updated_at
   */
  return !!(user && user.user_subscription === "premium");
};

export default usePremiumUser;
