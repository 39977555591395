export type Interest = {
  category: string;
  created_at: string;
  id: number;
  name: string;
  updated_at: string;
};

export enum EInterestCategories {
  WaterRec = "Water Rec",
  Fishing = "Fishing",
  Events = "Events",
  BoatingKnowHow = "Boating Know How",
  LeisureActivities = "Leisure Activities",
  HereToThere = "Here to There",
  Racing = "Racing",
  Local = "Local",
  BoatTypes = "Boat Types",
}
