import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";

export const styles = {
  icon: {
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
  },
  arrowIcon: {
    color: "action.active",
    fontSize: 20,
  },

  listItem: {
    "& .MuiListItemButton-root:hover, &.mobileOpen .MuiListItemButton-root": {
      pl: 3.5,
      backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.04)}`,
      color: "primary.main",

      transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "& .MuiSvgIcon-root": {
        color: "primary.main",
      },
    },

    "&:before": {
      content: "''",
      width: "6px",
      height: "100%",
      top: 0,
      position: "absolute",
      backgroundColor: "primary.main",
      transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      opacity: 0,
    },

    "&:hover:before, &.mobileOpen:before": {
      opacity: 0.12,
    },

    "&.mobileOpen .MuiSvgIcon-root[data-testid='KeyboardArrowRightIcon']": {
      transform: "rotate(-90deg)",
      transition: "transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },

    "&.mobileClose .MuiSvgIcon-root[data-testid='KeyboardArrowRightIcon']": {
      transform: "rotate(90deg)",
      transition: "transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
  },
};
