// import { layoutStyles } from "@/themes/layoutStyles";

export const styles = {
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dialogContent: {
    py: 1,
    overflow: "visible",
  },

  container: {
    flexGrow: 1,
    bgcolor: "background.paper",
    display: "flex",
    width: "100%",
  },

  tabs: {
    borderRight: 1,
    borderColor: "divider",
    minWidth: 210,
  },

  tab: {
    minHeight: "unset",
    justifyContent: "flex-start",
    pl: 1,
    pr: 4,
    "& svg": {
      mr: 1,
    },
  },
};
