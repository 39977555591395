export const styles = {
  zoomControlsContainer: {
    p: 2,
  },

  fabButton: {
    boxShadow: 3,
    backgroundColor: "background.paper",
    color: "action.active",
    "& .MuiSvgIcon-root": {
      fontSize: 22,
    },
  },

  zoomControls: {
    display: "flex",
    flexDirection: "column",
    boxShadow: 3,
    borderRadius: "20px",
    height: "80px",
    width: "40px",

    "& .MuiFab-root": {
      boxShadow: 0,
      backgroundColor: "background.paper",
      color: "action.active",
    },

    "& .MuiFab-root:first-of-type": {
      borderRadius: "40px 40px 0 0",
      borderBottom: "1px solid",
      borderColor: "divider",
    },

    "& .MuiFab-root:last-of-type": {
      borderRadius: "0 0 40px 40px",
    },
  },

  textButton: {
    color: "#90CAF9",
    p: 0,
    typography: "body2",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
};
