import { Box, Stack, Container, Card, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Verified } from "@mui/icons-material";

import { styles } from "./styles";

function PremiumThanksLayout() {
  const navigate = useNavigate();

  return (
    <Box sx={styles.wrapper}>
      <Container maxWidth="lg">
        <Box sx={styles.boxColumns}>
          <Stack spacing={3}>
            <Card>
              <Stack
                alignItems="center"
                justifyContent="center"
                spacing={3}
                sx={styles.dialogHeader}
              >
                <Stack alignItems="center" justifyContent="center" spacing={0}>
                  <Verified sx={styles.dialogHeaderIcon} />
                  <Typography sx={styles.dialogHeaderTitle} variant="h5">
                    THANKS FOR UNLOCKING PREMIUM FEATURES
                  </Typography>
                </Stack>

                <Button
                  color="whiteBg"
                  size="large"
                  sx={styles.dialogButton}
                  variant="contained"
                  onClick={() => navigate("/")}
                >
                  Go to Navigate
                </Button>
              </Stack>
            </Card>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

export default PremiumThanksLayout;
