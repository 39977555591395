import axios from "axios";

/**
 * Get the last segment of a file uri
 * Server expects a file_name when associating an asset to an entity
 *
 * @param {File} file the local file
 * @returns the file name to identify the asset in S3
 */
export const getFileName = (file: File) => {
  const timestamp = new Date().getTime();

  return `${file.name.split(".").shift()}_${timestamp}.${file.name.split(".").pop()}`;
};

interface IUploadAssetProps {
  url: string;
  file: File;
  fileName?: string | undefined;
}

/**
 * Uploads a local file to S3
 *
 * TODO: Axios PUT to S3 issue; Use XHR
 *
 * @param {Object} options
 * @param {Object} options.url the upload url, usually pre-signed
 * @param {Object} options.fileUri the local file uri. Ie: `file://var/...'
 * @param {Object} options.fileName the file name to store the file as
 */

const uploadAsset = async ({ url, file }: IUploadAssetProps) => {
  await axios.request({
    method: "put",
    headers: {
      "Content-Type": "image/jpeg",
    },
    url,
    data: file,
  });
};

export default uploadAsset;
