export const styles = {
  chip: {
    px: 0.25,
    py: 0.5,
    ml: -0.25,
    "& .MuiChip-label": {
      pl: 0.25,
      fontWeight: 700,
    },
  },
};
