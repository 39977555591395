import {
  Stack,
  FormControl,
  TextField,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Checkbox,
  DialogContent,
  DialogActions,
  Box,
  Button,
  Slider,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import * as yup from "yup";

import { styles } from "./styles";

import { IFeedbackForm } from "@/types/feedback";
import { useAppSelector, useAppDispatch } from "@/hooks/useRedux";
import { browserInfo } from "@/utils/browser";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import ServiceUser from "@/services/user";

interface IFeedbackModal {
  onClose: () => void;
}

const formSchema = yup.object({
  email: yup.string().trim().required("Email is a required field"),
  message: yup.string().trim().required("Feedback is a required field"),
});

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
];

function valuetext(value: number) {
  return `${value}`;
}

function FeedbackModal({ onClose }: IFeedbackModal) {
  const dispatch = useAppDispatch();
  const { userInformation } = useAppSelector((state) => state.user);
  const [improveOptions, setImproveOptions] = useState<string[] | []>([]);

  const handleForm = async (values: IFeedbackForm) => {
    try {
      const { status } = await ServiceUser.sendFeedBack(values);

      if (status === 201) {
        dispatch(
          setSnackBarMsjSucceded({ state: true, type: "success", msj: "Your feedback was sent" }),
        );
        onClose();
      }
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
  };

  const formik = useFormik({
    initialValues: {
      improve_options: "",
      message: "",
      email: userInformation?.email || "",
      rating: 1,
      device: browserInfo,
    } as IFeedbackForm,
    validationSchema: formSchema,
    onSubmit: handleForm,
  });

  const handleSetRating = (event: Event, value: number | number[]) => {
    formik.setFieldValue("rating", value);
  };

  useEffect(() => {
    formik.setFieldValue("improve_options", improveOptions.join(","));
  }, [improveOptions]);

  const handleCheckImprove = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setImproveOptions([...improveOptions, event.target.name]);
    } else {
      setImproveOptions(improveOptions.filter((option: string) => option !== event.target.name));
    }
  };

  return (
    <>
      <DialogContent sx={styles.dialogContent}>
        <Stack component="form" spacing={2}>
          <FormControl>
            <FormLabel component="legend" id="experience-rate">
              Please rate your experience on a scale of 0-10.
            </FormLabel>
            <Box sx={{ pt: 2, pb: 1, px: 3, mt: 1, backgroundColor: "grey.50", borderRadius: 1 }}>
              <Slider
                aria-label="Temperature"
                defaultValue={1}
                getAriaValueText={valuetext}
                id="rating"
                marks={marks}
                max={10}
                min={0}
                step={1}
                value={formik.values.rating}
                valueLabelDisplay="auto"
                onChange={handleSetRating}
              />
            </Box>
          </FormControl>

          <FormControl>
            <FormGroup onChange={handleCheckImprove}>
              <FormLabel component="legend">
                What features can be improved, if any? (Select all that apply).
              </FormLabel>
              <Stack direction="row" sx={{ mt: 1 }}>
                <Stack sx={{ flex: 1 }}>
                  <FormControlLabel
                    control={<Checkbox name="Routing" size="small" />}
                    label="Routing"
                  />
                  <FormControlLabel
                    control={<Checkbox name="Depth" size="small" />}
                    label="Depth"
                  />
                  <FormControlLabel
                    control={<Checkbox name="Map Info" size="small" />}
                    label="Map Info"
                  />
                  <FormControlLabel
                    control={<Checkbox name="Search" size="small" />}
                    label="Search"
                  />
                </Stack>
                <Stack sx={{ flex: 1 }}>
                  <FormControlLabel
                    control={<Checkbox name="Captain's Log" size="small" />}
                    label="Captain's Log"
                  />
                  <FormControlLabel
                    control={<Checkbox name="Profile" size="small" />}
                    label="Profile"
                  />
                  <FormControlLabel
                    control={<Checkbox name="Other" size="small" />}
                    label="Other"
                  />
                </Stack>
              </Stack>
            </FormGroup>
          </FormControl>
          <FormControl>
            <TextField
              multiline
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.errors.message}
              id="message"
              label="Questions/Additional Feedback"
              placeholder="Share your questions, thoughts, suggestions or great ideas!"
              rows={4}
              onChange={formik.handleChange}
            />
          </FormControl>
          <FormControl>
            <TextField
              defaultValue={formik.values.email}
              helperText={formik.errors.email}
              id="email"
              label="Email"
              onChange={formik.handleChange}
            />
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button autoFocus variant="contained" onClick={() => formik.handleSubmit()}>
          Submit
        </Button>
      </DialogActions>
    </>
  );
}

export default FeedbackModal;
