import { createSlice } from "@reduxjs/toolkit";

import { MIN_ZOOM_MAP_LIMIT } from "@/utils/maps";

export interface IMap {
  zoom: number;
  coordsParams: string | null;
  mapState: { zoom: number; center: { lat: number; lng: number } } | null;
}

const initialState: IMap = {
  zoom: MIN_ZOOM_MAP_LIMIT,
  coordsParams: null,
  mapState: null,
};

const mapParamsSlice = createSlice({
  name: "mapParamsSlice",
  initialState,
  reducers: {
    addZoomParam: (state, action) => {
      state.zoom = action.payload;
    },
    addCoordsParams: (state, action) => {
      state.coordsParams = action.payload;
    },
    addMapState: (state, action) => {
      state.mapState = action.payload;
    },
  },
});

export const { addZoomParam, addCoordsParams, addMapState } = mapParamsSlice.actions;

export default mapParamsSlice.reducer;
