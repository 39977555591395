/* global google */
import { Box } from "@mui/material";
import React from "react";

import { styles } from "./styles";
import NavAidsList from "./NavAidsList";

import { useGetOptimizedNavAidsQuery } from "@/store/api/argoApi";
import { getRegionFromBounds } from "@/utils/maps";
import { TRegionData } from "@/types/map";
import { useThrottle } from "@/hooks/useThrottle";

interface INavAids {
  bounds: google.maps.LatLngBounds;
  zoom?: number;
  clogpage: boolean;
}

function NavAids({ bounds, zoom, clogpage }: INavAids) {
  const region: TRegionData = getRegionFromBounds(bounds);
  const throttledRegion = useThrottle(region);
  const { data: navsAidsData } = useGetOptimizedNavAidsQuery(
    {
      sw_latitude: throttledRegion.southWestLat,
      sw_longitude: throttledRegion.southWestLng,
      ne_latitude: throttledRegion.northEastLat,
      ne_longitude: throttledRegion.northEastLng,
      kinds: "buoy,beacon,restricted_area",
      page_total: 10000,
    },
    { skip: !throttledRegion.hasRegion },
  );

  return (
    <Box sx={styles.container}>
      {navsAidsData?.data?.items && (
        <NavAidsList clogpage={clogpage} items={navsAidsData?.data?.items} zoom={zoom} />
      )}
    </Box>
  );
}

export default NavAids;
