import { Navigate } from "react-router-dom";
import React from "react";

import { useAppSelector } from "@/hooks/useRedux";

interface IPremiumRoute {
  children: React.ReactElement;
}

function AuthenticatedRoute({ children }: IPremiumRoute): React.ReactElement {
  const { userInformation } = useAppSelector((state) => state.user);

  return userInformation ? children : <Navigate to="/" />;
}

export default AuthenticatedRoute;
