export const styles = {
  affiliationList: {
    px: 2,
    "& .MuiListItem-root:last-of-type": {
      borderBottom: "none",
    },
  },

  avatar: {
    border: "2px solid",
    borderColor: "secondary.main",

    width: 24,
    height: 24,
  },
};
