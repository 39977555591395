export const styles = {
  container: {
    position: "absolute",
    top: "300px",
    right: "500px",
    zIndex: 30,
  },

  iconButton: {
    "& .MuiSvgIcon-root": {
      filter: "drop-shadow(0px 1px 1px rgb(0 0 0 / 0.3))",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    position: "relative",
  },

  avatar: {
    border: "2px solid",
    borderColor: "background.paper",
    boxShadow: 2,
    width: 28,
    height: 28,
  },

  avatarCommonAffiliation: {
    border: "2px solid",
    borderColor: "secondary.main",
    boxShadow: 2,
    width: 28,
    height: 28,
  },

  avatarCommonGroup: {
    border: "2px solid",
    borderColor: "secondary.main",
    boxShadow: 2,
    width: 28,
    height: 28,
  },

  avatarCommonGroupIcon: {
    position: "absolute",
    width: "12px",
    height: "12px",
    borderRadius: "80%",
    border: "2px solid #19787B",
    bottom: "6px",
    right: "6px",
    backgroundColor: "secondary.main",
    backgroundImage: 'url("/img/markers/boaters/3x/burgee.png")',
    backgroundRepeat: "round",
    zIndex: 999999,
  },
};
