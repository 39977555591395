export const styles = {
  denseList: {
    pb: 2,
    "& .MuiListItem-root": {
      py: 0,
    },
  },

  listItemIcon: {
    minWidth: "32px",
    color: "primary.main",
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
  },
};
