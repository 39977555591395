import { Typography, Box, Divider, Stack, Button } from "@mui/material";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import BaseModal from "@/components/Modals/BaseModal/BaseModal";
import { IMapPois } from "@/types/pois";
import EditApproachModal from "@/components/Modals/EditApproachModal";

interface IPlaceInfoApproach {
  poiSelected: IMapPois;
}
function PlaceInfoApproach({ poiSelected }: IPlaceInfoApproach) {
  const [openEditApproach, setEditApproach] = useState<boolean>(false);

  const infoApproach =
    poiSelected?.approach ||
    poiSelected?.tidal_change ||
    poiSelected?.current ||
    poiSelected?.tide_and_extra;

  return (
    <>
      <Stack alignItems="center" direction="row" justifyContent="space-between" px={2} py={1}>
        <Typography component="h4" lineHeight="normal" variant="subtitle1">
          Approach
        </Typography>
        <Button
          color="secondary"
          startIcon={!infoApproach ? <AddIcon /> : <EditIcon />}
          onClick={() => setEditApproach(true)}
        >
          {!infoApproach ? "Add Approach Info" : "Edit Approach Info"}
        </Button>
      </Stack>

      <Box pb={infoApproach ? 2 : 0} px={infoApproach ? 2 : 0}>
        {poiSelected?.approach && <Typography>Approach: {poiSelected?.approach} ft </Typography>}
        {poiSelected?.tidal_change && (
          <Typography>Tidal Change: {poiSelected?.tidal_change} ft </Typography>
        )}
        {poiSelected?.current && <Typography>Current: {poiSelected?.current}</Typography>}
        {poiSelected?.tide_and_extra && (
          <Typography sx={{ mt: 2 }}>{poiSelected?.tide_and_extra}</Typography>
        )}
      </Box>

      <Divider />

      {openEditApproach && (
        <BaseModal
          ariaDescribedby="Edit Approach Info"
          ariaLabelledby="Edit Approach Info"
          open={openEditApproach}
          title="Edit Approach Info"
          onClose={() => setEditApproach(false)}
        >
          <EditApproachModal poiSelected={poiSelected} onClose={() => setEditApproach(false)} />
        </BaseModal>
      )}
    </>
  );
}

export default PlaceInfoApproach;
