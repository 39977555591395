import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";

const depthIndicator = {
  width: "100%",
  position: "relative",
  "& .depthIndicatorItem": {
    width: "100%",
    flex: 1,
  },

  "& .depthIndicatorItem:first-of-type .depthIndicatorColor": {
    borderRadius: "12px 0px 0px 12px",
  },

  "& .depthIndicatorItem:last-of-type .depthIndicatorColor": {
    borderRadius: "0px 12px 12px 0px",
  },

  "&:hover .editButton": {
    opacity: 1,
    transform: "translateY(0px)",
  },
};

export const styles = {
  depthIndicator: {
    ...depthIndicator,
  },

  depthIndicatorDisabled: {
    ...depthIndicator,
    filter: "grayscale(100%)",
    opacity: 0.5,
    "& .editButton": {
      display: "none",
    },
    "& .overlay": {
      "&:before": {
        display: "none",
      },
    },
  },

  depthIndicatorContainer: {
    pb: 0.5,
    pt: 2,
    px: 1.5,
  },

  depthIndicatorColor: {
    height: "22px",
  },

  listItem: {
    pr: 2.5,
    pt: 1.5,
    pb: 1,
  },

  overlay: {
    position: "relative",
    "&:before": {
      content: "''",
      width: "calc(100% + 24px)",
      height: "calc(100% + 20px)",
      top: -16,
      left: -12,
      position: "absolute",
      borderRadius: "8px 8px 0px 0px",
      opacity: 0,
      zIndex: 1,
      backgroundColor: `${alpha(defaultTheme.palette.common.black, 0.08)}`,
      transition: defaultTheme.transitions.create(["all", "transform"], {
        duration: defaultTheme.transitions.duration.standard,
      }),
    },

    "&:hover:before": {
      opacity: 1,
    },
  },

  depthShadingContainer: {
    border: "1px solid",
    borderColor: "divider",
    borderRadius: 1,
    pointerEvents: "all",
  },

  sliderContainer: {
    px: 1.5,
    py: 0.75,
    "& .MuiTypography-root": {
      flex: 1,
    },
    "& .MuiSlider-root": {
      flex: 2,
      height: "4px",
    },
  },

  editButton: {
    backgroundColor: "common.white",
    position: "absolute",
    left: "calc(50% - 17px)",
    boxShadow: 4,
    opacity: 0,
    transform: "translateY(20px)",
    transition: defaultTheme.transitions.create(["all", "transform"], {
      duration: defaultTheme.transitions.duration.standard,
    }),
    zIndex: 2,
    "&:hover": {
      backgroundColor: "common.white",
    },
  },
};
