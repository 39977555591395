export const styles = {
  input: {
    width: "100%",
    zIndex: 9,
    mx: 1.5,
    "& .MuiSvgIcon-root": {
      mr: 1,
    },
    "&:after, :before": {
      display: "none",
    },
    "&.Mui-focused": {
      color: "primary.main",
    },
  },
  arrowBackIcon: {
    cursor: "pointer",
    color: "primary.main",
  },
};
