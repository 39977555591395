import { PathLayer } from "@deck.gl/layers";

import { Position, Route } from "@/types/map";
import { colorForDepth, validateNoDepthRoute } from "@/utils/route";
import { UserInformation } from "@/types/users";
import { setDepthAlert } from "@/store/slices/route";

type BartLine = {
  name: number;
  color: string;
  path: [lng: number, lat: number][];
};

export function getRouteLayer(
  routeCreated: Route | null,
  userInformation: UserInformation | undefined,
  zoom: number,
  dispatch: any,
) {
  if (!routeCreated) return null;

  const handleGeneratePath = (routeCreated: Route) => {
    const pathFormatted: BartLine[] = [];

    let currentRoute: [lng: number, lat: number][] = [];
    let noDepth = false;

    if (routeCreated) {
      noDepth = validateNoDepthRoute(routeCreated?.course_locations);

      routeCreated.course_locations.forEach((item: Position, i: number) => {
        if (item?.lng !== undefined && item?.lat !== undefined) {
          const nextItem = routeCreated.course_locations[i + 1];
          const isLastItem = i === routeCreated.course_locations.length - 1;

          if (!isLastItem && nextItem?.lng !== undefined && nextItem?.lat !== undefined) {
            currentRoute.push([item.lng, item.lat]);
            currentRoute.push([nextItem.lng, nextItem.lat]);

            if (
              colorForDepth(userInformation?.current_vessel, noDepth, nextItem.depth) !==
              colorForDepth(userInformation?.current_vessel, noDepth, item.depth)
            ) {
              pathFormatted.push({
                name: i + 1,
                color: colorForDepth(userInformation?.current_vessel, noDepth, item.depth),
                path: currentRoute,
              });
              currentRoute = [];
            }
          } else {
            pathFormatted.push({
              name: i + 1,
              color: colorForDepth(userInformation?.current_vessel, noDepth, item.depth),
              path:
                currentRoute.length > 0
                  ? [...currentRoute, [item.lng, item.lat]]
                  : [[item.lng, item.lat]],
            });
          }
        }
      });
    }

    if (noDepth) dispatch(setDepthAlert(noDepth));

    return pathFormatted;
  };

  const layer = new PathLayer<BartLine>({
    id: "PathLayer",
    data: handleGeneratePath(routeCreated),

    getColor: (d: BartLine) => {
      if (!d.color) return [0, 0, 0] as any;
      const hex = d.color;

      // convert to RGB
      return hex.match(/[0-9a-f]{2}/g)?.map((x) => parseInt(x, 16));
    },
    getPath: (d: BartLine) => d.path,
    getWidth: () => {
      const baseWidth = 100;
      const referenceZoom = 13;
      const width = baseWidth * 2 ** (referenceZoom - zoom);

      return width;
    },
    capRounded: true,
    pickable: true,
  });

  return layer;
}
