import React, { useState, useMemo } from "react";
import { Stack, Typography, Divider, Button } from "@mui/material";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import { styles } from "./styles";
import FuelInfoChip from "./FuelInfoChip";

import { IMapPois } from "@/types/pois";
import BaseModal from "@/components/Modals/BaseModal/BaseModal";
import EditFuelModal from "@/components/Modals/EditFuelModal";
import { fuelDataFormatter } from "@/utils/poi";

dayjs.extend(minMax);

interface IPlaceInfoFuel {
  poiSelected: IMapPois;
}

function PlaceInfoFuel({ poiSelected }: IPlaceInfoFuel) {
  const [openEditFuelModal, setEditFuelModal] = useState<boolean>(false);

  const fuelData = useMemo(() => fuelDataFormatter(poiSelected), [poiSelected]);

  const infoFuelData =
    fuelData.length > 0 ||
    poiSelected.gas_updated_at ||
    poiSelected.diesel_updated_at ||
    poiSelected.gas_non_ethanol ||
    poiSelected.valv_tect ||
    poiSelected.fuel_discounts ||
    poiSelected.other_gas_info ||
    poiSelected.fuel_dock;

  return (
    <>
      <Stack alignItems="center" direction="row" justifyContent="space-between" px={2} py={1}>
        <Typography component="h4" lineHeight="normal" variant="subtitle1">
          Fuel
        </Typography>
        <Button
          color="secondary"
          startIcon={fuelData.length <= 0 ? <AddIcon /> : <EditIcon />}
          onClick={() => setEditFuelModal(true)}
        >
          {fuelData.length <= 0 ? `Add Fuel` : `Edit Fuel`}
        </Button>
      </Stack>
      {infoFuelData && (
        <Stack pb={2} px={2} spacing={1}>
          <Stack direction="row" spacing={1} sx={styles.fuel}>
            {fuelData.map((fuelInfo) => (
              <FuelInfoChip key={fuelInfo.label} fuelInfo={fuelInfo} />
            ))}
          </Stack>

          {poiSelected.gas_non_ethanol && (
            <Typography color="text.primary" variant="body2">
              Non-Ethanol Fuel Available
            </Typography>
          )}
          {poiSelected.valv_tect && (
            <Typography color="text.primary" variant="body2">
              ValvTect: {poiSelected.valv_tect}
            </Typography>
          )}
          {poiSelected.fuel_discounts && (
            <Typography color="text.primary" variant="body2">
              {poiSelected.fuel_discounts}
            </Typography>
          )}
          {poiSelected.other_gas_info && (
            <Typography color="text.primary" variant="body2">
              {poiSelected.other_gas_info}
            </Typography>
          )}
          {poiSelected.fuel_dock && (
            <Typography color="text.primary" variant="body2">
              Fuel Dock Depth: {poiSelected.fuel_dock} ft
            </Typography>
          )}

          {(poiSelected.gas_updated_at || poiSelected.diesel_updated_at) && (
            <Typography color="text.secondary" variant="body2">
              {`Last updated by ${
                poiSelected.last_fuel_change_by === "poi_owner" ? "owner" : "user"
              } on ${dayjs(poiSelected.last_fuel_change_at).format("MMMM DD, YYYY")}`}
            </Typography>
          )}
        </Stack>
      )}
      <Divider />
      {openEditFuelModal && (
        <BaseModal
          ariaDescribedby="Edit Fuel"
          ariaLabelledby="Edit Fuel"
          open={openEditFuelModal}
          title="Edit Fuel"
          onClose={() => setEditFuelModal(false)}
        >
          <EditFuelModal poiSelected={poiSelected} onClose={() => setEditFuelModal(false)} />
        </BaseModal>
      )}
    </>
  );
}

export default PlaceInfoFuel;
