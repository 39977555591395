import { useAppSelector } from "./useRedux";

import { Affiliation } from "@/types/affiliation";

const useUserAffiliations = () => {
  const userAffiliations: Affiliation[] | undefined = useAppSelector(
    (state) => state.user.affiliations,
  );

  return { userAffiliations };
};

export default useUserAffiliations;
