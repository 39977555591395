export const styles = {
  modalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  cardMedia: {
    width: "initial",
    borderRadius: "8px",
    maxHeight: "calc(100vh - 32px)",
    maxWidth: "calc(100vw - 104px)",
    boxShadow: 4,
  },
  closeButton: {
    position: "absolute",
    top: 0,
    right: -36,
    boxShadow: 4,
  },
};
