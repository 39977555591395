import PrivatePlacesList from "./PrivatePlacesList";

import { useGetPlacesQuery } from "@/store/api/argoApi";

function PrivatePlaces({ clogpage }: { clogpage: boolean }) {
  const { data } = useGetPlacesQuery({});

  return <PrivatePlacesList clogpage={clogpage} items={data} />;
}

export default PrivatePlaces;
