export const styles = {
  container: {
    position: "relative",
  },

  clearance: {
    position: "absolute",
    top: 14,
    left: 31,
    fontSize: "10px",
    color: "text.primary",
    fontFamily: "sans-serif",
    fontWeight: "bold",
    width: "21px",
    height: "13px",
    textAlign: "center",
  },
};
