import React from "react";
import { styled } from "@mui/system";
import { Typography, Box } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import { Verified } from "@mui/icons-material";

import PromoModal from "../../Modals/PromoModal";

import BaseModal from "@/components/Modals/BaseModal";
// import PremiumModal from "@/components/Modals/PremiumModal";
import useDisclosure from "@/hooks/useDisclosure";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import usePremiumUser from "@/hooks/usePremiumUser";
import GreetingModal from "@/components/Modals/GreetingModal";
import { setCoords } from "@/store/slices/map";
import {
  membership_name_yearly,
  membership_name_monthly,
} from "@/components/Premium/PremiumPaymentCard/PremiumPaymentCard";

const PremiumButtonStyled = styled((props: ButtonProps) => <Button {...props} />)(({ theme }) => ({
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "0px",
  width: "100%",
  color: theme.palette.common.white,
  transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  backgroundImage: `linear-gradient(180deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,

  "&:after": {
    content: "''",
    position: "absolute",
    display: "block",
    width: "100%",
    height: "32px",
    background: `linear-gradient(180deg, ${theme.palette.primary.light}, transparent)`,
    left: 0,
    top: 0,
    zIndex: 1,
  },

  "& .MuiSvgIcon-root": {
    marginBottom: theme.spacing(0.5),
    fontWeight: 700,
    zIndex: 2,
  },

  "& .MuiTypography-caption": {
    fontWeight: 700,
    lineHeight: "normal",
    zIndex: 2,
  },

  "&:hover": {
    backgroundImage: `linear-gradient(180deg, ${theme.palette.primary.light}, rgb(1, 107, 170, 1))`,
    ".wave, & .wave:nth-of-type(2)": {
      animationPlayState: "paused",
    },
  },

  "& .wave": {
    backgroundImage: `url(${"img/wave.svg"})`,
    backgroundRepeat: "repeat-x",
    position: "absolute",
    top: "-16px",
    width: "640px",
    height: "16px",
    animation: "wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite",
    transform: "translate3d(0, 0, 0)",
  },

  "& .wave:nth-of-type(2)": {
    top: "-14.5px",
    animation:
      "wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite",
    opacity: 0.75,
  },

  "@keyframes wave": {
    "0%": {
      marginLeft: 0,
    },
    "100%": {
      marginLeft: "480px",
    },
  },

  "@keyframes swell": {
    "0%, 100%": {
      transform: "translate3d(0,-6px,0)",
    },
    "50%": {
      transform: "translate3d(0,3px,0)",
    },
  },
}));

function PremiumButton() {
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userInformation } = useAppSelector((state) => state.user);
  const { logged } = useAppSelector((state) => state.auth);
  const { outDateOfTrial } = useAppSelector((state) => state.premium);
  const { coords } = useAppSelector((state) => state.map);

  const isPremium = usePremiumUser();
  const isMonthlyOrYearly =
    (userInformation?.latest_user_subscription &&
      !userInformation?.latest_user_subscription?.membership_activated) ||
    (userInformation?.latest_user_subscription?.membership_activated &&
      (userInformation?.latest_user_subscription?.membership_name === membership_name_yearly ||
        userInformation?.latest_user_subscription?.membership_name === membership_name_monthly)) ||
    (isPremium && !userInformation?.latest_user_subscription);

  const handleOpenModals = () => {
    onOpen();
    if (coords) dispatch(setCoords(undefined));
  };

  return (
    <>
      <PremiumButtonStyled onClick={handleOpenModals}>
        <Box className="wave" />
        <Box className="wave" />
        <Verified />
        <Typography variant="caption">
          {isPremium ? `Argo Premium` : `Upgrade to Premium`}
        </Typography>
      </PremiumButtonStyled>

      {/* <PremiumModal open={premiumSubscriptionModal} onClose={handleToggleSubInfoModal} /> */}
      {logged ? (
        <BaseModal
          darkHeader
          ariaDescribedby="Premium Payment"
          ariaLabelledby="Premium Payment"
          open={isOpen}
          size="xs"
          onClose={onClose}
        >
          {outDateOfTrial && !isMonthlyOrYearly ? (
            <GreetingModal
              isLogged={logged}
              isPremium={userInformation?.user_subscription === "premium"}
              onClose={onClose}
            />
          ) : userInformation?.user_subscription === "premium" && !isMonthlyOrYearly ? (
            <GreetingModal
              isLogged={logged}
              isPremium={userInformation?.user_subscription === "premium"}
              onClose={onClose}
            />
          ) : (
            <PromoModal />
          )}
        </BaseModal>
      ) : (
        <BaseModal
          darkHeader
          ariaDescribedby="Premium Modal Unlock"
          ariaLabelledby="Premium Modal Unlock"
          open={isOpen}
          size="xs"
          onClose={onClose}
        >
          <PromoModal />
        </BaseModal>
      )}
    </>
  );
}

export default PremiumButton;
