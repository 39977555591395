import { layoutStyles } from "@/themes/layoutStyles";
import defaultTheme from "@/themes/theme";

export const styles = {
  drawerContainer: {
    paddingTop: "68px",
    overflowY: "hidden",
  },

  scrollContainer: {
    ...layoutStyles.scroll,
    overflowY: "auto",
    maxHeight: "calc(100vh - 165px)",
  },

  addPlacesButton: {
    position: "absolute",
    bottom: "16px",
    right: "16px",
    backgroundColor: defaultTheme.palette.primary.main,
    color: defaultTheme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: defaultTheme.palette.primary.light,
    },
  },

  tabs: {
    borderBottom: "1px solid",
    borderColor: "divider",
  },

  pinIcon: {
    width: 28,
    height: 28,
  },

  descriptionText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  emptyContainer: {
    background: "white",
  },

  emptyState: {
    marginTop: "100px",
    "& div div": {
      height: "100px",
      width: "240px",
    },
  },

  pinCard: {
    width: "100%",
    cursor: "pointer",
    px: 2,
    py: 1.5,
  },

  favoriteCheckbox: {
    p: 0.75,
    "& .MuiSvgIcon-root": { fontSize: 16 },
  },
};
