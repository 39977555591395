import { useState, useEffect } from "react";

import { useAppDispatch } from "./useRedux";

import { setSnackBarMsjSucceded, asyncUpdateUserDataAction } from "@/store/slices/auth/actions";

const useAuth = () => {
  const dispatch = useAppDispatch();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkLocalStorage = async () => {
      try {
        const authenticatedUser = await dispatch(asyncUpdateUserDataAction());

        if (authenticatedUser) {
          setUser(authenticatedUser);
        }

        setLoading(false);
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
        setLoading(false);
      }
    };

    checkLocalStorage();
  }, []);

  return { user, loading };
};

export default useAuth;
