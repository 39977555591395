/* eslint-disable react/prop-types */
import { ListItem, ListItemAvatar, ListItemText } from "@mui/material";

import { styles } from "./styles";

export default function RouteResultListItem({ icon, primaryLabel, secondaryLabel }: any) {
  return (
    <ListItem dense>
      {icon && <ListItemAvatar sx={styles.listItemAvatar}>{icon}</ListItemAvatar>}
      <ListItemText
        primary={primaryLabel}
        primaryTypographyProps={{
          variant: "caption",
          color: "text.secondary",
        }}
        secondary={secondaryLabel}
        secondaryTypographyProps={{
          variant: "subtitle2",
          color: "text.primary",
        }}
      />
    </ListItem>
  );
}
