import { Stack, Skeleton } from "@mui/material";

interface Props {
  numberOfItems?: number;
}

function SkeletonListItem() {
  return (
    <Stack alignItems="center" direction="row" px={2} py={1.5} spacing={2} sx={{ width: "100%" }}>
      <Skeleton
        animation="wave"
        height={40}
        style={{ flexShrink: 0 }}
        variant="circular"
        width={40}
      />
      <Stack spacing={1} sx={{ width: "100%" }}>
        <Skeleton animation="wave" sx={{ fontSize: "12px" }} variant="text" width="60%" />
        <Skeleton animation="wave" sx={{ fontSize: "12px" }} variant="text" width="20%" />
      </Stack>
    </Stack>
  );
}

export default function SearchResultsSkeleton({ numberOfItems = 10 }: Props) {
  return (
    <Stack spacing={0} sx={{ width: "100%" }}>
      {[...Array(numberOfItems)].map((o, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <SkeletonListItem key={i} />
      ))}
    </Stack>
  );
}
