import { AxiosResponseHeaders } from "axios";

import { UserData } from "@/types/auth";

export const saveDataUser = (data: UserData, headers: AxiosResponseHeaders) => {
  const { uid, client, expiry, "access-token": token } = headers;

  localStorage.setItem(
    "auth",
    JSON.stringify({
      uid,
      client,
      expiry,
      "access-token": token,
    }),
  );

  localStorage.setItem(
    "user",
    JSON.stringify({
      ...data,
    }),
  );

  return data;
};
