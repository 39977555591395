import { alpha } from "@mui/material/styles";

import { layoutStyles } from "@/themes/layoutStyles";
import defaultTheme from "@/themes/theme";

export const styles = (categorySelected?: boolean) => ({
  scrollContainer: {
    ...layoutStyles.scroll,

    overflowY: "auto",
    maxHeight: "100vh",
    position: "relative",
    height: "100%",

    "&:before": {
      content: "''",
      position: "sticky",
      display: "block",
      width: "100%",
      height: categorySelected ? "125px" : "72px",
      pointerEvents: "none",
      background: `linear-gradient(0deg, ${alpha(defaultTheme.palette.common.white, 0)} 0%, ${alpha(
        defaultTheme.palette.common.white,
        1,
      )} 50%)`,
      right: 0,
      top: 0,
      zIndex: 1,
    },
  },
});
