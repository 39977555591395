import { Stack, DialogContent, DialogActions, Button, List, Box, Alert } from "@mui/material";
import { useFormik } from "formik";

import { styles } from "./styles";
import EditFuelListItem from "./EditAmenitiesListItem";

import servicePoi from "@/services/poi";
import { useAppDispatch } from "@/hooks/useRedux";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { addSelectedPoi } from "@/store/slices/search";
import { IMapPois } from "@/types/pois";
import { TAmenitiesItems } from "@/components/PlaceInfo/PlaceInfoSections/PlaceInfoAmenities/PlaceInfoAmenities";

interface IEditAmenitiesModal {
  onClose: () => void;
  amenitiesItems: TAmenitiesItems[];
  poiSelected: IMapPois;
}

function EditAmenitiesModal({ onClose, amenitiesItems, poiSelected }: IEditAmenitiesModal) {
  const dispatch = useAppDispatch();

  const handleForm = async (values: any) => {
    if (poiSelected.boaters_can_update_amenities) {
      try {
        const { status, data } = await servicePoi.updatePoiInfo(poiSelected?.id, {
          ...values,
          other_transportation_info: values.transportation_info,
          other_pet_info: values.pets_welcome_info,
        });

        if (status === 200) {
          dispatch(addSelectedPoi(data.data));
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Amenities was edited.",
            }),
          );
          onClose();
        }
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      supplies: poiSelected?.supplies,
      supplies_info: poiSelected?.supplies_info,
      cable_satellite: poiSelected?.cable_satellite,
      cable_satellite_info: poiSelected?.cable_satellite_info,
      captains_lounge: poiSelected?.captains_lounge,
      captains_lounge_info: poiSelected?.captains_lounge_info,
      fitness_center: poiSelected?.fitness_center,
      fitness_center_info: poiSelected?.fitness_center_info,
      floating_docks: poiSelected?.floating_docks,
      floating_docks_info: poiSelected?.floating_docks_info,
      grocery: poiSelected?.grocery,
      grocery_info: poiSelected?.grocery_info,
      haul_out_capabilities: poiSelected?.haul_out_capabilities,
      haul_out_capabilities_info: poiSelected?.haul_out_capabilities_info,
      ice: poiSelected?.ice,
      ice_info: poiSelected?.ice_info,
      laundry: poiSelected?.laundry,
      laundry_info: poiSelected?.laundry_info,
      repair_capabilities: poiSelected?.repair_capabilities,
      repair_capabilities_info: poiSelected?.repair_capabilities_info,
      pets_welcome: poiSelected?.pets_welcome,
      pets_welcome_info: poiSelected?.other_pet_info,
      picnic_area: poiSelected?.picnic_area,
      picnic_area_info: poiSelected?.picnic_area_info,
      playground: poiSelected?.playground,
      playground_info: poiSelected?.playground_info,
      pump_out: poiSelected?.pump_out,
      pump_out_info: poiSelected?.pump_out_info,
      restaurant: poiSelected?.restaurant,
      restaurant_info: poiSelected?.restaurant_info,
      bathrooms: poiSelected?.bathrooms,
      bathrooms_info: poiSelected?.bathrooms_info,
      security: poiSelected?.security,
      security_info: poiSelected?.security_info,
      showers: poiSelected?.showers,
      showers_info: poiSelected?.showers_info,
      pool: poiSelected?.pool,
      pool_info: poiSelected?.pool_info,
      transportation: poiSelected?.transportation,
      transportation_info: poiSelected?.other_transportation_info,
      trash: poiSelected?.trash,
      trash_info: poiSelected?.trash_info,
      wifi: poiSelected?.wifi,
      wifi_info: poiSelected?.wifi_info,
    },
    onSubmit: handleForm,
  });

  return (
    <>
      {!poiSelected.boaters_can_update_amenities && (
        <Box sx={{ pl: 3, pr: 3, mb: 2 }}>
          <Alert severity="error">
            Editing amenities data has been disabled by the facility owner.
          </Alert>
        </Box>
      )}

      <DialogContent>
        <Stack
          spacing={2}
          sx={
            !poiSelected.boaters_can_update_amenities
              ? { opacity: 0.6, pointerEvents: "none", py: 0 }
              : { py: 0 }
          }
        >
          <Stack mb={2} sx={styles.card}>
            <List disablePadding sx={styles.list}>
              {amenitiesItems.map((item) => (
                <EditFuelListItem
                  key={item.primary}
                  label={item.primary}
                  valueName={item.valueName}
                  values={formik.values}
                  onChangeValue={formik.setFieldValue}
                />
              ))}
            </List>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={
          !poiSelected.boaters_can_update_amenities
            ? { opacity: 0.6, pointerEvents: "none", ...styles.dialogActions }
            : { ...styles.dialogActions }
        }
      >
        <Button onClick={onClose}>Cancel</Button>
        <Button type="button" variant="contained" onClick={() => formik.handleSubmit()}>
          Save
        </Button>
      </DialogActions>
    </>
  );
}

export default EditAmenitiesModal;
