/* eslint-disable no-console */
import { Chip, Divider, Stack, Typography, TextField } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { DatePicker } from "@mui/x-date-pickers";

import { EPeriodFilter } from "../CaptainsLogList";

import { styles } from "./styles";

interface IReportPeriodFilter {
  filter: string;
  customDate: { date_from: Date; date_to: Date };
  onSelectPeriodFilter: (perdio: EPeriodFilter) => void;
  onSetCustomDate: (date: Date, dateType: string) => void;
}

function ReportPeriodFilter({
  filter,
  customDate,
  onSetCustomDate,
  onSelectPeriodFilter,
}: IReportPeriodFilter) {
  return (
    <Stack mb={3}>
      <Stack pb={3} px={3} spacing={2}>
        <Typography variant="subtitle1">Report Period</Typography>
        <Stack direction="row" spacing={1}>
          <Chip
            icon={filter === EPeriodFilter.Last3Days ? <DoneIcon /> : undefined}
            label="Last 3 days"
            sx={filter === EPeriodFilter.Last3Days ? styles.chip : styles.inactiveChip}
            onClick={() => onSelectPeriodFilter(EPeriodFilter.Last3Days)}
          />
          <Chip
            icon={filter === EPeriodFilter.LastWeek ? <DoneIcon /> : undefined}
            label="Last week"
            sx={filter === EPeriodFilter.LastWeek ? styles.chip : styles.inactiveChip}
            onClick={() => onSelectPeriodFilter(EPeriodFilter.LastWeek)}
          />
          <Chip
            icon={filter === EPeriodFilter.LastMonth ? <DoneIcon /> : undefined}
            label="Last month"
            sx={filter === EPeriodFilter.LastMonth ? styles.chip : styles.inactiveChip}
            onClick={() => onSelectPeriodFilter(EPeriodFilter.LastMonth)}
          />
          <Chip
            icon={filter === EPeriodFilter.CustomPeriod ? <DoneIcon /> : undefined}
            label="Custom Period"
            sx={filter === EPeriodFilter.CustomPeriod ? styles.chip : styles.inactiveChip}
            onClick={() => onSelectPeriodFilter(EPeriodFilter.CustomPeriod)}
          />
        </Stack>
        {filter === EPeriodFilter.CustomPeriod && (
          <Stack direction="row" spacing={2}>
            <DatePicker
              renderInput={(props) => <TextField {...props} label="Start Date" sx={{ flex: 1 }} />}
              value={customDate.date_from}
              onChange={(newValue) => onSetCustomDate(newValue as Date, "date_from")}
            />
            <DatePicker
              renderInput={(props) => <TextField {...props} label="End Date" sx={{ flex: 1 }} />}
              value={customDate.date_to}
              onChange={(newValue) => onSetCustomDate(newValue as Date, "date_to")}
            />
          </Stack>
        )}
      </Stack>
      <Divider />
    </Stack>
  );
}

export default ReportPeriodFilter;
