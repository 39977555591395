import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  List,
  Typography,
  Stack,
  IconButton,
  RadioGroup,
  DialogActions,
  Button,
  FormControl,
  DialogContent,
} from "@mui/material";
import { CloseOutlined, DownloadOutlined, MailOutline } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

import { styles } from "./styles";
import ActionModalListItem from "./ActionModalListItem";

import BaseIcon from "@/components/BaseIcon";
import { ReactComponent as FileExport } from "&/img/icons/FileExportOutlined.svg";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { useAppDispatch } from "@/hooks/useRedux";
import serviceVoyages from "@/services/voyages";

interface IActionModal {
  onClose: () => void;
  voyageId: number | undefined;
}

const ActionOptions = [
  {
    icon: <MailOutline />,
    primaryLabel: "Send GPX file to My Email",
    value: "gpx-email",
    selected: true,
  },
  {
    icon: <DownloadOutlined />,
    primaryLabel: "Download GPX File",
    value: "gpx-file",
    selected: false,
  },
];

function ActionModal({ onClose, voyageId }: IActionModal) {
  const dispatch = useAppDispatch();
  const [gpxDownloadType, setGpxDownloadType] = useState<string>("gpx-email");

  const handleSetGpxDownloadType = (value: string) => {
    setGpxDownloadType(value);
  };

  const handleDownloadGpxFile = async () => {
    try {
      if (voyageId) {
        if (gpxDownloadType === "gpx-file") {
          const { status, data } = await serviceVoyages.downloadGpxFile(voyageId);

          const blob = new Blob([data], { type: "application/xml" });

          const downloadLink = document.createElement("a");

          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.download = `route-gpx-file-${voyageId}.gpx`;

          downloadLink.click();

          window.URL.revokeObjectURL(downloadLink.href);

          if (status === 200) {
            dispatch(
              setSnackBarMsjSucceded({
                state: true,
                type: "success",
                msj: "GPX file downloaded.",
              }),
            );
          }
        } else {
          const { status } = await serviceVoyages.downloadGpxFileByEmail(voyageId);

          if (status === 200) {
            dispatch(
              setSnackBarMsjSucceded({
                state: true,
                type: "success",
                msj: "GPX file sent to your email.",
              }),
            );
          }
        }

        onClose();
      }
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
  };

  return (
    <Dialog open maxWidth="xs" onClose={onClose}>
      <DialogTitle sx={styles.dialogTitle}>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack alignItems="center" direction="row">
            <BaseIcon icon={FileExport} sx={{ color: "primary.main" }} />
            <Typography color="primary.main" sx={{ ml: 1 }} variant="h6">
              Export as GPX File
            </Typography>
          </Stack>
          <Stack>
            <IconButton onClick={onClose}>
              <CloseOutlined sx={{ fontSize: 22 }} />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ pb: 1 }}>
        <FormControl fullWidth>
          <RadioGroup
            aria-labelledby="action options"
            name="action-options"
            sx={styles.radioGroup}
            value={gpxDownloadType}
          >
            <List disablePadding>
              {ActionOptions.map((item) => (
                <ActionModalListItem
                  key={item.value}
                  icon={item.icon}
                  primary={item.primaryLabel}
                  selected={gpxDownloadType === item.value}
                  value={item.value}
                  onClick={handleSetGpxDownloadType}
                />
              ))}
            </List>
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          loading={false}
          type="button"
          variant="contained"
          onClick={handleDownloadGpxFile}
        >
          Apply
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ActionModal;
