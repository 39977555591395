import { Button, Grid } from "@mui/material";

import IconChip from "@/components/IconChip";
import { ITempPin, TPOI_TYPE } from "@/utils/poi";

interface IPinsList {
  items: TPOI_TYPE[];
  iconUrl: string;
  onAddPin: (item: ITempPin) => void;
}

function PinsList({ items, iconUrl, onAddPin }: IPinsList) {
  return (
    <Grid container columnSpacing={1} rowSpacing={1}>
      {items.map((item: TPOI_TYPE) => (
        <Grid key={item.label} item xs="auto">
          <Button key={item.value} sx={{ p: 0 }} onClick={() => onAddPin(item as ITempPin)}>
            <IconChip
              icon={`/img/markers${iconUrl}/${item.value.replace("/", "")}.svg`}
              label={item.label}
            />
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}

export default PinsList;
