import { layoutStyles } from "@/themes/layoutStyles";

export const styles = {
  container: {
    m: 1.5,
    mt: "68px",
    top: 0,
    left: "488px",
    zIndex: 6,
    position: "absolute",
    height: "calc(100vh - 92px)",
    overflow: "hidden",
    width: 408,
  },

  headerIcon: {
    color: "var(--Light-Primary-Dark, #08467E)",
  },

  footerPanel: {
    p: 2,
  },

  headerTitle: {
    color: "var(--Light-Primary-Dark, #08467E)",
    fontFeatureSettings: "'liga' off, 'clig' off",
  },

  headerPanel: {
    px: 2,
    py: 1.5,
    backgroundColor: "var(--Light-Primary-Shades-4p, rgba(2, 119, 189, 0.04))",
  },

  mainContent: {
    ...layoutStyles.scroll,
    overflowY: "auto",
    height: "calc(100vh - 220px)",
    py: 3,
    px: 2,
  },

  colorsSection: {
    mt: 1,
  },
};
