import { useState } from "react";
import { TextField, Button, Stack, Typography, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import dayjs from "dayjs";

import BaseModal from "@/components/Modals/BaseModal";
import ServiceMap from "@/services/map";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { feetToMeter, meterToFeet, vesselDefault, getPriorityCostFactor } from "@/utils/boats";
import { isLatLng } from "@/utils/poi";
import { ERouteSettings } from "@/types/route";
import socialService from "@/services/social";
import uploadAsset from "@/utils/uploadAsset";

type SaveRoute = {
  name: string;
  description: string;
};

interface ISaveRouteForm {
  open: boolean;
  onClose: () => void;
  onCleanRouteStatus: () => void;
  onTakeScreenshot: () => Promise<File | null>;
}

const handleCheckRouteName = (value?: string) => {
  if (value && isLatLng(value)) {
    return "Custom Location";
  }

  return value;
};

export default function SaveRouteForm({
  open,
  onClose,
  onCleanRouteStatus,
  onTakeScreenshot,
}: ISaveRouteForm) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { startPoint, endPoint } = useAppSelector((state) => state.route);
  const { userInformation, userOptions } = useAppSelector((state) => state.user);

  const handleSubmit = async (values: SaveRoute) => {
    try {
      const routeName = values.name || `Route ${dayjs().format("YYYY-MM-DD hh:mm:ss A")}`;
      const routeDescription = values.description;

      const dataVoyage = {
        name: routeName,
        description: routeDescription,
        voyage_type: "route",
        taken: false,
        manual_route: false,
        average_speed: userInformation?.current_vessel?.average_speed,
        boat_type: userInformation?.current_vessel?.boat_type,
        buffer: userInformation?.current_vessel
          ? userInformation.current_vessel.buffer
          : vesselDefault.defaultBuffer * meterToFeet,
        draft: userInformation?.current_vessel
          ? userInformation.current_vessel.draft
          : vesselDefault.defaultDraft * meterToFeet,
        height: userInformation?.current_vessel
          ? userInformation.current_vessel.height
          : vesselDefault.defaultHeight,
        boat_name: userInformation?.current_vessel?.name,
        manufacturer_model: userInformation?.current_vessel?.manufacturer_model,
        fuel_type: userInformation?.current_vessel?.fuel_type,
        fuel_consumption: userInformation?.current_vessel?.fuel_consumption,
        length: userInformation?.current_vessel?.length,
        beam: userInformation?.current_vessel?.beam,
        origin: "mm",
      };

      const { status, data } = await ServiceMap.createVoyage(dataVoyage);
      const voyage_id = data.id;

      if (status === 201) {
        const routeScreenShot = await onTakeScreenshot();

        if (routeScreenShot instanceof File && voyage_id) {
          const assetResponse = await socialService.createVoyageImage({
            voyageId: voyage_id,
            fileName: routeScreenShot.name,
            kind: "map",
          });

          const presignedUrl = assetResponse.upload_url;

          await uploadAsset({ url: presignedUrl, file: routeScreenShot });
        }

        const prioritycostfactor =
          (startPoint && endPoint && getPriorityCostFactor(startPoint, endPoint)) || 0;
        const avoidOcean = userOptions.route_settings === ERouteSettings.Avoid;
        const avoidInland = userOptions.route_settings === ERouteSettings.Outside;
        const hug_shore =
          userOptions.route_settings === ERouteSettings.Outside ? userOptions.hug_shore : undefined;

        const dataRoute = {
          name: routeName,
          description: routeDescription,
          start_name: handleCheckRouteName(startPoint?.name),
          end_name: handleCheckRouteName(endPoint?.name),
          min_depth: userInformation?.current_vessel
            ? userInformation.current_vessel.draft * feetToMeter
            : vesselDefault.defaultDraft,
          buffer_depth: userInformation?.current_vessel
            ? userInformation.current_vessel.buffer * feetToMeter
            : vesselDefault.defaultBuffer,
          bridge_vert: userInformation?.current_vessel
            ? userInformation.current_vessel.height * feetToMeter
            : vesselDefault.defaultHeight * feetToMeter,
          bridge_horiz: userInformation?.current_vessel
            ? userInformation.current_vessel.beam * feetToMeter
            : undefined,
          prioritycostfactor,
          avoid_ocean: avoidOcean,
          avoid_inland: avoidInland,
          end_lat: endPoint?.lat,
          end_lng: endPoint?.lng,
          start_lat: startPoint?.lat,
          start_lng: startPoint?.lng,
          voyage_id,
          hug_shore,
          access: "canada", // TODO: Set this conditionally based on valid subscriptions.
        };
        const { status } = await ServiceMap.saveRouteCourses(dataRoute);

        if (status === 201) {
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Route successfully saved to Captain's Log",
            }),
          );
          onCleanRouteStatus();
        }
      }
      setLoading(false);
      onClose();
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    } as SaveRoute,
    onSubmit: async (values: SaveRoute) => {
      setLoading(true);
      handleSubmit(values);
    },
  });

  return (
    <BaseModal open={open} size="xs" title="Save to Captain’s Log" onClose={onClose}>
      <Stack component="form" spacing={2} sx={{ px: 3, pb: 2 }} onSubmit={formik.handleSubmit}>
        <Typography color="text.secondary" variant="body1">
          Add a route name and description.
        </Typography>

        <Stack spacing={2}>
          <TextField
            fullWidth
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            label="Route Name"
            name="name"
            placeholder="e.g. St. Michaels, Maryland"
            value={formik.values.name ?? ""}
            onChange={formik.handleChange}
          />
          <TextField
            fullWidth
            multiline
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
            label="Route Description"
            minRows={4}
            name="description"
            placeholder="e.g. An awesome trip on the Chesapeake Bay with friends"
            value={formik.values.description ?? ""}
            onChange={formik.handleChange}
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button
            color="whiteBg"
            sx={{ color: "primary.main" }}
            type="submit"
            variant="outlined"
            onClick={onClose}
          >
            Go Back
          </Button>
          <Button color="primary" disabled={loading} type="submit" variant="contained">
            {loading ? <CircularProgress size={30} sx={{ color: "white" }} /> : "Save & Exit"}
          </Button>
        </Stack>
      </Stack>
    </BaseModal>
  );
}
