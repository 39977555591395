export const styles = {
  accordionBorder: {
    border: "1px solid",
    borderColor: "divider",
    overflow: "hidden",
    borderRadius: "8px",
  },

  numberedList: {
    typography: "body2",
    listStyleType: "decimal",
    pl: 2,
    py: 0,
    "& .MuiListItem-root": {
      display: "list-item",
      px: 0,
      pt: 0,
      pb: 0.75,
    },
    "& .MuiSvgIcon-root": {
      color: "action.active",
      fontSize: 14,
      mb: -0.25,
    },
  },

  bulletList: {
    typography: "body2",
    listStyleType: "disc",
    pl: 2,
    pt: 1,
    pb: 0,
    "& .MuiListItem-root": {
      display: "list-item",
      px: 0,
      pt: 0,
      pb: 0.75,
    },
    "& .MuiSvgIcon-root": {
      color: "action.active",
      fontSize: 14,
      mb: -0.25,
    },
  },
};
