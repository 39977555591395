import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  SvgIconProps,
} from "@mui/material";
import React from "react";

import { styles } from "./styles";

import { addPoisConfiguration } from "@/store/slices/map";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";

interface IMapOptionsListItem {
  icon?: React.ReactElement<SvgIconProps> | null;
  idName: string;
  name: string;
  disabled?: boolean;
}

export default function MapOptionsListItem({
  icon = null,
  idName,
  name,
  disabled = false,
}: IMapOptionsListItem) {
  const dispatch = useAppDispatch();
  const { poisConfigured } = useAppSelector((state) => state.map);

  const handleSwitch = async () => {
    dispatch(addPoisConfiguration({ ...poisConfigured, [idName]: !poisConfigured?.[idName] }));
  };

  return (
    <ListItem disablePadding>
      <ListItemButton disabled={disabled} sx={styles.listItemButton} onClick={handleSwitch}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText
          id="#"
          primary={name}
          primaryTypographyProps={{
            variant: "body1",
          }}
        />
        <Switch checked={poisConfigured[idName]} color="secondary" edge="end" size="small" />
      </ListItemButton>
    </ListItem>
  );
}
