import * as React from "react";
import { Typography, Box, List, ListItem } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { ChevronRight, ExpandMoreOutlined, Lock, MyLocation } from "@mui/icons-material";

import { styles } from "./styles";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters square elevation={0} {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:first-of-type": {
    borderTop: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  boxShadow: "inset 0 1px 10px rgb(0 0 0 / 5%)",
  "& .MuiIconButton-root": {
    pointerEvents: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreOutlined />} {...props} />
))(({ theme }) => ({
  "&.MuiButtonBase-root:hover": {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.12)}`,
    color: theme.palette.primary.main,
  },
  "& .MuiAccordionSummary-content": {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexDirection: "column",
  },
  "&.MuiButtonBase-root.Mui-expanded": {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.12)}`,
    color: theme.palette.primary.main,
  },
}));

export default function ActivateLocationAccordion() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box sx={styles.accordionBorder}>
      <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <AccordionSummary
          aria-controls="panel1a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel1a-header"
        >
          <Typography sx={{ lineHeight: "normal" }} variant="subtitle2">
            Chrome
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={styles.numberedList}>
            <ListItem>On your computer, open Chrome and go to Argo.</ListItem>
            <ListItem>
              At the bottom right, click My location <MyLocation />.
              <List sx={styles.bulletList}>
                <ListItem>If you&apos;re asked to share your location, choose Allow.</ListItem>
                <ListItem>
                  If the blue dot appears and shows your location, then Argo already has location
                  permission in your browser.
                </ListItem>
                <ListItem>
                  If there&apos;s a message that says &quot;Argo does not have permission to use
                  your location,&quot; continue to the next steps.
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              To the left of the web address, click Lock <Lock />.
            </ListItem>
            <ListItem>
              Click <b>Site settings.</b>
            </ListItem>
            <ListItem>
              To the right of &quot;Location,&quot; choose <b>Allow.</b>
            </ListItem>
            <ListItem>
              Reload the Argo page and click My location <MyLocation /> again.
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <AccordionSummary
          aria-controls="panel2a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel2a-header"
        >
          <Typography sx={{ lineHeight: "normal" }} variant="subtitle2">
            Firefox
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={styles.numberedList}>
            <ListItem>On your computer, open Firefox and go to Argo.</ListItem>
            <ListItem>
              At the bottom right, click My location <MyLocation />.
              <List sx={styles.bulletList}>
                <ListItem>
                  When asked to share your location, choose <b>Allow or Allow location access.</b>
                </ListItem>
                <ListItem>You might find &quot;Show your location&quot; instead.</ListItem>
                <ListItem>
                  If the blue dot appears and shows your location, then Argo already has location
                  permission in your browser.
                </ListItem>
                <ListItem>
                  If there’s a message that says &quot;Argo does not have permission to use your
                  location,&quot; continue to the next steps.
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              To the left of the web address, click Lock <Lock />.
            </ListItem>
            <ListItem>
              Click <b>Connection secure</b> <ChevronRight /> <b>More information.</b>
            </ListItem>
            <ListItem>
              Select <b>Permissions.</b>
            </ListItem>
            <ListItem>
              To the right of &quot;Access your location,&quot; uncheck <b>Use Default.</b> Then,
              choose <b>Allow.</b>
            </ListItem>
            <ListItem>
              Reload the Argo page and click My location <MyLocation /> again.
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
        <AccordionSummary
          aria-controls="panel3a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel3a-header"
        >
          <Typography sx={{ lineHeight: "normal" }} variant="subtitle2">
            Safari
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography gutterBottom variant="body2">
            <b>Important:</b> To use Safari, you must have Location Services enabled.
          </Typography>
          <List sx={styles.numberedList}>
            <ListItem>On your computer, open Safari and go to Argo.</ListItem>
            <ListItem>
              At the bottom right, click My location <MyLocation />.
              <List sx={styles.bulletList}>
                <ListItem>
                  You should be asked to share your location. Choose <b>Allow.</b>
                </ListItem>
                <ListItem>You might find &quot;Show your location&quot; instead.</ListItem>
                <ListItem>
                  If the blue dot appears and shows your location, then Argo already has location
                  permission in your browser.
                </ListItem>
                <ListItem>
                  If there&apos;s a message that says &quot;Argo does not have permission to use
                  your location&quot; refresh your browser.
                </ListItem>
              </List>
            </ListItem>
          </List>

          <Typography gutterBottom variant="body2">
            <b>To turn on Location Services in Safari:</b>
          </Typography>
          <List sx={styles.numberedList}>
            <ListItem>
              Click the Apple icon <ChevronRight /> <b>System Preferences.</b>
            </ListItem>
            <ListItem>
              Click the &quot;Security & Privacy&quot; icon. Then, select Privacy.
            </ListItem>
            <ListItem>In the bottom left, click the Lock icon so that it&apos;s unlocked.</ListItem>
            <ListItem>
              Check <b>Enable Location Services.</b>
            </ListItem>
            <ListItem>
              In the list, check <b>Safari.</b>
            </ListItem>
            <ListItem>Click the Lock icon so that it&apos;s locked.</ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
        <AccordionSummary
          aria-controls="panel4a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel4a-header"
        >
          <Typography sx={{ lineHeight: "normal" }} variant="subtitle2">
            Microsoft Edge
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={styles.numberedList}>
            <ListItem>On your computer, open Microsoft Edge and go to Argo.</ListItem>
            <ListItem>
              At the bottom right, click My location <MyLocation />.
              <List sx={styles.bulletList}>
                <ListItem>
                  If you’re asked to share your location, chose <b>Allow.</b>
                </ListItem>
                <ListItem>You might find &quot;Show your location&quot; instead.</ListItem>
                <ListItem>
                  If the blue dot appears and shows your location, then Argo already has location
                  permission in your browser.
                </ListItem>
                <ListItem>
                  If there’s a message that says &quot;Argo does not have permission to use your
                  location&quot; continue to the next steps.
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              To the left of the web address, click Lock <Lock />.
            </ListItem>
            <ListItem>
              Click <b>Permissions for this site.</b>
            </ListItem>
            <ListItem>
              To the right of &quot;Location&quot; choose <b>Allow.</b>
            </ListItem>
            <ListItem>
              Reload the Argo page and click My location <MyLocation />.
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
