import { Paper, Fab, Grow, Box, ClickAwayListener, Tooltip } from "@mui/material";
import { Layers, LayersOutlined } from "@mui/icons-material";

import { styles } from "./styles";
import MapOptionsAccordion from "./MapOptionsAccordion";
import MapOptionDepthShading from "./MapOptionDepthShading";
import MapTypes from "./MapTypes";

import PanelHeader from "@/components/PanelHeader/PanelHeader";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { addOpenMapOptions, setCoords } from "@/store/slices/map";
import { useGetDepthShadingsQuery, useGetUserOptionsQuery } from "@/store/api/argoApi";

export default function MapOptions() {
  const dispatch = useAppDispatch();
  const { openMapOptions, coords } = useAppSelector((state) => state.map);
  const { user } = useAppSelector((state) => state.auth);
  const { data: depthShadingData } = useGetDepthShadingsQuery({ user_id: user?.id });
  const { data: userOptionsData } = useGetUserOptionsQuery(undefined);
  const { logged } = useAppSelector((state) => state.auth);
  const handleOpenModal = () => {
    dispatch(addOpenMapOptions(true));
    if (coords) dispatch(setCoords(undefined));
  };

  const handleCloseModal = () => {
    dispatch(addOpenMapOptions(false));
  };

  return (
    <ClickAwayListener onClickAway={handleCloseModal}>
      <Box sx={styles.container}>
        <Tooltip arrow placement="left" title="Map Options">
          <Fab size="small" sx={styles.fabButton} onClick={handleOpenModal}>
            <Layers />
          </Fab>
        </Tooltip>
        <Grow
          unmountOnExit
          in={openMapOptions}
          style={{ transformOrigin: "top right" }}
          timeout="auto"
        >
          <Paper elevation={6} sx={{ ...styles.paper, zIndex: 6 }}>
            <PanelHeader close={handleCloseModal} icon={<LayersOutlined />} title="Map Options" />
            <Box className="mapOptionsScroll" sx={styles.scrollContainer}>
              <MapTypes />
              {logged && depthShadingData && userOptionsData?.data && (
                <MapOptionDepthShading
                  depthShadingData={depthShadingData}
                  shaderOpacity={userOptionsData?.data?.shader_opacity}
                  shaderOpacityEnabled={userOptionsData?.data?.depth_shading_enabled}
                />
              )}
              <MapOptionsAccordion />
            </Box>
          </Paper>
        </Grow>
      </Box>
    </ClickAwayListener>
  );
}
