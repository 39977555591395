import { layoutStyles } from "@/themes/layoutStyles";

export const styles = {
  header: {
    px: 2,
    py: 1.5,
  },

  containerTop: {
    zIndex: 30,
    position: "absolute",
    top: "-188px",
    left: "-168px",
    paddingBottom: 4, // Don't delete this css property, works to the close functionality
    boxSizing: "border-box",
  },

  containerTopLeft: {
    zIndex: 30,
    position: "absolute",
    top: "-188px",
    left: "-336px",
    paddingBottom: 4, // Don't delete this css property, works to the close functionality
    boxSizing: "border-box",
  },

  containerTopRight: {
    zIndex: 30,
    position: "absolute",
    top: "-188px",
    left: "0px",
    paddingBottom: 4, // Don't delete this css property, works to the close functionality
    boxSizing: "border-box",
  },

  containerBottom: {
    zIndex: 30,
    position: "absolute",
    top: "0px",
    left: "-168px",
    paddingTop: 4, // Don't delete this css property, works to the close functionality
    boxSizing: "border-box",
  },

  containerBottomLeft: {
    zIndex: 30,
    position: "absolute",
    top: "0px",
    left: "-336px",
    paddingTop: 4, // Don't delete this css property, works to the close functionality
    boxSizing: "border-box",
  },

  containerBottomRight: {
    zIndex: 30,
    position: "absolute",
    top: "0px",
    left: "0px",
    paddingTop: 4, // Don't delete this css property, works to the close functionality
    boxSizing: "border-box",
  },

  paper: {
    zIndex: 20,
    width: "360px",
    p: 2,
  },

  paperToTop: {
    width: "360px",
    p: 2,
    cursor: "pointer",
    bottom: 0,
    transformOrigin: "top",
    position: "absolute",
  },

  closeButton: {
    position: "absolute",
    right: "4px",
    top: "4px",
  },

  image: {
    flex: 1,
    display: "center",
    justifyContent: "center",
  },

  textContainer: {
    flex: 4,
  },

  textParagraph: {
    lineHeight: "1.1",
  },

  reportPinIcon: {
    width: 64,
    height: 64,
  },

  avatar: {
    width: 64,
    height: 64,
    borderRadius: 1,
  },

  likeButton: {
    minWidth: 54,
    color: "action.active",
    boxShadow: 1,
    "&:hover": {
      boxShadow: 3,
    },
    "&.Mui-active .MuiButton-startIcon": {
      color: "success.main",
    },
  },

  ellipsedText: {
    ...layoutStyles.ellipsisOneLine,
    wordBreak: "break-all",
  },

  dislikeButton: {
    minWidth: 54,
    boxShadow: 1,
    color: "action.active",
    "&:hover": {
      boxShadow: 3,
    },
    "&.Mui-active .MuiButton-startIcon": {
      color: "error.main",
    },
  },
};
