/* eslint-disable react/prop-types */
import {
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import { useUnitSystem, LengthUnits } from "@argonav/unit-system";

import { styles } from "./styles";

import { ISearchResultListItem } from "@/types/pois";

export default function SearchResultListItem({
  icon,
  id,
  distance,
  primaryLabel,
  secondaryLabel,
  action,
  numberList,
  selected,
  showSecondaryAction,
  secondaryActionIcon,
  secondaryAction,
}: ISearchResultListItem) {
  const { distanceUnitHandler }: { distanceUnitHandler: any } = useUnitSystem();
  const distanceFromLocation = distance
    ? distanceUnitHandler({
        originalUnit: LengthUnits.METERS,
        originalValue: Number(distance),
      })
    : undefined;

  return (
    <ListItem key={`${numberList + 1}. ${primaryLabel}`} disablePadding>
      <ListItemButton selected={selected} onClick={action}>
        {icon && (
          <ListItemAvatar sx={styles.listItemAvatar}>
            {icon}
            {distanceFromLocation && (
              <Typography variant="caption">{`${distanceFromLocation.value} ${distanceFromLocation.unit}`}</Typography>
            )}
          </ListItemAvatar>
        )}
        <ListItemText
          id={id?.toString()}
          primary={`${numberList + 1}. ${primaryLabel}`}
          primaryTypographyProps={{
            variant: "subtitle2",
          }}
          secondary={secondaryLabel}
          secondaryTypographyProps={{
            variant: "body2",
            sx: styles.secondaryText,
          }}
        />
        {showSecondaryAction && (
          <ListItemSecondaryAction>
            <IconButton aria-label="delete" edge="end" size="small" onClick={secondaryAction}>
              {secondaryActionIcon}
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItemButton>
    </ListItem>
  );
}
