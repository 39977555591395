export const KEY_CODE_ENTER = "Enter";
export const KEY_GMODAL_NON_USER = "gModalNoN";
export const KEY_GMODAL_USER = "gModal_";
export const KEY_FMODAL_USER = "fModal_";
export const KEY_FMODAL_NON_USER = "fModalNoN";
export const KEY_RWMODAL_USER = "rwModal_";

/* Users and Friends */
export const KEY_USER_FRIENDS = "friends";
export const KEY_USER_NON_FRIENDS = "non_friends";

/* Report Pins Votes */
export const KEY_VOTE_DOWN = "vote_type_down";
export const KEY_VOTE_UP = "vote_type_up";

/* Viewport Modal */
export const KEY_VMODAL_NON_USER = "vModalNoN";
export const KEY_VMODAL_USER = "vModal_";

/* New Quick Pin Modal */
export const KEY_PMODAL_NON_USER = "pModalNoN";
export const KEY_PMODAL_USER = "pModal_";
