import { Box } from "@mui/material";

import BoatEditModal from "@/components/Route/BoatEditModal";
import PinsOptions from "@/components/Pins/PinsOptions";
import RouteOptions from "@/components/Route/RouteOptions";
import RouteWarning from "@/components/Route/RouteWarning";
import { useAppSelector } from "@/hooks/useRedux";

function OtherOptions() {
  const { creatingRoute, noDepthAlert } = useAppSelector((state) => state.route);

  return (
    <>
      {creatingRoute ? (
        <>
          <Box sx={{ mt: 1.5 }}>
            <BoatEditModal />
          </Box>
          <Box sx={{ mt: 1.5 }}>
            <RouteOptions />
          </Box>
        </>
      ) : (
        <Box sx={{ mt: 1.5 }}>
          <PinsOptions />
        </Box>
      )}
      {noDepthAlert && (
        <Box sx={{ mt: 1.5 }}>
          <RouteWarning />
        </Box>
      )}
    </>
  );
}

export default OtherOptions;
