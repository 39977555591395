export const styles = {
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dialogContent: {
    py: 2,
  },

  dialogActions: {
    px: 3,
    py: 2,
  },

  avatar: {
    border: "4px solid",
    borderColor: "background.paper",
    boxShadow: 12,
    width: 140,
    height: 140,
  },

  cover: {
    minHeight: 160,
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  editGroup: {
    borderRadius: 1,
    border: "1px solid",
    borderColor: "divider",
    overflow: "hidden",
    minWidth: 460,
  },

  editGroupContent: {
    p: 2,
    pb: 2.5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  editGroupSize: {
    width: 160,
    "& .MuiSvgIcon-root": {
      fontSize: 18,
      color: "primary.main",
    },
  },

  editGroupActions: {
    px: 2,
    py: 1,
    backgroundColor: "grey.50",
  },
};
