import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function LocationMarkerActive(props: SvgIconProps) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 20 33">
      <circle cx="10" cy="25" fill="#D52B1E" r="6.75" stroke="white" strokeWidth="2" />
      <path
        d="M10 24L9.43144 24.4891L10 25.15L10.5686 24.4891L10 24ZM10 24C10.5686 24.4891 10.5687 24.489 10.5688 24.4889L10.5692 24.4884L10.5705 24.4869L10.5753 24.4813L10.5934 24.4601L10.6623 24.379C10.7222 24.3082 10.8096 24.2044 10.9205 24.0711C11.1422 23.8044 11.4581 23.4193 11.8368 22.9434C12.5937 21.9923 13.6045 20.6748 14.6167 19.2122C15.6271 17.7524 16.6492 16.1335 17.4215 14.5813C18.1818 13.0532 18.75 11.4939 18.75 10.1818C18.75 5.26489 14.8483 1.25 10 1.25C5.15171 1.25 1.25 5.26489 1.25 10.1818C1.25 11.4939 1.81817 13.0532 2.57853 14.5813C3.35085 16.1335 4.3729 17.7524 5.3833 19.2122C6.39554 20.6748 7.40627 21.9923 8.16315 22.9434C8.5419 23.4193 8.85782 23.8044 9.07954 24.0711C9.19042 24.2044 9.27778 24.3082 9.33773 24.379L9.4066 24.4601L9.42471 24.4813L9.42952 24.4869L9.43084 24.4884L9.43123 24.4889C9.43135 24.489 9.43144 24.4891 10 24Z"
        fill="url(#locationMarkerIconActive)"
        stroke="white"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="locationMarkerIconActive"
          x1="20"
          x2="20"
          y1="9.08663"
          y2="-7.277"
        >
          <stop stopColor="#EF3319" />
          <stop offset="1" stopColor="#FFD9A4" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
