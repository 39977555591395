import { Stack, Box, Typography } from "@mui/material";

import { styles } from "./styles";

import { depth_shading_items } from "@/types/map";

interface IDepthIndicatorLayout {
  shaderItems: depth_shading_items[];
}
function DepthIndicatorLayout({ shaderItems }: IDepthIndicatorLayout) {
  const shaderItemsCopy = [...shaderItems];
  const sortedArray = shaderItemsCopy.sort((a, b) => Number(a.distance) - Number(b.distance));

  return (
    <>
      {sortedArray.map((item) => (
        <Stack key={item.id} className="depthIndicatorItem" spacing={0.25}>
          <Box
            className="depthIndicatorColor"
            sx={{
              ...styles.depthIndicatorColor,
              backgroundColor: `rgb(${item.color})`,
            }}
          />
          <Typography color="text.secondary" textAlign="center" variant="caption">
            {item.distance}
            {item.distance === "11" && "+"} ft
          </Typography>
        </Stack>
      ))}
    </>
  );
}

export default DepthIndicatorLayout;
