import { useAppSelector } from "./useRedux";

import { Interest } from "@/types/interest";

const useUserInterests = () => {
  const userInterests: Interest[] | undefined = useAppSelector((state) => state.user.interests);

  return { userInterests };
};

export default useUserInterests;
