import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { APIProvider } from "@vis.gl/react-google-maps";
import * as Sentry from "@sentry/react";

import ContainerApp from "./ContainerApp";

import appTheme from "@/themes/theme";
import { store } from "@/store/store";

Sentry.init({
  dsn: "https://06269a6bbf7ee8d41c06e1aa31463f1f@o4508139262050304.ingest.us.sentry.io/4508201989308416",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Provider store={store}>
    <ThemeProvider theme={appTheme}>
      <CssBaseline enableColorScheme />
      <GoogleOAuthProvider clientId={import.meta.env.VITE_APP_GOOGLE_CLIENT_ID}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <APIProvider apiKey={import.meta.env.VITE_APP_GOOGLE_MAPS_API_KEY}>
            <ContainerApp />
          </APIProvider>
        </LocalizationProvider>
      </GoogleOAuthProvider>
    </ThemeProvider>
  </Provider>,
);
