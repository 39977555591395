import React from "react";
import {
  Stack,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Rating,
  TextField,
  Chip,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { styles } from "./styles";

import { useAppDispatch } from "@/hooks/useRedux";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import ServicePoi from "@/services/poi";
import { TRating } from "@/types/pois";
import { addSelectedPoi } from "@/store/slices/search";
import { newOverallRaiting } from "@/utils/poi";

const formSchema = yup.object({
  rating: yup.number().nullable().required("Rating is required."),
  review: yup.string().required("Review description is required."),
});

interface IReviewAddModal {
  onClose: () => void;
  poiSelected: any;
}

const labels: { [index: string]: string } = {
  0.5: "0.5",
  1: "1",
  1.5: "1.5",
  2: "2",
  2.5: "2.5",
  3: "3",
  3.5: "3.5",
  4: "4",
  4.5: "4.5",
  5: "5",
};

function getLabelText(value: number) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

function ReviewAddModal({ onClose, poiSelected }: IReviewAddModal) {
  const dispatch = useAppDispatch();
  const [hover, setHover] = React.useState(-1);

  const handleSubmit = async (values: TRating) => {
    try {
      const { status, data } = await ServicePoi.setPoiRating(poiSelected?.id, values);

      if (status === 201) {
        dispatch(
          addSelectedPoi({
            ...poiSelected,
            poi_ratings_count: poiSelected.poi_ratings_count + 1,
            poi_ratings_overall: Math.round(
              newOverallRaiting(
                poiSelected.poi_ratings_overall,
                poiSelected.poi_ratings_count,
                values?.rating || 0,
              ),
            ),
            poi_ratings: [
              data,
              ...poiSelected.poi_ratings.slice(0, poiSelected.poi_ratings.length - 1),
            ],
            all_reviews: [data, ...poiSelected.all_reviews],
          }),
        );

        dispatch(
          setSnackBarMsjSucceded({
            state: true,
            type: "success",
            msj: "Your review was added.",
          }),
        );
        onClose();
      }
    } catch (error: any) {
      dispatch(
        setSnackBarMsjSucceded({
          state: true,
          type: "error",
          msj: error.response.data.message.toString(),
        }),
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      rating: null,
      review: "",
    } as TRating,
    validationSchema: formSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Box sx={{ px: 3 }}>
        <Stack spacing={2}>
          <Box sx={styles.header}>
            <Typography gutterBottom component="h4" sx={styles.poiTitle} variant="h6">
              {poiSelected?.name}
            </Typography>
            <Typography gutterBottom variant="body2">
              {poiSelected?.address}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <DialogContent>
        <Stack mb={2}>
          <Typography variant="subtitle2">Rate this place</Typography>
          <Stack alignItems="center" direction="row" spacing={1}>
            <FormControl error={formik.touched.rating && Boolean(formik.errors.rating)}>
              <Rating
                getLabelText={getLabelText}
                id="rating"
                name="rating"
                precision={1}
                sx={styles.rating}
                value={formik.values.rating}
                onChange={(event, value) => formik.setFieldValue("rating", Number(value))}
                onChangeActive={(event, newHover) => {
                  setHover(newHover);
                }}
              />
              <FormHelperText id="name-text" sx={{ color: "error.main" }}>
                {formik.touched.rating && formik.errors.rating}
              </FormHelperText>
            </FormControl>
            {hover !== -1 && (
              <Chip
                label={labels[hover !== -1 ? hover : formik.values.rating || 0]}
                sx={styles.chip}
              />
            )}
          </Stack>
        </Stack>
        <FormControl
          error={formik.touched.review && Boolean(formik.errors.review)}
          sx={{ width: "100%" }}
        >
          <TextField
            fullWidth
            multiline
            id="review"
            label="Write your review"
            minRows={4}
            name="review"
            placeholder="Share details of your experience at this place. (optional)"
            size="small"
            value={formik.values.review}
            onChange={formik.handleChange}
          />
          <FormHelperText id="name-text" sx={{ color: "error.main" }}>
            {formik.touched.review && formik.errors.review}
          </FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Box>
  );
}

export default ReviewAddModal;
