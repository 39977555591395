import { useEffect, useRef, useState } from "react";
import isEqual from "lodash.isequal";

const THROTTLE_INTERVAL = 3000; // 3 secs

export function useThrottle<T>(value: T, interval = THROTTLE_INTERVAL): T {
  const [throttledValue, setThrottledValue] = useState<T>(value);
  const lastExecuted = useRef<number>(Date.now());

  useEffect(() => {
    if (!isEqual(value, throttledValue)) {
      if (Date.now() >= lastExecuted.current + interval) {
        lastExecuted.current = Date.now();
        setThrottledValue(value);
      } else {
        const timerId = setTimeout(() => {
          lastExecuted.current = Date.now();
          setThrottledValue(value);
        }, interval);

        return () => clearTimeout(timerId);
      }
    }
  }, [value, interval]);

  return throttledValue;
}
