import * as React from "react";
import { ListItem, ListItemIcon, ListItemText, ListItemButton, Radio } from "@mui/material";

import { styles } from "./styles";

interface IActionModalListItem {
  primary: string;
  secondary?: string;
  // eslint-disable-next-line no-undef
  icon?: JSX.Element;
  value: string;
  selected: boolean;
  onClick: (value: string) => void;
}

export default function ActionModalListItem({
  primary,
  secondary,
  icon,
  value,
  selected,
  onClick,
}: IActionModalListItem) {
  return (
    <ListItem disablePadding divider>
      <ListItemButton selected={selected} sx={styles.listItemButton} onClick={() => onClick(value)}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText
          primary={primary}
          primaryTypographyProps={{
            variant: !secondary ? "body1" : "subtitle1",
          }}
          secondary={secondary}
          secondaryTypographyProps={{
            variant: "body2",
          }}
        />
        <ListItemIcon>
          <Radio size="small" value={value} />
        </ListItemIcon>
      </ListItemButton>
    </ListItem>
  );
}
