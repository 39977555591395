import React, { useState, forwardRef, ForwardedRef } from "react";
import SlickSlider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";

interface ISlider {
  children: React.ReactNode;
  settings?: any;
}

const Slider = forwardRef((props: ISlider, ref: ForwardedRef<any>) => {
  const [isSwiping, setIsSwiping] = useState(false);

  const defaultSettings = {
    className: `slider variable-width sliderback`,
    accessibility: false,
    arrows: false,
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 2,
    variableWidth: true,
    swipeToSlide: false,
    beforeChange: () => setIsSwiping(true),
    afterChange: () => setIsSwiping(false),
  };

  const mergedSettings = { ...defaultSettings, ...props.settings };

  return (
    <SlickSlider {...mergedSettings} ref={ref}>
      {React.Children.map(props.children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              if (isSwiping) {
                e.stopPropagation();
                e.preventDefault();
              }
            },
          } as React.HTMLAttributes<HTMLElement>);
        }

        return child;
      })}
    </SlickSlider>
  );
});

export default Slider;
