export enum ERouteSettings {
  Default = "default",
  Avoid = "avoid",
  Outside = "outside",
}

export type TRoutePoints = {
  depth: number;
  id: number;
  lat: number;
  lng: number;
};
export interface ICourse {
  id: number;
  course_bridges: any;
  distance: string;
  start_name: string;
  start_address: string | null;
  start_lat: string;
  start_lng: string;
  end_name: string;
  end_address: string | null;
  end_lat: string;
  end_lng: string;
  start_place: string | null;
  end_place: string | null;
  start_poi: string | null;
  end_poi: string | null;
  voyage_id: number;
  created_at: string;
  updated_at: string;
  course_locations: TRoutePoints[];
}

export interface IVoyage {
  id: number;
  description: string;
  name: string;
  voyage_type: string;
  created_at: string;
  updated_at: string;
  distance?: string;
  taken: boolean;
  start_date: string | null;
  end_date: string | null;
  manual_route: boolean;
  favorite: boolean;
  favorite_datetime: string | null;
  saved: boolean;
  draft: number;
  buffer: number;
  average_speed: number;
  fuel_consumption: string;
  fuel_type: string;
  origin: string;
  offline: boolean;
  start_location_name: string;
  end_location_name: string;
  boat_name: string;
  manufacturer_model: string;
  height: string;
  courses: ICourse[];
  vessel: any;
  assets: any;
}

export interface IVoyages {
  items: IVoyage[];
  total_items: number;
}
