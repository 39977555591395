import { Box, Stack, Chip, Collapse, IconButton, Tooltip } from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";

import SearchCategories from "../SearchCategories/SearchCategories";

import { styles } from "./styles";

import useDisclosure from "@/hooks/useDisclosure";
import { TPOI_TYPE } from "@/utils/poi";

interface ISearchCategoriesCarousel {
  selectedCategories: TPOI_TYPE[];
  onDeleteCategory: (value: TPOI_TYPE) => void;
  onSetInputValue: (value: TPOI_TYPE | undefined, action: boolean, clearAll?: any) => void;
}

export default function SearchCategoriesCarousel({
  selectedCategories,
  onDeleteCategory,
  onSetInputValue,
}: ISearchCategoriesCarousel) {
  const { isOpen, onToggle } = useDisclosure();

  const handleSelectCategory = (item: TPOI_TYPE, action?: boolean) => {
    onSetInputValue(item, action || false);
  };

  const sortedSelectedCategories = [...selectedCategories].sort((a: TPOI_TYPE, b: TPOI_TYPE) =>
    a.label.localeCompare(b.label),
  );

  return (
    <Box sx={styles.wrapper}>
      <Box sx={isOpen ? styles.carouselContainerOpen : styles.carouselContainer}>
        <Box sx={styles.carousel}>
          <Box sx={styles.container}>
            <Stack direction="row" spacing={0.75}>
              {sortedSelectedCategories.map((item) => (
                <Chip
                  key={item.value}
                  color="primary"
                  label={item.label}
                  sx={styles.chip}
                  onDelete={() => onDeleteCategory(item)}
                />
              ))}
            </Stack>
          </Box>
        </Box>
        <Box sx={styles.buttonContainer}>
          <Tooltip arrow placement="bottom" title="Search Categories">
            <IconButton
              sx={{ ...styles.openButton, transform: isOpen ? "rotate(180deg)" : "rotate(0deg)" }}
              onClick={onToggle}
            >
              <ExpandMoreOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={styles.categoriesContainer}>
        <Collapse unmountOnExit in={isOpen} timeout="auto">
          <SearchCategories
            selectedCategories={selectedCategories}
            setSelectedCategories={handleSelectCategory}
          />
        </Collapse>
      </Box>
    </Box>
  );
}
