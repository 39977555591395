/* eslint-disable react/jsx-props-no-spreading */
import { IconButton, Stack, TextField, Tooltip } from "@mui/material";
import {
  TripOriginOutlined,
  SwapVertOutlined,
  Place,
  SearchOutlined,
  CloseOutlined,
} from "@mui/icons-material";
import React from "react";
import { useUnitSystem } from "@argonav/unit-system";

import { styles } from "./styles";

import { Props } from "@/types/users";
import BaseIcon from "@/components/BaseIcon";
import { ReactComponent as LockOpen } from "&/img/icons/LockOpenIcon.svg";
import { ReactComponent as LockClosed } from "&/img/icons/LockClosedIcon.svg";
import { isLatLng, isDMSFormat, isDDMFormat } from "@/utils/poi";

export default function RouteSearchBox({
  setPoint,
  startInput,
  endInput,
  switchLocations,
  inputsFocus,
  setFocus,
  clearRouteInput,
  lockedValue,
  onUnlockStartPoint,
  onLockStartPoint,
  voyageEditMode,
}: Props) {
  const handleSetFocus = (e: React.FocusEvent<HTMLInputElement>, type: number) => {
    e.target.select();
    setFocus(type);
  };

  const { coordinatesFormatHandler } = useUnitSystem();

  const extractLatAndLng = (input: string) => {
    const [lat, lng] = input.split(",");

    return { lat, lng };
  };

  let inputStartValue;
  let inputEndValue;

  if (isLatLng(startInput) || isDMSFormat(startInput) || isDDMFormat(startInput)) {
    const startValues = startInput ? extractLatAndLng(startInput) : { lat: "", lng: "" };

    const latLngFormattedStart = coordinatesFormatHandler({
      latitude: Number(startValues?.lat),
      longitude: Number(startValues?.lng),
    });

    inputStartValue = latLngFormattedStart;
  } else {
    inputStartValue = startInput;
  }

  if (isLatLng(endInput) || isDMSFormat(endInput) || isDDMFormat(endInput)) {
    const endValues = endInput ? extractLatAndLng(endInput) : { lat: "", lng: "" };

    const latLngFormattedEnd = coordinatesFormatHandler({
      latitude: Number(endValues?.lat),
      longitude: Number(endValues?.lng),
    });

    inputEndValue = latLngFormattedEnd;
  } else {
    inputEndValue = endInput;
  }

  return (
    <Stack alignItems="center" direction="row" spacing={0.5} sx={styles.container}>
      <Stack alignItems="center" justifyContent="space-between" sx={styles.iconTrack}>
        <TripOriginOutlined color="primary" sx={{ fontSize: 16 }} />
        <Place color="error" sx={{ fontSize: 20 }} />
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <TextField
          InputProps={{
            endAdornment: startInput ? (
              <Stack direction="row">
                {lockedValue && !voyageEditMode ? (
                  <IconButton onClick={onUnlockStartPoint}>
                    <BaseIcon icon={LockClosed} />
                  </IconButton>
                ) : (
                  <>
                    {!voyageEditMode && (
                      <Tooltip arrow placement="top" title="Lock Starting Point">
                        <IconButton onClick={onLockStartPoint}>
                          <BaseIcon icon={LockOpen} />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip arrow placement="top" title="Clear">
                      <IconButton onClick={() => clearRouteInput(1)}>
                        <CloseOutlined />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </Stack>
            ) : (
              <SearchOutlined />
            ),
          }}
          disabled={lockedValue}
          focused={inputsFocus === 1}
          label="Choose a starting point"
          sx={{
            ...styles.textfield,
            "& .MuiSvgIcon-root": {
              opacity: startInput ? 1 : 0,
            },
          }}
          type="text"
          value={inputStartValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPoint(e, 1)}
          onFocus={(e: React.FocusEvent<HTMLInputElement>) => handleSetFocus(e, 1)}
        />
        <TextField
          InputProps={{
            endAdornment: endInput ? (
              <IconButton onClick={() => clearRouteInput(2)}>
                <CloseOutlined />
              </IconButton>
            ) : (
              <SearchOutlined />
            ),
          }}
          focused={inputsFocus === 2 || lockedValue}
          label="Choose a destination"
          sx={{
            ...styles.textfield,
            "& .MuiSvgIcon-root": {
              opacity: endInput ? 1 : 0,
            },
          }}
          type="text"
          value={inputEndValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPoint(e, 2)}
          onFocus={(e: React.FocusEvent<HTMLInputElement>) => handleSetFocus(e, 2)}
        />
      </Stack>
      <IconButton aria-label="delete" size="small" sx={styles.iconButton} onClick={switchLocations}>
        <SwapVertOutlined />
      </IconButton>
    </Stack>
  );
}
