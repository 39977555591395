import { DialogContent, DialogActions, Button, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import GpxDropzone from "@/components/GpxDropzone";

interface IImportGpxModal {
  files: File[];
  loading: boolean;
  onClose: () => void;
  onSave: () => void;
  onUploadFile: (files: File[]) => void;
}

function ImportGpxModal({ files, loading, onClose, onUploadFile, onSave }: IImportGpxModal) {
  return (
    <>
      <DialogContent sx={{ pt: 0, pb: 0 }}>
        <Typography color="text.secondary" sx={{ mb: "12px" }} variant="body1">
          Import multiple places from a GPX file.
        </Typography>
        <GpxDropzone action={onUploadFile} files={files} />
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button type="button" onClick={onClose}>
          Discard
        </Button>
        <LoadingButton autoFocus loading={loading} variant="contained" onClick={onSave}>
          Save
        </LoadingButton>
      </DialogActions>
    </>
  );
}

export default ImportGpxModal;
