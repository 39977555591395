import { useEffect, useMemo } from "react";
import { useMap } from "@vis.gl/react-google-maps";
import { GoogleMapsOverlay } from "@deck.gl/google-maps";
import { LayersList } from "@deck.gl/core";

interface IDeckGlOverlay {
  layers: LayersList;
}

function DeckGlOverlay({ layers }: IDeckGlOverlay) {
  const deck = useMemo(() => new GoogleMapsOverlay({ interleaved: true }), []);

  const map = useMap();

  useEffect(() => {
    deck.setMap(map);

    return () => deck.setMap(null);
  }, [map]);
  useEffect(() => deck.setProps({ layers }), [layers]);

  // no dom rendered by this component
  return null;
}

export default DeckGlOverlay;
