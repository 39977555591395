import { TextField, Stack, Button, InputAdornment } from "@mui/material";
import { SwapVert, SwapHoriz } from "@mui/icons-material";
import React from "react";

import { NAME_INPUT_LAT_DEGREE, NAME_INPUT_LNG_DEGREE } from "../SearchCoordinatesExpanded";

interface ISearchCoordinatesDD {
  isToggledXAxis: boolean;
  isToggledYAxis: boolean;
  latValue: string;
  lngValue: string;
  onHandleToggleXAxis: () => void;
  onHandleToggleYAxis: () => void;
  onSetCoordinatesValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export default function SearchCoordinatesDD({
  isToggledXAxis,
  isToggledYAxis,
  latValue,
  lngValue,
  onHandleToggleXAxis,
  onHandleToggleYAxis,
  onSetCoordinatesValue,
}: ISearchCoordinatesDD) {
  return (
    <Stack spacing={1.5}>
      <Stack direction="row" spacing={1}>
        <Button
          color="primary"
          startIcon={<SwapVert />}
          sx={{ borderRadius: 1, typography: "h6" }}
          variant="text"
          onClick={onHandleToggleYAxis}
        >
          {isToggledYAxis ? "S" : "N"}
        </Button>
        <TextField
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="start">º</InputAdornment>,
          }}
          id="latitude"
          name={NAME_INPUT_LAT_DEGREE}
          type="text"
          value={latValue}
          variant="outlined"
          onChange={onSetCoordinatesValue}
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <Button
          color="primary"
          startIcon={<SwapHoriz />}
          sx={{ borderRadius: 1, typography: "h6" }}
          variant="text"
          onClick={onHandleToggleXAxis}
        >
          {isToggledXAxis ? "E" : "W"}
        </Button>
        <TextField
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="start">º</InputAdornment>,
          }}
          id="longitude"
          name={NAME_INPUT_LNG_DEGREE}
          type="text"
          value={lngValue}
          variant="outlined"
          onChange={onSetCoordinatesValue}
        />
      </Stack>
    </Stack>
  );
}
