import { layoutStyles } from "@/themes/layoutStyles";

export const styles = {
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dialogContent: {
    ...layoutStyles.scroll,
    py: 2,
  },

  dialogActions: {
    px: 3,
    py: 2,
  },

  homeportInput: {
    "& .MuiInputBase-input": {
      textFillColor: "rgba(0, 0, 0, 0.87)",
    },
  },
};
