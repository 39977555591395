import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";
import { convertDistance } from "geolib";
import { useState } from "react";
import {
  LengthUnits,
  VolumeUnits,
  SpeedUnits,
  ShortLengthUnit,
  convertToKnots,
  convertToGallons,
  useUnitSystem,
} from "@argonav/unit-system";

import { styles } from "./styles";
import EditBoatForm from "./EditBoatForm";

import { useAppSelector, useAppDispatch } from "@/hooks/useRedux";
import { TVessel } from "@/types/vessel";
import ServiceVessel from "@/services/vessel";
import { getVesselsSuccess, getUserInformation } from "@/store/slices/user/actions";
import useUserInformation from "@/hooks/useUserInformation";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { vesselDefault } from "@/utils/boats";
import { capitalizeWord } from "@/utils/globals";
import AlertDialog from "@/components/AlertDialog";

const formSchema = yup.object({
  name: yup.string().required("Name is a required field"),
});

interface IEditBoatModal {
  open: boolean;
  close: () => void;
}
export default function EditBoatModal({ open, close }: IEditBoatModal) {
  const dispatch = useAppDispatch();
  const { uploadBoatImage } = useUserInformation();
  const { userInformation, userOptions } = useAppSelector((state) => state.user);
  const [alert, setAlert] = useState<boolean>(false);
  const { lengthUnitHandler, consumptionUnitHandler, speedUnitHandler } = useUnitSystem();
  const vessel = userInformation?.current_vessel;
  const [boatType, setBoatType] = useState<string>(vessel?.boat_type || "power");

  const handleConvertToFeet = (values: TVessel) => {
    if (capitalizeWord(userOptions.length_unit.toLowerCase()) !== LengthUnits.FEET) {
      values.beam = convertDistance(Number(values.beam), ShortLengthUnit.FtString);
      values.draft = convertDistance(Number(values.draft), ShortLengthUnit.FtString);
      values.buffer = convertDistance(Number(values.buffer), ShortLengthUnit.FtString);
      values.length = convertDistance(Number(values.length), ShortLengthUnit.FtString);
      values.height = convertDistance(Number(values.height), ShortLengthUnit.FtString);
    }

    if (capitalizeWord(userOptions.speed_unit.toLowerCase()) !== SpeedUnits.KNOT) {
      values.average_speed = Number(
        convertToKnots(values.average_speed, userOptions.speed_unit as SpeedUnits).toFixed(1),
      );
    }

    if (capitalizeWord(userOptions.volume_unit.toLowerCase()) !== VolumeUnits.GALLONS) {
      values.fuel_consumption = String(convertToGallons(Number(values.fuel_consumption)));
    }

    /* After set values on feet and knots, we return the values and default conversions */

    if (String(values.buffer) === "") {
      values.buffer = convertDistance(
        Number(vesselDefault.defaultBuffer),
        ShortLengthUnit.FtString,
      );
    }

    if (String(values.draft) === "") {
      values.draft = convertDistance(Number(vesselDefault.defaultDraft), ShortLengthUnit.FtString);
    }

    if (String(values.height) === "") {
      values.height = vesselDefault.defaultHeight;
    }

    return values;
  };

  const handleCheckSpeedType = (value: string) => {
    if (value === "power") return 20;
    if (value === "jet_ski") return 50;
    if (value === "sail") return 5;
    if (value === "paddle") return 3;
    if (value === "other") return 10;

    return 20;
  };

  const handleForm = async (values: TVessel) => {
    const valuesConverted = handleConvertToFeet(values);

    if (!alert && valuesConverted.buffer <= 2) {
      setAlert(true);

      return;
    }
    setAlert(false);
    if (vessel) {
      try {
        if (values?.image instanceof File && vessel.id) {
          await uploadBoatImage(values.image, vessel.id);
        }

        const { status, data } = await ServiceVessel.editVessel(vessel?.id, valuesConverted);

        if (status === 200) {
          dispatch(getVesselsSuccess([data]));
          dispatch(getUserInformation(userInformation?.id));
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Boat info was saved.",
            }),
          );
          close();
        }
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      }
    } else {
      try {
        const { status, data } = await ServiceVessel.createVessel(values);

        if (status === 201) {
          if (values?.image instanceof File && data?.id) {
            await uploadBoatImage(values.image, data.id);
          }
          const { data: dataVessel } = await ServiceVessel.getVessels();

          dispatch(getVesselsSuccess(dataVessel));
          dispatch(getUserInformation(userInformation?.id));
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Boat info was saved.",
            }),
          );
          close();
        }
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      }
    }
  };

  const boatBeam = lengthUnitHandler({
    originalUnit: LengthUnits.FEET,
    originalValue: vessel?.beam || 0,
  });
  const boatDraft = lengthUnitHandler({
    originalUnit: LengthUnits.FEET,
    originalValue: vessel?.draft || 5,
  });
  const boatBuffer = lengthUnitHandler({
    originalUnit: LengthUnits.FEET,
    originalValue: vessel?.buffer || 5,
  });
  const boatLength = lengthUnitHandler({
    originalUnit: LengthUnits.FEET,
    originalValue: vessel?.length || 0,
  });
  const boatHeight = lengthUnitHandler({
    originalUnit: LengthUnits.FEET,
    originalValue: vessel?.height || 16,
  });
  const boatAvgSpeed = speedUnitHandler({
    originalUnit: SpeedUnits.KNOT,
    originalValue: vessel?.average_speed || handleCheckSpeedType(boatType || "power"),
  });
  const boatFuelConsumption = consumptionUnitHandler({
    originalUnit: VolumeUnits.GALLONS,
    originalValue: Number(vessel?.fuel_consumption) || 0,
  });

  const formik = useFormik({
    initialValues: {
      name: vessel?.name,
      boat_type: vessel?.boat_type || "power",
      manufacturer_model: vessel?.manufacturer_model,
      beam: boatBeam.value,
      draft: boatDraft.value,
      buffer: boatBuffer.value,
      length: boatLength.value,
      height: boatHeight.value,
      average_speed: boatAvgSpeed.value,
      fuel_consumption: boatFuelConsumption.value,
      fuel_type: vessel?.fuel_type,
      image: vessel?.assets[0]?.download_url,
      port: vessel?.port,
      port_name: vessel?.port_name,
      lat: vessel?.lat,
      lng: vessel?.lng,
    } as TVessel,
    validationSchema: formSchema,
    onSubmit: handleForm,
  });

  const handleOnchangeBoatType = (value: string) => {
    formik.setFieldValue("boat_type", value);

    const boatAvgSpeed = speedUnitHandler({
      originalUnit: SpeedUnits.KNOT,
      originalValue: vessel?.average_speed || handleCheckSpeedType(value || "power"),
    });

    formik.setFieldValue("average_speed", boatAvgSpeed.value);
    setBoatType(value);
  };

  return (
    <>
      <Dialog
        aria-describedby="edit-boat-dialog-description"
        aria-labelledby="edit-boat-dialog-title"
        maxWidth="sm"
        open={open}
        onClose={close}
      >
        <DialogTitle id="alert-dialog-title" sx={styles.dialogTitle}>
          Edit Boat Information
          <IconButton size="small" onClick={close}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <EditBoatForm
            formData={formik}
            isEditing={vessel?.id !== undefined}
            onChangeBoatType={handleOnchangeBoatType}
          />
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button onClick={close}>Cancel</Button>
          <Button autoFocus variant="contained" onClick={() => formik.handleSubmit()}>
            Save
          </Button>
        </DialogActions>
      </Dialog>{" "}
      {alert && (
        <AlertDialog
          close={() => setAlert(false)}
          confirm={() => formik.handleSubmit()}
          message="We recommend a buffer of at least 3 ft (~1 m) or 2 times the draft. This will give you an adequate margin of safety above your draft, keeping your route in deeper water. The default is 5 ft (~1.5 m)."
          open={alert}
          positiveButtonTitle="Save"
          title="Warning"
        />
      )}
    </>
  );
}
