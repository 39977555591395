import { Stack, Skeleton } from "@mui/material";

export default function PlacePopoverSkeleton() {
  return (
    <Stack direction="row" justifyContent="flex-start" spacing={1.5} sx={{ width: "100%" }}>
      <Skeleton
        animation="wave"
        height={48}
        sx={{ flexShrink: 0, px: 2 }}
        variant="circular"
        width={48}
      />
      <Stack sx={{ width: "100%" }}>
        <Skeleton animation="wave" sx={{ fontSize: "24px" }} variant="text" width="50%" />
        <Skeleton animation="wave" sx={{ fontSize: "16px" }} variant="text" width="70%" />
        <Skeleton animation="wave" sx={{ fontSize: "16px", mt: 1 }} variant="text" width="80%" />
        <Stack direction="row" mt={1} spacing={1}>
          <Skeleton animation="wave" height={24} variant="rounded" width={90} />
          <Skeleton animation="wave" height={24} variant="rounded" width={90} />
        </Stack>
      </Stack>
    </Stack>
  );
}
