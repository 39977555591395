import React from "react";
import {
  Stack,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Box,
  List,
  ListItem,
} from "@mui/material";

import ActivateLocationAccordion from "../ActivateLocationAccordion/ActivateLocationAccordion";

import { styles } from "./styles";

interface IActivateLocationModal {
  onClose: () => void;
}

function ActivateLocationModal({ onClose }: IActivateLocationModal) {
  return (
    <>
      <DialogContent>
        <Stack component="form" spacing={2}>
          <Typography>
            Argo may have trouble finding your location. If the GPS location of your blue dot on the
            map is inaccurate or missing, you can take steps to help fix the problem.
          </Typography>

          <Typography>
            Tip: These steps will also improve your search results and make them more relevant to
            you.
          </Typography>

          <Box>
            <Typography gutterBottom variant="subtitle1">
              Give Argo location permission in your browser
            </Typography>
            <ActivateLocationAccordion />
          </Box>
          <Box>
            <Typography gutterBottom variant="subtitle1">
              More ways to improve location accuracy
            </Typography>
            <Typography>
              If you get an error like &quot;Your location cannot be determined&quot; or your
              location is still wrong, here are some steps you can take:
            </Typography>
            <List sx={styles.bulletList}>
              <ListItem>Reload your browser (such as Chrome, Firefox, or Safari).</ListItem>
              <ListItem>Check to make sure you have a strong internet connection.</ListItem>
              <ListItem>
                Double-check your browser’s permission settings using the instructions above.
              </ListItem>
              <ListItem>Restart your computer.</ListItem>
            </List>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button autoFocus variant="contained" onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </>
  );
}

export default ActivateLocationModal;
