import React from "react";
import {
  Stack,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  TextField,
  Box,
  Select,
  MenuItem,
  Alert,
} from "@mui/material";
import { useFormik } from "formik";
import { SelectChangeEvent } from "@mui/material/Select";

import { styles } from "./styles";

import servicePoi from "@/services/poi";
import { useAppDispatch } from "@/hooks/useRedux";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { addSelectedPoi } from "@/store/slices/search";
import { IMapPois } from "@/types/pois";

type TApproachForm = {
  approach: string | null;
  tidal_change: string | null;
  current: string | undefined;
  tide_and_extra: string | null;
};

interface IEditTransientModal {
  onClose: () => void;
  poiSelected: IMapPois;
}

function EditApproachModal({ onClose, poiSelected }: IEditTransientModal) {
  const dispatch = useAppDispatch();

  const handleForm = async (values: TApproachForm) => {
    if (poiSelected.boaters_can_update_approachs) {
      try {
        const { status, data } = await servicePoi.updatePoiInfo(poiSelected?.id, values);

        if (status === 200) {
          dispatch(addSelectedPoi(data.data));
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Approach Info was edited.",
            }),
          );
          onClose();
        }
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      approach: poiSelected?.approach,
      tidal_change: poiSelected?.tidal_change,
      current: poiSelected?.current,
      tide_and_extra: poiSelected?.tide_and_extra,
    } as TApproachForm,
    onSubmit: handleForm,
  });

  return (
    <>
      {!poiSelected.boaters_can_update_approachs && (
        <Box sx={{ pl: 3, pr: 3, mb: 2 }}>
          <Alert severity="error">
            Editing approach data has been disabled by the facility owner.
          </Alert>
        </Box>
      )}
      <Box
        component="form"
        sx={
          !poiSelected.boaters_can_update_approachs ? { opacity: 0.6, pointerEvents: "none" } : null
        }
        onSubmit={formik.handleSubmit}
      >
        <DialogContent sx={{ py: 0 }}>
          <Stack spacing={2} sx={{ mt: 1 }}>
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth>
                <InputLabel htmlFor="length-max">Approach</InputLabel>
                <OutlinedInput
                  endAdornment={<InputAdornment position="end">ft</InputAdornment>}
                  id="approach"
                  label="Approach"
                  name="approach"
                  value={formik.values.approach}
                  onChange={formik.handleChange}
                />
              </FormControl>

              <FormControl fullWidth>
                <InputLabel htmlFor="beam-max">Tidal Change</InputLabel>
                <OutlinedInput
                  endAdornment={<InputAdornment position="end">ft</InputAdornment>}
                  id="tidal_change"
                  label="Tidal Change"
                  name="tidal_change"
                  value={formik.values.tidal_change}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </Stack>
            <Stack direction="row">
              <FormControl fullWidth>
                <InputLabel htmlFor="beam-max">Current</InputLabel>
                <Select
                  id="current"
                  label="Current"
                  labelId="current"
                  value={formik.values.current}
                  onChange={(e: SelectChangeEvent) =>
                    formik.setFieldValue("current", e.target.value as string)
                  }
                >
                  <MenuItem value="Strong">Strong</MenuItem>
                  <MenuItem value="Weak">Weak</MenuItem>
                  <MenuItem value="None">None</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <TextField
              fullWidth
              multiline
              id="tide_and_extra"
              label="Other info"
              minRows={3}
              name="tide_and_extra"
              size="small"
              value={formik.values.tide_and_extra}
              onChange={formik.handleChange}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Box>
    </>
  );
}

export default EditApproachModal;
