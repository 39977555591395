import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";

export const styles = {
  formControl: {
    width: "100%",
  },

  listItems: {
    pb: 0,
    pl: 0,
    pr: 0,
  },

  listItemContainer: {
    m: 0,
    p: 0,
  },

  listContainer: {
    m: 0,
    p: 0,
    pr: 1,
    width: "100%",
  },

  listItemSubHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  formControlLabel: {
    mx: 0,
    width: "100%",
    border: "2px solid",
    borderColor: "divider",
    borderRadius: 1,
    px: 2,
    py: 1.5,
    transition: defaultTheme.transitions.create(["all", "transform"], {
      duration: defaultTheme.transitions.duration.standard,
    }),
    "&:hover": {
      borderColor: `${alpha(defaultTheme.palette.primary.main, 0.5)}`,
      backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.04)}`,
    },
  },
};
