import { useState } from "react";
import { Stack, DialogActions, DialogContent, Button, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Edit } from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import { styles } from "./styles";

// import BaseIcon from "@/components/BaseIcon";
import StartIndicatorIcon from "&/img/markers/location/StartIndicator.png";
import EndIndicatorIcon from "&/img/markers/location/EndIndicator.png";
import { IVoyage } from "@/types/route";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import ServiceMap from "@/services/map";
import { getVoyage, setSelectedVoyage } from "@/store/slices/captainslog/actions";
import { addCoursesCopy, addVoyages } from "@/store/slices/captainslog";
import Dropzone from "@/components/Dropzone";
import useUserInformation from "@/hooks/useUserInformation";
import BaseIcon from "@/components/BaseIcon";

const formSchema = yup.object({
  name: yup.string().required("Name is a required field"),
});

const BYTE_SIZE_LIMIT = 2e6;

interface IEditRouteModal {
  selectedVoyage: IVoyage | null | undefined;
  onClose: () => void;
  onCloseRouteSaved: () => void;
  onUpdateItem: (value: boolean) => void;
}

type TEditRouteForm = {
  name: string;
  description: string;
  image?: File | string | undefined;
};

function EditRouteModal({
  selectedVoyage,
  onClose,
  onCloseRouteSaved,
  onUpdateItem,
}: IEditRouteModal) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { voyages, coursesCopy } = useAppSelector((state) => state.captainslog);
  const [loading, setLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const { uploadVoyageImage } = useUserInformation();

  const handleEditLocalVoyages = (items: IVoyage[] | undefined, values: TEditRouteForm) =>
    items?.map((item: IVoyage) => {
      if (item.id === selectedVoyage?.id) {
        return {
          ...item,
          name: values.name,
          description: values.description,
        };
      }

      return item;
    });

  const handleCreateCourseCopy = () => {
    dispatch(addCoursesCopy(selectedVoyage?.courses));
    navigate("/");
  };

  const handleCloseEditRoute = () => {
    if (selectedVoyage?.id) dispatch(getVoyage(selectedVoyage?.id));
    onClose();
  };

  const handleForm = async (values: TEditRouteForm) => {
    if (!selectedVoyage && voyages && voyages?.items.length > 0) return;
    onUpdateItem(true);
    setLoading(true);
    try {
      if (values?.image instanceof File && selectedVoyage?.id) {
        await uploadVoyageImage(values.image, selectedVoyage.id);
      }

      let successFullStatus = false;
      const editCourses =
        (coursesCopy?.[0].end_lat &&
          coursesCopy?.[0].end_lat !== selectedVoyage?.courses[0].end_lat) ||
        (coursesCopy?.[0].end_lng &&
          coursesCopy?.[0].end_lng !== selectedVoyage?.courses[0].end_lng) ||
        (coursesCopy?.[0].start_lat &&
          coursesCopy?.[0].start_lat !== selectedVoyage?.courses[0].start_lat) ||
        (coursesCopy?.[0].start_lng &&
          coursesCopy?.[0].start_lng !== selectedVoyage?.courses[0].start_lng);

      const { status } = await ServiceMap.editVoyage(selectedVoyage?.id ?? 0, values);

      if (status === 204) {
        successFullStatus = true;
        dispatch(setSelectedVoyage({ ...selectedVoyage, ...values }));
        dispatch(addVoyages({ ...voyages, items: handleEditLocalVoyages(voyages?.items, values) }));
      }

      /* Edit Courses */
      if (editCourses) {
        const { status: statusCoursesDelete } = await ServiceMap.deleteRouteCourses(
          selectedVoyage?.id ?? 0,
          selectedVoyage?.courses[0]?.id ?? 0,
        );

        if (statusCoursesDelete === 204) {
          const { status: statusCoursesSave, data } = await ServiceMap.saveRouteCourses(
            selectedVoyage?.courses[0],
          );

          dispatch(
            setSelectedVoyage({
              ...selectedVoyage,
              courses: [data],
            }),
          );
          if (statusCoursesSave === 204) successFullStatus = true;
        }
      }

      if (successFullStatus) {
        if (selectedVoyage?.id) dispatch(getVoyage(selectedVoyage?.id));
        dispatch(
          setSnackBarMsjSucceded({
            state: true,
            type: "success",
            msj: "Route changes saved.",
          }),
        );
      }
      setLoading(false);
      dispatch(addCoursesCopy(null));
      onCloseRouteSaved();
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      setLoading(false);
      dispatch(addCoursesCopy(null));
      onClose();
    }
  };

  const formik = useFormik({
    initialValues: {
      name: selectedVoyage?.name,
      description: selectedVoyage?.description,
      image: selectedVoyage?.assets[0]?.download_url,
    } as TEditRouteForm,
    validationSchema: formSchema,
    onSubmit: handleForm,
  });

  const handleRouteImage = (files: File[]) => {
    setFiles([...files]);
    const imgFile = files[0];

    if (["image/jpeg", "image/png"].includes(imgFile?.type)) {
      if (imgFile.size < BYTE_SIZE_LIMIT) {
        formik.setFieldValue("image", imgFile);
      }
    } else {
      formik.setFieldValue("image", null);
    }
  };

  const routeCreated = selectedVoyage?.courses?.[0];

  return (
    <>
      <DialogContent sx={{ py: 1 }}>
        <Stack component="form" spacing={2} onSubmit={formik.handleSubmit}>
          <TextField
            defaultValue={formik.values.name}
            id="name"
            label="Name"
            variant="outlined"
            onChange={formik.handleChange}
          />
          <TextField
            fullWidth
            multiline
            defaultValue={formik.values.description}
            id="description"
            label="Description"
            minRows={3}
            name="description"
            onChange={formik.handleChange}
          />
          <Stack spacing={1}>
            <Stack alignItems="center" direction="row" justifyContent="space-between">
              <Typography color="text.secondary" lineHeight="normal" variant="body2">
                Start & Destination Location
              </Typography>

              <Button color="secondary" startIcon={<Edit />} onClick={handleCreateCourseCopy}>
                Edit
              </Button>
            </Stack>
            <Stack sx={styles.routePointsBox}>
              <Stack alignItems="center" className="routePointsItem" direction="row" spacing={2}>
                <BaseIcon src={StartIndicatorIcon} type="png" />
                <Typography>
                  {routeCreated?.start_address ||
                    `${routeCreated?.start_lat}, ${routeCreated?.start_lng}`}
                </Typography>
              </Stack>
              <Stack alignItems="center" className="routePointsItem" direction="row" spacing={2}>
                <BaseIcon src={EndIndicatorIcon} type="png" />
                <Typography>
                  {routeCreated?.end_address ||
                    `${routeCreated?.end_lat}, ${routeCreated?.end_lng}`}
                </Typography>
              </Stack>
            </Stack>
            <Stack pt={1}>
              <Dropzone action={handleRouteImage} files={files} />
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Button onClick={handleCloseEditRoute}>Cancel</Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          onClick={() => formik.handleSubmit()}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </>
  );
}

export default EditRouteModal;
