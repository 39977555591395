import { configureStore, Middleware } from "@reduxjs/toolkit";

import { argoApi } from "./api/argoApi";
import reducer from "./reducers";

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(argoApi.middleware as Middleware),
});
