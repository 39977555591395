import {
  Fab,
  Box,
  ClickAwayListener,
  Dialog,
  DialogTitle,
  Typography,
  Stack,
  IconButton,
  DialogActions,
  Button,
  DialogContent,
  Tooltip,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState, useEffect } from "react";

import { styles } from "./styles";
import RouteOptionsForm from "./RouteOptionsForm";

import BaseIcon from "@/components/BaseIcon";
import { ReactComponent as EditRouteIcon } from "&/img/icons/edit-route.svg";
import useDisclosure from "@/hooks/useDisclosure";
import { ERouteSettings } from "@/types/route";
import { useAppSelector, useAppDispatch } from "@/hooks/useRedux";
import usePremiumUser from "@/hooks/usePremiumUser";
import { setPremiumSubscriptionModal, setUserOptions } from "@/store/slices/user";
import userService from "@/services/user";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { setCoords } from "@/store/slices/map";

function RouteOptions() {
  const dispatch = useAppDispatch();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { userOptions } = useAppSelector((state) => state.user);
  const [routeSettings, setRouteSettings] = useState<ERouteSettings>(userOptions?.route_settings);
  const [hugShore, setHugShore] = useState<boolean>(userOptions?.hug_shore || false);
  const [loading, setLoading] = useState<boolean>(false);

  const { logged } = useAppSelector((state) => state.auth);
  const { coords } = useAppSelector((state) => state.map);

  const isPremium = usePremiumUser();

  const handleOpen = () => {
    if (logged && isPremium) {
      setRouteSettings(userOptions?.route_settings);
      if (coords) dispatch(setCoords(undefined));
      onOpen();
    } else {
      dispatch(setPremiumSubscriptionModal(true));
    }
  };

  const handleChangeSettings = (value: ERouteSettings) => {
    setRouteSettings(value);
    setHugShore(false);
  };

  const handleSubmitRouteSettings = async () => {
    setLoading(true);
    const userOptionsEdited = {
      ...userOptions,
      route_settings: routeSettings,
      hug_shore: routeSettings !== ERouteSettings.Outside ? false : hugShore,
    };

    try {
      const { status } = await userService.editUserOptions({
        route_settings: routeSettings,
        hug_shore: routeSettings !== ERouteSettings.Outside ? false : hugShore,
      });

      if (status === 200) {
        switch (routeSettings) {
          case ERouteSettings.Avoid: {
            dispatch(setUserOptions(userOptionsEdited));
            break;
          }
          case ERouteSettings.Outside: {
            dispatch(setUserOptions(userOptionsEdited));
            break;
          }
          default: {
            dispatch(setUserOptions(userOptionsEdited));
          }
        }
      }
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
    setLoading(false);
    onClose();
  };

  useEffect(() => {
    if (userOptions?.route_settings === ERouteSettings.Avoid) {
      setRouteSettings(ERouteSettings.Avoid);
    } else if (userOptions?.route_settings === ERouteSettings.Outside) {
      setRouteSettings(ERouteSettings.Outside);
    } else {
      setRouteSettings(ERouteSettings.Default);
    }
  }, [userOptions]);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box>
        <Tooltip arrow placement="left" title="Route Settings">
          <Fab size="small" sx={styles.fabButton} onClick={handleOpen}>
            <BaseIcon icon={EditRouteIcon} />
          </Fab>
        </Tooltip>
        <Dialog maxWidth="xs" open={isOpen} onClose={onClose}>
          <DialogTitle sx={styles.dialogTitle}>
            <Stack alignItems="center" direction="row" justifyContent="space-between">
              <Stack alignItems="center" direction="row">
                <BaseIcon icon={EditRouteIcon} sx={{ color: "primary.main" }} />
                <Typography color="primary.main" sx={{ ml: 1 }} variant="h6">
                  Route Settings
                </Typography>
              </Stack>
              <Stack>
                <IconButton onClick={onClose}>
                  <CloseOutlined sx={{ fontSize: 22 }} />
                </IconButton>
              </Stack>
            </Stack>
          </DialogTitle>
          <DialogContent sx={{ pb: 1 }}>
            <RouteOptionsForm
              hugShore={hugShore}
              routeSettings={routeSettings}
              setHugShore={setHugShore}
              onChangeSettings={handleChangeSettings}
            />
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Button onClick={onClose}>Cancel</Button>
            <LoadingButton
              loading={loading}
              type="button"
              variant="contained"
              onClick={handleSubmitRouteSettings}
            >
              Apply
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Box>
    </ClickAwayListener>
  );
}

export default RouteOptions;
