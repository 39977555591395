const mapStyles = [
  {
    featureType: "all",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "all",
    stylers: [{ visibility: "on" }],
  },
];

export default {
  MainMap: {
    mapId: "b2a569cc12b3e49e",
    defaultCenter: { lat: 29.443838, lng: -94.761623 },
    defaultZoom: 5,
    minZoom: 5,
    maxZoom: 18,
    gestureHandling: "greedy",
    disableDefaultUI: true,
    clickableIcons: false,
    draggableCursor: "default",
    draggingCursor: "pointer",
    defaultBounds: {
      north: 49.3844,
      south: 24.3963,
      east: -66.9289,
      west: -124.7354,
    },
    restriction: {
      latLngBounds: {
        north: 72,
        south: 8.0,
        east: -51.70754,
        west: -179.99,
      },
      strictBounds: false,
    },
    style: {
      width: "100%",
      height: "100%",
    },
    styles: mapStyles,
  },
};
