import { Paper, Box, Typography, Stack, Button, Avatar } from "@mui/material";
import React from "react";

import { styles } from "./styles";

import { IFriends } from "@/types/users";
import { privacyHandler } from "@/utils/friends";

interface IBoaterPopover {
  hasCommonAffiliation?: boolean;
  boater: IFriends;
  grownToTop?: boolean;
  showFullProfile: (e: React.MouseEvent<HTMLElement>) => void;
}

export default function BoaterPopover({
  hasCommonAffiliation,
  boater,
  grownToTop,
  showFullProfile,
}: IBoaterPopover) {
  const { showProfileImage } = privacyHandler({ user: boater, noPrivacy: false });

  const avatarUrl = showProfileImage
    ? boater.image || "/img/placeholders/defaultUserMarkerSquare.png"
    : "/img/placeholders/captainHatMarkerSquare.png";

  const coverUrl = showProfileImage
    ? boater.cover || "img/placeholders/argoBG.jpg"
    : "img/placeholders/argoBG.jpg";

  return (
    <Box sx={styles.popoverContainer}>
      <Paper
        className="placePopover"
        elevation={6}
        sx={grownToTop ? styles.paperToTop : styles.paper}
      >
        <Box
          sx={{
            ...styles.coverImage,
            backgroundImage: `url(${coverUrl})`,
          }}
        />
        <Stack alignItems="center" spacing={2} sx={styles.infoContainer}>
          <Avatar
            alt={`${boater.first_name} ${boater.last_name}`}
            src={avatarUrl}
            sx={hasCommonAffiliation ? styles.avatarCommonAffiliation : styles.avatar}
          />
          <Stack spacing={2}>
            <Box>
              <Typography component="h5" sx={styles.title} textAlign="center" variant="h6">
                {showProfileImage ? `${boater.first_name} ${boater.last_name}` : "Fellow Boater"}
              </Typography>
              <Typography color="text.secondary" textAlign="center" variant="body2">
                {boater?.current_vessel?.name}
              </Typography>
            </Box>
            <Button color="primary" variant="outlined" onClick={showFullProfile}>
              Show full profile
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
}
