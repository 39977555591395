import { TextField, Stack, Button, InputAdornment } from "@mui/material";
import { SwapVert, SwapHoriz } from "@mui/icons-material";
import React from "react";

import {
  NAME_INPUT_LAT_DEGREE,
  NAME_INPUT_LNG_DEGREE,
  NAME_INPUT_LAT_MINUTES,
  NAME_INPUT_LNG_MINUTES,
} from "../SearchCoordinatesExpanded";

import { TDDM } from "@/types/map";

interface ISearchCoordinatesDDM {
  isToggledXAxis: boolean;
  isToggledYAxis: boolean;
  latValue: TDDM;
  lngValue: TDDM;
  onHandleToggleXAxis: () => void;
  onHandleToggleYAxis: () => void;
  onSetCoordinatesValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function SearchCoordinatesDDM({
  isToggledXAxis,
  isToggledYAxis,
  latValue,
  lngValue,

  onHandleToggleXAxis,
  onHandleToggleYAxis,
  onSetCoordinatesValue,
}: ISearchCoordinatesDDM) {
  return (
    <Stack spacing={1.5}>
      <Stack direction="row" spacing={1}>
        <Button
          color="primary"
          startIcon={<SwapVert />}
          sx={{ borderRadius: 1, typography: "h6" }}
          variant="text"
          onClick={onHandleToggleYAxis}
        >
          {isToggledYAxis ? "S" : "N"}
        </Button>
        <TextField
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="start">º</InputAdornment>,
          }}
          defaultValue="0"
          id=""
          name={NAME_INPUT_LAT_DEGREE}
          type="text"
          value={latValue.degrees}
          variant="outlined"
          onChange={onSetCoordinatesValue}
        />
        <TextField
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="start">‘</InputAdornment>,
          }}
          id=""
          name={NAME_INPUT_LAT_MINUTES}
          type="text"
          value={latValue.minutes}
          variant="outlined"
          onChange={onSetCoordinatesValue}
        />
      </Stack>

      <Stack direction="row" spacing={1}>
        <Button
          color="primary"
          startIcon={<SwapHoriz />}
          sx={{ borderRadius: 1, typography: "h6" }}
          variant="text"
          onClick={onHandleToggleXAxis}
        >
          {isToggledXAxis ? "E" : "W"}
        </Button>
        <TextField
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="start">º</InputAdornment>,
          }}
          defaultValue="0"
          id=""
          name={NAME_INPUT_LNG_DEGREE}
          type="text"
          value={lngValue.degrees}
          variant="outlined"
          onChange={onSetCoordinatesValue}
        />
        <TextField
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="start">‘</InputAdornment>,
          }}
          id=""
          name={NAME_INPUT_LNG_MINUTES}
          type="text"
          value={lngValue.minutes}
          variant="outlined"
          onChange={onSetCoordinatesValue}
        />
      </Stack>
    </Stack>
  );
}
