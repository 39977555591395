import React, { useMemo } from "react";

import AdvancedMarker from "../AdvancedMarker";
import { getNavAidsIcon } from "../Utils/NavAidsIcons";
import NavigationAidPopover from "../../MapPopovers/NavigationAidPopover";

import { addStartPoint, addEndPoint } from "@/store/slices/route";
import { setPremiumSubscriptionModal } from "@/store/slices/user";
import { useAppSelector, useAppDispatch } from "@/hooks/useRedux";
import { INavAids } from "@/types/navaids";
import { IconKeysNavAidsBeacons } from "@/types/markers";

interface INavAidsList {
  items: INavAids[];
  zoom?: number;
  clogpage: boolean;
}

interface INavsAidsData {
  [key: string]: boolean;
}

const ZOOM_LEVEL = 12;

const ZOOM_LEVEL_NOOA_RESTRICTION = 16;

function NavAidsList({ items, zoom, clogpage }: INavAidsList) {
  const dispatch = useAppDispatch();
  const { poisConfigured } = useAppSelector((state) => state.map);
  const { creatingRoute, startPoint } = useAppSelector((state) => state.route);
  const { logged } = useAppSelector((state) => state.auth);

  const navsAidsStatus: INavsAidsData = {
    buoy: poisConfigured.Buoy,
    beacon: poisConfigured.Buoy,
    restricted_area: poisConfigured["Restricted area"],
  };

  /**
   * This function checks the zoom level to show the pois
   * @param key Type string
   * @returns boolean
   */
  const zoomChecks = (key: string) => {
    if (poisConfigured.noaaLayer) {
      if (zoom && zoom > ZOOM_LEVEL) {
        if ((key !== "buoy" && key !== "beacon") || (zoom && zoom >= ZOOM_LEVEL_NOOA_RESTRICTION)) {
          return true;
        }
      }

      return false;
    }

    if (zoom && zoom > ZOOM_LEVEL) {
      return true;
    }

    return false;
  };

  /**
   * This function handles the set of the aid for routes
   * @param item Type INavAids
   */
  const handleSetAid = (item: INavAids) => {
    if (logged && !clogpage) {
      if (creatingRoute) {
        const lat = Number(item.latitude);
        const lng = Number(item.longitude);

        if (!startPoint) {
          dispatch(
            addStartPoint({
              lat,
              lng,
              name: item.title,
            }),
          );
        } else {
          dispatch(
            addEndPoint({
              lat,
              lng,
              name: item.title,
            }),
          );
        }
      }
    } else {
      dispatch(setPremiumSubscriptionModal(true));
    }
  };

  // const getStylesIconCluster = (type: string) => ({
  //   url: `./img/markers/clusters/3x/${type}.png`,
  //   width: 40,
  //   height: 40,
  //   textSize: 11,
  //   backgroundPosition: "center center",
  // });

  // const filterMarkersByType = (items: INavAids[], type: string) =>
  //   items.filter(
  //     (item) =>
  //       (item?.kind === "restricted_area"
  //         ? "Restrictedarea"
  //         : capitalizeWord(item?.kind?.replace("/", "").replace(" ", ""))) === type,
  //   );

  // const renderMarkers = useCallback(
  //   (markers: INavAids[], clusterer: any) =>
  //     markers.map((marker: INavAids) => {
  //       const key: string =
  //         marker?.kind === "restricted_area"
  //           ? "Restrictedarea"
  //           : capitalizeWord(marker?.kind?.replace("/", "").replace(" ", ""));

  //       if (!zoomChecks(key) || !navsAidsStatus[key]) return null;

  //       const imageURL =
  //         marker.kind === "buoy"
  //           ? "buoys/3x/"
  //           : marker.kind === "beacon"
  //           ? "beacons/3x/"
  //           : "pois/place/3x/";

  //       const size = new window.google.maps.Size(36, 36);

  //       return (
  //         <Box key={marker.id}>
  //           <NavAidMarker
  //             key={marker.id}
  //             clusterer={clusterer}
  //             icon={`${imageURL}${
  //               marker.icon || marker?.kind?.replace("/", "").replace(" ", "")
  //             }.png`}
  //             navAid={marker}
  //             position={{ lat: Number(marker.latitude), lng: Number(marker.longitude) }}
  //             size={size}
  //             onClick={() => handleSetAid(marker)}
  //           />
  //         </Box>
  //       );
  //     }),
  //   [items, navsAidsStatus],
  // );

  // const getMarkerClusterers = useMemo(() => {
  //   const uniqueTypes = Array.from(
  //     new Set(
  //       items.map((item) => {
  //         const type: string =
  //           item?.kind === "restricted_area"
  //             ? "Restrictedarea"
  //             : capitalizeWord(item?.kind?.replace("/", "").replace(" ", ""));

  //         return type;
  //       }),
  //     ),
  //   );

  //   return uniqueTypes.map((type) => (
  //     <MarkerClusterer
  //       key={type}
  //       options={{
  //         maxZoom: 14,
  //         styles: [getStylesIconCluster(type)],
  //         minimumClusterSize: 4,
  //       }}
  //     >
  //       {(clusterer) => <Box>{renderMarkers(filterMarkersByType(items, type), clusterer)}</Box>}
  //     </MarkerClusterer>
  //   ));
  // }, [items, renderMarkers]);

  const filteredItems = useMemo(
    () =>
      items.filter((item) => {
        const key = item.kind.replace("/", "").replace(" ", "");

        return zoomChecks(key) && navsAidsStatus[key];
      }),
    [items, zoom, navsAidsStatus, poisConfigured],
  );

  return (
    <>
      {filteredItems.map((navaid: INavAids) => (
        <AdvancedMarker
          key={navaid.id}
          icon={getNavAidsIcon(navaid.icon as IconKeysNavAidsBeacons)}
          position={{ lat: Number(navaid.latitude), lng: Number(navaid.longitude) }}
          onClick={() => handleSetAid(navaid)}
        >
          {logged && (
            <NavigationAidPopover
              icon={getNavAidsIcon(navaid.icon as IconKeysNavAidsBeacons)}
              inCaptainsLog={clogpage}
              navAid={navaid}
            />
          )}
        </AdvancedMarker>
      ))}
    </>
  );
}

export default NavAidsList;
