import React from "react";
import { ListItem, ListItemButton, ListItemText, Switch } from "@mui/material";

import { styles } from "./styles";

interface IAffiliationListItem {
  name: string;
  checked: boolean;
  onSetAffiliation: () => void;
}

export default function AffiliationListItem({
  name,
  checked,
  onSetAffiliation,
}: IAffiliationListItem) {
  return (
    <ListItem disablePadding divider>
      <ListItemButton sx={styles.listItemButton} onClick={onSetAffiliation}>
        <ListItemText
          id="#"
          primary={name}
          primaryTypographyProps={{
            variant: "body2",
            color: "text.secondary",
            pr: 1,
          }}
        />
        <Switch checked={checked} color="secondary" edge="end" size="small" />
      </ListItemButton>
    </ListItem>
  );
}
