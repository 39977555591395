export const styles = {
  radio: {
    ml: "-9px",
  },

  depthIndicator: {
    width: "100%",
    "& .depthIndicatorItem": {
      width: "100%",
      flex: 1,
    },

    "& .depthIndicatorItem:first-of-type .depthIndicatorColor": {
      borderRadius: "12px 0px 0px 12px",
    },

    "& .depthIndicatorItem:last-of-type .depthIndicatorColor": {
      borderRadius: "0px 12px 12px 0px",
    },
  },
};
