import { useState } from "react";
import { Stack, Typography, Divider, List, Button, CardMedia } from "@mui/material";
import { Check } from "@mui/icons-material";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import PlaceInfoListItem from "../../PlaceInfoListItem";

import { styles } from "./styles";

import SnagASlipButton from "@/components/SnagASlipButton/";
import { IMapPois } from "@/types/pois";
import BaseModal from "@/components/Modals/BaseModal/BaseModal";
import EditTransientModal from "@/components/Modals/EditTransientModal";
import PhotoModal from "@/components/Modals/PhotoModal";
import useDisclosure from "@/hooks/useDisclosure";

interface IPlaceInfoTransient {
  poiSelected: IMapPois;
}

function PlaceInfoTransient({ poiSelected }: IPlaceInfoTransient) {
  const handleCheckAmp = () => {
    const availableAmp = [];

    if (poiSelected.amp_30 || poiSelected.amp_50 || poiSelected.amp_100) {
      if (poiSelected.amp_30) availableAmp.push(`30`);
      if (poiSelected.amp_50) availableAmp.push(`50`);
      if (poiSelected.amp_100) availableAmp.push(`100`);

      return availableAmp.join(", ");
    }

    return null;
  };

  const infoSlip =
    poiSelected.call_dockmaster ||
    poiSelected.dockwa ||
    poiSelected.snag_a_slip ||
    poiSelected.other_reservation;

  const [openEditTransientModal, setEditTransientModal] = useState<boolean>(false);
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Stack alignItems="center" direction="row" justifyContent="space-between" px={2} py={1}>
        <Typography component="h4" lineHeight="normal" variant="subtitle1">
          Transient Slips/Moorings
        </Typography>

        <Button
          color="secondary"
          startIcon={!infoSlip ? <AddIcon /> : <EditIcon />}
          onClick={() => setEditTransientModal(true)}
        >
          {!infoSlip ? "Add Transient Info" : "Edit Transient Info"}
        </Button>
      </Stack>
      <Stack>
        {poiSelected?.slip_mooring_diagram_asset_link && (
          <Stack pb={1} px={2}>
            <CardMedia
              alt={`${poiSelected.name}`}
              component="img"
              height="180"
              image={
                `${poiSelected?.slip_mooring_diagram_asset_link}` || "/img/placeholders/Image.png"
              }
              sx={{
                borderRadius: 0.5,
                border: "1px solid",
                borderColor: "divider",
                cursor: "pointer",
              }}
              onClick={onOpen}
            />
          </Stack>
        )}
      </Stack>

      {(poiSelected.transient_available || poiSelected.moorings_available) && (
        <List disablePadding sx={styles.denseList}>
          {poiSelected.transient_available && (
            <PlaceInfoListItem
              icon={<Check />}
              primary="Slips Available"
              secondary={`$${poiSelected.transient_price} per ft - Last updated by ${
                poiSelected.last_transient_price_change_by === "poi_owner" ? "owner" : "user"
              } on ${dayjs(poiSelected.transient_updated_at).format("MMMM DD, YYYY")}`}
            />
          )}
          {poiSelected.moorings_available && (
            <PlaceInfoListItem
              icon={<Check />}
              primary="Moorings Available"
              secondary={`$${poiSelected.transient_price_night} per night - Last updated by ${
                poiSelected.last_transient_price_change_by === "poi_owner" ? "owner" : "user"
              } on ${dayjs(poiSelected.transient_updated_at).format("MMMM DD, YYYY")}`}
            />
          )}
        </List>
      )}

      <Stack px={2} spacing={2}>
        <>
          {poiSelected.length_max && (
            <Typography>Length Max: {poiSelected.length_max} ft</Typography>
          )}
          {poiSelected.beam_max && <Typography>Beam Max: {poiSelected.beam_max} ft</Typography>}
          {poiSelected.transient && (
            <Typography>Dockage Depth: {poiSelected.transient} ft</Typography>
          )}
        </>
        {(poiSelected.amp_30 || poiSelected.amp_50 || poiSelected.amp_100) && (
          <Typography>{handleCheckAmp()} amp power available</Typography>
        )}
        {infoSlip && <Typography gutterBottom>For slip reservations:</Typography>}
      </Stack>

      {(poiSelected.call_dockmaster || poiSelected.dockwa) && (
        <List disablePadding sx={styles.denseList}>
          {poiSelected.call_dockmaster && (
            <PlaceInfoListItem icon={<Check />} primary="Call Dockmaster" />
          )}
          {poiSelected.dockwa && <PlaceInfoListItem icon={<Check />} primary="Dockwa" />}
        </List>
      )}

      {(poiSelected.snag_a_slip || poiSelected.other_reservation) && (
        <Stack pb={2} px={2} spacing={2}>
          {poiSelected.snag_a_slip && <SnagASlipButton url={poiSelected.snag_a_slip_url} />}
          {poiSelected.other_reservation && (
            <Typography color="text.secondary">{poiSelected.other_reservation}</Typography>
          )}
        </Stack>
      )}
      {poiSelected.other_transient_info && (
        <Stack pb={2} px={2} spacing={2}>
          <Typography>{poiSelected.other_transient_info}</Typography>
        </Stack>
      )}
      <Divider />
      {openEditTransientModal && (
        <BaseModal
          ariaDescribedby="Edit Transient Info"
          ariaLabelledby="Edit Transient Info"
          open={openEditTransientModal}
          title="Edit Transient Info"
          onClose={() => setEditTransientModal(false)}
        >
          <EditTransientModal
            poiSelected={poiSelected}
            onClose={() => setEditTransientModal(false)}
          />
        </BaseModal>
      )}
      <PhotoModal
        open={isOpen}
        url={`${poiSelected?.slip_mooring_diagram_asset_link}` || "/img/placeholders/Image.png"}
        onClose={onClose}
      />
    </>
  );
}

export default PlaceInfoTransient;
