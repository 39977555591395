export const styles = {
  container: {
    pl: 1,
    pr: 2,
    py: 3,
    borderBottom: "1px solid",
    borderColor: "divider",
  },

  iconTrack: {
    height: "88px",
    px: 1,
    position: "relative",
    "&:before": {
      content: "''",
      position: "absolute",
      display: "block",
      width: "2px",
      height: "calc(100% - 52px)",
      borderLeft: "2px dashed",
      transform: "translateX(50%)",
      borderColor: "error.main",
      backgroundColor: "transparent",
      right: "50%",
      top: 26,
    },
  },

  textfield: {
    width: "100%",
    "& .MuiSvgIcon-root": {
      transition: "all .1s linear",
    },
    "& .Mui-focused": {
      color: "primary.main",
      "& .MuiSvgIcon-root": {
        opacity: 1,
      },
    },
  },

  iconButton: {
    width: "32px",
    height: "32px",
  },
};
