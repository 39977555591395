/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
import { Snackbar } from "@mui/material";

import { useAppSelector } from "@/hooks/useRedux";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert ref={ref} elevation={6} {...props} />
));

interface ICustomizedSnackbar {
  message: string;
  severity: AlertColor | undefined;
  open: boolean;
  close: () => void;
}

export default function CustomizedSnackbar({
  open,
  close,
  severity,
  message,
}: ICustomizedSnackbar) {
  const { selectedPoi } = useAppSelector((state) => state.search);
  const { creatingRoute } = useAppSelector((state) => state.route);
  const { boaterSelectedInfoDrawer } = useAppSelector((state) => state.boaters);
  const { reportPinSelectedDrawer } = useAppSelector((state) => state.reportpins);
  const screenWidth =
    selectedPoi || creatingRoute || !!boaterSelectedInfoDrawer || !!reportPinSelectedDrawer
      ? window.innerWidth / 2 + 260
      : window.innerWidth / 2;

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      autoHideDuration={6000}
      open={open}
      sx={{ bottom: { sm: 12 }, left: { sm: `${screenWidth}px` } }}
      onClose={close}
    >
      <Alert severity={severity} sx={{ width: "100%", zIndex: 999 }} onClose={close}>
        {message}
      </Alert>
    </Snackbar>
  );
}
