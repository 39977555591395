import React from "react";
import { Stack, Typography, DialogContent, Button, Box, Rating, List } from "@mui/material";
import { RateReview } from "@mui/icons-material";

import { styles } from "./styles";

import PlaceInfoReviewsListItem from "@/components/PlaceInfo/PlaceInfoSections/PlaceInfoReviews/PlaceInfoReviewsListItem";

interface IReviewsViewModal {
  poiSelected: any;
  onSetModal: () => void;
}

function ReviewsViewModal({ poiSelected, onSetModal }: IReviewsViewModal) {
  return (
    <>
      <Box sx={{ px: 3 }}>
        <Stack component="form" spacing={2}>
          <Box sx={styles.header}>
            <Typography gutterBottom component="h4" sx={styles.poiTitle} variant="h6">
              {poiSelected?.name}
            </Typography>
            <Typography gutterBottom variant="body2">
              {poiSelected?.address}
            </Typography>
            <Stack alignItems="center" direction="row" spacing={1}>
              <Typography color="text.secondary" component="h5" variant="h5">
                {poiSelected?.poi_ratings_overall}
              </Typography>
              <Rating
                readOnly
                defaultValue={poiSelected?.poi_ratings_overall}
                name="rating-place"
                precision={1}
              />
              <Typography color="text.secondary" component="h3" variant="body2">
                ({poiSelected?.poi_ratings_count} reviews)
              </Typography>
            </Stack>
            <Button
              fullWidth
              size="large"
              startIcon={<RateReview />}
              sx={{ mt: 2 }}
              variant="outlined"
              onClick={onSetModal}
            >
              Write a Review
            </Button>
          </Box>
        </Stack>
        <Typography component="h4" py={2} variant="subtitle1">
          Reviews
        </Typography>
      </Box>
      <DialogContent sx={{ mt: -1, pt: 0, px: 1 }}>
        {poiSelected?.poi_ratings_count <= 0 ? (
          <Typography sx={{ pl: 2 }} variant="body2">
            No reviews added for this place so far.
          </Typography>
        ) : (
          <List disablePadding>
            {poiSelected?.all_reviews?.map((review: any) => (
              <PlaceInfoReviewsListItem key={review.id} review={review} />
            ))}
          </List>
        )}
      </DialogContent>
    </>
  );
}

export default ReviewsViewModal;
