import { Stack, Typography } from "@mui/material";

import { styles } from "./styles";

import EmptyState from "@/components/EmptyState";

interface ICaptianLogsReportPdf {
  fetchingReport: boolean;
  reportArrayBuffer: ArrayBuffer | null;
}

function CaptainLogsReportPdf({ fetchingReport, reportArrayBuffer }: ICaptianLogsReportPdf) {
  const blob = new Blob([reportArrayBuffer!], { type: "application/pdf" });
  const fileURL = URL.createObjectURL(blob);

  return (
    <Stack alignItems="center" justifyContent="center" sx={styles.Container}>
      {fetchingReport || !reportArrayBuffer ? (
        <Stack alignItems="center">
          <EmptyState
            imageSize={{ height: 100, width: 240 }}
            imageURL="img/placeholders/EmptyStateWorking.svg"
          />
          <Typography color="primary" variant="h6">
            Creating Report
          </Typography>
          <Typography sx={styles.loadingText} variant="body2">
            Loading Information...
          </Typography>
        </Stack>
      ) : (
        <Stack alignItems="center" height="100%" width="100%">
          <object
            data={`${fileURL}#toolbar=0&navpanes=0&scrollbar=0`}
            height="100%"
            type="application/pdf"
            width="100%"
          >
            <p>Your browser doesn&apos;t support Pdf&apos;s file previews.</p>
          </object>
        </Stack>
      )}
    </Stack>
  );
}

export default CaptainLogsReportPdf;
