import { createSlice } from "@reduxjs/toolkit";

import { Position, Route, RoutePosition } from "@/types/map";

export interface IRoutes {
  error?: any;
  startPoint: RoutePosition | null;
  endPoint: RoutePosition | null;
  inputsFocus: number;
  routeCreated: Route | null;
  creatingRoute: boolean;
  routePointSetted: boolean;
  coordinateValuesSetted: boolean;
  pointSearchedByCoords: Position | null;
  filterTypePoi: string[];
  noDepthAlert: boolean;
  cancelRouteToken: any;
}

export const initialState: IRoutes = {
  error: null,
  startPoint: null,
  endPoint: null,
  inputsFocus: 1,
  routeCreated: null,
  creatingRoute: false,
  routePointSetted: false,
  coordinateValuesSetted: false,
  pointSearchedByCoords: null,
  filterTypePoi: [],
  noDepthAlert: false,
  cancelRouteToken: null,
};

const routeSlice = createSlice({
  name: "routeSlice",
  initialState,
  reducers: {
    addError: (state, action) => {
      state.error = action.payload;
    },
    addStartPoint: (state, action) => {
      state.startPoint = action.payload;
    },
    addEndPoint: (state, action) => {
      state.endPoint = action.payload;
    },
    addRouteCreated: (state, action) => {
      state.routeCreated = action.payload;
    },
    setInputsFocus: (state, action) => {
      state.inputsFocus = action.payload;
    },
    addCreatingRoute: (state, action) => {
      state.creatingRoute = action.payload;
    },
    addPointSearchedByCoords: (state, action) => {
      state.pointSearchedByCoords = action.payload;
    },
    resetRouteParams: (state, action) => ({ ...state, ...action.payload }),
    setDepthAlert: (state, action) => {
      state.noDepthAlert = action.payload;
    },
    setCancelRouteToken: (state, action) => {
      state.cancelRouteToken = action.payload;
    },
    setRoutePointSetted: (state, action) => {
      state.routePointSetted = action.payload;
    },
    setCoordinatesValuesSetted: (state, action) => {
      state.coordinateValuesSetted = action.payload;
    },
  },
});

export const {
  addCreatingRoute,
  addRouteCreated,
  addStartPoint,
  addEndPoint,
  setInputsFocus,
  addPointSearchedByCoords,
  resetRouteParams,
  addError,
  setDepthAlert,
  setCancelRouteToken,
  setRoutePointSetted,
  setCoordinatesValuesSetted,
} = routeSlice.actions;

export default routeSlice.reducer;
