import { DialogContent, Stack, Typography, Box, Button } from "@mui/material";

import { styles } from "./styles";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { setMyPinsDrawer } from "@/store/slices/reportpins";

interface ICongratsPinModal {
  onClose: () => void;
}

function CongratsPinModal({ onClose }: ICongratsPinModal) {
  const dispatch = useAppDispatch();
  const { congratsModal } = useAppSelector((state) => state.reportpins);

  return (
    <DialogContent sx={{ pb: 1 }}>
      <Stack
        alignItems="center"
        direction="column"
        justifyContent="center"
        px={2}
        py={3}
        spacing={0}
      >
        <Stack alignItems="center" direction="column" mb={1} spacing={0.5}>
          <Box
            sx={{
              ...styles.image,
              backgroundImage: `url('img/placeholders/ThankYouPin.svg')`,
            }}
          />

          <Typography color="primary" textAlign="center" variant="h6">
            Thank You
          </Typography>
        </Stack>

        {congratsModal?.type === "places" ? (
          <Typography color="text.secondary" mb={2} textAlign="center" variant="body2">
            This place will be reviewed by the Argo Team and if approved will be added to the
            community database. Open My Pins to check the review status of your pins.
          </Typography>
        ) : (
          <Typography color="text.secondary" mb={2} textAlign="center" variant="body2">
            This report pin can be seen by the Argo community. Open My Pins to check the review
            status of your pins.
          </Typography>
        )}

        <Button
          variant="contained"
          onClick={() => {
            dispatch(setMyPinsDrawer({ open: true, type: congratsModal?.type }));
            onClose();
          }}
        >
          My Pins
        </Button>
      </Stack>
    </DialogContent>
  );
}

export default CongratsPinModal;
