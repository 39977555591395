import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";

export const styles = {
  containerPrimary: {
    backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.08)}`,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    px: 2,
    py: 1.5,
    color: "primary.dark",
  },
  containerPrimaryLight: {
    backgroundColor: `${alpha(defaultTheme.palette.common.white, 0.08)}`,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    px: 2,
    py: 1.5,
    color: "primary.main",
  },
};
