import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";

export const styles = {
  list: {
    border: "1px solid",
    borderColor: "divider",
    borderRadius: 1,
    overflow: "hidden",
    width: "100%",
  },

  listItem: {
    py: 1.5,
    pr: 2,
    pl: 3,
    transition: defaultTheme.transitions.create(["background-color", "transform"], {
      duration: defaultTheme.transitions.duration.standard,
    }),
    "&:hover": {
      backgroundColor: `${alpha(defaultTheme.palette.common.black, 0.05)}`,
    },
  },

  select: {
    minWidth: 220,
  },
};
