import { layoutStyles } from "@/themes/layoutStyles";

export const styles = {
  avatarIconButton: {
    p: 0,
  },

  avatar: {
    border: "2.5px solid",
    borderColor: "background.paper",
    boxShadow: 4,
    width: 40,
    height: 40,
  },

  burgerMenu: {
    p: 0,
    border: "2.5px solid",
    borderColor: "background.paper",
    backgroundColor: "background.paper",
    boxShadow: 4,
    width: 40,
    height: 40,
    "&:hover": {
      backgroundColor: "background.paper",
    },
  },

  paper: {
    position: "fixed",
    top: 70,
    right: 12,
    width: "408px",
    overflow: "hidden",
  },

  scrollContainer: {
    ...layoutStyles.scroll,

    overflowY: "auto",
    maxHeight: "calc(100vh - 158px)",

    "& .menuListItemPremium": {
      color: "primary.main",
      "& .MuiListItemIcon-root": {
        color: "primary.main",
      },
    },

    "& .MuiDivider-root:last-of-type": {
      display: "none",
    },
  },

  scrollProfileContainer: {
    ...layoutStyles.scroll,

    overflowY: "auto",
    maxHeight: "calc(100vh - 94px)",
  },

  sublevel: {
    borderTop: "1px solid",
    borderColor: "divider",
    boxShadow: "inset 0 1px 10px rgb(0 0 0 / 5%)",
    "& .MuiListItemButton-root": {
      pl: 3.5,
    },
  },
};
