import { alpha } from "@mui/material/styles";

import { layoutStyles } from "@/themes/layoutStyles";
import defaultTheme from "@/themes/theme";

export const styles = {
  wrapper: {
    height: "100%",
    width: "100%",
    position: "fixed",
    zIndex: 24,
    backgroundColor: "common.white",
    display: { md: "flex", lg: "none" },
    overflowY: { xs: "auto", sm: "unset" },
    ...layoutStyles.scroll,
  },

  main: {
    height: "100%",
    display: "flex",
    flexDirection: { xs: "column", sm: "row", md: "row" },
    justifyContent: "flex-end",
    alignItems: "center",
  },

  argoMockupsContainer: {
    zIndex: 999,
    width: "100%",
  },

  argoMockups: {
    height: { xs: "220px" },
    width: { xs: "320px" },
    backgroundImage: `url(${"img/illustrations/ArgoMockups.png"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  textContainer: {
    backgroundColor: "primary.main",
    width: { xs: "100%", sm: "60%", md: "60%" },
    height: { xs: "75%", sm: "100%", md: "100%" },
    px: { xs: 3, sm: 10, md: 12 },
    py: { xs: 3, sm: 0 },
  },

  argoLogo: {
    fontSize: { xs: 70, md: 86 },
    ml: -1.5,
    "& path": {
      fill: "white",
    },
  },

  blackButton: {
    backgroundColor: "grey.900",
    borderRadius: 1,
    boxShadow: 4,
    py: 1,
    width: { xs: 150 },
    "& .MuiSvgIcon-root": {
      width: 135,
      height: { xs: 32 },
    },
    "&:hover": {
      backgroundColor: "common.black",
    },
  },

  paper: {
    ...layoutStyles.scroll,
  },

  dialogActions: {
    px: 3,
    py: 2,
    backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.04)}`,
    borderTop: "1px solid",
    borderColor: "divider",
  },
};
