import {
  Dialog,
  DialogTitle,
  Typography,
  Stack,
  IconButton,
  DialogContent,
  Divider,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { useMap } from "@vis.gl/react-google-maps";

import PinsList from "../PinsList";

import { styles } from "./styles";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { PLACES_TYPE, REPORTPINS_TYPE, ITempPin } from "@/utils/poi";
import { setTempPin, setMyPinsDrawer } from "@/store/slices/reportpins";
import useSearch from "@/hooks/useSearch";
import { setShowSearchArea, addSelectedPoi } from "@/store/slices/search";

interface IPinsModal {
  isOpen: boolean;
  title: string;
  quickpin?: any;
  pinsType: "places" | "reportpins" | "both";
  currentPinType?: ITempPin | undefined;
  onClose: () => void;
}

function PinsModal({ isOpen, title, pinsType, currentPinType, quickpin, onClose }: IPinsModal) {
  const map = useMap();
  const dispatch = useAppDispatch();
  const { tempPin, myPinsDrawer } = useAppSelector((state) => state.reportpins);
  const { poiList, selectedCategories, showSearchArea, selectedPoi } = useAppSelector(
    (state) => state.search,
  );
  const { setCleanPoiList, setCleanPoiCategory } = useSearch();

  const handleAddPin = (item: ITempPin) => {
    if (quickpin) {
      dispatch(
        setTempPin({
          ...item,
          title: quickpin.name,
          quickpinId: quickpin.id,
          location: { lat: quickpin.lat, lng: quickpin.lng },
        }),
      );
      if (selectedPoi) dispatch(addSelectedPoi(null));
      if (myPinsDrawer?.open) dispatch(setMyPinsDrawer({ open: false, type: "places" }));
    } else {
      const center = map && map.getCenter();

      dispatch(
        setTempPin({
          ...tempPin,
          ...item,
          editPinInfo: tempPin?.editPinInfo?.place_type
            ? { ...tempPin.editPinInfo, place_type: item.value }
            : tempPin?.editPinInfo?.kind
            ? { ...tempPin.editPinInfo, kind: item.value }
            : undefined,
          location: { lat: center?.lat(), lng: center?.lng() },
        }),
      );
      if (poiList) setCleanPoiList();
      if (selectedCategories) setCleanPoiCategory();
      if (showSearchArea) dispatch(setShowSearchArea(false));
    }

    onClose();
  };

  return (
    <Dialog maxWidth="sm" open={isOpen} onClose={onClose}>
      <DialogTitle sx={styles.dialogTitle}>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack alignItems="center" direction="row">
            <Typography variant="h6">{title}</Typography>
          </Stack>
          <Stack>
            <IconButton onClick={onClose}>
              <CloseOutlined sx={{ fontSize: 22 }} />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        {currentPinType && (
          <Stack pb={1} px={3}>
            <Typography gutterBottom variant="subtitle1">
              Current Type
            </Typography>
            <PinsList
              iconUrl={pinsType === "places" ? `/pois/private` : `/reportpins/private`}
              items={[currentPinType]}
              onAddPin={handleAddPin}
            />
          </Stack>
        )}
        {currentPinType && <Divider sx={{ mt: 1, mb: 2 }} />}
        {(pinsType === "reportpins" || pinsType === "both") && (
          <Stack pb={currentPinType ? 3 : 2} pt={0} px={3}>
            <Typography gutterBottom variant="subtitle1">
              {currentPinType ? "Choose a new type of Report Pin:" : "Report Pins"}
            </Typography>
            <PinsList
              iconUrl="/reportpins/private"
              items={REPORTPINS_TYPE}
              onAddPin={handleAddPin}
            />
          </Stack>
        )}
        {pinsType === "both" && <Divider sx={{ mt: 1, mb: 2 }} />}
        {(pinsType === "places" || pinsType === "both") && (
          <Stack pb={3} pt={0} px={3}>
            <Typography gutterBottom variant="subtitle1">
              {currentPinType ? "Choose a new type of Place Pin:" : "Place Pins"}
            </Typography>
            <PinsList iconUrl="/pois/private" items={PLACES_TYPE} onAddPin={handleAddPin} />
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default PinsModal;
