import { layoutStyles } from "@/themes/layoutStyles";

export const styles = {
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dialogActions: {
    px: 3,
    py: 2,
  },

  searchBox: {
    position: "relative",
  },

  paper: {
    position: "absolute",
    top: "100%",
    left: 0,
    zIndex: 9999,
    width: "100%",
    overflow: "hidden",
  },

  selectedBox: {
    overflow: "hidden",
    borderRadius: 1,
    backgroundColor: "grey.50",
    border: "1px solid",
    borderColor: "divider",
  },

  scrollContainer: {
    ...layoutStyles.scroll,

    overflowY: "auto",
    maxHeight: "210px",
  },
};
