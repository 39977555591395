export const styles = {
  dialogActions: {
    px: 3,
    py: 2,
  },

  card: {
    border: "1px solid",
    borderColor: "divider",
    borderRadius: 1,
    minWidth: "400px",
    overflow: "hidden",
  },

  list: {
    "& .MuiDivider-root:last-of-type": {
      display: "none",
    },
  },

  formGroup: {
    width: "100%",
    "& .MuiFormControlLabel-root": {
      flex: 1,
    },
  },
};
