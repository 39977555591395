import { Stack, Skeleton } from "@mui/material";

interface Props {
  numberOfItems?: number;
}

function SkeletonListItem() {
  return (
    <Stack
      alignItems="flex-start"
      className="skeletonListItem"
      direction="row"
      px={2}
      py={1.5}
      spacing={2}
      sx={{ width: "100%", borderBottom: "1px solid", borderColor: "divider" }}
    >
      <Stack spacing={0.5} sx={{ width: "100%" }}>
        <Skeleton animation="wave" sx={{ fontSize: "12px" }} variant="text" width="20%" />
        <Skeleton animation="wave" sx={{ fontSize: "16px" }} variant="text" width="80%" />
        <Skeleton animation="wave" sx={{ fontSize: "12px" }} variant="text" width="40%" />
        <Skeleton animation="wave" sx={{ fontSize: "12px" }} variant="text" width="20%" />
      </Stack>
      <Skeleton
        animation="wave"
        height={24}
        style={{ flexShrink: 0 }}
        variant="circular"
        width={24}
      />
    </Stack>
  );
}

export default function CaptainsLogListSkeleton({ numberOfItems = 10 }: Props) {
  return (
    <Stack
      spacing={0}
      sx={{
        width: "100%",
        "& .skeletonListItem:last-of-type": { borderBottom: "none" },
        height: "calc(100vh - 182px)",
        overflowY: "hidden",
      }}
    >
      {[...Array(numberOfItems)].map((o, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <SkeletonListItem key={i} />
      ))}
    </Stack>
  );
}
