import axios from "./config";

import { ISearchUsersServiceParams } from "@/types/friends";

export default {
  searchUsers: (params: ISearchUsersServiceParams) =>
    axios.request({
      method: "get",
      url: `api/v1/search_users`,
      params,
    }),
  searchUsersById: (id: number) =>
    axios.request({
      method: "get",
      url: `api/v1/search_users/${id}`,
    }),
};
