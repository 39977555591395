import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";

export const styles = {
  favoriteCheckbox: {
    color: "primary.main",
    backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.08)}`,
    "& .MuiCircularProgress-root": {
      color: `${alpha(defaultTheme.palette.primary.main, 0.3)}`,
    },
  },

  circularProgressSmall: {
    m: "1px",
  },

  circularProgress: {
    m: "1px",
  },

  markerContainer: {
    // border: "1px solid red",
    pt: 0.5,
    pr: 3,
    pl: 3,
  },

  markerContainerSelected: {
    p: 0,
    position: "relative",
    bottom: "32px",
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
    },
    "&:before": {
      content: "''",
      width: "48px",
      height: "74px",
      top: -8,
      left: -6,
      position: "absolute",
      zIndex: -1,
      backgroundImage: `url(${"/img/markers/common/markerSelectedBg.svg"})`,
    },

    "& .BridgeClearanceCaption": {
      position: "absolute",
      top: 10,
      left: 7,
      fontSize: "10px",
      color: "text.primary",
      fontFamily: "sans-serif",
      fontWeight: "bold",
      width: "21px",
      height: "13px",
      textAlign: "center",
    },
  },

  popoverWrapper: {
    position: "relative",
    zIndex: 999999,
  },

  popoverContainer: {
    position: "absolute",
  },

  popOverTop: {
    top: -43,
    left: -130,
  },

  popOverBottom: {
    top: -2,
    left: -130,
  },

  popOverTopLeft: {
    top: 0,
    left: -340,
  },

  popOverTopRight: {
    top: 0,
    left: 64,
  },

  popOverBottomLeft: {
    top: -45,
    left: -340,
  },

  popOverBottomRight: {
    top: -45,
    left: 64,
  },
};
