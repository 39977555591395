import { Props } from "@/types/users";

export const urlFromObject = (obj: Props) =>
  Object.keys(obj)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join("&");

// Milliseconds before ENC overlay map is disabled.
// WMS slow tiles make the whole map slow, so we have to disable the overlay
// if having a poor service response. default 2 secs.
export const NOAA_ENC_POOR_SERVICE_LIMIT_TIME = 2000;

// Inteval time for pinging ENC. default 10 secs.
export const NOAA_ENC_POOR_SERVICE_PING = 10000;

// Number of requests saved to keep track of service quality.
export const NOAA_ENC_POOR_SERVICE_SAMPLE_SIZE = 10;

// Number of packages that can fail before we alert the user.
export const NOAA_ENC_POOR_SERVICE_FAILS_LIMIT = 4;

export const ENC_UNIT = {
  Meters: 1,
  Feet: 2,
};

export const ENC_LAYERS = {
  DepthOnly: "show:2",
  FullMap: "show:0,1,2,3,4,5,6,7",
};

// The user will enter the address to the public WMS layer here.  The data must be in WGS84
export const ENC_URL_BASE =
  "https://noaa.argonav.io/arcgis/rest/services/MCS/ENCOnline/MapServer/exts/MaritimeChartService/MapServer/export";
// var format = "image/png"; //type of image returned  or image/jpeg
// The layer ID.  Can be found when using the layers properties tool in ArcMap
// var layers = "1,2,4,5,7,8,10,11,13,14,17,18,20,21,24,25,27,28,31,32,34,35,38,39,41,42";
// var srs = "EPSG:4326"; //projection to display. This is the projection of google map. Don't change unless you know what you are doing.

/**
 * ENC Tiles Display Parameters.
 * @param {number} unitValue ENC number for unit type, defined by ENC_UNIT.
 * @returns Object with all display parameters.
 */
export const displayParams = (unitValue: number) => ({
  ECDISParameters: {
    version: "10.6.1 P5",
    DynamicParameters: {
      Parameter: [
        { name: "AreaSymbolizationType", value: 2 },
        { name: "AttDesc", value: 1 },
        { name: "ColorScheme", value: 0 },
        { name: "CompassRose", value: 1 },
        { name: "DataQuality", value: 1 },
        { name: "DateDependencyRange", value: "" },
        { name: "DateDependencySymbols", value: 1 },
        { name: "DeepContour", value: 30 },
        { name: "DisplayAIOFeatures", value: "1,2,3,4,5,6,7" },
        { name: "DisplayCategory", value: "1,2,4" },
        { name: "DisplayDepthUnits", value: unitValue },
        { name: "DisplayFrames", value: 2 },
        { name: "DisplayFrameText", value: 0 },
        { name: "DisplayFrameTextPlacement", value: 1 },
        { name: "DisplayLightSectors", value: 2 },
        { name: "DisplayNOBJNM", value: 2 },
        { name: "DisplaySafeSoundings", value: 2 },
        { name: "HonorScamin", value: 2 },
        { name: "IntendedUsage", value: "0" },
        { name: "IsolatedDangers", value: 1 },
        { name: "IsolatedDangersOff", value: 1 },
        { name: "LabelContours", value: 1 },
        { name: "LabelSafetyContours", value: 1 },
        { name: "OptionalDeepSoundings", value: 1 },
        { name: "PointSymbolizationType", value: 2 },
        { name: "RemoveDuplicateText", value: 2 },
        { name: "SafetyContour", value: 30 },
        { name: "SafetyDepth", value: 30 },
        { name: "ShallowContour", value: 2 },
        { name: "TextHalo", value: 2 },
        { name: "TwoDepthShades", value: 1 },
      ],
      ParameterGroup: [
        {
          name: "AreaSymbolSize",
          Parameter: [
            { name: "scaleFactor", value: 1 },
            { name: "minZoom", value: 0.1 },
            { name: "maxZoom", value: 1.2 },
          ],
        },
        {
          name: "DatasetDisplayRange",
          Parameter: [
            { name: "minZoom", value: 0.1 },
            { name: "maxZoom", value: 1.2 },
          ],
        },
        {
          name: "LineSymbolSize",
          Parameter: [
            { name: "scaleFactor", value: 1 },
            { name: "minZoom", value: 0.1 },
            { name: "maxZoom", value: 1.2 },
          ],
        },
        {
          name: "PointSymbolSize",
          Parameter: [
            { name: "scaleFactor", value: 1 },
            { name: "minZoom", value: 0.1 },
            { name: "maxZoom", value: 1.2 },
          ],
        },
        {
          name: "TextGroups",
          Parameter: [
            { name: "11", value: 2 },
            { name: "21", value: 2 },
            { name: "23", value: 2 },
            { name: "24", value: 2 },
            { name: "25", value: 2 },
            { name: "26", value: 2 },
            { name: "27", value: 2 },
            { name: "28", value: 2 },
            { name: "29", value: 2 },
            { name: "30", value: 2 },
          ],
        },
        {
          name: "TextSize",
          Parameter: [
            { name: "scaleFactor", value: 1.2 },
            { name: "minZoom", value: 0.9 },
            { name: "maxZoom", value: 1.2 },
          ],
        },
      ],
    },
  },
});

const ENCODE_PARAMS = {
  dpi: "192",
  transparent: "true",
  format: "jpeg",
  frames_on: "true",
  f: "image",
  bboxSR: '{"wkid":102100}',
  size: "512,512",
};

/**
 *
 * @returns (string) URL for fetching ENC tiles.
 */
export const createEncUrl = (unit: keyof typeof ENC_UNIT = "Feet", layers = ENC_LAYERS.FullMap) => {
  const encodedParams = urlFromObject({
    ...ENCODE_PARAMS,
    layers,
  });
  const url = `${ENC_URL_BASE}?display_params=${JSON.stringify(
    displayParams(ENC_UNIT[unit]),
  )}&${encodedParams}`;

  return url;
};

export const createEncUrl_old = (bboxParams: string, layers = ENC_LAYERS.FullMap) => {
  const encodedParams = urlFromObject({
    ...ENCODE_PARAMS,
    display_params: JSON.stringify(displayParams(ENC_UNIT.Feet)),
    layers,
  });
  const url = `${ENC_URL_BASE}?${bboxParams}&${encodedParams}`;

  return url;
};

export const createRncUrl = () =>
  "https://tileservice.charts.noaa.gov/tiles/50000_1/{z}/{x}/{y}.png";
