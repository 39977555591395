import {
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import React, { useState } from "react";

interface IPasswordInput {
  label: string;
  name: string;
  formikData: any;
}

function PasswordInput({ label, name, formikData }: IPasswordInput) {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormControl
      error={formikData.touched[name] && Boolean(formikData.errors[name])}
      variant="outlined"
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <OutlinedInput
        fullWidth
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        id={name}
        label={label}
        name={name}
        type={showPassword ? "text" : "password"}
        onChange={formikData.handleChange}
      />
      <FormHelperText id="password-text">
        {formikData.touched[name] && formikData.errors[name]}
      </FormHelperText>
    </FormControl>
  );
}

export default PasswordInput;
