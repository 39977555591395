export const styles = {
  listItemButton: {
    flexDirection: "column",
    width: "100%",
    p: 0,
  },

  listItemHeader: {
    px: 2,
    pt: 2,
    width: "100%",
  },

  listItemDescription: {
    px: 2,
    pb: 2,
    width: "100%",
  },

  listItem: {
    "& .MuiListItemSecondaryAction-root": {
      top: 12,
      right: 12,
      transform: "unset",
    },
  },

  favoriteCheckbox: {
    p: 0.75,
    "& .MuiSvgIcon-root": { fontSize: 16 },
  },
};
