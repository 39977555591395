import {
  Stack,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  List,
  Switch,
  ListItem,
  ListItemText,
  Box,
  Alert,
} from "@mui/material";
import { useFormik } from "formik";

import { styles } from "./styles";
import EditFuelListItem from "./EditFuelListItem";

import { IMapPois } from "@/types/pois";
import servicePoi from "@/services/poi";
import { useAppDispatch } from "@/hooks/useRedux";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { addSelectedPoi } from "@/store/slices/search";

export type TFuelForm = {
  gas: boolean | null | undefined;
  gas_octane_87: boolean | null | undefined;
  gas_octane_87_value: string | null;
  gas_octane_89: boolean | null | undefined;
  gas_octane_89_value: string | null;
  gas_octane_93: boolean | null | undefined;
  gas_octane_93_value: string | null;
  gas_octane_94_plus: boolean | null | undefined;
  gas_octane_94_plus_value: string | null;
  diesel: boolean | null | undefined;
  diesel_value: string | null;
  gas_non_ethanol: boolean | null | undefined;
};

interface IEditFuelModal {
  onClose: () => void;
  poiSelected: IMapPois;
}

const GasTypes = [
  {
    label: "87 Octane",
    valueName: "gas_octane_87",
  },
  {
    label: "89 Octane",
    valueName: "gas_octane_89",
  },
  {
    label: "93 Octane",
    valueName: "gas_octane_93",
  },
  {
    label: "94+ Octane",
    valueName: "gas_octane_94_plus",
  },
];

function EditFuelModal({ onClose, poiSelected }: IEditFuelModal) {
  const dispatch = useAppDispatch();

  const handleForm = async (values: TFuelForm) => {
    if (poiSelected.boaters_can_update_prices) {
      try {
        const { status, data } = await servicePoi.updatePoiInfo(poiSelected?.id, values);

        if (status === 200) {
          dispatch(addSelectedPoi(data.data));
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Fuel was edited.",
            }),
          );
          onClose();
        }
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      gas: poiSelected?.gas,
      gas_octane_87: poiSelected?.gas_octane_87,
      gas_octane_87_value: poiSelected?.gas_octane_87_value,
      gas_octane_89: poiSelected?.gas_octane_89,
      gas_octane_89_value: poiSelected?.gas_octane_89_value,
      gas_octane_93: poiSelected?.gas_octane_93,
      gas_octane_93_value: poiSelected?.gas_octane_93_value,
      gas_octane_94_plus: poiSelected?.gas_octane_94_plus,
      gas_octane_94_plus_value: poiSelected?.gas_octane_94_plus_value,
      diesel: poiSelected?.diesel,
      diesel_value: poiSelected?.diesel_value,
      gas_non_ethanol: poiSelected?.gas_non_ethanol,
    } as TFuelForm,
    onSubmit: handleForm,
  });

  const handleGasValues = () => {
    formik.setFieldValue("gas", !formik.values.gas);

    if (formik.values.gas) {
      formik.setFieldValue("gas_octane_87", false);
      formik.setFieldValue("gas_octane_89", false);
      formik.setFieldValue("gas_octane_93", false);
      formik.setFieldValue("gas_octane_94_plus", false);
    }
  };

  return (
    <>
      {!poiSelected.boaters_can_update_prices && (
        <Box sx={{ pl: 3, pr: 3, mb: 2 }}>
          <Alert severity="error">
            Editing fuel data has been disabled by the facility owner.{" "}
          </Alert>
        </Box>
      )}
      <Box
        component="form"
        sx={!poiSelected.boaters_can_update_prices ? { opacity: 0.6, pointerEvents: "none" } : null}
        onSubmit={formik.handleSubmit}
      >
        <DialogContent sx={{ py: 0 }}>
          <Stack spacing={2}>
            <Stack mb={2} sx={styles.card}>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                sx={styles.cardHeaderGas}
              >
                <Typography color="primary.main" variant="subtitle1">
                  Gas
                </Typography>
                <Switch
                  checked={!!formik.values.gas}
                  color="secondary"
                  edge="end"
                  size="small"
                  onChange={handleGasValues}
                />
              </Stack>
              <List disablePadding sx={styles.list}>
                {GasTypes.map((item) => (
                  <EditFuelListItem
                    key={item.label}
                    label={item.label}
                    valueName={item.valueName}
                    values={formik.values}
                    onChangeValue={formik.setFieldValue}
                  />
                ))}
              </List>
            </Stack>
            <Stack mb={2} sx={styles.card}>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                sx={styles.cardHeaderDiesel}
              >
                <Typography color="secondary.main" variant="subtitle1">
                  Diesel
                </Typography>
                <Switch
                  checked={!!formik.values.diesel}
                  color="secondary"
                  id="diesel"
                  name="diesel"
                  size="small"
                  onChange={() => formik.setFieldValue("diesel", !formik.values.diesel)}
                />
              </Stack>
              <List disablePadding sx={styles.list}>
                <EditFuelListItem
                  label="Diesel"
                  valueName="diesel"
                  values={formik.values}
                  onChangeValue={formik.setFieldValue}
                />
              </List>
            </Stack>
            <ListItem disablePadding component="div">
              <ListItemText id="switch-list-label-wifi" primary="Non-Ethanol Fuel Available" />
              <Switch
                checked={!!formik.values.gas_non_ethanol}
                color="secondary"
                id="gas_non_ethanol"
                name="gas_non_ethanol"
                size="small"
                onChange={() =>
                  formik.setFieldValue("gas_non_ethanol", !formik.values.gas_non_ethanol)
                }
              />
            </ListItem>
          </Stack>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Box>
    </>
  );
}

export default EditFuelModal;
