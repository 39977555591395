import { Fab } from "@mui/material";
import { Warning } from "@mui/icons-material";
import { useEffect, useState } from "react";

import { styles } from "./styles";

import BaseModal from "@/components/Modals/BaseModal/BaseModal";
import useDisclosure from "@/hooks/useDisclosure";
import RouteWarningModal from "@/components/Modals/RouteWarningModal";
import { useAppSelector } from "@/hooks/useRedux";
import { KEY_RWMODAL_USER } from "@/utils/keys";

function RouteWarning() {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { user, logged } = useAppSelector((state) => state.auth);
  const [firstOpen, setFirstOpen] = useState<boolean>(false);

  useEffect(() => {
    if (logged && user) {
      const rwModalData = localStorage.getItem(KEY_RWMODAL_USER + user.email);

      if (!rwModalData) {
        setFirstOpen(true);
        onOpen();
      }
    }
  }, []);

  const handleSetDontShowAgain = () => {
    const modalKey = logged && user ? KEY_RWMODAL_USER + user.email : "nouser";

    localStorage.setItem(modalKey, "true");
  };

  return (
    <>
      <Fab
        size="small"
        sx={styles.fabButton}
        onClick={() => {
          if (firstOpen) setFirstOpen(false);
          onOpen();
        }}
      >
        <Warning />
      </Fab>
      {isOpen && (
        <BaseModal
          ariaDescribedby="No Depth Data"
          ariaLabelledby="No Depth Data"
          icon={<Warning sx={{ color: "grey.600" }} />}
          open={isOpen}
          size="xs"
          title="No Depth Data"
          onClose={onClose}
        >
          <RouteWarningModal
            firstOpen={firstOpen}
            onClose={onClose}
            onDontShowAgain={handleSetDontShowAgain}
          />
        </BaseModal>
      )}
    </>
  );
}

export default RouteWarning;
