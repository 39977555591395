import { alpha } from "@mui/material/styles";

import { layoutStyles } from "@/themes/layoutStyles";
import defaultTheme from "@/themes/theme";

export const styles = {
  mainStack: {
    width: "100%",
    height: "calc(100vh - 210px)",
  },

  buttonContainer: {
    py: 3,
  },

  input: {
    width: "100%",
    mx: 1.5,
    "& .MuiSvgIcon-root": {
      mr: 1,
    },
    "&:after, :before": {
      display: "none",
    },
    "&.Mui-focused": {
      color: "primary.main",
    },
  },

  searchBox: {
    px: 0.5,
    height: "53px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },

  paper: {
    width: "100%",
    overflow: "hidden",
    display: "relative",
  },

  list: {
    "& ul": {
      ...layoutStyles.scroll,

      overflowY: "auto",
      maxHeight: "calc(100vh - 245px)",
    },
    // ...layoutStyles.scroll,

    // overflowY: "auto",
    // maxHeight: "calc(100vh - 110px)",

    "& .MuiDivider-root:last-of-type": {
      display: "none",
    },

    // "& .MuiList-root": {
    //   pb: 8,
    // },
  },

  tableCell: {
    px: 0,
    py: 1,
  },

  inactiveChip: {
    fontWeight: 700,
  },

  chip: {
    fontWeight: 700,
    color: "primary.main",
    backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.08)}`,

    "& .MuiSvgIcon-root": {
      fontSize: "18px",
      color: `${alpha(defaultTheme.palette.primary.main, 0.7)}`,
    },

    "&:hover": {
      "& .MuiSvgIcon-root": {
        color: "primary.main",
      },
    },
  },
};
