/* global GeolocationPosition */
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  FormControl,
  Grow,
  InputAdornment,
  OutlinedInput,
  Box,
  List,
  Paper,
  CircularProgress,
} from "@mui/material";
import { Close, Search, CloseOutlined } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { useMap } from "@vis.gl/react-google-maps";

import { styles } from "./styles";

import useDebounce from "@/hooks/useDebounce";
import ServiceMap from "@/services/map";
import { IMapPois } from "@/types/pois";
import SearchResultListItem from "@/components/Search/SearchResultListItem/SearchResultListItem";
import { Position } from "@/types/map";
import { findPoi, getDistanceFromLocation } from "@/utils/poi";
import { getPosition } from "@/utils/maps";
import useDisclosure from "@/hooks/useDisclosure";

function EditHomePortModal({ open, close, onSetHomePort }: any) {
  const map = useMap();
  const [options, setOptions] = useState<[] | IMapPois[]>([]);
  const [value, setValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const debouncedValue = useDebounce(value, 1000);
  const [position, setPosition] = useState<Position | null>(null);
  const { onOpen, isOpen, onClose } = useDisclosure();

  const showPosition = (pos: GeolocationPosition) => {
    setPosition({
      lat: pos.coords.latitude,
      lng: pos.coords.longitude,
    });
  };

  const handleCleanInput = () => {
    setValue("");
    setOptions([]);
    onClose();
  };

  const handleGetPois = async () => {
    try {
      setLoading(true);
      const center = map && map.getCenter();
      const dataRequest = {
        page_total: 20,
        page: 1,
        location: "anywhere",
        distance: 3000,
        keyword: debouncedValue,
        lat: position?.lat || center?.lat(),
        lng: position?.lng || center?.lng(),
      };
      const { status, data } = await ServiceMap.searchMapPois(dataRequest);

      if (status === 200) {
        setOptions(data);
      }
      setLoading(false);
    } catch (error: any) {
      alert(error.toString());
      setLoading(false);
    }
  };

  useEffect(() => {
    if (debouncedValue !== "") {
      handleGetPois();
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (!position) {
      getPosition(showPosition);
    }
  }, [options, position]);

  const handleInputChange = (event: any) => {
    setValue(event.target.value);
  };

  const handleCloseModal = () => {
    close();
  };

  const handleSetHomePort = (newValue: IMapPois) => {
    if (newValue.lat && newValue.lng) {
      onSetHomePort("port_name", newValue.name);
      onSetHomePort("port", newValue.address);
      onSetHomePort("lat", newValue.lat);
      onSetHomePort("lng", newValue.lng);
    }
    close();
  };

  return (
    <Dialog
      PaperProps={{
        sx: { overflow: "unset" },
      }}
      aria-describedby="edit-boat-dialog-description"
      aria-labelledby="edit-boat-dialog-title"
      maxWidth="xs"
      open={open}
      scroll="body"
    >
      <DialogTitle id="alert-dialog-title" sx={styles.dialogTitle}>
        Add Home Port
        <IconButton size="small" onClick={handleCloseModal}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ overflow: "unset", pb: 3 }}>
        <Stack spacing={3}>
          <Typography variant="body1">
            Add your home port to make it easier to route to/from this location.
          </Typography>
          <Box sx={styles.searchBox}>
            <FormControl fullWidth>
              <OutlinedInput
                endAdornment={
                  <>
                    {loading && (
                      <InputAdornment position="end">
                        <CircularProgress size={20} />
                      </InputAdornment>
                    )}
                    {!loading && options.length > 0 && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="directions"
                          color="primary"
                          onClick={handleCleanInput}
                        >
                          <CloseOutlined />
                        </IconButton>
                      </InputAdornment>
                    )}
                  </>
                }
                id="search"
                placeholder="Search"
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
                value={value || ""}
                onChange={handleInputChange}
                onKeyDown={onOpen}
              />
            </FormControl>
            <Grow unmountOnExit in={isOpen} style={{ transformOrigin: "top left" }} timeout="auto">
              <Paper elevation={4} sx={styles.paper}>
                <Box sx={{ ...styles.scrollContainer, maxHeight: "280px" }}>
                  <List disablePadding>
                    {options.map((item, index) => (
                      <SearchResultListItem
                        key={item.id}
                        action={() => handleSetHomePort(item)}
                        distance={
                          position
                            ? getDistanceFromLocation(position, {
                                lat: Number(item.lat),
                                lng: Number(item.lng),
                              })
                            : undefined
                        }
                        icon={findPoi(item.poi_type)}
                        numberList={index}
                        primaryLabel={item.name}
                        secondaryLabel={item.address || `${item.lat}, ${item.lng}`}
                      />
                    ))}
                  </List>
                </Box>
              </Paper>
            </Grow>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default EditHomePortModal;
