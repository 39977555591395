import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";

export const styles = {
  closeButton: {
    position: "absolute",
    right: "4px",
    top: "4px",
  },

  image: {
    flex: 1,
    display: "center",
    justifyContent: "center",
    pt: 1,
    "& img": {
      width: "48px",
    },
  },

  textContainer: {
    flex: 4,
  },

  alertMarker: {
    width: 48,
    height: 48,
  },

  containerTop: {
    zIndex: 30,
    position: "absolute",
    top: "-283px",
    left: "-108px",
    paddingBottom: 4.5,
    boxSizing: "border-box",
  },

  containerTopLeft: {
    zIndex: 30,
    position: "absolute",
    top: "-283px",
    left: "-212px",
    paddingBottom: 4.5, // Don't delete this css property, works to the close functionality
    boxSizing: "border-box",
  },

  containerTopRight: {
    zIndex: 30,
    position: "absolute",
    top: "-283px",
    left: "0px",
    paddingBottom: 4.5, // Don't delete this css property, works to the close functionality
    boxSizing: "border-box",
  },

  containerBottom: {
    zIndex: 30,
    position: "absolute",
    top: "0px",
    left: "-108px",
    paddingTop: 4.5, // Don't delete this css property, works to the close functionality
    boxSizing: "border-box",
  },

  containerBottomLeft: {
    zIndex: 30,
    position: "absolute",
    top: "0px",
    left: "-212px",
    paddingTop: 4.5, // Don't delete this css property, works to the close functionality
    boxSizing: "border-box",
  },

  containerBottomRight: {
    zIndex: 30,
    position: "absolute",
    top: "0px",
    left: "0px",
    paddingTop: 4.5, // Don't delete this css property, works to the close functionality
    boxSizing: "border-box",
  },

  popoverContainer: {
    width: "360px",
    // border: "1px solid green",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },

  paper: {
    zIndex: 20,
    width: "240px",
    overflow: "hidden",
  },

  paperToTop: {
    width: "240px",
    overflow: "hidden",
    cursor: "pointer",
    bottom: 0,
    transformOrigin: "top",
    position: "absolute",
  },

  // paper: {
  //   width: 240,
  //   zIndex: 20,
  //   height: "180px",
  //   overflow: "hidden",
  //   // minHeight: "17.188rem",
  //   maxHeight: "17.188rem",
  // },

  iconButton: {
    p: 0,
    "& .MuiSvgIcon-root": {
      filter: "drop-shadow(0px 1px 1px rgb(0 0 0 / 0.3))",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  coverImage: {
    position: "relative",
    minHeight: 100,
    backgroundSize: "cover",
    backgroundPosition: "center",
    "&:before": {
      content: "''",
      width: "100%",
      height: "100%",
      top: 0,
      position: "absolute",
      background: `${alpha(defaultTheme.palette.common.black, 0.3)}`,
    },
  },

  header: {
    px: 2,
    py: 1.5,
  },

  title: {
    width: "208px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  infoContainer: {
    mt: "-48px",
    px: 2,
    pb: 2,
  },

  avatar: {
    border: "4px solid",
    borderColor: "background.paper",
    boxShadow: 12,
    width: 84,
    height: 84,
  },

  avatarCommonAffiliation: {
    border: "4px solid",
    borderColor: "secondary.main",
    boxShadow: 12,
    width: 84,
    height: 84,
  },
};
