/* global GeolocationPosition */
import { useState, useEffect } from "react";

import { Position } from "@/types/map";

function useGeoLocation() {
  const [location, setLocation] = useState<Position | null>(null);
  const [error, setError] = useState<string | null>(null);

  const refresh = () => {
    setLocation(null);
    setError(null);
  };

  const getPosition = () => {
    if (!navigator.geolocation) {
      setError("Geolocation is not supported by this browser.");

      return;
    }

    function handleSuccess(position: GeolocationPosition) {
      const { latitude, longitude } = position.coords;

      setLocation({ lat: Number(latitude), lng: Number(longitude) });
    }

    function handleError(error: any) {
      setError(error.message);
    }

    navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
  };

  useEffect(() => {
    getPosition();
  }, []);

  return { location, error, refresh, getPosition };
}

export default useGeoLocation;
