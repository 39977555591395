/* global GeolocationPosition */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  FormControl,
  Grow,
  InputAdornment,
  OutlinedInput,
  Box,
  List,
  ListItem,
  Paper,
  CircularProgress,
} from "@mui/material";
import { Close, DeleteOutline, Search, CloseOutlined } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { useMap } from "@vis.gl/react-google-maps";

import useDisclosure from "../../../hooks/useDisclosure";

import { styles } from "./styles";

import useDebounce from "@/hooks/useDebounce";
import ServiceUser from "@/services/user";
import { Affiliation } from "@/types/affiliation";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { fetchAffiliationsSucceded } from "@/store/slices/user";
import SearchResultListItem from "@/components/Search/SearchResultListItem/SearchResultListItem";
import { Position } from "@/types/map";
import { findPoi, getDistanceFromLocation } from "@/utils/poi";
import { getPosition } from "@/utils/maps";
import EmptyState from "@/components/EmptyState";

export default function EditAffiliationsModal({ open, close }: any) {
  const dispatch = useAppDispatch();
  const map = useMap();
  const { affiliations } = useAppSelector((state) => state.user);

  const [options, setOptions] = useState<[] | Affiliation[]>([]);
  const [affiliationsCopy, setAffiliationsCopy] = useState<[] | Affiliation[]>([]);
  const [value, setValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const debouncedValue = useDebounce(value, 1000);
  const [position, setPosition] = useState<Position | null>(null);
  const { onOpen, isOpen, onClose } = useDisclosure();

  const showPosition = (pos: GeolocationPosition) => {
    setPosition({
      lat: pos.coords.latitude,
      lng: pos.coords.longitude,
    });
  };

  const handleCleanInput = () => {
    setValue("");
    setOptions([]);
    onClose();
  };

  const handleGetAffiliations = async () => {
    try {
      setLoading(true);
      const center = map && map.getCenter();

      const lat = position?.lat.toString() || center?.lat().toString() || "";
      const lng = position?.lng.toString() || center?.lng().toString() || "";
      const { status, data } = await ServiceUser.searchAffiliations(debouncedValue, lat, lng);

      if (status === 200) {
        setOptions(data);
      }
      setLoading(false);
    } catch (error: any) {
      alert(error.toString());
      setLoading(false);
    }
  };

  useEffect(() => {
    setAffiliationsCopy(affiliations);
  }, []);

  useEffect(() => {
    if (value !== "") {
      handleGetAffiliations();
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (!position) {
      getPosition(showPosition);
    }
  }, [options]);

  const handleInputChange = (event: any) => {
    setValue(event.target.value);
  };

  const handleSaveAffiliations = async () => {
    let itemsToSave: [] | Affiliation[] = [];
    let itemsToRemove: [] | number[] = [];

    try {
      affiliations.forEach((item: Affiliation) => {
        if (!affiliationsCopy.some((ui: Affiliation) => ui.id === item.id)) {
          itemsToSave = [...itemsToSave, item];
        }
      });

      affiliationsCopy.forEach((item: Affiliation) => {
        if (!affiliations.some((i: Affiliation) => i.id === item.id)) {
          itemsToRemove = [...itemsToRemove, item.id];
        }
      });

      if (itemsToRemove.length > 0) {
        await ServiceUser.removeUserAffiliations({ poi_ids: itemsToRemove });
      }
      if (itemsToSave.length > 0) {
        itemsToSave.forEach(async (item: Affiliation) => {
          await ServiceUser.addUserAffiliations(item.id);
        });
      }
      close();
    } catch (error: any) {
      alert(error.toString());
    }
  };

  const handleCloseModal = () => {
    dispatch(fetchAffiliationsSucceded(affiliationsCopy));
    close();
  };

  const handleSetAffiliations = (newValue: Affiliation) => {
    const isExistentItem = affiliations.find((i) => i.id === newValue.id);

    if (!isExistentItem) {
      dispatch(fetchAffiliationsSucceded([...affiliations, newValue]));
    }
    handleCleanInput();
  };

  const handleRemoveAffiliations = (newValue: Affiliation) => {
    const isExistentItem = affiliations.find((i) => i.id === newValue.id);

    if (isExistentItem) {
      dispatch(
        fetchAffiliationsSucceded(affiliations.filter((i: Affiliation) => i.id !== newValue.id)),
      );
    }
    handleCleanInput();
  };

  return (
    <Dialog
      PaperProps={{
        sx: { overflow: "unset" },
      }}
      aria-describedby="edit-boat-dialog-description"
      aria-labelledby="edit-boat-dialog-title"
      maxWidth="sm"
      open={open}
      scroll="body"
    >
      <DialogTitle id="alert-dialog-title" sx={styles.dialogTitle}>
        Edit Affiliations
        <IconButton size="small" onClick={handleCloseModal}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ overflow: "unset" }}>
        <Stack spacing={3}>
          <Typography variant="body1">
            Adding affiliations to your profile, such as Marinas and Yacht Clubs you belong to, is a
            great way to help friends and other Argo users connect with you.
          </Typography>
          <Box sx={styles.searchBox}>
            <Typography gutterBottom color="text.secondary" variant="subtitle2">
              Add Affiliation
            </Typography>
            <FormControl fullWidth>
              <OutlinedInput
                endAdornment={
                  <>
                    {loading && (
                      <InputAdornment position="end">
                        <CircularProgress size={20} />
                      </InputAdornment>
                    )}
                    {!loading && options.length > 0 && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="directions"
                          color="primary"
                          onClick={handleCleanInput}
                        >
                          <CloseOutlined />
                        </IconButton>
                      </InputAdornment>
                    )}
                  </>
                }
                id="search"
                placeholder="Search"
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
                value={value || ""}
                onChange={handleInputChange}
                onKeyDown={onOpen}
              />
            </FormControl>
            <Grow unmountOnExit in={isOpen} style={{ transformOrigin: "top left" }} timeout="auto">
              <Paper elevation={4} sx={styles.paper}>
                <Box sx={{ ...styles.scrollContainer, maxHeight: "280px" }}>
                  <List disablePadding>
                    {options.map((item, index) => (
                      <SearchResultListItem
                        key={item.id}
                        action={() => handleSetAffiliations(item)}
                        distance={
                          position
                            ? getDistanceFromLocation(position, {
                                lat: Number(item.lat),
                                lng: Number(item.lng),
                              })
                            : undefined
                        }
                        icon={findPoi(item.poi_type)}
                        numberList={index}
                        primaryLabel={item.name}
                        secondaryLabel={item.address || `${item.lat}, ${item.lng}`}
                      />
                    ))}
                  </List>
                </Box>
              </Paper>
            </Grow>
          </Box>
          <Box>
            <Typography gutterBottom color="text.secondary" variant="subtitle2">
              Active Affiliations
            </Typography>
            <Box sx={styles.selectedBox}>
              <Box sx={styles.scrollContainer}>
                <List disablePadding>
                  {affiliations.length <= 0 ? (
                    <ListItem disablePadding>
                      <EmptyState
                        imageURL="img/placeholders/EmptyStateNoResults.svg"
                        subtitle="It seems you haven't searched yet. Once you start, your viewing history will appear here."
                        sx={{ height: "100%", px: 3 }}
                        title="No Results"
                      />
                    </ListItem>
                  ) : (
                    affiliations.map((item, index) => (
                      <SearchResultListItem
                        key={item.id}
                        showSecondaryAction
                        distance={
                          position
                            ? getDistanceFromLocation(position, {
                                lat: Number(item.lat),
                                lng: Number(item.lng),
                              })
                            : undefined
                        }
                        icon={findPoi(item.poi_type)}
                        numberList={index}
                        primaryLabel={item.name}
                        secondaryAction={() => handleRemoveAffiliations(item)}
                        secondaryActionIcon={<DeleteOutline />}
                        secondaryLabel={item.address || `${item.lat}, ${item.lng}`}
                      />
                    ))
                  )}
                </List>
              </Box>
            </Box>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Button onClick={handleCloseModal}>Cancel</Button>
        <Button autoFocus variant="contained" onClick={handleSaveAffiliations}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
