import { Box, Typography, Chip } from "@mui/material";

interface IFuelInfoChip {
  fuelInfo: any;
}
function FuelInfoChip({ fuelInfo }: IFuelInfoChip) {
  return (
    <Box className="fuelBox">
      <Typography gutterBottom textAlign="center" variant="body2">
        {fuelInfo.value && `$${fuelInfo.value}`}
      </Typography>
      <Chip
        color={fuelInfo.label === "Diesel" ? "secondary" : "primary"}
        label={fuelInfo.label}
        size="small"
      />
    </Box>
  );
}

export default FuelInfoChip;
