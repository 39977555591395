import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import SignInForm from "./SignInForm";
import EmailSignInForm from "./EmailSignInForm";
import SignUpForm from "./SignUpForm";
import LoginLayout from "./LoginLayout";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { setSnackBarMsj } from "@/store/slices/auth/actions";
import CustomizedSnackbar from "@/components/CustomizedSnackbar";
import usePremiumUser from "@/hooks/usePremiumUser";
import {
  membership_name_yearly,
  membership_name_monthly,
} from "@/components/Premium/PremiumPaymentCard/PremiumPaymentCard";

export default function LogIn() {
  const { snackBarMsj, logged } = useAppSelector((state) => state.auth);
  const { id } = useParams();
  const [optionValue, setOptionValue] = useState(1);
  const dispatch = useAppDispatch();
  const isPremium = usePremiumUser();
  const { userInformation } = useAppSelector((state) => state.user);

  const isMonthlyOrYearly =
    userInformation?.latest_user_subscription?.membership_name === membership_name_yearly ||
    userInformation?.latest_user_subscription?.membership_name === membership_name_monthly ||
    !userInformation?.latest_user_subscription ||
    (userInformation?.latest_user_subscription &&
      !userInformation?.latest_user_subscription?.membership_activated);

  const handleSnackBarClose = () => {
    dispatch(setSnackBarMsj(false, "", ""));
  };

  useEffect(() => {
    if (logged && isPremium) {
      if (logged && isPremium && isMonthlyOrYearly) {
        window.location.href = `/premium-payment${
          id === "lifetime" ? `?type_subscription=us-lifetime` : ""
        }`;
      } else {
        window.location.href = "/";
      }
    } else if (logged && !isPremium) {
      window.location.href = `/premium-payment${
        id === "lifetime" ? `?type_subscription=us-lifetime` : ""
      }`;
    }
  }, []);

  const routeArrow = () => {
    if (optionValue === 1) {
      window.location.href = "/";
    }
    if (optionValue === 2) {
      setOptionValue(1);
    }
    if (optionValue > 2) {
      setOptionValue(2);
    }
  };

  if (logged) return null;

  return (
    <>
      <LoginLayout action={routeArrow}>
        {optionValue === 1 && <SignInForm setOptionValue={setOptionValue} />}
        {optionValue === 2 && <EmailSignInForm setOptionValue={setOptionValue} />}
        {optionValue === 3 && <SignUpForm setOptionValue={setOptionValue} />}
      </LoginLayout>
      <CustomizedSnackbar
        close={handleSnackBarClose}
        message={snackBarMsj.msj}
        open={snackBarMsj.state}
        severity={snackBarMsj.type}
      />
    </>
  );
}
