import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  Stack,
  Slider,
  Box,
  Avatar,
  Typography,
  FormHelperText,
} from "@mui/material";
import { Close, PhotoSizeSelectActual, PhotoSizeSelectLarge } from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";

import { styles } from "./styles";

import useUserInformation from "@/hooks/useUserInformation";
import { EditUserInfoPayload } from "@/types/users";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";

const imgFormatErrorMessage = "Please select a valid image file.";
const imgSizeErrorMessage = "Please upload a file smaller than 2 MB";
const size = 2e6;

const formSchema = yup.object({
  firstName: yup.string().trim().required("First Name is a required field"),
  lastName: yup.string().trim().required("Last Name is a required field"),
  username: yup
    .string()
    .trim()
    .required("Please enter a valid username.")
    .matches(
      /^[^*!"#$€£¥%&'()*+,/:;<=>?@[\]^`{|}~\\ ]+$/,
      "Username cannot contain special characters.",
    ),
});

interface IProfileForm {
  firstName: string;
  lastName: string;
  username: string;
  cover: string | File;
  avatar: string | File;
}

interface IEditProfileModal {
  open: boolean;
  close: () => void;
}

export default function EditProfileModal({ open, close }: IEditProfileModal) {
  const dispatch = useDispatch();
  const {
    userInformation,
    editUser,
    userError,
    uploadAvatar,
    uploadCover,
    destroyAvatar,
    destroyCover,
  } = useUserInformation();
  const [tempAvatar, setTempAvatar] = useState<string | null>(null);
  const [tempCover, setTempCover] = useState<string | null>(null);
  const [avatarImgErr, setAvatarImgErr] = useState<string>();
  const [coverImgErr, setCoverImgErr] = useState<string>();
  const showResize = false;

  const formik = useFormik({
    initialValues: {
      firstName: userInformation?.first_name,
      lastName: userInformation?.last_name,
      username: userInformation?.username,
      cover: userInformation?.cover || "",
      avatar: userInformation?.image,
    } as IProfileForm,
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      try {
        const payload: EditUserInfoPayload = {
          username: values.username || "",
          last_name: values.lastName || "",
          first_name: values.firstName || "",
        };

        let result = await editUser(payload);

        if (values.avatar instanceof File) {
          result = result && (await uploadAvatar(values.avatar));
        } else if (values.avatar === "") {
          result = result && (await destroyAvatar());
        }

        if (values.cover instanceof File) {
          result = result && (await uploadCover(values.cover));
        } else if (values.cover === "") {
          result = result && (await destroyCover());
        }

        if (result) {
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Your profile was edited.",
            }),
          );
          close();
        }
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      }
    },
  });

  useEffect(() => {
    // Update formik values
    formik.setValues({
      firstName: userInformation?.first_name || "",
      lastName: userInformation?.last_name || "",
      username: userInformation?.username || "",
      cover: userInformation?.cover || "",
      avatar: userInformation?.image || "",
    });
  }, [userInformation]);

  const handleCoverImg = (event: any) => {
    const imgFile = event.currentTarget.files[0];

    if (["image/jpeg", "image/png"].includes(imgFile?.type)) {
      if (imgFile.size < size) {
        const url = URL.createObjectURL(event.currentTarget.files[0]);

        formik.setFieldValue("cover", event.currentTarget.files[0]);

        setTempCover(url);
        setCoverImgErr("");
      } else {
        setCoverImgErr(imgSizeErrorMessage);
      }
    } else if (imgFile) {
      setCoverImgErr(imgFormatErrorMessage);
    }
  };

  const handleCoverDelete = () => {
    formik.setFieldValue("cover", "");
    setCoverImgErr("");
  };

  const handleAvatarImg = (event: any) => {
    const imgFile = event.currentTarget.files[0];

    if (["image/jpeg", "image/png"].includes(imgFile?.type)) {
      if (imgFile.size < size) {
        formik.setFieldValue("avatar", event.currentTarget.files[0]);
        const url = URL.createObjectURL(event.currentTarget.files[0]);

        setTempAvatar(url);
        setAvatarImgErr("");
      } else {
        setAvatarImgErr(imgSizeErrorMessage);
      }
    } else if (imgFile) {
      setAvatarImgErr(imgFormatErrorMessage);
    }
  };

  const handleAvatarDelete = () => {
    formik.setFieldValue("avatar", "");
    setTempAvatar(null);
    setAvatarImgErr("");
  };

  return (
    <Dialog
      aria-describedby="edit-dialog-description"
      aria-labelledby="edit-dialog-title"
      maxWidth="sm"
      open={open}
      onClose={close}
    >
      <DialogTitle id="alert-dialog-title" sx={styles.dialogTitle}>
        Edit Profile
        <IconButton size="small" onClick={close}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack component="form" pt={1} spacing={2} onSubmit={formik.handleSubmit}>
          <FormControl error={formik.touched.firstName && Boolean(formik.errors.firstName)}>
            <InputLabel htmlFor="first-name">First Name</InputLabel>
            <OutlinedInput
              defaultValue={formik.initialValues.firstName}
              id="firstName"
              label="First Name"
              onChange={formik.handleChange}
            />
          </FormControl>
          <FormControl error={formik.touched.lastName && Boolean(formik.errors.lastName)}>
            <InputLabel htmlFor="last-name">Last Name</InputLabel>
            <OutlinedInput
              defaultValue={formik.initialValues.lastName}
              id="lastName"
              label="Last Name"
              onChange={formik.handleChange}
            />
          </FormControl>
          <FormControl error={formik.touched.username && Boolean(formik.errors.username)}>
            <InputLabel htmlFor="user-name">User Name</InputLabel>
            <OutlinedInput
              defaultValue={formik.initialValues.username}
              id="username"
              label="User Name"
              onChange={formik.handleChange}
            />
            <FormHelperText id="name-text" sx={{ color: "error.main" }}>
              {formik.touched.username && formik.errors.username}
            </FormHelperText>
          </FormControl>
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="body2">
              Profile Picture
            </Typography>
            {avatarImgErr && (
              <Typography color="red" variant="body2">
                {avatarImgErr}
              </Typography>
            )}
            <Box sx={styles.editGroup}>
              <Box sx={styles.editGroupContent}>
                <Avatar
                  alt={`${userInformation?.first_name || ""} ${userInformation?.last_name || ""}`}
                  src={tempAvatar || formik?.values?.avatar?.toString()}
                  sx={styles.avatar}
                />
              </Box>
              <Stack direction="row" justifyContent="space-between" sx={styles.editGroupActions}>
                {showResize && (
                  <Stack alignItems="center" direction="row" spacing={2} sx={styles.editGroupSize}>
                    <PhotoSizeSelectLarge />
                    <Slider aria-label="size" defaultValue={50} size="small" />
                    <PhotoSizeSelectActual />
                  </Stack>
                )}
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button size="small" onClick={handleAvatarDelete}>
                    Delete
                  </Button>
                  <label htmlFor="avatar">
                    <input
                      hidden
                      accept="image/*"
                      id="avatar"
                      name="avatar"
                      type="file"
                      onChange={handleAvatarImg}
                    />
                    <Button component="span" size="small" variant="outlined">
                      Add Photo
                    </Button>
                  </label>
                </Stack>
              </Stack>
            </Box>
          </Stack>
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="body2">
              Cover Picture
            </Typography>
            {coverImgErr && (
              <Typography color="red" variant="body2">
                {coverImgErr}
              </Typography>
            )}
            <Box sx={styles.editGroup}>
              <Box
                sx={{
                  ...styles.cover,
                  backgroundImage: `url(${tempCover || formik?.values?.cover})`,
                }}
              />
              <Stack direction="row" justifyContent="space-between" sx={styles.editGroupActions}>
                {showResize && (
                  <Stack alignItems="center" direction="row" spacing={2} sx={styles.editGroupSize}>
                    <PhotoSizeSelectLarge />
                    <Slider aria-label="size" size="small" />
                    <PhotoSizeSelectActual />
                  </Stack>
                )}
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button size="small" onClick={handleCoverDelete}>
                    Delete
                  </Button>
                  <label htmlFor="cover">
                    <input
                      hidden
                      accept="image/*"
                      id="cover"
                      name="cover"
                      type="file"
                      onChange={handleCoverImg}
                    />
                    <Button component="span" size="small" variant="outlined">
                      Add Photo
                    </Button>
                  </label>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
      {userError && (
        <Typography color="red" variant="body2">
          {userError}
        </Typography>
      )}
      <DialogActions sx={styles.dialogActions}>
        <Button onClick={close}>Cancel</Button>
        <Button autoFocus type="submit" variant="contained" onClick={() => formik.handleSubmit()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
