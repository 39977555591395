import { Box, CircularProgress } from "@mui/material";

import { styles } from "./styles";

function CenteredCircularProgress() {
  return (
    <Box sx={styles.container}>
      <CircularProgress />
    </Box>
  );
}

export default CenteredCircularProgress;
